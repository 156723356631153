import * as React from 'react'
import {observer} from 'mobx-react-lite'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Suggestion from "./Suggestion";




export default observer(({
                             field,
                             className,
                             InputLabelProps,
                             margin,
                             fullWidth,
                             suggestion,
                             suggestionPrefix,
                             ...rest
                         }) => {
    const applySuggestion = ()=>{
        field.onChange(suggestion)
    }


    return (
        <FormControl fullWidth={fullWidth} margin={margin} className={className}>
            <InputLabel error ={!suggestion && field.error} {...InputLabelProps} >{field.label}</InputLabel>
            <Input
                type={field.type || "text"}
                {...field.bind()}
                {...rest}
            />
            <FormHelperText error ={!suggestion && field.error} >
                {suggestion
                    ? ( <Suggestion suggestion={suggestion} suggestionPrefix={suggestionPrefix} onApply={applySuggestion} /> )
                    : (field.error)  }
            </FormHelperText>
        </FormControl>
    )
})