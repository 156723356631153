import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import {useHistory} from "react-router-dom";
import {joinPath} from "../../utils/helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    marginBottom:{
        marginBottom: theme.spacing(2)
    }

}));


function CompensationPlan({parentMatch}){

    const classes = useStyles();
    const {videoStore,commonStore } = useStores();
    const {t} = useTranslation("starfish");

    const showVideo  = ()=>{
        videoStore.setMedia( {
            url: commonStore.language === "fr" ? "905658713":"905658713",
            title: commonStore.language === "fr" ? "Vidéo sur le plan de rémunération":"Compensation Plan Video",
            vimeo: true } )
    }

    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/compensation_plan.png`
                    : `/img/compensation_plan.png` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={classes.textContainer} style={{width:"100%"}} display="flex" alignItems="center" flexDirection="column" >
                <Typography align={"center"} className={clsx( classes.headTextNoTopMargin)} variant="h3">{t("compensation.title_pre")}<span className={classes.linkColor}>{t("compensation.title")}</span>{t("compensation.title_post")}</Typography>
                <Typography className={clsx( classes.mainText, classes.marginBottom )} variant="body1">{t("compensation.description")}</Typography>
                <Typography className={clsx( classes.mainText )}  variant="h6">{t("compensation.welcome")}</Typography>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={clsx( classes.textContainer)} display="flex" justifyContent="center"  flexWrap={"wrap"} style={{ gap:10}} >
                <Button variant="contained" color="primary" download target="_blank" href="https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_compensationplan_en.pdf?alt=media&token=6e39010f-9e6c-4ad7-865d-91af4b8e8f9f" >{t("compensation.us")}</Button>
                <Button variant="contained" color="primary" download target="_blank" href="https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fca_compensationplan_fr.pdf?alt=media&token=ee9d6469-4417-4f02-bb70-8434bdc94a8e">{t("compensation.ca-fr")}</Button>
                <Button variant="contained" color="primary" download target="_blank" href="https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fca_compensationplan_en.pdf?alt=media&token=ad946429-c130-471d-bf28-6dc16298aba5" >{t("compensation.ca-en")}</Button>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={clsx( classes.textContainer)}
                 display="flex" flexDirection="column"  alignItems={"center"}
                 justifyContent="center"  flexWrap={"wrap"} style={{ gap:10}} >
                <Typography className={clsx( classes.mainText, classes.marginBottom )} variant="body1">{t("compensation.video-description")}</Typography>
                <Button variant="contained" color="primary" onClick={showVideo}>{t("compensation.video-btn")}</Button>
            </Box>
        </section>
        <section >
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default CompensationPlan