import RestService from "./RestService";

export default class IncomeAllocationService extends RestService {
  constructor() {
    super("/api/incomeallocation");
  }

  listLT() {
    return this.get(`${this.baseUrl}/lt`);
  }

  createLT(entity, options) {
    return this.post(`${this.baseUrl}/lt`, entity, options);
  }

  updateLT(entity, id) {
    return this.put(`${this.baseUrl}/lt/${this.getId(entity, id)}`, entity);
  }

  deleteLT(entityId, params) {
    return this.del(`${this.baseUrl}/lt/${this.getId(entityId)}`, params);
  }
}
