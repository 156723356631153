import cloneDeep from 'lodash/cloneDeep';
import plugins from './plugins';
import MobxReactForm from "mobx-react-form";


const genericFields = {
    "recipientName": {
        label: 'Recipient Name',
        placeholder: 'Enter the Recipient’s First and Last Name',
        rules: 'string|required'
    },
    "notes": {
        label: 'Message',
        placeholder: 'Add a Personal Message (optional)',
        rules: 'string',
    }
};




export default function create(hooks) {
    return new MobxReactForm({ fields: genericFields }, { plugins, hooks });
}

