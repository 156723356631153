import React from "react";
import Snackbar from "../components/Snackbar";
import {useStores} from "../hooks/use-stores";
import {observer} from "mobx-react";

function SnackbarContainer(){
    const { commonStore } = useStores();
    const closeSnackbar = () => {
        commonStore.hideMessage();
    };
    return <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={6000} variant={commonStore.messageType}
              message={commonStore.message || ""}
              open={commonStore.messageShown} onClose={closeSnackbar}>
    </Snackbar>
}

export default observer(SnackbarContainer)

