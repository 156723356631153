import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class ServiceStatusStore {
  serviceStatusLoading = null;
  serviceStatusInfo = null;

  allServiceStatusLoading = null;
  allServiceStatusInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "serviceStatusError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  checkStatus = generateLoadEntity(
    "serviceStatusInfo",
    this,
    "serviceStatusLoading",
    (params) => {
      return services.ServiceStatusCheckingService.fetch(params);
    },
    "serviceStatusInfo"
  );

  checkAllStatus = generateLoadEntity(
    "allServiceStatusInfo",
    this,
    "allServiceStatusLoading",
    () => {
      return services.ServiceStatusCheckingService.list();
    },
    "allServiceStatusInfo"
  );
}

export default ServiceStatusStore;
