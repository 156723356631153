import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Container, Divider, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import VideoModal from "./VideoModal";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        overflow: 'auto',
        justifyContent: "center",
        maxWidth: '100%',
        backgroundColor: 'white',
        padding: theme.spacing(4)
    },
    title: {
        fontSize: '2.2rem',
        marginBottom: theme.spacing(.4)
    },
    subTitle: {
        fontSize: '1.1rem'
    },
    section: {
        color: 'black',
        backgroundColor: 'white',
        width: '100%',
    },
    infoContainer: {
        backgroundColor: 'white',
        color: 'black',
        padding: '20px'
    },
    button: {
        maxWidth: 'max-content',
        width: '100%',
        background: '#47a447',
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            borderColor: '#51b451 #51b451 #3f923f',
            backgroundColor: '#51b451'
        }
    },
    purchaseTicketButton: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        color: 'white',
        backgroundColor: 'black'
    },
    watchNowButton: {
        fontWeight: 'bold',
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

const PURCHASE_TICKETS_TAG = 'purchasetickets';

const SectionList = ({ info, hasVideoPermissions, showEmptyVideoSection, emptyVideoSectionTitle }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [videoModalOpen, setVideoModalOpen] = useState(false)
    const [currentVideoInfo, setCurrentVideoInfo] = useState(null);
    const [videoModalTitle, setVideoModalTitle] = useState('');

    const openVideo = (sectionDetails) => {
        if (hasVideoPermissions) {
            setVideoModalOpen(true);
            setCurrentVideoInfo(sectionDetails);
            setVideoModalTitle(sectionDetails.title);
        } else {
            const buttonLinkSection = info?.additionalSections.filter(section => section.tag === PURCHASE_TICKETS_TAG)[0]
            if (buttonLinkSection) {
                window.open(buttonLinkSection.buttonLink)
            }
        }
    };

    const closeModal = () => {
        setVideoModalOpen(false);
        setCurrentVideoInfo(null);
    }

    return (
        <Container className={classes.container}>
            <Paper elevation={3} className={classes.section}>
                {info.additionalSections && info.additionalSections.length > 0 && info.additionalSections.map(as => {
                    if (as.tag === PURCHASE_TICKETS_TAG && hasVideoPermissions) {
                        // don't show purchase if user has video permissions - means they have a ticket already
                        return null
                    }

                    return <Box className={classes.infoContainer}>
                        <Typography variant="h2" className={classes.title}>{as.title}</Typography>
                        <Typography variant="h3" className={classes.subTitle}>{as.subTitle}</Typography>
                        {as.htmlDescription && <Box dangerouslySetInnerHTML={{ __html: as.htmlDescription }} />}
                        {as.buttonLink && as.buttonText && <Button className={clsx(classes.button, classes.purchaseTicketButton)} onClick={() => window.open(as.buttonLink)}>{as.buttonText}</Button>}
                    </Box>
                }
                )}
            </Paper>
            <Divider className={classes.divider} />
            {
                info.sections && info.sections.length > 0 && info.sections.map(section =>
                    section.sectionDetails && section.sectionDetails.length > 0 && section.sectionDetails.map(sd =>
                        sd.vimeoID ? (
                            <Paper elevation={3} className={classes.section}>
                                <Box className={classes.infoContainer}>
                                    <Typography variant="h2" className={classes.title}>{sd.title}</Typography>
                                    <Button className={clsx(classes.button, classes.watchNowButton)} onClick={() => openVideo(sd)}>{t('seminarWebinar:content.watchNow')}</Button>
                                </Box>
                            </Paper>
                        ) :
                        showEmptyVideoSection &&
                            <Paper elevation={3} className={classes.section}>
                                <Box className={classes.infoContainer}>
                                    <Typography variant="h2" className={classes.title}>{emptyVideoSectionTitle}</Typography>
                                    <Button className={clsx(classes.button, classes.watchNowButton)} disabled>{t('seminarWebinar:content.comingSoon')}</Button>
                                </Box>
                            </Paper>
                    )
                )
            }
            {hasVideoPermissions &&
                <VideoModal
                    videoModalOpen={videoModalOpen}
                    onCloseVideoModal={closeModal}
                    currentVideoInfo={currentVideoInfo}
                    setCurrentVideoInfo={setCurrentVideoInfo}
                    videoModalTitle={videoModalTitle}
                    relatedVideos={[]} // only showing one video at a time
                    hasVideoPermissions={hasVideoPermissions}
                    customStringMap={info.additionalKeys}
                />
            }
        </Container>
    );
};

export default SectionList;
