import React, { useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import useAudio from './useAudio';
import { useStores } from '../../../hooks/use-stores';

const DEFAULT_MAX_TIME = 60;

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    recordingTime: {
        fontSize: '1.5rem'
    },
    iconContainer: {
        marginTop: theme.spacing(1)
    },
    recordingIcon: {
        fontSize: '3rem',
        color: 'white'
    },
    stopIcon: {
        fontSize: '3rem',
        color: 'black'
    },
    recordButton: {
        whiteSpace: 'pre-line'
    }
}));

const AudioRecorder = ({ onRecord, maxRecordingTimeInSeconds, onFinished }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { lifelineStore } = useStores();
    let { register, audioError } = useAudio();

    const audioRef = useRef(null);
    const maxTimeInMS = (maxRecordingTimeInSeconds || DEFAULT_MAX_TIME) * 1000;

    const [timeLeft, setTimeLeft] = useState(maxTimeInMS);
    const [capturing, setCapturing] = useState(false);
    const [finalAudio, setFinalAudio] = useState(null);

    let mediaRecorder = useRef(null);

    const startRecording = async () => {
        audioRef.current = await register();
        mediaRecorder.current = new MediaRecorder(audioRef.current);

        // Clear any old audio 
        if (finalAudio) {
            URL.revokeObjectURL(finalAudio);
            setFinalAudio(null);
            lifelineStore.audioFile = null;
        }

        // Reset time left
        setTimeLeft(maxTimeInMS);

        let countdown;
        let data = [];

        // we need a reference to the 1 minute promise timeout so we can end it early if needed
        let resolveTimeoutPromise;

        mediaRecorder.current.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                data.push(event.data)
            }
        };

        mediaRecorder.current.onstart = () => {
            countdown = setInterval(() => setTimeLeft(timeLeft => timeLeft - 1000), 1000);
            setCapturing(true);
            onRecord(true);
        }

        // Set up a max time for the recording - it will auto stop then
        let recorded = new Promise(resolve => {
            resolveTimeoutPromise = resolve;
            setTimeout(resolve, maxTimeInMS);
        }).then(
            () => {
                setCapturing(false);
                onRecord(false);
                stopRecording();
            }
        );

        let stopped = new Promise((resolve, reject) => {
            mediaRecorder.current.onstop = () => {
                resolve(); //when the record is stopped, we resolve 
                if (resolveTimeoutPromise)
                    resolveTimeoutPromise(); // if we stop the movie manually, resolve the timeout promise
            }
            mediaRecorder.current.onerror = (event) => {
                reject(event.name);
                if (resolveTimeoutPromise)
                    resolveTimeoutPromise();// if we stop the movie manually, resolve the timeout promise
            }
        });

        mediaRecorder.current.start(1000); // timeslice to 1 sec or ffmpeg freaks out

        await Promise.any([
            recorded,
            stopped,
        ])

        clearInterval(countdown)
        audioSuccess(data);
    }

    const stopRecording = () => {
        setCapturing(false);
        onRecord(false);
        mediaRecorder?.current.stop();
        audioRef.current?.getTracks().forEach(track => track.stop());
    }

    const audioSuccess = (recordedChunks) => {
        const blob = new Blob(recordedChunks, {
            type: "audio/mpeg"
        });

        setFinalAudio(window.URL.createObjectURL(blob));
        onFinished(blob);
        setCapturing(false);
        onRecord(false);
    }

    return (
        <Container className={classes.wrapper}>
            {audioError ?
                <>
                    <Alert severity="error">{audioError}</Alert>
                    <Button onClick={() => onFinished(null)}>{t('lifeline:audioRecorder.continue')}</Button>
                </> :
                <Box style={{ width: '100%' }} direction="column" alignItems="center" justifyContent="center" >
                    {!finalAudio && <div>
                        <Typography className={classes.recordingTime} style={{ color: capturing ? 'red' : 'black' }}>{moment(timeLeft).format('mm:ss')}</Typography>
                    </div>
                    }
                    {
                        !capturing && finalAudio &&
                        <audio controls style={{width: "100%"}}>
                            <source src={finalAudio} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                    }
                    <div className={classes.iconContainer}>
                        {capturing ? (
                            <Button onClick={stopRecording} variant="contained" color="default"><StopIcon className={classes.stopIcon} /></Button>
                        ) : <Button onClick={(startRecording)} variant="contained" color="secondary" className={classes.recordButton}><MicIcon className={classes.recordingIcon} />{t('lifeline:audioRecorder.record')}</Button>}
                    </div>
                </Box>
            }
        </Container>
    )
}

export default AudioRecorder;