import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {starFishColor} from "./styles";
import {Box, Typography} from "@material-ui/core";

const useStyles = makeStyles( (theme) => ({
        root:(props)=>( {
            height: props.height,
            borderRadius: 5,
            margin:5,
            width: props.width,
            backgroundColor: theme.palette.grey[700],
        }),
        bar: (props)=>( {
            borderRadius: 5,
            ...(props.color==="primary"?starFishColor(theme, "backgroundColor"):{}),
            ...(props.color==="error"?{backgroundColor: theme.palette.error.main}:{}),
            ...(props.color==="success"?{backgroundColor: theme.palette.success.main}:{}),
        }),
    }))


function ProgressWithLabel({ max, value, label, height=10, color="primary", width= "100%", hideValue=false }) {

    const classes = useStyles({height, color, width});

    return <Box position="relative" display="inline-flex" width="100%">
        <LinearProgress variant="determinate"
                        classes={{
                            root: classes.root,
                            bar: classes.bar,
                        }}
                  height={20}
                  value={ Math.min( 100,  100*value/max ) }  />
        {!hideValue && <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="caption" component="div" color="textSecondary">{label}</Typography>
        </Box>}
    </Box>
}


export default  ProgressWithLabel