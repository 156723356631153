import {Route, Switch, useLocation} from "react-router";
import {joinPath} from "../../utils/helpers";
import {Box, makeStyles, Typography, Link as MuiLink} from "@material-ui/core";
import {Link, Redirect, useHistory, useRouteMatch} from "react-router-dom";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import IFrame from "../../components/IFrame";
import PageTitle from "../../components/PageTitle";
import React, {useEffect, useMemo, useState} from "react";
import menu from "./menu.json";
import Life from "../Starfish/Life";
import {starFishColor} from "../../components/styles";
import {useStores} from "../../hooks/use-stores";
import _ from "lodash";
import LibraryRoutes from "../LibraryRoutes";
import Policies from "./Policies";
import LTAI from "./LTAI";
import {useViewContext} from "../../contexts/useViewContext";
import MarketPlaceService from "./components/MarketPlaceService";
import ComplianceTest from "./ComplianceTest";
import MenuItem from "../../components/MenuItem";



function itemUrl(item, lang){
    if ( typeof item.url === "object" ) {
        return item.url[lang]
    }
    return item.url
}

function  startsWithOneOf ( value, list ){
    return _.some( list, x=>value.startsWith(x))
}

const internalPages = ["https://shop.lifeinfoapp.com"
    , "https://appview.mainhomepage.com"
    , "https://my.lifeinfoapp.com"
    , "https://legacy.lifeinfoapp.com/"];


const fullScreenFramePages = ["autoship"];



function defaultComponentFactory(authStore, match, item, language){
    let result
    let url = itemUrl ( item, language);
    let tag = item.tag||url;


    let isUrlAbsolute = url && ( url.startsWith("http://") || url.startsWith("https://") )

    if ( item && item.loginGuid &&  url ) {
        url =  url + (url.indexOf("?")>-1?"&":"?") + `loginguid=${authStore.loginGuid}`
    }

    switch (tag) {
        case "#":
            result = () => <LinksMenu parentMatch={match} title={item.title} items={[]} showBack iFrame />
            break;
        case "memberfulloffice":
        case "events":
        case "programandexplanation":
            result = () => <LinksMenu parentMatch={match} items={menu.find(x=>x.tag===(tag)).items} showBack iFrame />
            break;
        case 'rascal':
            result = () =><IFrame url={`https://my.rascal-radio.com/index.aspx?loginguid=${authStore.loginGuid}`} />
            break
        case 'accelerator':
            result = () => <Life/>
            break
        case 'policiesandprocedures':
            result = () => <Policies/>
            break
        case 'thing2travel':
            result = () => <IFrame url={url} />
            break
        case 'ltai':
            result = () => <LTAI/>
            break
        case "marketplaceproducts":
            result = () =>    <LinksMenu parentMatch={match} items={menu.find(x=>x.tag==="marketplaceproducts").items} showBack iFrame />
            break
        case "marketplaceservices":
            result = () =>    <LinksMenu parentMatch={match} items={menu.find(x=>x.tag==="marketplaceservices").items} showBack iFrame tag={'marketplaceservices'}/>
            break
        case "lifeproducts":
            result = () =>    <LinksMenu parentMatch={match} items={menu.find(x=>x.tag==="lifeproducts").items} showBack iFrame />
            break
        case "complianceTest":
            result = () =>    <ComplianceTest />
            break
        default:

            break
    }
    if ( !result ) {
        if( url && url.startsWith("https://my.lifeinfoapp.com")  && !fullScreenFramePages.includes( item.tag ) )
            result = () => <Redirect to={(tag).replace("https://my.lifeinfoapp.com","")}/>

    }
    if ( !result ){
        const fullScreenFrame =  url && isUrlAbsolute && ( fullScreenFramePages.includes( item.tag ) || !startsWithOneOf( url, internalPages ) )
            result = () => <IFrame url={url} title={item.title}  fullScreenFrame={fullScreenFrame} returnTitle="Back To Royal"  />
    }

    return result
}

function  getComponent(authStore, componentFactory, match, item, language){

    let result
    if ( componentFactory )
        result = componentFactory(match, item)
    if( !result )
        result = defaultComponentFactory(authStore, match, item, language)
    return result
}

function LinksMenu( {parentMatch, items, title, showBack=true, linkFactory, componentFactory, collapsePath=true, tag="" }){
    const match = useRouteMatch();
    const history = useHistory();
    const location =  useLocation();
    const viewContext = useViewContext();
    const [backLink, setBackLink] = useState(null);

    useEffect(()=>{
        if ( location&& location.state && location.state.backLink ){
            setBackLink( location.state.backLink)
        }
    }, location )

    const {authStore, commonStore} = useStores();
    const {currentUser} = authStore;
    const { t } = useTranslation("starfish");

    const filteredItems  = useMemo(()=>{
        return items.filter(x=> {
            if ( x.subscription ){
                return authStore.subscriptions[x.subscription]
            }

            return !x.membersOnly || currentUser.isMember
        })

    }, [currentUser])


    const handleBack = (event)=>{
        event.preventDefault();
        window.history.back()
    }

    return  <Switch>
        {filteredItems.find(x=> x.tag ==="library") && LibraryRoutes(match.path) }
        {filteredItems.map( (link,i )=> {
                    let p = joinPath(match.path, link.tag || link.title.toLowerCase())
                    return <Route path={p}
                                  key={i}
                                  render={() => {
                                      const component = getComponent(authStore, componentFactory, match, link, commonStore.language)

                                      const componentElement = <Box style={{marginBottom: viewContext.frameLess?0: '60px'}}>
                                          {!component
                                              ? <>
                                                  {
                                                      link.url === "#"
                                                          ? <>
                                                              {<PageTitle>{link.title}</PageTitle>}
                                                              {showBack && <MenuItem
                                                                  title={t(`royal.back`)}
                                                                  path={match.path}
                                                                  onClick={(event) => {
                                                                      if (!match || !match.path)
                                                                          handleBack(event)
                                                                  }}
                                                              />}
                                                          </>

                                                          : <IFrame url={link.url} title={link.title}/>
                                                  }
                                              </>
                                              : component()}
                                      </Box>

                                      const isMarketPlaceServices = tag === 'marketplaceservices'
                                      if (isMarketPlaceServices) {
                                        return <MarketPlaceService serviceTag={link.serviceTag} component={componentElement}/>
                                      }
                                      return componentElement
                                  }
                                  }
                    />
                }
            )
        }

        <Route path="*"
               render={() => {
                   let backpath = backLink ||  parentMatch && parentMatch.path
                   return <>
                       {title && <PageTitle>{title}</PageTitle>}
                      {showBack && <MenuItem
                          isBack
                          title={t(`royal.back`)}
                          onClick={(event) => {
                            if( !backpath )
                                handleBack(event)
                          }}
                          path={backpath}

                      />}
                       {filteredItems.map((link, i) => {
                       return <MenuItem
                           collapsePath={collapsePath}
                           key={`${link.tag || link.title.toLowerCase()}-${i}` }
                           title={link.title}
                           path={link.tag || link.title.toLowerCase() }
                           url={ itemUrl( link, commonStore.language ) }
                           item={link}
                           match={match}
                           parentMatch={parentMatch||match}
                       />;
                   })}
                   </>
               }}
        />
    </Switch>

}


export default LinksMenu;