import Auth from "./AuthService";
import UserProfile from "./UserProfileService";
import Categories from "./CategoryService";
import Library from "./LibraryService";
import MenuItems from "./MenuItemsService";
import Members from "./MembersService";
import Status from "./StatusService";
import Notifications from "./NotificationsService";
import News from "./NewsService";
import Web from "./WebService";
import Utility from "./UtilityService";
import Spouse from "./SpouseService";
import Sendout from "./SendoutService";
import PublicSendout from "./PublicSendout";
import ShoppingCart from './ShoppingCartService';
import Enrollment from "./EnrollmentService";
import MemberEnrollment from "./MemberEnrollmentService";
import AutoShipService from "./AutoShipService";
import ShareService from "./ShareService";
import OnboardService from "./OnboardService";
import ConventionProgramService from "./ConventionProgramService";
import SubscriptionService from "./SubscriptionService";
import BaseLoadService from "./BaseLoadService";
import ShoppingBossService from "./ShoppingBossService";
import HelpService from "./HelpService";
import SupportService from "./SupportService";
import SeminarWebinarService from "./SeminarWebinarService";
import LifelineService from "./LifelineService";
import GiftCardsService from "./GiftCards";
import Spark from "./Spark";
import TopGun from "./TopGunService";
import MyForms from "./MyFormsService";
import MyTeams from "./MyTeamsService";
import BannerService from "./BannersService";
import ConventionLeaderhipService from "./ConventionLeaderhipService";
import ConventionProgramServicePublic from "./PublicConventionService";
import TopTenService from "./TopTenService";
import CriticalMessageService from "./CriticalMessageService";
import MyVolume from "./MyVolume";
import ResetPasswordService from "./ResetPasswordService";
import MyProgress from "./MyProgress";
import MyIncome from "./MyIncome";
import MyTrainingIncome from "./MyTrainingIncome";
import IncomeAllocation from "./IncomeAllocation";
import IncomeAllocationService from "./IncomeAllocation";
import ServiceStatusCheckingService from "./ServiceStatusCheckingService";
import TestService from "./TestService";
import WebTextService from "./WebTextService";
import UserSearchService from "./UserSearchService";
import ProductService from "./ProductService";
import PVTransferService from "./PVTransferService";
import EventsService from "./Events";

export default {
    Auth: new Auth(),
    Base: new BaseLoadService(),
    Spark: new Spark(),
    UserProfile: new UserProfile(),
    Categories: new Categories(),
    Library: new Library(),
    Members: new Members(),
    MenuItems: new MenuItems(),
    Status: new Status(),
    Notifications: new Notifications(),
    News: new News(),
    Spouse: new Spouse(),
    Web: new Web(),
    Utility: new Utility(),
    Sendout: new Sendout(),
    PublicSendout: new PublicSendout(),
    ShoppingCart: new ShoppingCart(),
    Enrollment: new Enrollment(),
    MemberEnrollment: new MemberEnrollment(),
    AutoShip: new AutoShipService(),
    Subscription: new SubscriptionService(),
    Share: new ShareService(),
    Onboard: new OnboardService(),
    ConventionProgramService: new ConventionProgramService(),
    ConventionProgramServicePublic: new ConventionProgramServicePublic(),
    ShoppingBoss: new ShoppingBossService(),
    Help: new HelpService(),
    Support: new SupportService(),
    SeminarWebinarService: new SeminarWebinarService(),
    LifelineService: new LifelineService(),
    TopGun: new TopGun(),
    TopTen: new TopTenService(),
    MyForms: new MyForms(),
    MyTeams: new MyTeams(),
    Banners: new BannerService(),
    GiftCards: new GiftCardsService(),
    ConventionLeaderhipService: new ConventionLeaderhipService(),
    CriticalMessage: new CriticalMessageService(),
    ResetPasswordService: new ResetPasswordService(),
    MyVolume: new MyVolume(),
    MyProgress: new MyProgress(),
    MyIncome: new MyIncome(),
    MyTrainingIncome: new MyTrainingIncome(),
    IncomeAllocation: new IncomeAllocation(),
    ServiceStatusCheckingService: new ServiceStatusCheckingService(),
    TestService: new TestService(),
    WebTextService: new WebTextService(),
    UserSearchService: new UserSearchService(),
    ProductService: new ProductService(),
    PVTransferService: new PVTransferService(),
    Events: new EventsService()
};

