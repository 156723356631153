export const physicalAndDigitalSKUs = {
    'TL0231C': {
        PhysicalProductId: 14625,
        DigitalProductId: 14622
    },
    'eTL0231C': {
        PhysicalProductId: 14625,
        DigitalProductId: 14622
    },
    'TL0330B': {
        PhysicalProductId: 14820,
        DigitalProductId: 15343
    },
    'eTL0330BEXP': {
        PhysicalProductId: 14820,
        DigitalProductId: 15343
    },
    'TL0364A': {
        PhysicalProductId: 10169,
        DigitalProductId: 8258
    },
    'eTL0364A': {
        PhysicalProductId: 10169,
        DigitalProductId: 8258
    },
    'TL0385A': {
        PhysicalProductId: 6423,
        DigitalProductId: 8204
    },
    'eTL0385A': {
        PhysicalProductId: 6423,
        DigitalProductId: 8204
    },
    'TL0393A': {
        PhysicalProductId: 6586,
        DigitalProductId: 8249
    },
    'eTL0393A': {
        PhysicalProductId: 6586,
        DigitalProductId: 8249
    },
    'TL0395': {
        PhysicalProductId: 6727,
        DigitalProductId: 8205
    },
    'eTL0395': {
        PhysicalProductId: 6727,
        DigitalProductId: 8205
    },
    'TL0415': {
        PhysicalProductId: 7103,
        DigitalProductId: 8229
    },
    'eTL0415': {
        PhysicalProductId: 7103,
        DigitalProductId: 8229
    },
    'TL0426': {
        PhysicalProductId: 7259,
        DigitalProductId: 8206
    },
    'eTL0426': {
        PhysicalProductId: 7259,
        DigitalProductId: 8206
    },
    'TL0431': {
        PhysicalProductId: 7341,
        DigitalProductId: 9697
    },
    'eTL0431': {
        PhysicalProductId: 7341,
        DigitalProductId: 9697
    },
    'TL0574A': {
        PhysicalProductId: 14823,
        DigitalProductId: 14822
    },
    'eTL0574A': {
        PhysicalProductId: 14823,
        DigitalProductId: 14822
    },
    'TL0631': {
        PhysicalProductId: 13253,
        DigitalProductId: 13836
    },
    'eTL0631': {
        PhysicalProductId: 13253,
        DigitalProductId: 13836
    }
}