import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import {makeAutoObservable, reaction} from "mobx";
import sumBy from "lodash/sumBy";


class MyTrainingIncomeStore {
    PVPeriodID=null
    error = null;
    dataLoading= null;

    data = null

    constructor(periodsStore, authStore) {
        makeAutoObservable(this);

        this.periodsStore = periodsStore;
        this.authStore = authStore;

        reaction( ()=> this.periodsStore.paidPeriods.slice(),
            periods => {
                if (periods.length > 1) {
                    let beforeTimeSwitch = false

                    const today = new Date()
                    if (today.getDate() === 1 && today.getHours() < 2) {
                        beforeTimeSwitch = true
                    }
                    if (today.getDate() < 3 && !beforeTimeSwitch)
                        this.setPVPeriodID(periods[1].PVPeriodID)
                    else
                        this.setPVPeriodID(periods[0].PVPeriodID)
                } else if (periods.length == 1) {
                    this.setPVPeriodID(periods[0].PVPeriodID)
                }
            })

    }

    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    setPVPeriodID(value) {
        this.PVPeriodID = value
        this.resetData()
    }

    resetData(){

    }


    get msbasic(){
        return this.data?.bonusSummary?.DiscountMSBasic + this.data?.bonusSummary?.DiscountMSBasicSC
    }

    get msbasicYTD(){
        return this.data?.bonusSummaryYTD?.DiscountMSBasic + this.data?.bonusSummaryYTD?.DiscountMSBasicSC
    }

    get mspremium(){
        return this.data?.bonusSummary?.DiscountMSPremium + this.data?.bonusSummary?.DiscountMSPremiumSC
    }
    get mspremiumYTD(){
        return this.data?.bonusSummaryYTD?.DiscountMSPremium + this.data?.bonusSummaryYTD?.DiscountMSPremiumSC
    }

    get seminarTickets(){
        return this.data?.bonusSummary?.DiscountTicketSeminar + this.data?.bonusSummary?.DiscountTicketSeminarSC
    }

    get seminarTicketsYTD(){
        return this.data?.bonusSummaryYTD?.DiscountTicketSeminar + this.data?.bonusSummaryYTD?.DiscountTicketSeminarSC
    }

    get events(){
        return this.data?.bonusSummary?.OMSpeak +
            this.data?.bonusSummary?.SemSpeak +
            this.data?.bonusSummary?.OMStipend +
            this.data?.bonusSummary?.SemStipend
    }

    get eventsYTD(){
        return this.data?.bonusSummaryYTD?.OMSpeak +
            this.data?.bonusSummaryYTD?.SemSpeak +
            this.data?.bonusSummaryYTD?.OMStipend +
            this.data?.bonusSummaryYTD?.SemStipend
    }

    get adjustments(){
        return this.data?.bonusSummary?.Deduction +
            this.data?.bonusSummary?.ConvReimburse +
            this.data?.bonusSummary?.AdjustedPreviousPeriod
    }

    get adjustmentsYTD(){
        return this.data?.bonusSummaryYTD?.Deduction +
            this.data?.bonusSummaryYTD?.ConvReimburse +
            this.data?.bonusSummaryYTD?.AdjustedPreviousPeriod
    }

    get conventionTickets(){
        return this.data?.bonusSummary?.DiscountTicketConvention +
            this.data?.bonusSummary?.DiscountTicketConventionSC +
            this.data?.bonusSummary?.DiscountTicketConventionGeneral +
            this.data?.bonusSummary?.DiscountTicketConventionGeneralSC
    }


    get conventionTicketsYTD(){
        return this.data?.bonusSummaryYTD?.DiscountTicketConvention +
            this.data?.bonusSummaryYTD?.DiscountTicketConventionSC +
            this.data?.bonusSummaryYTD?.DiscountTicketConventionGeneral +
            this.data?.bonusSummaryYTD?.DiscountTicketConventionGeneralSC
    }


    get rptEventsSource() { //"Reason = 'OMSpeak' Or Reason = 'SemSpeak' Or Reason = 'OMStipend' Or Reason = 'SemStipend'"
        return this.data?.events
    }


    get _EventsBonus(){
        return sumBy(this.rptEventsSource, 'Amount')
    }

    loadData = generateLoadEntity(  "incomeData",
        this,
        "dataLoading",
        () => {
            return services.MyTrainingIncome.list( {PVPeriodID: this.PVPeriodID} );
        },
        "data")



}

export default MyTrainingIncomeStore;