import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Link,
    makeStyles,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import {useTheme} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    marginBottom:{
        marginBottom: theme.spacing(2)
    }

}));


function EventSpeakers(){
    const { commonStore } = useStores();
    const {t} = useTranslation("starfish");
    const theme = useTheme();
    const classes = useStyles();



    return <div>
        <section className={clsx( classes.sectionDense)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/event-speaker-fr.jpg`
                    : `/img/event-speaker-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <Container maxWidth={"md"}>
               <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           1. {t("eventSpeaker.speaker_contract_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.speaker_contract")}</span>
                           {t("eventSpeaker.speaker_contract_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/us_speakerlicenseagreement_en.pdf" target="_blank">{t("eventSpeaker.speaker_contract_form")}</Link>
                   </AccordionDetails>
               </Accordion>

                <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           2. {t("eventSpeaker.speaker_bio_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.speaker_bio")}</span>
                           {t("eventSpeaker.speaker_bio_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/us_bioguidelinesforspeakers_en2.pdf" target="_blank">
                           {t("eventSpeaker.bio_guidelines_form")}</Link>
                   </AccordionDetails>
               </Accordion>

                <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           3. {t("eventSpeaker.open_meeting_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.open_meeting")}</span>
                           {t("eventSpeaker.open_meeting_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/US_openmeetingguideline_EN.pdf" target="_blank">
                           {t("eventSpeaker.open_meeting_guidelines_form")}</Link>
                   </AccordionDetails>
               </Accordion>
                <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           4. {t("eventSpeaker.open_meeting_tracking_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.open_meeting_tracking")}</span>
                           {t("eventSpeaker.open_meeting_tracking_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/us_openmeetingtrackingform_en2.pdf" target="_blank">
                           {t("eventSpeaker.open_meeting_tracking_form")}</Link>
                   </AccordionDetails>
               </Accordion>
                <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           5. {t("eventSpeaker.virtual_event_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.virtual_event")}</span>
                           {t("eventSpeaker.virtual_event_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/us_LIFE_Virtual_Event_Technical_Recommendations_en_V2.pdf" target="_blank">
                           {t("eventSpeaker.virtual_event_technical_recommendations_form")}</Link>
                   </AccordionDetails>
               </Accordion>
                <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           6. {t("eventSpeaker.seminar_recognition_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.seminar_recognition")}</span>
                           {t("eventSpeaker.seminar_recognition_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/US_seminarrecognition_EN.pdf" target="_blank">
                           {t("eventSpeaker.seminar_recognition_form")}</Link>

                   </AccordionDetails>
               </Accordion>
                <Accordion >
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography align="left" className={classes.headText} variant="h5">
                           7. {t("eventSpeaker.convention_recognition_pre")}
                           <span className={classes.linkColor}>{t("eventSpeaker.convention_recognition")}</span>
                           {t("eventSpeaker.convention_recognition_post")}</Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <Link href="https://resources.lifeinfoapp.com/forms/US_conventionrecognition_EN.pdf" target="_blank">
                           {t("eventSpeaker.convention_recognition_form")}</Link>

                   </AccordionDetails>
               </Accordion>
            </Container>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default EventSpeakers