import { makeAutoObservable, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateCreteEntity, generateDeleteEntity, generateLoadEntity, generateLoadList } from "../utils/mobx";
import keyBy from "lodash/keyBy";
import createEnrollCustomer from "../forms/enrollCustomer";
import createBillingAddressForm from "../forms/billingAddress";
import createBillingInfoForm from "../forms/billingInfo";
import createShippingInfoForm from "../forms/enrollShippingAddress";
import {STARFISH_KIT_EN_SKU, STARFISH_KIT_ES_SKU, STARFISH_KIT_FR_SKU} from "../utils/constants";

const permanentItems = ["enroll01", "eLifeAppSuperPlusD", "eStarfishSub", STARFISH_KIT_EN_SKU, STARFISH_KIT_FR_SKU, STARFISH_KIT_ES_SKU];
const multiOptionsGroups = { "enroll03": ["enroll04"] };
const additionalWarningItems = ["eACCELERATOR", "enroll03", "eLifeAppSuperSub",];

function canDeleteAdapter(cart) {
    if (!cart) return;
    let items = Array.isArray(cart) ? cart : cart.cartItems;
    if (items)
        items.forEach(x => {
            x.canDelete = !permanentItems.includes(x.productSKU);

            x.additionalWarning = !!additionalWarningItems.find(y => x.productSKU && x.productSKU.startsWith(y));
        });
    return cart;

}

function joinMultiOptions(items) {
    const removed = []
    items.forEach(x => {
        if (multiOptionsGroups[x.productSKU]) {
            const subItemsSKUs = multiOptionsGroups[x.productSKU];
            const subItems = items.filter(y => subItemsSKUs.includes(y.productSKU));
            if (subItems.length > 0) {
                if (!x.alternatives) x.alternatives = [{ ...x }];
                subItems.forEach(subItem => {
                    x.alternatives.push(subItem);
                    removed.push(subItem.productSKU);
                })
            }
        }
    })
    return items.filter(x => !removed.includes(x.productSKU));
}


function combineItemsWithRecomendations(cart, additionalItems, occurrence, isStarfishSignup = false) {
    let items = cart ? cart.cartItems.filter(x => x.occurrence === occurrence) : [];
    let recommended = additionalItems ? additionalItems.filter(x => x.occurrence === occurrence && x.recommended) : [];
    if (isStarfishSignup === true) {
        recommended = recommended.filter(x => !x.productSKU.startsWith('eLifeAppSuperSub'));
    } else {
        recommended = recommended.filter(x => !x.productSKU.startsWith('eStarfishSub'));
    }
    let containsFullStart = cart ? cart.cartItems.filter(x => x.productSKU.startsWith('eFSBundleSp')) : [];
    if (!!containsFullStart && !!containsFullStart.length) {
        recommended = recommended.filter(x => !x.productSKU.startsWith('eLifeAppSuperSub'));
    }

    recommended.forEach(x => {
        let existing = items.find(i => x.productSKU === i.productSKU || (x.alternatives && x.alternatives.find(a => a.productSKU === i.productSKU)));
        console.log("existing", existing, x.productSKU);
        if (!existing) {
            x.removed = true;
            x.canDelete = !permanentItems.includes(x.productSKU);;
            x.totalTCs = x.productTC;
            items.unshift(x);
        }
        else {
            existing.alternatives = x.alternatives
            existing.effectiveProductSKU = existing.productSKU
            existing.effectiveProductPrice = existing.productPrice
            existing.effectiveProductID = existing.productID
            existing.canDelete = !permanentItems.includes(x.productSKU);;
            existing.totalTCs = x.productTC;
        }
    });

    return items;
}

class EnrollmentStore {
    constructor(commonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }
    referralInfo = null;
    additionalItems = null;
    loadingReferralInfo = false;
    loadingAdditionalItems = false;
    loadingCartInfo = false;
    loadingCartTotals = false;
    loadingRenewalCartInfo = false;
    addingItemToCart = false;
    creating = false;
    cart = null;
    renewalCart = null;
    totals = null;
    expired = false;
    activeStep = "1";
    product = "";
    //forms:
    personalForm = null;
    personalFormSubmitting = false;

    billingInfoForm = null;
    billingInfoFormSubmitting = false;

    billingAddressForm = null;
    billingAddressFormSubmitting = false;

    shippingAddressForm = null;
    shippingAddressFormSubmitting = false;

    shippingAddressFormEdit = null;
    shippingAddressFormEditSubmitting = false;

    //modalForms:
    personalFormEdit = null;
    personalFormEditSubmitting = false;

    billingInfoFormEdit = null;
    billingInfoFormEditSubmitting = false;

    billingAddressFormEdit = null;
    billingAddressFormEditSubmitting = false;

    countries = [];
    loadingCountries = false;
    effectiveProductSKU = null;
    paymentID = null;

    get oneTimeItems() {
        return combineItemsWithRecomendations(this.cart, this.additionalItems, "onetime");
    }

    get recurringItems() {
        return combineItemsWithRecomendations(this.cart, this.additionalItems, "recurring", this.isStarfishSignup);
    }

    get cartTotal() {
        return this.totals && this.totals[0].cartTotal;
    }

    get effectiveAdditionalItems() {
        if (!this.cart || !this.additionalItems) return null;
        let existing = keyBy([...this.oneTimeItems, ...this.recurringItems], "productSKU");
        const additionalItems = this.additionalItems.filter(x => !existing[x.productSKU]);
        if (this.isStarfishSignup === true) {
            return additionalItems.filter(x => x.productSKU.startsWith('eACCELERATOR') || x.productSKU.startsWith('eStarfishSub'));
        } else {
            return additionalItems.filter(x => !x.productSKU.startsWith('eStarfishSub'));
        }
    }

    setPaymentId(newVal) {
        this.paymentID = newVal;
    }

    get isStarfishSignup() {
        return this.product?.includes('starfish') || this.product?.includes('topcustomer') ? true : false;
    }

    setProduct(product) {
        this.product = product;
    }

    initPersonalForm(preferredLanguage, country) {
        this.personalForm = createEnrollCustomer({ preferredLanguage, country }
            , {
                onSuccess: () => {
                    this.personalFormSubmitting = false;
                    this.handleNextStep();
                    if (!this.billingAddressForm.isDirty) {
                        this.billingAddressForm.update({
                            billFirstName: this.personalForm.values().firstName,
                            billLastName: this.personalForm.values().lastName
                        });
                    }
                },
                onError: () => { this.personalFormSubmitting = false; }
            });
    }
    initPersonalFormEdit(onSuccess) {
        this.personalFormEdit = createEnrollCustomer({ ...this.personalForm.values() }
            , {
                onSuccess: () => {
                    this.personalFormEditSubmitting = false;
                    if (this.personalFormEdit)
                        this.personalForm.update(this.personalFormEdit.values());
                    onSuccess();
                },
                onError: () => { this.personalFormEditSubmitting = false; }
                ,
            });
    }

    initBillingInfoForm(country) {
        this.billingInfoForm = createBillingInfoForm({ country }
            , {
                onSuccess: () => { runInAction(() => { this.billingInfoFormSubmitting = false; this.handleNextStep(); }); },
                onError: () => { runInAction(() => { this.billingInfoFormSubmitting = false; }); }
            });
    }

    initBillingInfoFormEdit(onSuccess) {
        this.billingInfoFormEdit = createBillingInfoForm({ ...this.billingInfoForm.values() }
            , {
                onSuccess: () => {
                    runInAction(() => {
                        this.billingInfoFormEditSubmitting = false;
                        if (this.billingInfoFormEdit)
                            this.billingInfoForm.update({ ...this.billingInfoFormEdit.values() });
                        onSuccess();
                    });
                },
                onError: () => { runInAction(() => { this.billingInfoFormEditSubmitting = false; }); }
                ,
            });
    }
    initBillingAddressForm(country) {
        this.billingAddressForm = createBillingAddressForm({ billCountry: country }
            , {
                onSuccess: () => { this.billingAddressFormSubmitting = false; this.handleNextStep(); },
                onError: () => { this.billingAddressFormSubmitting = false; }
            }, { lockCountry: true });
    }
    initBillingAddressFormEdit(onSuccess) {
        this.billingAddressFormEdit = createBillingAddressForm({ ...this.billingAddressForm.values() }
            , {
                onSuccess: () => {
                    runInAction(() => {
                        this.billingAddressFormEditSubmitting = false;
                        if (this.billingAddressFormEdit)
                            this.billingAddressForm.update(this.billingAddressFormEdit.values());
                        onSuccess();
                    });
                },
                onError: () => { runInAction(() => { this.billingAddressFormEditSubmitting = false; }); }
                ,
            }, { lockCountry: true });
    }

    initShippingInfoForm(country) {
        this.shippingAddressForm = createShippingInfoForm({ country }
            , {
                onSuccess: () => { runInAction(() => { this.shippingAddressFormSubmitting = false; this.handleNextStep(); }); },
                onError: () => { runInAction(() => { this.shippingAddressFormSubmitting = false; }); }
            });
    }

    initShippingInfoFormEdit(onSuccess) {
        this.shippingAddressFormEdit = createShippingInfoForm({ ...this.shippingAddressForm.values() }
            , {
                onSuccess: () => {
                    runInAction(() => {
                        this.shippingAddressFormEditSubmitting = false;
                        if (this.shippingAddressFormEdit)
                            this.shippingAddressForm.update(this.shippingAddressFormEdit.values());
                        onSuccess();
                    });
                },
                onError: () => { runInAction(() => { this.shippingAddressFormEditSubmitting = false; }); }
                ,
            }, { lockCountry: true });
    }



    submitPersonalForm() {
        this.personalFormSubmitting = true;
        this.personalForm.submit();
    }
    submitPersonalEditForm() {
        this.personalFormEditSubmitting = true;
        this.personalFormEdit.submit();
    }
    submitBillingInfoForm() {
        this.billingInfoFormSubmitting = true;
        this.billingInfoForm.submit();
    }
    submitBillingInfoEditForm() {
        this.billingInfoFormEditSubmitting = true;
        this.billingInfoFormEdit.submit();
    }
    submitBillingAddressForm() {
        this.billingAddressFormSubmitting = true;
        this.billingAddressForm.submit();
    }
    submitBillingAddressEditForm() {
        this.billingAddressFormEditSubmitting = true;
        this.billingAddressFormEdit.submit();
    }

    submitShippingAddressForm() {
        this.shippingAddressFormSubmitting = true;
        this.shippingAddressForm.submit();
    }
    submitShippingAddressEditForm() {
        this.shippingAddressFormEditSubmitting = true;
        this.shippingAddressFormEdit.submit();
    }



    closePersonalFormEdit() {
        this.personalFormEdit = null;
    }
    closeBillingInfoFormEdit() {
        this.billingInfoFormEdit = null;
    }
    closeBillingAddressFormEdit() {
        this.billingAddressFormEdit = null;
    }
    closeShippingAddressFormEdit() {
        this.shippingAddressFormEdit = null;
    }

    async removeAlternative(item, alternativeSKU, country) {
        const selectedItem = this.cart.cartItems.find(x => x.productSKU === (item.effectiveProductSKU || item.productSKU))
        if (selectedItem) {

            await this.removeFromCart({
                cartID: this.cart.cartID,
                productID: selectedItem.productID,
                lang: this.commonStore.language
            });
            runInAction(() => {
                this.effectiveProductSKU = null
            })

            this.refreshItems({ cartID: this.cart.cartID, country, lang: this.commonStore.language })
        }
    }

    async switchAlternative(item, alternativeSKU, country) {
        const alternative = item.alternatives.find(x => x.productSKU === alternativeSKU);
        const selectedItem = this.cart.cartItems.find(x => x.productSKU === (item.effectiveProductSKU || item.productSKU))

        if (selectedItem) {
            await this.removeFromCart({
                cartID: this.cart.cartID,
                productID: selectedItem.productID,
                lang: this.commonStore.language
            });
        }

        try {
            await this.addToCart({
                cartID: this.cart.cartID,
                productID: alternative.productID,
                lang: this.commonStore.language,
                qty: 1
            });

            runInAction(() => {
                item.effectiveProductSKU = alternative.productSKU
                item.effectiveProductPrice = alternative.productPrice
                item.effectiveProductID = alternative.productID
                this.effectiveProductSKU = alternative.productSKU;
            })
        }
        catch (e) {

        }
        finally {

            //DEBUG: enforce enroll04 logic without server support
            // if ( alternative.productID === 20756 ){
            //     let enroll03 = this.cart.cartItems.find(x => x.productSKU === "enroll03" )
            //     if ( enroll03) {
            //         this.cart.cartItems.remove(enroll03)
            //     }
            //     this.cart.cartItems.unshift( {"productID":20756,"productSKU":"enroll04","productTitle":"Wallet Fast Funding","productDescription":"&nbsp;Fund your wallet immediately with $50 so you can start earning Starfish Perks.&nbsp; Funds are available for use as soon as you complete Identity Verification.<br>","productSummary":"","imageURL":"https://resources.lifeinfoapp.com/dnn/sc_images/starfish_black.jpg","quantity":1,"productPrice":"$100.00","productTC":0,"productPV":"0.00","totalCost":100,"totalPV":"0.00","totalTCs":"0.00","isDigital":1,"occurrence":"onetime","sortOrder":0})
            // }
            // else

            this.refreshItems({ cartID: this.cart.cartID, country, lang: this.commonStore.language })

        }

    }

    resetCart() {
        //this.cart = null
        //this.totals = null
    }
    setError(error, type = "series") {
        error = extractErrorMessage(error);
        if (error && type === "verify") {
            this.expired = true;
        }
        this.error = error;
    }

    setActiveStep(activeStep) {
        this.activeStep = activeStep;
    }

    get havePhysicalItems() {
        return this.cart && this.cart.cartItems && this.cart.cartItems.some(x => !x.isDigital)
            && !this.product.startsWith("starfish.kitmember")
            && !this.product.startsWith("starfish.kitupline")
    }

    handleNextStep() {
        let step = Math.min(6, Number(this.activeStep) + 1)
        if (step ===5 && !this.havePhysicalItems) {
            step = 6;
        }
        this.setActiveStep(step.toString());
    }

    handlePrevStep() {
        let step = Math.max(1, Number(this.activeStep) - 1);
        if (step ===5 && !this.havePhysicalItems) {
            step = 4;
        }
        this.setActiveStep(step.toString());
    }

    loadCountries = generateLoadList("countries", this, "loadingCountries"
        , () => { return services.Enrollment.listCountries(); }, "countries", "countries");

    verify = generateLoadEntity("verify", this, "loadingReferralInfo"
        , (loginGuid) => { return services.Enrollment.verify(loginGuid); }, "referralInfo");

    loadCart = generateLoadEntity("loadCart", this, "loadingCartInfo"
        , ({ product, lang, country }) => {
            return services.Enrollment.cart(product, lang, country);
        }, "cart", null, canDeleteAdapter);

    loadCartTotal = generateLoadEntity("loadCartTotals", this, "loadingCartTotals"
        , ({ cartID, lang, country, city = '', zip = '', region = '' }) => {
            return services.Enrollment.cartTotal(cartID, country, lang, city, zip, region);
        }, "totals");

    loadRenewalCart = generateLoadEntity("loadRenewalCart", this, "loadingRenewalCartInfo"
    , () => {
        return services.Enrollment.renewalCart();
    }, "renewalCart");

    loadAdditionalItems = generateLoadEntity("loadAdditionalItems", this, "loadingAdditionalItems"
        , async ({ country, lang }) => {

            const result = await services.Enrollment.additionalItems(country, lang);
            //DEBUG: enforce enroll04 logic without server support
            //  result.push(    {
            //      "productID": 20756,
            //      "productSKU": "enroll04",
            //      "productTitle": "Wallet Fast Funding",
            //      "productType": "Enrollment",
            //      "imageURL": "https://resources.lifeinfoapp.com/dnn/sc_images/starfish_black.jpg",
            //      "productPrice": "$100.00",
            //      "productPV": 0,
            //      "productTC": 0,
            //      "productDescription": "&nbsp;Fund your wallet immediately with $100 so you can start earning Starfish Perks.&nbsp; Funds are available for use as soon as you complete Identity Verification.<br>",
            //      "productSummary": "",
            //      "recommended": true,
            //      "occurrence": "onetime",
            //      "listOrder": 1
            //  })
            return joinMultiOptions(result);

        }, "additionalItems");


    addToCart = generateCreteEntity("addToCart", this, "addingItemToCart"
        , (params) => { return services.Enrollment.addItemToCart(params); });

    removeFromCart = generateDeleteEntity("removeItemFromCart", this, "removingItemToCart"
        , (params) => { return services.Enrollment.removeItemFromCart(params); });

    create = generateCreteEntity("create", this, "creating"
        , (params) => { return services.Enrollment.create(params); }, null, { throwError: true });

    createShopCustomer = generateCreteEntity("create", this, "creating"
        , (params) => { return services.Enrollment.enrollSC(params); }, null, { throwError: true });

    refreshItems = generateLoadEntity("refreshItems", this, "loadingCartInfo"
        , ({ cartID, lang, country }) => { return services.Enrollment.loadItems(cartID, country, lang); }
        , (result) => {
            if (this.cart) {
                if (this.cart.cartItems)
                    this.cart.cartItems.replace(result);
                else
                    this.cart.cartItems = result;
            }
        }, null, canDeleteAdapter);
}

export default EnrollmentStore;