import React from 'react'
import {Link} from 'react-router-dom'
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useStores} from "../../hooks/use-stores";


const useStyles = makeStyles((theme) => ({
    footer:{
        height:200,
    },
    logo:{
        height:30
    }
}))


export default function Footer() {
    const classes = useStyles();
    const { commonStore } = useStores();
    const {  onStarfishOrRoyalStarfish } = commonStore;
  if (onStarfishOrRoyalStarfish) return null;
  return (
    <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} className={classes.footer}>
        <Grid container item alignItems={"center"} justifyContent={"center"} direction={"column"}>
            <img
              className={classes.logo}
              src= {"/img/RoyalLogoWhite.png"}
              alt=""
            />
            <p>Copyright 2024</p>
      </Grid>

      <Grid container className="footer__container" alignItems={"center"} justifyContent={"center"} spacing={2}>
        <Grid item>Terms of Use</Grid>
        <Grid item>US</Grid>
        <Grid item>Canada</Grid>
      </Grid>
    </Grid>
  )
}
