import React from "react";
import { Switch, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import OnBoarding from "./OnBoarding";
import Members from "./Members";
import ShoppingBossOnboarding from "../components/ShoppingBossOnboarding";
import Help from "./Profile/Help";

const useStyles = makeStyles((theme) => ({

    container: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    }
}));

export default observer(({ prefix, native = true }) => {
    const classes = useStyles();

    return <Container maxWidth={"sm"} className={classes.container} disableGutters>
        <Switch>
            <Route path={`${prefix}/profile/help`} component={Help} />
            <Route path={`${prefix}/onboarding/nationalbrandstores`} component={ShoppingBossOnboarding} />
            <Route path={`${prefix}/onboarding/:tag`} component={OnBoarding} />
            <Route path={`${prefix}/members`} render={() => {
                return <Members />;
            }} />
        </Switch>

    </Container>;

});

