import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
}));


function StarGetter(){

    const classes = useStyles();
    const {commonStore, videoStore} = useStores();
    const {t} = useTranslation("starfish");
    const theme = useTheme();

    const showVideo  = ()=>{
        videoStore.setMedia( {url: "167486083",  title:"Power Player", vimeo: true } )
    }

    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/pp-image1-fr.jpg`
                    : `/img/pp-image1-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("powerplayer.power")}<span className={classes.linkColor}>{t("powerplayer.player")}</span></Typography>
                <Typography align="center" className={classes.mainText}  variant="body1">{t("powerplayer.description")}</Typography>
            </div>
            <Button color="primary" variant="contained" onClick={showVideo}>
                {t("powerplayer.watch_video")}</Button>
        </section>

        <section className={clsx( classes.section, classes.noBottomMargin)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/pp-image2-fr.jpg`
                    : `/img/pp-image2-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>

        <section className={clsx( classes.section, classes.noBottomMargin)}>
            <img
                src={ `/img/pp-image3.jpg`}
                className={classes.middleImage} role="img"/>
        </section>


        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText} variant="h3">{t("powerplayer.for_all_details")}<span className={classes.linkColor}>{t("powerplayer.start_tracking")}</span></Typography>

            </div>
            <Button color="primary" variant="contained" target="_blank" href={
                commonStore.language === "fr"
                    ? "https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_powerplayertracker_fr.pdf?alt=media&token=36c50cd4-03c2-458f-8474-5fcb1c11382c"
                    : "https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_powerplayertracker_en.pdf?alt=media&token=e4f99eaa-72d8-40d1-ae6a-ce43220557a6"
            }>
                {t("powerplayer.view_tracking_sheet")}</Button>
        </section>

        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default StarGetter