import React from "react";
import {  observer } from "mobx-react-lite";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import { joinPath} from "../../../utils/helpers";
import TransactionInfo from "./TransactionInfo";
import TransactionsList from "./TransactionsList";

export default observer ( function TransactionsRoot () {
    const transactionsMatch = useRouteMatch();


    return  <Switch>
        <Route path={joinPath( transactionsMatch.url, `create`)}  render={props=>{
            return <TransactionInfo parentMatch={transactionsMatch}/>
        }} />
        <Route path={joinPath( transactionsMatch.url, `:transactionType`)}  render={props=>{
            return <TransactionsList  parentMatch={transactionsMatch}/>
        }} />

        <Route path={transactionsMatch.url}  render={props=>{
            return <TransactionsList parentMatch={transactionsMatch}/>
        }} />

    </Switch>
})