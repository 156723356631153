import OwlCarousel from "react-owl-carousel2";
import React from "react";
import {observer} from "mobx-react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({

    block:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    title:{
        paddingTop: 70,
        paddingBottom: 20
    },
    speakerImg:{
        width:"100px !important",
        height:100,
        borderRadius:"50%",
        display: "inline-block !important"
    },
    thumbContainer:{
        position: "relative"
    },

    loadOverlay:{
        position: "absolute",
        top:0,
        bottom:0,
        left:0,
        right:0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }
}))
const SpeakersCarousel = ({ speakerAudioLoading, speakers, onClickHandler, options }) => {

    const classes = useStyles()


    if (speakers === null || speakers === undefined) return ('')

    return (
        <>
            <Typography variant={"h5"} className={classes.title}>Speakers</Typography>
            <OwlCarousel
                options={options}
            >
                {speakers.map((speaker) => {
                    return (
                        <div className={classes.thumbContainer}>
                            <div key={speaker.speaker} className={classes.block}>
                                <img
                                    onClick={() => onClickHandler(speaker.speaker)}
                                    alt="product speaker"
                                    className={classes.speakerImg}
                                    src={speaker.imageURL}
                                />
                                <span>{speaker.speaker}</span>
                            </div>
                            {speakerAudioLoading && speakerAudioLoading.includes(speaker.speaker) &&
                            <div className={classes.loadOverlay }>
                                <CircularProgress color={"secondary"} thickness={3}/>
                            </div>}
                        </div>
                    )
                })}
            </OwlCarousel>
        </>
    )
}


export default observer(SpeakersCarousel)