import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgb(40,50,55)',
        borderRadius: '10px',
        margin: `${theme.spacing(2)}px 0`,
        padding: theme.spacing(2),
    },
    balanceAlertText: {
        width: '75%',
        marginRight: theme.spacing(1),
        fontSize: '.8rem',
        '& > span': {
            fontSize: '1rem',
            fontWeight: 'bold',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.2rem'
            }
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.9rem',
            marginRight: theme.spacing(2),
        }
    },
    addMoneyButton: {
        width: '25%',
        borderRadius: '20px',
        textTransform: 'none',
        backgroundColor: '#007BFF',
        fontSize: '.75rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '.9rem',
            marginRight: theme.spacing(2),
        }
    }
}));


const BalanceNotification = ({ balance, onAddMoney }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.wrapper}>
            <Typography className={classes.balanceAlertText} dangerouslySetInnerHTML={{ __html: t('starfish:home.balance', { balance }) }} />
            <Button className={classes.addMoneyButton} onClick={onAddMoney}>
                {t('starfish:home.addMoney')}
            </Button>
        </Box>
    );
};

export default BalanceNotification;