import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import BTCConfirmWithdrawal from './BTCConfirmWithdrawal';
import BTCWithdrawalHome from './BTCWithdrawalHome';
import BTCWithdrawalSuccess from './BTCWithdrawalSuccess';
import { joinPath } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
    header: {
        background: `rgb(5, 73, 255)`,
        textAlign: 'center',
        padding: '20px 0px',
        height: '66px'
    },
    headerText: {
        fontSize: 22,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    routeContainer: {
        position: 'relative',
        margin: '0 auto',
        marginTop: '20px',
        maxWidth: '500px',
        padding: 0,
        height: 'calc(100% - 66px - 20px)',
    }
}));

const BTCWithdrawal = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const withdrawalMatch = useRouteMatch();

    const [withdrawAmountAfterFee, setWithdrawAmountAfterFee] = useState(null);
    const [btcDepositAddress, setBtcDepositAddress] = useState('');

    return (
        <Container style={{ padding: 0, height: '100%' }}>
            <Box className={classes.header}>
                <Typography
                    variant="h2"
                    className={classes.headerText}
                >
                    {t('wallet:withdrawal.title')}
                </Typography>
            </Box>
            <Container className={classes.routeContainer}>
                <Switch>
                    <Route path={joinPath(withdrawalMatch.url, `/confirm`)} render={props => {
                        return <BTCConfirmWithdrawal setWithdrawAmountAfterFee={setWithdrawAmountAfterFee} btcDepositAddress={btcDepositAddress} />
                    }} />
                    <Route path={joinPath(withdrawalMatch.url, `/success`)} render={props => {
                        return <BTCWithdrawalSuccess withdrawAmountAfterFee={withdrawAmountAfterFee} btcDepositAddress={btcDepositAddress} />
                    }} />
                    <Route path={'*'} render={props => {
                        return <BTCWithdrawalHome setBtcDepositAddress={setBtcDepositAddress} />
                    }} />
                </Switch>
            </Container>
        </Container>
    )
}

export default BTCWithdrawal;