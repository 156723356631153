import React from "react";
import {Box, Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import {useHistory} from "react-router-dom";
import {joinPath} from "../../utils/helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    marginBottom:{
        marginBottom: theme.spacing(2)
    },

    linksBlock:({isCA})=>({
        flex: 1,
        [theme.breakpoints.down(940)]: {
           flex: "unset",
        },
    ...(isCA?{"&:first-child": {
            [theme.breakpoints.up(940)]: {
                marginRight: 20
            },

            [theme.breakpoints.down(940)]: {
                paddingBottom: 25
            }
        },
        "&:last-child": {
            [theme.breakpoints.up(940)]: {
                borderLeft: `1px solid white`,
                paddingLeft: 20
            },

            [theme.breakpoints.down(940)]: {
                paddingTop: 25
            }
        }}:{})
    }),
    linkWithButton:{
        padding: 10,
        whiteSpace: "nowrap",
        [theme.breakpoints.down(999)]: {
            whiteSpace: "unset",
        },
        "& a":{
            marginRight: 10
        }
    }

}));


function Policies({parentMatch}){


    const {authStore, commonStore } = useStores();
    const {t} = useTranslation("starfish");

    const {isShoppingOnlyUser} = authStore

    const isCA = authStore.country === "CA";

    const classes = useStyles({isCA});

    return <div>
        <section className={clsx( classes.sectionDense)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/policies.jpg`
                    : `/img/policies.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <Box className={classes.textContainer} style={{width:"100%"}} display="flex" alignItems="center" flexDirection="column" >
                <Typography align={"center"} className={clsx( classes.headTextNoTopMargin)} variant="h3">{t("policies_procedures.title_pre")}<span className={classes.linkColor}>{t("policies_procedures.title")}</span>{t("policies_procedures.title_post")}</Typography>
                <Typography align={"center"} className={clsx( classes.mainText )} variant="h6">{t("policies_procedures.description")}</Typography>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            <Box display={"flex"} alignItems="center" flexWrap="wrap" justifyContent={"center"}>
                <Box flexDirection={"column"} alignItems="center" className={classes.linksBlock}>
                    {!isShoppingOnlyUser && <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href={
                            commonStore.language === "fr"
                                ? "https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_tac_fr.pdf?alt=media&token=b4382fd7-8845-4a01-b780-0e4a4f4ca8ae"
                                :"https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_tac_en.pdf?alt=media&token=4feb2281-b3e3-4f7a-afae-98057aa3817a" }>{t("policies_procedures.view")}</Button>
                        1.{" "}{t("policies_procedures.policy1")}
                    </Box>}
                    {!isShoppingOnlyUser && <Box display="flex"  alignItems={"center"}  className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href={
                            commonStore.language === "fr"
                                ? "https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2FUS_policiesandprocedures_fr_V42.pdf?alt=media&token=11dad3fc-8e25-473f-96b4-3376ea766e9f"
                                :"https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_policiesandprocedures_en_V_42.pdf?alt=media&token=611281cd-03b5-4cc2-b665-18dd523c4ea1" }>{t("policies_procedures.view")}</Button>
                        2.{" "}{t("policies_procedures.policy2")}
                    </Box>}
                    <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                        <Button variant="contained" color="primary" download target="_blank" href={
                            commonStore.language === "fr"
                                ? "https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fca_returnmaterialauthorizationform_fr.pdf?alt=media&token=12283ba5-2e75-4b7b-b643-a32880e7f376"
                                :"https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_returnmaterialauthorizationform_en.pdf?alt=media&token=75ad3a0f-ac5f-4c5a-b9bd-cc01704fca57" }>{t("policies_procedures.view")}</Button>
                        { isShoppingOnlyUser?"1":"3"}.{" "}{t("policies_procedures.policy3")}
                    </Box>
                </Box>
                 <>
                    <Box flexDirection={"column"} alignItems="center" className={classes.linksBlock}>
                        {!isShoppingOnlyUser && <Box display="flex"  alignItems={"center"}  className={classes.linkWithButton }>
                            <Button variant="contained" color="primary" download target="_blank" href="https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_tac_en.pdf?alt=media&token=4feb2281-b3e3-4f7a-afae-98057aa3817a" >{t("policies_procedures.view")}</Button>
                            1.{" "}{t("policies_procedures.policy1_tr")}
                        </Box>}
                        {!isShoppingOnlyUser && <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                            <Button variant="contained" color="primary" download target="_blank" href="https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_policiesandprocedures_en_V_42.pdf?alt=media&token=611281cd-03b5-4cc2-b665-18dd523c4ea1" >{t("policies_procedures.view")}</Button>
                            2.{" "}{t("policies_procedures.policy2_tr")}
                        </Box>}
                        <Box display="flex"  alignItems={"center"} className={classes.linkWithButton }>
                            <Button variant="contained" color="primary" download target="_blank" href="https://firebasestorage.googleapis.com/v0/b/adminlifeinfoapp.appspot.com/o/forms%2Fus_returnmaterialauthorizationform_en.pdf?alt=media&token=75ad3a0f-ac5f-4c5a-b9bd-cc01704fca57" >{t("policies_procedures.view")}</Button>
                            { isShoppingOnlyUser?"1":"3"}.{" "}{t("policies_procedures.policy3_tr")}
                        </Box>
                    </Box>
                </>
            </Box>
        </section>
        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default Policies