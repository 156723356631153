import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";


function PersonalFormEC({form, onSubmit, submitting}) {
    const {t} = useTranslation();
    return form ? <Grid container spacing={3} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} lg={7}>
            <FormControl fullWidth>
                <InputLabel
                    shrink
                    style={{position:"initial"}} error ={form.$("celuda1").error||form.$("celuda2").error}>{t(form.$("celuda1").label)}</InputLabel>
                <Grid container>
                    <Grid item xs={5}><Input value={form.$("celuda1").value} onChange={form.$("celuda1").onChange}></Input></Grid>
                    <Grid container item xs={1} alignItems={"center"} justifyContent={"center"}>-</Grid>
                    <Grid item xs={6}> <Input value={form.$("celuda2").value} onChange={form.$("celuda2").onChange}></Input></Grid>
                </Grid>
                <FormHelperText  error ={ form.$("celuda1").error||form.$("celuda2").error} >
                    {form.$("celuda1").error||form.$("celuda2").error}
                </FormHelperText>
            </FormControl>
        </Grid>
        {( onSubmit && ( submitting === undefined || !submitting  )) && <Grid item xs={12} lg={7}>
            <Button  onClick={onSubmit} fullWidth variant={"contained"}
                     color="primary">{t("enrollment:continue")}</Button>

        </Grid>}
        { onSubmit && ( submitting ) && <Grid item xs={12} lg={7}>
            <CircularProgress/>
        </Grid>}
    </Grid>:null
}

export default observer(PersonalFormEC)