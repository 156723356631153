import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogTitle, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import CreateVideoModal from './CreateVideoModal';
import Header from './Header';
import NavBar from './NavBar';
import PostGrid from './PostGrid';
import services from '../../../services';
import { useStores } from '../../../hooks/use-stores';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: 0,
        paddingBottom: theme.spacing(4),
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const TAB_LIST = {
    MY_TEAM: {
        id: 4,
        notificationID: 'MyTeam',
        name: 'lifeline:home.myTeam'
    },
    FROM_LIFE: {
        id: 1,
        notificationID: 'FromUpline',
        name: 'lifeline:home.fromLife'
    },
    PREMIUM: {
        id: 3,
        notificationID: 'Premium',
        name: 'lifeline:home.premium',
    },
    ARCHIVES: {
        id: 2,
        notificationID: 'Archived',
        name: 'lifeline:home.archived'
    }
}

const Home = ({ lifelineMatch }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { authStore, lifelineStore } = useStores();

    const [page, setPage] = useState(1);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState([]);

    const [notificationCount, setNotificationCount] = useState({});
    const [forSpouse, setForSpouse] = useState(false);
    const [currentListType, setCurrentListType] = useState(TAB_LIST.MY_TEAM);

    const [createVideoModalOpen, setCreateVideoModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);

    const { commonStore, loginGuid } = authStore;

    const switchTab = (newTab) => {
        setCurrentListType(newTab);
    }

    const getNewMessageCount = async (desiredList = -1) => {
        try {
            const result = await services.LifelineService.getNewMessageCount(loginGuid, desiredList, forSpouse);
            setNotificationCount(result);
        } catch (e) {
            setError(t('lifeline:home.countFail'));
        }
    }

    const getSettings = async () => {
        try {
            const result = await services.LifelineService.getSettings(loginGuid, forSpouse);
            lifelineStore.settings = result;
        } catch (e) {
            setError(t('lifeline:home.settingsFail'))
        }
    }

    const loadMoreVideos = async (clearOldMessages) => {
        if (!loading) {
            setLoading(true);
            setError(null);
            try {
                const result = await services.LifelineService.getVideosFromNewAPI(loginGuid, currentListType.id, forSpouse, page);
                if (clearOldMessages) { // clears old messages
                    setVideos(result.Messages)
                } else {
                    setVideos([...videos, ...result.Messages])
                }
                if (!result.hasMore) {
                    setHasMore(false);
                }
            } catch (e) {
                commonStore.warn(t('lifeline:home.loadFail'));
                setHasMore(false);
            } finally {
                setLoading(false)
            }
        }
    }

    const markMessage = async (messageID, listenedTo) => {
        try {
            const result = await services.LifelineService.markMessage(loginGuid, messageID, listenedTo, forSpouse);
            if (result) {
                await getNewMessageCount(); // when we mark the message, update the new message count
            }
        } catch (e) {
            commonStore.warn(t('lifeline:home.markFail'));
        }
    }

    const archive = async (messageID) => {
        await handleArchiveDelete(messageID, true);
    }

    const deleteMessage = async (messageID) => {
        await handleArchiveDelete(messageID, false);
    }

    const handleArchiveDelete = async (messageID, isArchiving) => {
        try {
            const result = await services.LifelineService.archive(loginGuid, messageID, isArchiving, forSpouse);
            if (result && result.statusCode == 1) {
                await getNewMessageCount();

                const removed = videos.filter(vid => vid.ID != messageID);
                setVideos(removed);
            } else {
                throw new Error('Failed to modify message');
            }
        } catch (e) {
            commonStore.warn(t('lifeline:home.modifyFail'));
        }
    }

    const respondToMessage = (voicemailID, replyUserID, lastName, replyLastName, replyFirstName, responseType) => {
        lifelineStore.respondingToMessageID = voicemailID;
        lifelineStore.responseType = responseType;
        lifelineStore.replyUserID = replyUserID;

        // Clear out any previous audio files
        if (lifelineStore.audioFile) {
            lifelineStore.audioFile = null;
        }

        if (responseType === 'passDownToGroup') {
            lifelineStore.groupName = `${lastName} (team)`;
        } else if (responseType === 'reply') {
            lifelineStore.replyName = `${replyLastName} ${replyFirstName}`;
        }

        history.push(`${lifelineMatch.url}/finalizeVideo`);

        // Recording audio is part of the create video flow, so open the modal that contains this logic
        setCreateVideoModalOpen(true);
    }

    const showCreateVideoModal = useCallback(() => {
        lifelineStore.responseType = null;
        history.push(`${lifelineMatch.url}`); // reset the modal
        setCreateVideoModalOpen(true);
    }, [createVideoModalOpen])

    const closeCreateVideoModal = useCallback(() => {
        setCreateVideoModalOpen(false);
    }, [createVideoModalOpen])

    const onScrollDown = () => {
        if (hasMore && !loading) {
            setPage(page + 1);
        }
    }

    const onTabChange = async () => {
        setVideos([])
        setPage(1);
        setHasMore(true);
        await loadMoreVideos(true);
    }

    const handleDeleteAll = async () => {
        if ( videos?.length === 0 ){
            commonStore.success(t('lifeline:home.nothingToDelete'));
            return
        }
        document.querySelectorAll('audio,video').forEach(media => media.pause())
        commonStore.showConfirm(``
            , "Delete"
            , `Delete All Messages from List?`
            , async ()=>{

                const messageTypeId = currentListType.id;
                try {
                    const result = await services.LifelineService.deleteAll(loginGuid, messageTypeId, forSpouse);
                    await loadMoreVideos(true);
                } catch (e) {
                    commonStore.warn(t('lifeline:home.deleteFail'));
                }
            } );
    }

    useEffect(() => {
        // Load the baseline to grab the user's downlines, etc.
        // We want it to run in the background, so don't async it
        lifelineStore.fetchBaseline();
    }, []);

    useEffect(() => {
        onTabChange();
    }, [currentListType])

    useEffect(() => {
        (async () => {
            if (hasMore)
                await loadMoreVideos();
        })();
    }, [page, hasMore])

    useEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            setInitialLoading(true);
            await getNewMessageCount();
            await getSettings();
            await loadMoreVideos();
            setInitialLoading(false);
        })();
    }, []);

    return (
        <Container className={classes.wrapper}>
            {error && <Alert severity="error">{error}</Alert>}
            <Header onSendNewMessage={showCreateVideoModal} onDeleteAllMessages={ videos?.length>0 ? handleDeleteAll: null} />
            {initialLoading ? <Box className={classes.progressContainer}><CircularProgress /></Box> :
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <NavBar items={TAB_LIST} currentTab={currentListType} onSwitchTab={switchTab} notificationCount={notificationCount} />
                    </Grid>
                    <PostGrid
                        loading={loading}
                        videos={videos}
                        onScrollDown={onScrollDown}
                        currentTab={currentListType}
                        onMarkMessage={markMessage}
                        onArchive={archive}
                        onDelete={deleteMessage}
                        onRespond={respondToMessage}
                    />
                </Grid>
            }
            <Box display="flex" justifyContent="center" alignItems="center">
                {!initialLoading && loading && <CircularProgress />}
                {!initialLoading && !hasMore && !error && videos?.length > 0 && !loading && <Alert severity="info">{t('lifeline:home.end')}</Alert>}
            </Box>
            <CreateVideoModal
                open={createVideoModalOpen}
                onClose={closeCreateVideoModal}
                lifelineMatch={lifelineMatch}
            />
        </Container>
    )
}

export default Home;