import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/styles";
import clsx from "clsx";
import Button from "@material-ui/core/Button";



const useStyles = makeStyles((theme) => ({
    root: {
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            padding:20,
            height: 70,


        },
    },
    button: {
        background: 'black',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        marginLeft: 30,
    },
    enabled: {
        backgroundColor: colors.colorAutoshipCancelled
    },
    disabled: {
        backgroundColor: colors.colorAutoshipDisabled
    }

}));


const AutoShipStatusBanner = ({ enabled, onClickHandler }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (<>
        {enabled ?
            <div className={clsx(classes.root, { [classes.enabled]: enabled, [classes.disabled]: !enabled })}>
                {t(enabled ? "shopping:autoship.autoship_cancelled" : "shopping:autoship.autoship_promotion")}
                {onClickHandler && <Button onClick={onClickHandler} className={classes.button} >Reactivate Autoship</Button>}
            </div>
            : null
        }
    </>

    );
};

export default AutoShipStatusBanner;
