import NumberFormat from "react-number-format";
import * as React from "react";

function NumberFormatCustom(props) {
    const { inputRef, onChange, mask = "#### #### #### ####", ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }} format={mask}
        />
    );
}

export default NumberFormatCustom