function  baseProps (isDark) {
    return {

        palette: {
            type: isDark ? 'dark' : 'light',
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#007BFF',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#FFFFFF'
            },
            info: {
                // light: will be calculated from palette.primary.main,
                main: '#007BFF',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#FFFFFF'
            },
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },

        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1180,
                lg: 1380,
                xl: 1920,
            },
        },
    }
}

export default baseProps;