import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import React, {useMemo, useState} from "react";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {FormControlLabel, FormGroup, Paper, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import LockOutlined from "@material-ui/icons/LockOutlined";
import AuthCode from 'react-auth-code-input'
import {useStores} from "../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router";
import {joinPath} from "../../utils/helpers";

const CODE_LENGTH = 8

const useStyles = makeStyles((theme) => ({
    root: {
        color: "#575962",
        height: "100%",
        width: "100%",
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed"
    },
    paper: {
        margin: 2,
        minWidth: 400
    },
    content: {
        padding: theme.spacing(4),
        paddingTop: 0,

    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#575962"
    },
    lock:{
        borderRadius: "50%",
        backgroundColor:"#e6f3fe",
        padding:20,
        display:"inline-flex",
        alignItems:"center",
        justifyContent:"center"
    },
    center:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    authContainer: {
        padding: 16
    },

    authInput: {
        width: "2ch",
        padding: 8,
        borderRadius: 8,
        fontSize: 40,
        textAlign: "center",
        marginRight: 12,
        border: "1px solid #99999999",
        textTransform: "uppercase"
    }
}));


export default observer(function RequestCode({  theme, shareMatch }) {
    const classes = useStyles();
    const { t } = useTranslation("share");
    const [code, setCode] = useState("");
    const {shareStore} = useStores()
    const history = useHistory()
    const {checkingCode, error} = shareStore

    const handleCodeChanged = (value)=>{
        setCode(value)
    }

    const handleContinue = async (value)=>{
        if ( charactersLeft === 0 ){
            let result = await shareStore.loadShareDetails(code)
            if( result )
                history.push( joinPath( shareMatch.url, code) )
        }
    }

    const  charactersLeft = useMemo(()=>{
        return CODE_LENGTH - code.length
    }, [code])


        return <ThemeProvider theme={theme}>
            <div open={true} className={classes.root}  >
                <Paper className={classes.paper}>
                    <DialogTitle className={classes.title}><div className={classes.lock}><LockOutlined/></div></DialogTitle>
                    <DialogContent className={classes.content}>
                        <div className={classes.center}><Typography variant={"h5"}>{t("easy_peasy")}</Typography></div>
                        <div className={classes.center}><Typography variant={"body1"}>{t("enter_code_label")}</Typography></div>
                        <AuthCode
                            characters={CODE_LENGTH}
                            onChange={handleCodeChanged}
                            containerClassName={classes.authContainer}
                            inputClassName={classes.authInput}
                        />
                        {error&& <Alert variant={"outlined"} color={"error"}>{t(error)}</Alert>}
                        {checkingCode[code]
                            ? <CircularProgress/>
                            : <Button disabled={charactersLeft>0} variant={"contained"} color={"primary"} fullWidth onClick={handleContinue}>
                                {charactersLeft>0?t("characters_left", {charactersLeft}):t("common:continue")}
                            </Button>}
                    </DialogContent>
                </Paper>
            </div>

        </ThemeProvider>;

});