function upperCasePipe(conformedValue) {
    return conformedValue.toUpperCase();
}

export function updatePostalCodeRules(form, country, fieldName = "billPostalCode") {
    if (country === "US") {
        form.$(fieldName).set("rules", "digits:5|required");
        form.$(fieldName).set("extra", { mask: "#####" });
    }
    else if (country === "CA") {
        form.$(fieldName).set("rules", "string|required");
        form.$(fieldName).set("extra", { mask: [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/], textMask: true, pipe: upperCasePipe });
    }
    else {
        form.$(fieldName).set("rules", "string|required");
        form.$(fieldName).set("extra", { mask: [/^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/], textMask: true, pipe: upperCasePipe });
    }
}

export function updateCardTypeRules(form, country, fieldName = "cardType") {
    if (country === "US") {
        form.$(fieldName).set("extra", [
            { value: "Visa", label: "Visa"},
            { value: "MasterCard", label: "Mastercard"},
            { value: "Discover", label: "Discover"},
            { value: "AMEX", label: "American Express"},
            { value: "VisaDebit", label: "Visa Debit"},
            { value: "MasterCardDebit", label: "Mastercard Debit"}] );
    }
    else if (country === "CA") {
        form.$(fieldName).set("extra", [
            { value: "Visa", label: "Visa"},
            { value: "MasterCard", label: "Mastercard"},
            { value: "VisaDebit", label: "Visa Debit"},
            { value: "MasterCardDebit", label: "Mastercard Debit"}] );
    }
    else {
        form.$(fieldName).set("extra", [
            { value: "Visa", label: "Visa"},
            { value: "MasterCard", label: "Mastercard"},
            { value: "VisaDebit", label: "Visa Debit"},
            { value: "MasterCardDebit", label: "Mastercard Debit"}] );
    }
}

export function updatePhoneRules(form, country) {
    if (country === "US") {
        form.$("phone").set("rules", "string|required");
    }
    else if (country === "CA") {
        form.$("phone").set("rules", "string|required");
    }
    else {
        form.$("phone").set("rules", "string");
    }
}

export function updateCardRules(form, cardType, codeField = 'cardCode') {
    if (cardType === "AMEX") {
        form.$("cardNumber").set("extra", "#### ###### #####");
        form.$(codeField).set("extra", "####");
        form.$(codeField).set("rules", "string|required|digits:4");
        form.$("cardNumber").set("rules", "string|required");
    }
    else if (cardType && cardType.includes("Visa")) {
        form.$("cardNumber").set("rules", "string|required|digits:16");
        form.$("cardNumber").set("extra", "#### #### #### ####");
        form.$(codeField).set("extra", "###");
        form.$(codeField).set("rules", "string|required|digits:3");
    } else {
        form.$("cardNumber").set("rules", "string|required");
        form.$("cardNumber").set("extra", "#### #### #### ####");
        form.$(codeField).set("extra", "###");
        form.$(codeField).set("rules", "string|required|digits:3");
    }
}

export function updateAllocationType(form, type, codeField = 'value') {
    if (type === 1) {
        form.$(codeField).set("label", "Value ($)" );
    }
    else {
        form.$(codeField).set("label", "Value (%)" );
    }



}