import {observer} from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import * as React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({

    suggestionHelp:{
        color:"#ffa534",
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"spaceBetween"


    },
    suggestionButton:{
        marginLeft:"auto",
        fontSize:10,
        color:"#ffa534",
        borderColor: "1px solid #ffa534"
    }
}));


export default  observer(({suggestionPrefix, suggestion, onApply})=>{
    const classes = useStyles();

    return <div className={classes.suggestionHelp}><span>{suggestionPrefix} {suggestion}</span>
        <Button size="small" variant="outlined" className={classes.suggestionButton} onClick={onApply}>Use suggestion</Button> </div>
})


