import plugins from './plugins';
import MobxReactForm from "mobx-react-form";

let from1to99 = [];
for (let i = 1; i <= 99; i++) {
    if (i < 10) {
        from1to99.push(`0${i}`);
    } else {
        from1to99.push(`${i}`);
    }
}

const fields = {
    US: {
        "ssn": {
            label: 'enrollmember:ssn',
            rules: 'string|required|US_SIN|pid_not_taken:ssn:ssnisalreadyregistered',
            placeholder: 'enrollmember:ssn',
            type: "mask",
            extra: '###-##-####'
        }
    },
    CA: {
        "ssn": {
            label: 'enrollmember:ssn_ca',
            rules: 'string|required|CA_SIN|pid_not_taken:ssn:sinisalreadyregistered',
            placeholder: 'enrollmember:ssn_ca',
            type: "mask",
            extra: '###-###-###'
        },
        "dateOfBirth": {
            label: 'enrollmember:dateofbirth',
            rules: 'date|required',
            placeholder: 'enrollmember:dateofbirth'
        },
    },

    AU: {
        "ssn": {
            label: 'enrollmember:ssn_au',
            rules: 'string|required|pid_not_taken:ssn:sinisalreadyregistered',
            placeholder: 'enrollmember:ssn_au',
            type: "mask",
            extra: '###-###-###'
        }
    },

    MX: {
        "rfcBusiness": {
            label: 'enrollmember:rfcbusiness',
            rules: 'string|required|pid_not_taken:rfcbusiness:thepersonalrfcenteredisregistered',
            placeholder: 'enrollmember:rfcbusiness',
        },

        "rfcPersonal": {
            label: 'enrollmember:rfcpersonal',
            rules: 'string|required|pid_not_taken:rfcpersonal:thebusinessrfcenteredisregistered',
            placeholder: 'enrollmember:rfcpersonal',
        },
        "dateOfBirth": {
            label: 'enrollmember:dateofbirth',
            rules: 'date|required',
            placeholder: 'enrollmember:dateofbirth'
        },
        "address3": {
            label: 'enrollmember:collfracc',
            rules: 'string|required',
            placeholder: 'enrollmember:collfracc',
        },
        "birthCity": {
            label: 'enrollmember:cityofbirth',
            rules: 'string|required',
            placeholder: 'enrollmember:cityofbirth',
        },
        "birthCountry": {
            label: 'enrollmember:countryofbirth',
            rules: 'string|required',
            placeholder: 'enrollmember:countryofbirth',
        }
    },

    EC: {
        "celuda1": {
            label: 'enrollmember:celuda',
            rules: 'string|required',
            placeholder: 'enrollmember:dni'
        },
        "celuda2": {
            rules: 'string|required',
        }
    },

    PE: {
        "dni": {
            label: 'enrollmember:dni',
            rules: 'string|required|pid_not_taken:dni:thednienteredisregistered',
            placeholder: 'enrollmember:dni'
        },
        "ruc": {
            label: 'enrollmember:ruc',
            rules: 'string|required|pid_not_taken:ruc:therucenteredisregistered',
            placeholder: 'enrollmember:ruc'
        }
    },


    FR: {
        "prefix": {
            label: 'enrollmember:courtesytitle',
            rules: 'string|required',
            placeholder: 'enrollmember:courtesytitle',
            extra: ["Mme", "Mlle", "M."]
        },

        "dateOfBirth": {
            label: 'enrollmember:dateofbirth',
            rules: 'date|required',
            placeholder: 'enrollmember:dateofbirth'
        },

        "birthCity": {
            label: 'enrollmember:cityofbirth',
            rules: 'string|required',
            placeholder: 'enrollmember:cityofbirth',
        },
        "birthCountry": {
            label: 'enrollmember:countryofbirth',
            rules: 'string|required',
            placeholder: 'enrollmember:countryofbirth',
        },
        "birthDept": {
            label: 'enrollmember:birthdeptnumber',
            rules: 'string|required',
            placeholder: 'enrollmember:birthdeptnumber',
            extra: from1to99
        },
        "ssn": {
            label: 'enrollmember:ssn',
            rules: 'string|required',
            placeholder: 'enrollmember:ssn',
        },
        "nationality": {
            label: 'enrollmember:countryofnationality',
            rules: 'string|required',
            placeholder: 'enrollmember:countryofnationality',
        },
        "distributorContract": {
            label: 'enrollmember:distributorcontract',
            rules: 'string|required',
            placeholder: 'enrollmember:distributorcontract',
            extra: ["VDI Mandataire", "Micro Entrepreneur", "Entreprise Individuelle", "Société"]
        },
        siret: {
            label: 'enrollmember:vat',
            rules: 'string|required_unless:distributorContract,VDI Mandataire',
            placeholder: 'enrollmember:vat',
        },
        subjectSocial: {
            label: 'enrollmember:subjecttosocialcontributions',
            rules: 'string|required',
            placeholder: 'enrollmember:subjecttosocialcontributions',
            extra: ["Oui", "Non"]
        },
        subjectVat: {
            label: 'enrollmember:subjecttovat',
            rules: 'string|required',
            placeholder: 'enrollmember:subjecttovat',
            extra: ["Oui", "Non"]
        }
    }
};



export default function create(country, hooks) {
    let f = { ...fields[country] };
    let form = new MobxReactForm({ fields: f }, { plugins, hooks, options: { validateDisabledFields: true } });
    form.country = country;
    if (country === "FR") {
        form.$('distributorContract')
            .observe(({ form, field, change }) => {
                switch (field.value) {
                    case "VDI Mandataire":
                        form.update({ subjectSocial: "Oui", subjectVat: "Non", siret: "" });
                        form.$("subjectSocial").set("disabled", true);
                        form.$("subjectVat").set("disabled", true);
                        form.$("siret").$extra = "hidden";
                        break;
                    case "Micro Entrepreneur":
                        form.update({ subjectSocial: "Non", subjectVat: "Non" });
                        form.$("subjectSocial").set("disabled", true);
                        form.$("subjectVat").set("disabled", true);
                        form.$("siret").$extra = "";
                        break;
                    case "Entreprise Individuelle":
                        form.$("siret").$extra = "";
                        form.update({ subjectSocial: "Non" });
                        form.$("subjectSocial").set("disabled", true);
                        form.$("subjectVat").set("disabled", false);
                        break;
                    case "Société":
                        form.$("siret").$extra = "";
                        form.update({ subjectSocial: "Non" });
                        form.$("subjectSocial").set("disabled", true);
                        form.$("subjectVat").set("disabled", false);
                        break;


                }
            });
    }
    return form;
}

