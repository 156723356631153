

const blacklist = ['078051120', '219099999', '457555462'];


const SIN_LENGTH = 9;

const luhnChecksum = (sin) => {
    let len = SIN_LENGTH;
    let mul = 0;
    const luhnArr = [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
    ];
    let sum = 0;
    while (len--) {
        sum += luhnArr[mul][parseInt(sin.charAt(len), 10)];
        mul = mul ^ 1;
    }
    console.log(sum)
    return sum % 10 === 0;
};


export const syncRules = {
    // REGEX from: https://gist.github.com/cbourdage/79ecd1a31aa344d9fc86
    nopobox:  { func: (value, requirement, attribute) => {
        return !value.match(/([Pp](ost)?[\.\s-]*?[Oo](ffice)?)+?[\.\s-]*?([Bb](ox)?)[\.\s+-]*?\d+/i);
    }, message: "Address cannot contain a PO Box number." },

    US_SIN:  { func: (value, requirement, attribute) => {
            const expression = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
            if (!expression.test(value)) {
                return false;
            }

            if( blacklist.indexOf(value.replace(/\D/g, '')) > -1){
                return  false
            }
            //value.split("").map( Number ).map( (v,i)=> ( ((i+1)%2===0)? v*2  )  )
            return true

        }, message: "Social Insurance Number is not valid" },



    CA_SIN:  { func: (value, requirement, attribute) => {
            const expression = /^(?!8|0\d{1})\d{9}$/;
            if (!expression.test(value)) {
                return false;
            }
            return luhnChecksum(value)

        }, message: "Social Insurance Number is not valid" }
};