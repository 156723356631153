import RestService from "./RestService";



export default class MenuItems extends RestService {
    constructor() {
        super('/api/lifeapp/notifications' )
    }

}
