import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import {observer} from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";

const VideoPreviewModal = ({t, width, mediaURL, onClickHandler }) => {

    if ( !mediaURL ) return null
    if (mediaURL.mediaURL) {
        if (mediaURL.mediaURL.endsWith(".mp4")) {
            return (
                <video height="350">
                    <source src={mediaURL.mediaURL} type="video/mp4" />
                </video>
            )
        }

        return <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClickHandler}
                    id="productImageModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
        >
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>{t('shopping:description.videopreview')}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => onClickHandler()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>

            <div
                className="modal-dialog modal-dialog-centered modal-product-image"
                role="document"
            >
                <div
                    style={{
                        height: width > 1000 ? 500 : width > 500 ? 400 : 350,
                        width: '100%'
                    }}
                    className="modal-content modal-product-image__wrapper">
                    <iframe
                        title="Video Preview"
                        id="iframeId"
                        frameBorder={0}
                        height={width > 1000 ? 480 : width > 500 ? 350 : 300}
                        width= '100%'
                        src={`https://player.vimeo.com/video/${mediaURL.mediaURL}`}></iframe>
                </div>
            </div>
        </Dialog>
    }

    // return image
    return <Dialog open={true} maxWidth={"sm"} fullWidth onClose={onClickHandler}
            id="productImageModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
    >
        <DialogTitle disableTypography >
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Typography>{t('shopping:description.preview')}</Typography>
                <div style={{ cursor: "pointer" }} onClick={() => onClickHandler()}>
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </div>
            </Grid>
        </DialogTitle>
        <DialogContent  >
            <Grid container justifyContent={"center"}>
                 <img src={mediaURL.imageURL} alt="" />
            </Grid>
        </DialogContent>
    </Dialog>
}


export default observer(VideoPreviewModal)