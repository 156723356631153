import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class TestStore {
  testLoading = null;
  testInfo = null;

  submitAnswerResult = null;
  submittingAnswer = null;

  testError = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "testError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  getTestByIdLabel = generateLoadEntity(
    "testInfo",
    this,
    "testLoading",
    ({ lang, testIdLabel }) => {
      return services.TestService.getTestByIdLabel(testIdLabel, lang);
    },
    "testInfo"
  );

  submitAnswer = generateLoadEntity(
    "testInfo",
    this,
    "submittingAnswer",
    ({ testIdLabel, lang, answers }) => {
      return services.TestService.submitAnswer(testIdLabel, lang, answers);
    },
    "testInfo"
  );
}

export default TestStore;
