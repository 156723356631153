import * as React from 'react'
import {observer} from 'mobx-react-lite'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";


export default observer(({
                             field,
                             className,
                             InputLabelProps,
                             margin,
                             options,
                             labelAccessor,
                             valueAccessor,
                             fullWidth,
                             onChange,
                             ...rest
                         }) => {

    valueAccessor = valueAccessor || ((x)=>x.value)
    labelAccessor = labelAccessor || ((x)=>x.label)

    const handleOnChnage = (e)=>{
        field.onChange(e)
        if ( onChange )
            onChange(e.target.value)
    }
    const {t} = useTranslation();

    return (
        <FormControl disabled={field.disabled} fullWidth={fullWidth} margin={margin} className={className}>
            <InputLabel error ={field.error} {...InputLabelProps} >{t(field.label)}</InputLabel>
            <Select
                fullWidth
                {...field.bind()}
                onChange={handleOnChnage}
                {...rest}
                label={t(field.label)}
                placeholder={t(field.placeholder)}
                >
                {(options|| field.extra).map(x=>{
                    return <MenuItem key={valueAccessor(x)} value={valueAccessor(x)}>{labelAccessor(x)}</MenuItem>
                })}
            </Select>
            <FormHelperText  error ={ field.error} >
                {field.error}
            </FormHelperText>
        </FormControl>
    )
})