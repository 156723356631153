import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo} from "react";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import { Paper} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {useHistory, useParams} from "react-router";
import {useStores} from "../../hooks/use-stores";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
        height: "100%",
        width: "100%",
        outline: 0,
        display: "flex",
        justifyContent: "flex-start",
        flexDirection:"column",
        position: "fixed"
    },
    header:{
        width:"100%",
        backgroundColor: "black",
        color: "white",
        height: 80,
        display: "flex",
        flexDirection:"column",
        alignItems: "center",

    },
    paper: {
        margin: 2
    },
    frame:{
        maxWidth: 600,
        minHeight: 400,
        width: "100%",
        marginTop: 100,
        border:"none"
    },
    content: {
        flex:1,
        padding: theme.spacing(4),
        paddingTop: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#575962"
    },
}));


export default observer(function RequestCode({  shareMatch, theme }) {
    const classes = useStyles();
    const { t } = useTranslation("share");
    const {shareStore} = useStores()
    const {code} = useParams()
    const history = useHistory()
    const {checkingCode, error, shareDetails } = shareStore
    const details = shareDetails[code]
    const type = shareDetails[code] && shareDetails[code].messageType

    useEffect(()=>{
        ( async ()=>{
            let result = await shareStore.loadShareDetails(code)
            if (!result)
                history.push(shareMatch.url)
        })()

    }, [code])

    const effectiveFrameUrl = useMemo( ()=>{
        switch (type) {
            case 6:
            case 7:
                return  `https://player.vimeo.com/video/${details.mediaURL}`
            case "marketingvideo":
            case "prezi":
                return  details.mediaURL
            default:
                return null
        }
    },[type, details, code ])

    return <ThemeProvider theme={theme}>
        <div className={classes.root}>
            {details && <header className={classes.header}>
                <Typography variant={"subtitle1"}>{t("this_message_sent")}</Typography>
                <Typography variant={"subtitle1"}>{`${details.senderInfo.senderDisplayName} <${details.senderInfo.senderEmail}>`} </Typography>
            </header>}
            <div className={classes.content}>
                {effectiveFrameUrl && <iframe className={classes.frame} src={effectiveFrameUrl}>

                </iframe>}
            </div>
        </div>
    2</ThemeProvider>;
});