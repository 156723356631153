import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        background: `rgb(5, 73, 255)`,
        borderRadius: '30px',
        color: 'white',
        textTransform: 'none',
        width: '100%',
        padding: '12px 0px',
        '&:hover': {
            background: 'rgb(3, 60, 234)'
        },
        '& > span': {
            fontSize: '16px'
        }
    },
    bottomButtonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '0px 18px'
    }
}));

const WithdrawButton = ({ onClick, disabled, children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.bottomButtonContainer}>
            <Button className={classes.button} onClick={onClick} disabled={disabled} >
                {children}
            </Button>
        </Box>
    )
}

export default WithdrawButton;