import { makeAutoObservable, reaction, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import shippingAddressForm from "../forms/shippingAddress";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList, generateLoadList2,
    generateUpdateEntity
} from "../utils/mobx";
import { getCookie, deleteCookie, setCookie } from "../utils/cookieUtil";

class SubscriptionStore {
    constructor() {
        makeAutoObservable(this);
    }
    error = null;
    updateSubscriptionError = null;
    updateAddressError = null;
    updatePaymentError = null;
    mySubscriptionsLoading = false;
    cancelingSubscription = false;
    renewingSubscription = false;
    reactivatingSubscription = false;
    addressUpdating = false;
    paymentUpdating = false;
    subscriptionsInfo = null;
    loadingMySubscriptions=null;
    gettingCheckoutGuid=null;
    mySubscription=null;


    setError(error, type = "") {
        error = extractErrorMessage(error);
        switch (type){
            case "updateSubscription":
                this.updateSubscriptionError = error;
                break;
            case "updateAddress":
                this.updateAddressError = error;
                break;
            case "updatePayment":
                this.updatePaymentError = error;
                break;
            default:
                this.error = error;
        }
    }

    get mySubscriptions(){
        return this.subscriptionsInfo ? this.subscriptionsInfo.current:[]
    }

    get availableSubscriptions(){
        return this.subscriptionsInfo ? this.subscriptionsInfo.available:[]
    }

    updateAddress = generateUpdateEntity("updateAddress", this, "addressUpdating"
        , (categoryID, shipID) => {
            return services.Subscription.updateShipping(categoryID, shipID);

        },);

    updatePayment = generateUpdateEntity("updatePayment", this, "paymentUpdating"
        , (categoryID, paymentID) => {
            return services.Subscription.updatePayment(categoryID, paymentID);

        },);


    cancelSubscription = generateUpdateEntity("cancelSubscription", this, "cancelingSubscription"
        , (categoryID) => {
            return services.Subscription.cancelSubscription(categoryID);

        },);

    renewSubscription = generateUpdateEntity("renewSubscription", this, "renewingSubscription"
        , (categoryID, orderId) => {
            return services.Subscription.renewSubscription(categoryID, orderId);

        },);

    reactivateSubscription = generateUpdateEntity("reactivateSubscription", this, "reactivatingSubscription"
        , (categoryID) => {
            return services.Subscription.reactivateSubscription(categoryID);

        },);


    update = generateUpdateEntity("updateSubscription", this, "updatingSubscription"
        , (categoryID, values) => {
            return services.Subscription.updateSubscription(categoryID, values);
        });


    loadManageSubscriptions = generateLoadEntity("loadManageSubscriptions", this, "mySubscriptionsLoading"
        , () => {
            return services.Subscription.loadManageSubscription();
        }, "subscriptionsInfo");

    loadMySubscriptionInfo = generateLoadEntity("loadMySubscriptionInfo", this, "loadingMySubscriptions"
        , (categoryID) => {
            return services.Subscription.loadMySubscription(categoryID);

        }, "mySubscription");


    getCheckoutGuid = generateCreteEntity("getCheckoutGuid", this, "gettingCheckoutGuid",
        async (productID ) => {
                    const cartResponse =  await  services.Subscription.createUserCart();
                    //const productByCategory = await  services.Subscription.getProductSKUbyCategory(categoryID);
                    await  services.Subscription.addItemToCart({
                        cartID: cartResponse.cartID,
                        productID: productID,
                        qty:1
                    } );
                    return cartResponse.cartID

},);



}

export default SubscriptionStore;