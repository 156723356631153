import React from 'react';

import MainCategoryListContainerItem from './MainCategoryListContainerItem';
import Grid from "@material-ui/core/Grid";
import {observer} from "mobx-react";
import {useShopType} from "../../../containers/shop/useShopType";
import MenuItem from "../../MenuItem";
import {Box} from "@material-ui/core";
import {joinPath} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";

function HomeListContainer({categoryItems, onAutoShipClick}) {
  const shopParams = useShopType()
  const {  t } = useTranslation();
  const renderHomeListItems = () => {
    if (categoryItems) {
      return categoryItems.map((category, i) => {

        return <MenuItem
          key={i}
          item={{}}
          title={t(`shopping:categories.${category.parentCategoryName}`)}
          path={joinPath( shopParams.home, `category/${category.parentCategoryID}`)}
          childCategories={category.childCategories}
          category={category}
          onAutoShipClick={onAutoShipClick}
        />
      })
    }
  }

    return (
      <Box container spacing={3} flex direction={"column"} >
        {renderHomeListItems()}
      </Box>
    )
}

export default observer(HomeListContainer);
