import React from "react";
import {observer} from "mobx-react";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import BuildIcon from "@material-ui/icons/Build";



const useStyles = makeStyles((theme) => ({
    root: {
       padding: theme.spacing(2),
        marginTop:  theme.spacing(5),
    }
}));

function UnderConstruction({isNativeApp}){
    const classes = useStyles()
    return <Alert icon={<BuildIcon fontSize="inherit" />} color={"info"} variant={"filled"} className={classes.root}
        action={
            <Button
                variant={"contained"}
                aria-label="home"
                color="primary"
                size="small"
                href="/"
            >
               Navigate to Home
            </Button>
        }
    >
        This page is under construction
    </Alert>
}

export default observer(UnderConstruction)

