import IdLessRestService from "./IdLessRestService";


export default class Billing extends IdLessRestService {
    constructor(parentService, parentId) {
        if (parentService) {
            if ( parentId)
                super(`${parentService.baseUrl}/${parentId }/paymentmethod`)
            else
                super(`${parentService.baseUrl}/paymentmethod`)
        }
        else
            throw new Error("Can be only nested service")
    }
}
