import React, {useEffect, useState} from 'react';
import {Route, Redirect, useLocation} from 'react-router';
import {observer} from "mobx-react-lite";
import {useStores} from "../hooks/use-stores";
import { boolean } from 'boolean';


function PrivateRouteWithConventionalGuest({component: Component, render, notLoggedUrl, ...rest}) {
    const {authStore} = useStores()
    const {isAuthenticated, isConventionGuest } = authStore

    return (<Route
        {...rest}
        render={(props) => {
            return ( isConventionGuest || ( isAuthenticated === true && authStore.token != null) )
                ? (Component?<Component {...props} {...rest} />:render(rest))
                : <Redirect to={"/"}/>

        }}
    />)
}

export default observer(PrivateRouteWithConventionalGuest)