import {withStyles} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        whiteSpace: "nowrap"
    }
})(MuiTableCell);

export default TableCell;