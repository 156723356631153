import RestService from "./RestService";


export default class MyIncomeService extends RestService {
    constructor() {
        super('/api/myincome');
    }

    t

}
