import { makeAutoObservable } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity, generateLoadList } from "../utils/mobx";

class MenuStore {
    constructor() {
        makeAutoObservable(this);
    }

    news = [];
    newsLoading = false;
    newsPostLoading = false;
    newsPost = [];
    path = "";
    error = null;

    setError(error, type = "news") {
        error = extractErrorMessage(error);
        this.error = error;
    }


    loadNews = generateLoadList("news", this, "newsLoading"
        , (fromModule) => {
            return services.News.getNews(fromModule);
        }, "news");

    loadNewsPost = generateLoadEntity("newsPost", this, "newsPostLoading"
        , ({ id, fromModule }) => {
            return services.News.getDetails(id, fromModule);
        }, "newsPost", "[0]");


}

export default MenuStore;