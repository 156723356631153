import {Box, Typography} from "@material-ui/core";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {greenColor, redColor} from "../../../components/styles";
import {formatNumber} from "../../../utils/helpers";



const useStyles = makeStyles((theme) => ({
    dangerColor: {
        ...redColor()
    },
    successColor: {
        ...greenColor()
    }
}));


function Variance({amount, reverseColor = false}) {

    amount = Number(amount);
    const classes = useStyles();

    if (amount === 0) {
        return <Typography style={{paddingLeft:6}} >
            {formatNumber(amount, 0)}
        </Typography>
    }
    if (amount > 0) {
        if (reverseColor) {
            return <Box display={'flex'} alignItems={'center'}>
                <KeyboardArrowUp className={classes.dangerColor}/>
                <Typography className={classes.dangerColor}>{formatNumber(amount, 0)}</Typography>
            </Box>
        } else {
            return <Box display={'flex'} alignItems={'center'}>
                <KeyboardArrowUp className={classes.successColor}/>
                <Typography className={classes.successColor}>{formatNumber(amount, 0)}</Typography>
            </Box>
        }
    }

    if (amount < 0) {
        if (reverseColor) {
            return <Box display={'flex'} alignItems={'center'}>
                <KeyboardArrowDown className={classes.successColor}/>
                <Typography variant={"body"} className={classes.successColor}>{formatNumber(amount * -1, 0)}
                </Typography>
            </Box>
        } else {
            return <Box display={'flex'} alignItems={'center'}>
                <KeyboardArrowDown className={classes.dangerColor}/>
                <Typography variant={"body"} className={classes.dangerColor}>{formatNumber(amount * -1, 0)}
                </Typography>
            </Box>
        }
    }
    return  ""
}

export  default Variance;