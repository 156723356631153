import RestService from "./RestService";



export default class News extends RestService {
    constructor() {
        super('/api/onboarding' )
    }


    cards(language){
        return this.get(`${this.baseUrl}/cards/${language}`)
    }

    memberOnboardingCards(language){
        return this.get(`/api/members/onboarding/cards/${language}`)
    }

    shoppingBossOnboarding(language){
        return this.get(`/api/lifeapp/promos/superapp`)
    }

}
