export function actions(theme) {
    return {
        actions: {
            justifyContent: "flex-end"
        }
    }
}
export function avatar(theme, zoom=1) {
    return {
        avatar:{
            height:70*zoom,
            width:70*zoom,
        }
    }
}

export function copyToClipBoard(theme) {
    return {
        copyToClipBoard: {
            color: "#007BFF",
            backgroundColor: "white",
            borderRadius: '22px',
            "&:hover": {
                color: "white",
                backgroundColor: "#007BFF",
            },
        }
    }
}


export function accordion(theme) {

    return {
        accordion:{
            //marginTop: 20
            '@media print': {
                "box-shadow": "none !important",
                "&.Mui-expanded": {
                    display: "block"
                }
            }

        },
        accordionSummary: {
            ...starFishColor(theme, "backgroundColor"),
            "&.Mui-expanded": {
                margin: 0,
                minHeight: 50
            },
            "& .MuiAccordionSummary-content": {
                margin: "11px 0"
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "11px 0"
            },
            '@media print': {
                backgroundColor: "white",
                "& p":{
                    fontSize: "1.2em",
                    fontWeight: "bold"
                },
                "& .MuiAccordionSummary-expandIcon":{
                    display: "none !important"
                }

            }
        },
        accordionDetails: {
            flexDirection: "column",
            padding: "0px 16px 8px 16px",
            [theme.breakpoints.down('sm')]: {
                padding: "0px 0px 8px 0px",
            }
        },
        accordionDetailsColumn: {
            flexDirection: "column",
            alignItems: "center"
        }
    }
}


export function card(theme) {
    return {
        cardWrapper:{
            display: "inline-flex"
        },
        card:{
            flexGrow: 1,
            flexBasis: 0,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column"
        },
        cardMainArea: {
            flexGrow: 1,
            display: "inline-flex",
            alignItems: "flex-start",
            justifyContent: "flex-start"
        },
        qty:{
            marginLeft:20
        },
        logo: {
            marginRight: theme.spacing(1)
        },
        ...avatar(theme)
    }
}

export function statusStyles(theme) {
    return {
        status: {
            textTransform: "uppercase"
        },
        statusActive: {
            color: theme.palette.success.main
        },
        statusDeclined: {
            color: theme.palette.error.main,
        },
    }
}

export function securityBanner(theme) {
    return {
        securityBanner: {
            padding: theme.spacing(2),
            margin: theme.spacing(2)
        }
    }
}


export function greenColor(theme, propNam="color") {
    return {
        [propNam]: "#51c07a"
    }
}
export function starFishColor(theme, propNam="color") {
    if ( !propNam )  return "#007BFF"
    return {
        [propNam]: "#007BFF"
    }
}

export function redColor(theme, propNam="color") {
    return {
        [propNam]: "red"
    }
}