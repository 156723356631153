import {makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadList} from "../utils/mobx";

class BannerStore {
    constructor( authStore ) {
        makeAutoObservable(this)
        this.authStore = authStore
    }

    bannersLoading = false
    banners = {royal: [], members: []}
    error = null

    setError(error, type = "banner") {
        error = extractErrorMessage(error)
        this.error = error
    }


    get membersBanners() {
        return this.banners["members"]
    }

    get royalBanner() {
        return this.banners["royal"]
    }


    async loadBanners() {
        if( !this.authStore.currentUser || !["US", "CA"].includes( this.authStore.currentUser.country) ) {
            return
        }
        if( !(this.authStore?.currentUser.isStarfish || this.authStore?.currentUser.isSuperAppPlus) ) {
            return
        }
        this.setError(null, "banners")
        this.bannersLoading = true
        try {
            let [royal, members] = await Promise.all([services.Banners.fetch("royal"), services.Banners.fetch("members")])
            this.banners["royal"].replace( royal.banners )
            this.banners["members"].replace( members.banners)
        }
        catch(e) {
            this.setError("Error while loading banners: " + extractErrorMessage(e), "banners")
        }
        finally {
            runInAction(() => {
                this.bannersLoading = false
            })
        }

    }
}

export default BannerStore