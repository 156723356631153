import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class CriticalMessageStore {
  criticalMessageLoading = null;
  criticalMessageInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "criticalMessageError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  loadData = generateLoadEntity(
    "criticalMessageInfo",
    this,
    "criticalMessageLoading",
    (params) => {
      return services.CriticalMessage.list(params);
    },
    "criticalMessageInfo"
  );
}

export default CriticalMessageStore;
