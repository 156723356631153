import React from 'react';
import Container from 'react-bootstrap/Container';

const LoadingIndicator = ({tag}) => {
  return (
    <Container className="search-main-content">
        <h1 className="message-loading">Loading <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </h1>
    </Container>
  )
}

export default LoadingIndicator;
