import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CreateVideoFlow from '../CreateVideo';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../hooks/use-stores';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        position: 'relative',
        background: 'white'
    },
    dialogContentRoot: {
        overflowY: 'visible',
    },
    paperFullWidth: {
        overflowY: 'visible',
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        background: 'white',
        color: 'black'
    },
    titleText: {
        fontSize: '1.4rem',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.8rem'
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 0,
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(1.5)
        }
    },
    button: {
        color: 'white'
    },
    closeIcon: {
        fontSize: '40px',
        color: 'black'
    }
}));

const TopLevelDialog = withStyles({
    root: {
        position: 'fixed',
        right: '0px',
        bottom: '0px',
        top: '0px',
        left: '0px',
        zIndex: 3,
    },
})(Dialog);

const CreateVideoModal = ({ open, onClose, lifelineMatch }) => {
    const { lifelineStore } = useStores();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const pageTitle = () => {
        if (location.pathname.includes('createVideo')) {
            return 'lifeline:createVideo.modal.recordNew';
        } else if (location.pathname.includes('reviewVideo')) {
            return 'lifeline:createVideo.modal.review';
        } else if (location.pathname.includes('finalizeVideo')) {
            return 'lifeline:createVideo.modal.finalize';
        } else if (location.pathname.includes('completed')) {
            return 'lifeline:createVideo.modal.completed';
        } else {
            return 'lifeline:createVideo.modal.sendNew'
        }
    }

    const closeModal = () => {
        if (location.pathname.includes('completed')) {
            window.location.href = lifelineMatch.url;
        } else {
            history.push(lifelineMatch.url)
            onClose();
        }
    }

    return (
        <TopLevelDialog
            classes={{
                root: classes.paperFullWidth,
            }}
            maxWidth="sm"
            fullWidth
            fullScreen={fullScreen}
            open={open}
            onClose={closeModal}
        >
            <DialogTitle disableTypography className={classes.title}>
                <Typography variant="h4" className={classes.titleText}>{t(pageTitle())}</Typography>
                {
                    !lifelineStore.uploading &&
                    <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
                        <CloseIcon color="error" className={classes.closeIcon} />
                    </IconButton>
                }
            </DialogTitle>
            <DialogContent className={classes.dialogContent} classes={{
                root: classes.dialogContentRoot
            }}>
                <CreateVideoFlow lifelineMatch={lifelineMatch} />
            </DialogContent>
        </TopLevelDialog>
    )
}

export default observer(CreateVideoModal);