import RestService from "./RestService";



export default class MenuItems extends RestService {
    constructor() {
        super('/api/lifeapp/menuitems/v3' )
    }

    web(){
        return this.get('/api/lifeapp/menuitems/web')
    }
}
