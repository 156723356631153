import RestService from "./RestService";

export default class Members extends RestService {
    constructor() {
        super('/api/members' )
    }

    async pid(){
        return this.get( `${this.baseUrl}/pid`)
    }

    async customers(){
        return this.get( `${this.baseUrl}/customers`)
    }


    async sendSeminarInvite(email, lang){
        return this.post( `${this.baseUrl}/customers/sendSeminarInvite`, {email, lang})
    }
    async sendPlatformInvite(toUserID, lang){
        return this.post( `${this.baseUrl}/customers/sendPlatformInvite`, {toUserID, lang})
    }
    async sendRoyalInvite(toUserID, lang){
        return this.post( `${this.baseUrl}/customers/sendRoyalInvite`, {toUserID, lang})
    }

   async updateCustomerToMemberEnrollStatusCall(customerLifeNo){
        return this.post( `${this.baseUrl}/customers/customerToMemberEnroll`, {customerLifeNo})
    }


    async lookupDownlineCustomer(userID, email){
        return this.get( `${this.baseUrl}/customers/lookupDownlineCustomer`, {email})
    }

}
