import Grid from "@material-ui/core/Grid";
import MaterialSelect from "./MaterialSelect";
import React from "react";
import {observer} from "mobx-react-lite";
const getExpMonths = () => {
    const currentYear = (new Date()).getFullYear() % 100;
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    return range(currentYear, currentYear + 10, +1);
};

function  CardExpYear({form, fieldName="cardExpYear"}) {

    return <Grid item xs={4} lg={4}>
        <MaterialSelect
            fullWidth
            margin="normal"
            field={form.$(fieldName)}
            InputLabelProps={{
                shrink: true,
            }}
            labelAccessor={(x => x.toString().padStart(2, "0"))}
            valueAccessor={(x => x)}
            options={getExpMonths()}
        />
    </Grid>
}


export default observer(CardExpYear)