import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { useStores } from "../hooks/use-stores";
import { setCookie } from '../utils/cookieUtil';


export default (observer(({ component: C, ...rest }) => {
    const location = useLocation();
    const { authStore } = useStores();
    const { currentUser } = authStore;
    let loginguid = new URLSearchParams(location.search).get("loginguid");
    let destination = new URLSearchParams(location.search).get("destination");
    const [redirectTo, setRedirectTo] = useState(false);

    useEffect(
        () => {
            if (loginguid) {
                (async () => {
                    let result = await authStore.loginByGuid(loginguid);
                    setCookie('nativeapp', 'true', 1);
                    setRedirectTo(true);
                })();
            }
        }, [loginguid, currentUser, authStore, destination]);

    if (currentUser && !!redirectTo) {
        if (destination === "membersonly")
            return <Redirect to={"/members/salesachiever"} />;
        else if (destination === "home")
            return <Redirect to={"/androidwebview"} />;
        else
            return <Redirect to={"/androidwebview"} />;
    }
    return null;

}));