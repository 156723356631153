import { Container, Paper, TextField, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useStores } from "../hooks/use-stores";

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    paddingTop: "60px",
  },
  page: {
    backgroundColor: "black",
    height: "100vh",
    color: "white",
  },
  header: {
    height: 58,
    borderBottom: "1px solid #007BFF",
  },
  headerContainer: {
    height: 58,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  headerLogo: {
    height: 27,
    width: 150,
  },
  headerText: {
    fontSize: 24,
    flexGrow: 1,
    textAlign: "center",
  },
  headerTextPassword: {
    fontWeight: "bold",
  },
  body: {
    height: "100%",
  },
  bodyContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    paddingTop: "40px",
  },
  errorMessage: { fontSize: "18px", marginTop: "50px", textAlign: "center" },

  form: {
    width: "500px",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
  },
  input: {
    marginTop: "12px",
    "& .MuiInputBase-input": {
      padding: "14px",
    },
  },
  submitButton: {
    display: "block",
    textTransform: "uppercase",
    margin: "16px auto 0 auto",
  },
  validation: {
    color: "rgb(239 68 68)",
  },
  successWrapper: { padding: "16px 0" },
  successTitle: {
    margin: "0 0 24px 0",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
  },
}));

function InvalidError({ error }) {
  const classes = useStyles();

  return (
    <p className={classes.errorMessage}>
      Invalid Link, Please Contact{" "}
      <a href="mailto:support@starfishperks.com" target="_blank">
        support@starfishperks.com
      </a>
    </p>
  );
}

function ResetPassword() {
  const [validattion, setValidation] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = Number(searchParams.get("id"));
  const token = searchParams.get("token");

  const { resetPasswordStore } = useStores();
  const {
    validateTokenInfo,
    validateTokenLoading,
    error,
    resetPasswordInfo,
    resetPasswordLoading,
  } = resetPasswordStore;
  const isValidatingToken =
    validateTokenLoading === null || validateTokenLoading === true
      ? true
      : false;
  const isInvalidToken = error && !validateTokenInfo;

  const validateToken = () => {
    if (!validateTokenLoading) {
      void resetPasswordStore.validateToken({
        token,
        userId,
      });
    }
  };
  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    if (error) {
      setValidation(error);
    }
  }, [error]);

  return (
    <div className={clsx(classes.page)}>
      <div className={clsx(classes.header)}>
        <Container maxWidth={"md"} className={classes.headerContainer}>
          <img src={`/starfish_full.png`} className={classes.headerLogo} />
        </Container>
      </div>
      <div className={classes.body}>
        <p className={classes.headerText}>
          <span className={classes.headerTextPassword}>Password</span>
          &nbsp;
          <span>Recovery</span>
        </p>
        <Container maxWidth={"md"} className={classes.bodyContainer}>
          {isValidatingToken ? (
            <div className={classes.progressContainer}>
              <CircularProgress size={90} />
            </div>
          ) : isInvalidToken ? (
            <InvalidError />
          ) : (
            <Paper className={classes.form}>
              {resetPasswordInfo ? (
                <div className={classes.successWrapper}>
                  <p className={classes.successTitle}>
                    Password reset successfully
                  </p>
                  <Button
                    className={classes.submitButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Proceed To Homepage
                  </Button>
                </div>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const form = e.target;
                    const formData = new FormData(form);
                    const formJson = Object.fromEntries(formData.entries());

                    if (formJson.password !== formJson.passwordConfirm) {
                      setValidation("Passwords do not match.");
                      return;
                    }

                    setValidation("");
                    void resetPasswordStore.resetPassword({
                      userId,
                      token,
                      password: formJson.password,
                    });
                  }}
                >
                  <div className={classes.inputGroup}>
                    <label htmlFor="password">Choose New password:</label>
                    <TextField
                      id="password"
                      name="password"
                      variant="outlined"
                      className={classes.input}
                      disabled={resetPasswordLoading}
                      type={"password"}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <label htmlFor="passwordConfirm">
                      Re-enter New Password:
                    </label>
                    <TextField
                      id="passwordConfirm"
                      name="passwordConfirm"
                      variant="outlined"
                      className={classes.input}
                      disabled={resetPasswordLoading}
                      type={"password"}
                    />
                  </div>

                  {validattion && (
                    <p className={classes.validation}>{validattion}</p>
                  )}

                  <Button
                    type={"submit"}
                    className={classes.submitButton}
                    variant="contained"
                    color="primary"
                  >
                    Reset password
                  </Button>
                </form>
              )}
            </Paper>
          )}
        </Container>
      </div>
    </div>
  );
}

export default observer(ResetPassword);
