import React, {useEffect, useState} from 'react';
import {Route, Redirect, useLocation} from 'react-router';
import {observer} from "mobx-react-lite";
import {useStores} from "../hooks/use-stores";
import { boolean } from 'boolean';

function useGuestToken(authStore) {
    let query =  new URLSearchParams(useLocation().search);
    return query.get("guest") || authStore.guestToken
}


function PrivateRoute({component: Component, render, notLoggedUrl, ...rest}) {
    const {authStore} = useStores()
    const {isAuthenticated, isGuest, guestTokenInvalid } = authStore
    let guestToken = useGuestToken(authStore);
    let location = useLocation();
    const  [hideNav,setHideNav] = useState( false )

    if (!notLoggedUrl)
        notLoggedUrl = authStore.loginUrl

    useEffect( ()=>{
        if ( guestToken && !isAuthenticated ){
            authStore.checkGuest(guestToken)
        }
        else if (isAuthenticated) {
            authStore.resetGuestToken()
        }
    }, [guestToken, isAuthenticated] )

    useEffect( ()=>{
        let query =  new URLSearchParams(location.search);
        let hide =   boolean( query.get("hidenav"))
        setHideNav(hide)
    }, [] )

    if ( guestToken && !isGuest && !guestTokenInvalid )
        return (<>loading</>)

    return (<Route
        {...rest}
        render={(props) => {
            return ( isGuest || ( isAuthenticated === true && authStore.token != null) )
                ? (Component?<Component {...props} {...rest} hideNav={hideNav} />:render(rest))
                : <Redirect to={notLoggedUrl}/>

        }}
    />)
}

export default observer(PrivateRoute)