import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import {starFishColor} from "../../../components/styles";
import StyledHeadTableRow from "./StyledHeadTableRow";
import StyledTableRow from "./StyledTableRow";


function AnnualBonusTable(){
    const {t} = useTranslation("customers");

    return        <Table size={"small"} style={{width:"auto"}}>
        <TableHead>
            <StyledHeadTableRow>
                <TableCell align={"center"}>
                    <pre>{t("table_head_pv_total")}</pre>
                </TableCell>
                <TableCell align={"center"}>{t("table_head_usd_total")}</TableCell>
            </StyledHeadTableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow>
                <TableCell align={"center"}>7,500</TableCell>
                <TableCell align={"center"}>500</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>15,000</TableCell>
                <TableCell align={"center"}>1,000</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>20,000</TableCell>
                <TableCell align={"center"}>1,500</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>30,000</TableCell>
                <TableCell align={"center"}>2,500</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>40,000</TableCell>
                <TableCell align={"center"}>4,000</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>50,000</TableCell>
                <TableCell align={"center"}>6,000</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>100,000</TableCell>
                <TableCell align={"center"}>15,000</TableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell align={"center"}>200,000</TableCell>
                <TableCell align={"center"}>40,000</TableCell>
            </StyledTableRow>
        </TableBody>
    </Table>
}

export default AnnualBonusTable