import RestService from "./RestService";


class SupportCaseService extends RestService {
    constructor(parentService, parentId) {
        if (parentService) {

            super(`${parentService.baseUrl}/message`)

        }
        else
            throw new Error("Can be only nested service")
    }

}


export default class SupportService extends RestService {
    constructor() {
        super('/api/support');
    }


    SupportCase(){
        return new SupportCaseService( this )
    }
}
