import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import {makeAutoObservable, reaction} from "mobx";
import sumBy from "lodash/sumBy";


class MyProgressStore {
    static BBBSwitchPeriodId = 159
    progressDataLoading = null;
    calculatingRetailSales = null;
    calculatingSalesAchiever = null;
    calculatingSalesAchieverPro = null;
    progressData = {}

    blbData=null
    blbDataLoading=null
    otcaData=null
    otcaDataLoading=null
    howTos=null
    howTosLoading=null
    BLBUser=null
    PVPeriodID=null
    retailSales=null
    salesAchieverData=null
    salesAchieverDataPro=null
    error = null;
    constructor(periodsStore, authStore) {
        makeAutoObservable(this);

        this.periodsStore = periodsStore;
        this.authStore = authStore;

        reaction( ()=> this.periodsStore.periods.slice(),
            periods => {
                if (periods.length > 1) {
                    let beforeTimeSwitch = false

                    const today = new Date()
                    if (today.getDate() === 1 && today.getHours() < 2) {
                        beforeTimeSwitch = true
                    }
                    if (today.getDate() < 3 && !beforeTimeSwitch)
                        this.setPVPeriodID(periods[1].PVPeriodID)
                    else
                        this.setPVPeriodID(periods[0].PVPeriodID)
                } else if (periods.length == 1) {
                    this.setPVPeriodID(periods[0].PVPeriodID)
                }
            })

    }

    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    setPVPeriodID(value) {
        this.PVPeriodID = value
        this.resetData()
    }

    resetData(){
        this.retailSales= null;
        this.salesAchieverData= null;
        this.salesAchieverDataPro= null;
        this.blbData= null;
        this.otcaData= null;
        this.otcaDataLoading= null;
        this.blbDataLoading= null;
        this.calculatingRetailSales= null;
        this.calculatingSalesAchiever= null;
        this.calculatingSalesAchieverPro= null;
    }

    loadHowTos = generateLoadEntity(  "howTos",
        this,
        "howTosLoading",
        (lang) => {
            return services.MyProgress.howtos( lang );
        },
        "howTos")

    calculateRetailSales = generateLoadEntity(  "progressData",
        this,
        "calculatingRetailSales",
        () => {
            return services.MyProgress.list( {PVPeriodID: this.PVPeriodID} );
        },
        "retailSales")

    calculateSalesAchiever = generateLoadEntity(  "salesAchieverData",
        this,
        "calculatingSalesAchiever",
        () => {
            return services.MyProgress.calculateSalesAchiever( {PVPeriodID: this.PVPeriodID} );
        },
        "salesAchieverData")



    calculateSalesAchieverPro = generateLoadEntity(  "salesAchieverDataPro",
        this,
        "calculatingSalesAchieverPro",
        () => {
            return services.MyProgress.calculateSalesAchieverPro( {PVPeriodID: this.PVPeriodID} );
        },
        "salesAchieverDataPro")


    calculateBLB = generateLoadEntity(  "blbData",
        this,
        "blbDataLoading",
        () => {
            this.blbData = null
            const method = this.PVPeriodID >= MyProgressStore.BBBSwitchPeriodId ? services.MyProgress.calculateBBBv2 : services.MyProgress.calculateBBB
            return method.call( services.MyProgress, {PVPeriodID: this.PVPeriodID, userFor: this.BLBUser?.UserID } );
        },
        "blbData")

    setBLBUserID = (value) => {
        this.BLBUser = value
        this.calculateBLB()
    }


    calculateOTCA = generateLoadEntity(  "otcaData",
        this,
        "otcaDataLoading",
        () => {
            return services.MyProgress.calculateOTCA( {PVPeriodID: this.PVPeriodID} );
        },
        "otcaData")

}





export default MyProgressStore