import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStores } from "../hooks/use-stores";
import PageTitle from "./PageTitle";
import { fixDomain } from "../utils/menuUtils";
import services from "../services";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        let result =  {
        width: "100%",
        height: "auto",
        top: props.isNativeApp ? ( props.withExternalTitle?"92px": props.ownTitle? "32px": "50px") : "unset",
        left: props.isNativeApp ? 0 : "unset",
        right: props.isNativeApp ? 0 : "unset",
        bottom: props.isNativeApp ? (props.isPlayerVisible?105:0) : "unset",
        position: props.isNativeApp ? "absolute" : "relative",
    }
    if ( props.fullScreenFrame ){
        result.position = "fixed"
        result.top = 0
        result.left = 0
        result.right = 0
        result.bottom = 0
        result.zIndex=2000
        result.backgroundColor = "black"
    }

    return result;
    },
    frameWrapper: (props) => {
        let result = {
        width: "100%",
         position: "relative",
        overflow: "hidden"
    }
        if ( props.fullScreenFrame ){
            result.position = "absolute"
            result.top = 40
            result.left = 0
            result.right = 0
            result.bottom = 0
        }
        else {
            result.height = props.isNativeApp ?( props.ownTitle? "calc(100% - 44px)":"100%" ) : ( props.withExternalTitle?"calc(100vh - 150px)":"calc(100vh - 115px)")
        }

        return result;
    },
    iframe: (props) => {
        let result = {
            width: "100%",
            height: "100%",
            border: 0
        }
        if ( props.fullScreenFrame ){
            result.position = "absolute"
            result.top = 0
            result.left = 0
            result.right = 0
            result.bottom = 0

        }
        return result;
    },
    progressContainer: (props) =>  ({
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: props.fullScreenFrame? "black": "rgba(200,200,200, 0.3)"
    }),

    backLink:{
        position: "absolute",
        top: 4,
        left: 20,
         zIndex:2001
    }

}));


export default observer(({ url, tag, withExternalTitle=false, fullScreenFrame=false, returnTitle="Back" }) => {

    const [loading, setLoading] = useState(false);
    const { menuStore } = useStores();
    const { webMenu } = menuStore;
    const definitionByTag = useMemo(() => {
        if (tag && webMenu)
            return webMenu.find(x => x.tag === tag);
        else
            return null;
    }, [tag, webMenu]);


    useEffect(() => {
        if (url || definitionByTag)
            setLoading(true);
    }, [url, definitionByTag]);


    const effectiveUrl = useMemo(() => {
        return fixDomain(definitionByTag ? definitionByTag.url : url);
    }, [definitionByTag, url]);

    useEffect(() => {
        (async () => { await services.Auth.verify(); })();
    }, [effectiveUrl]);

    const { commonStore, audioStore } = useStores();
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down(700));
    const { isNativeApp } = commonStore;
    const { isPlayerVisible } = audioStore;
    const classes = useStyles({ fullScreenFrame, isNativeApp:isNativeApp||isPhone, isPlayerVisible, withExternalTitle, ownTitle: !!definitionByTag });

    const handleOnLoad = () => {
        setLoading(false);
    };
    const handleScroll = (e) => {
        console.log(e)
    }

    return (
        <div className={classes.root}>
            {definitionByTag && <PageTitle forIframe>{definitionByTag.title}</PageTitle>}
            {fullScreenFrame && <Button startIcon={<ArrowBackIcon />}  color="primary" variant="outlined" className={classes.backLink}  onClick={()=>{window.history.back()}}>{returnTitle}</Button> }
            <div className={classes.frameWrapper}>
                <iframe src={effectiveUrl}
                        onScroll={handleScroll}
                    title={definitionByTag ? definitionByTag.title : effectiveUrl}
                    onLoad={handleOnLoad} className={classes.iframe}
                    allow="camera; microphone"
                />
                {loading && <div className={classes.progressContainer}><CircularProgress size={90} /></div>}
            </div>
        </div>
    );
});


