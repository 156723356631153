
import IdLessRestService from "./IdLessRestService";


export default class Address extends IdLessRestService {
    constructor(parentService, parentId) {
        if (parentService) {
            if ( parentId) {
                super(`${parentService.baseUrl}/${parentId}/address/shipping`)
                this.listAddress = `${parentService.baseUrl}/${parentId}/address`
            }
            else {
                super(`${parentService.baseUrl}/address/shipping`)
                this.listAddress = `${parentService.baseUrl}/address`
            }
        }
        else
            throw new Error("Can be only nested service")
    }

    list(){
        return this.get(  this.listAddress  )
    }

}
