import React from "react";
import {  observer } from "mobx-react-lite";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import { joinPath} from "../../../utils/helpers";
import ShippingInfoList from "./ShippingInfoList";
import ShippingInfoEdit from "./ShippingInfoEdit";


export default observer ( function ShippingInfoRoot () {
    const shippingInfoMatch = useRouteMatch();


    return  <Switch>
        <Route path={joinPath( shippingInfoMatch.url, `create`)}  render={props=>{
            return <ShippingInfoEdit parentMatch={shippingInfoMatch}/>
        }} />
        <Route path={joinPath( shippingInfoMatch.url, `:addressId/edit`)}  render={props=>{
            return <ShippingInfoEdit  parentMatch={shippingInfoMatch} />
        }} />

        <Route path={shippingInfoMatch.url}  render={props=>{
            return <ShippingInfoList parentMatch={shippingInfoMatch}/>
        }} />

    </Switch>
})