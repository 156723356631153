import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import {makeAutoObservable, reaction} from "mobx";
import sumBy from "lodash/sumBy";


class MyIncomeStore {
    PVPeriodID=null
    error = null;
    dataLoading= null;

    data = null

    constructor(periodsStore, authStore) {
        makeAutoObservable(this);

        this.periodsStore = periodsStore;
        this.authStore = authStore;

        reaction( ()=> this.periodsStore.paidPeriods.slice(),
            periods => {
                if (periods.length > 1) {
                    let beforeTimeSwitch = false

                    const today = new Date()
                    if (today.getDate() === 1 && today.getHours() < 2) {
                        beforeTimeSwitch = true
                    }
                    if (today.getDate() < 3 && !beforeTimeSwitch)
                        this.setPVPeriodID(periods[1].PVPeriodID)
                    else
                        this.setPVPeriodID(periods[0].PVPeriodID)
                } else if (periods.length == 1) {
                    this.setPVPeriodID(periods[0].PVPeriodID)
                }
            })

    }

    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    setPVPeriodID(value) {
        this.PVPeriodID = value
        this.resetData()
    }

    resetData(){

    }


    loadData = generateLoadEntity(  "incomeData",
        this,
        "dataLoading",
        () => {
            return services.MyIncome.list( {PVPeriodID: this.PVPeriodID} );
        },
        "data")



}

export default MyIncomeStore;