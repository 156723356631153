import cloneDeep from 'lodash/cloneDeep';
import plugins from './plugins';
import MobxReactForm from "mobx-react-form";


const genericFields = {
    "firstName":{
        label: 'First Name',
        rules: 'string',
    },
    "lastName":{
        label: 'Last Name',
        rules: 'string',
    },
    "email": {
        label: 'Email',
        rules: 'string|email|required|between:4,150',
    },
    "phone": {
        label: 'Phone',
        type:"tel",
        rules: 'string|between:7,150',
    },
}

function genericFieldsRequired(){
    let fields =  cloneDeep(  genericFields )
    Object.keys(fields).forEach( k=>{
        fields[k].rules +="|required"
    })

    return fields

}

const credentialsFields = {
    "username":{
        label: 'Username',
        rules: 'string|required|uniq_username',
    },
    "password":{
        label: 'Password',
        rules: 'string|required',
        type: 'password',
    }
}



export default function create( withCredentials, hooks ) {
    return new MobxReactForm({ fields:{  ...(withCredentials? { ...credentialsFields, ...genericFieldsRequired() }: genericFields) } }, { plugins, hooks });
}

