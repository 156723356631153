import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react-lite';
import { fromPairs, toPairs } from 'lodash'
import { Box, CircularProgress, Container, Dialog, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { useStores } from '../hooks/use-stores';
import BillingInfoForm from "../components/enrollment/billingInfoForm";
import BillingFormDef from "../forms/enrollBillingInfo";
import services from '../services';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        position: 'relative'
    },
    fullWidthField: {
        '& div': {
            width: '100%'
        }
    },
    overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: '#444444',
        zIndex: '2',
        top: 0,
        left: 0
    }
}));


const RenewMembershipModal = ({ isMemberExpired }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [renewalLoading, setRenewalLoading] = useState(false);
    const [showRenewSuccessMessage, setShowRenewSuccessMessage] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [paymentID, setPaymentID] = useState(0);
    const { authStore, commonStore, enrollmentStore, userBillingInfoStore, userProfileStore } = useStores();
    const { allCountries, regionsByCountry } = userProfileStore;

    const { country } = authStore;
    const { cards } = userBillingInfoStore;

    const localStore = useLocalStore(() => ({
        _form: new BillingFormDef({}, {
            onSuccess: async (form) => {
                localStore.formSubmitting = false;
            },
            onError: async (e) => {
                localStore.formSubmitting = false;
            },
            formSubmitting: false,
        }),
        submit() {
            this.formSubmitting = true;
            this._form.submit();
        },
        get error() {
            return this._form.error
        },
        update(values) {
            this._form.update(values)
        }
    }))

    const handleExisting = (newVal) => {
        setPaymentID(newVal);
    }

    const processRenewal = async () => {
        let paymentInfo;
        try {
            if (paymentID) {
                paymentInfo = cards.find(card => card.paymentID == paymentID);
                if (!paymentInfo) {
                    throw new Error('Payment Method not valid');
                }
            } else {
                localStore.submit();
                if (!localStore._form.error) {
                    let values = localStore._form.values();
                    const mapping = {
                        "billFirstName": "firstName"
                        , "billLastName": "lastName"
                        , "billCountry": "country"
                        , "billAddress": "address1"
                        , "billCity": "city"
                        , "billPostalCode": "postalCode"
                        , "billRegion": "region"
                    }

                    paymentInfo = fromPairs(toPairs(values).map(pair => {
                        return mapping[pair[0]] ? [mapping[pair[0]], pair[1]] : pair
                    }))
                }
            }

            if (paymentInfo) {
                setRenewalLoading(true);
                const membershipRenewalResponse = await services.Enrollment.renewMembership({
                    cartID: enrollmentStore?.renewalCart?.cartId,
                    ...paymentInfo
                })

                if (membershipRenewalResponse && membershipRenewalResponse.status == 1) {
                    setShowRenewSuccessMessage(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                } else {
                    throw new Error('Unable to enroll user - cart error');
                }
            } else {
                commonStore.showError(t('enrollment:selectPayment'));
            }


        } catch (e) {
            commonStore.showError(t('enrollment:renewError'));
        } finally {
            setRenewalLoading(false);
        }
    }

    const handleCountryChanged = (newVal) => {
        if (newVal)
            userProfileStore.loadRegionsByCountry(newVal);
    };

    const load = async () => {
        try {
            if( isMemberExpired ) {
                await Promise.all([userProfileStore.loadAllCountries(), userProfileStore.loadRegionsByCountry(country), userBillingInfoStore.loadAll()])
                await enrollmentStore.loadRenewalCart();

                // If we were able to get a renewal cart, then the renew request was successful
                if (enrollmentStore?.renewalCart?.cartId) {
                    setModalOpen(isMemberExpired);
                }
            }
        } catch (e) {
            // Explicitly do nothing here - we are unable to get the member's status or billing info
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, [])

    return (
        <Dialog
            open={modalOpen}
            fullWidth
        >
            <Container className={classes.container}>
                {
                    renewalLoading && <Box
                        className={classes.overlay}>
                        <CircularProgress />
                    </Box>
                }
                {(!renewalLoading && showRenewSuccessMessage) && <Box
                    className={classes.overlay}>
                    {t('enrollment:renewSuccess')}
                </Box>
                }
                <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '10px' }}>
                    {t('enrollment:membershipExpired')}
                </Typography>
                <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '10px' }}>
                    {t('enrollment:renewToday')}
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary={t('enrollment:annualRenewal')} />
                        <ListItemSecondaryAction>
                            <ListItemText primary={enrollmentStore?.renewalCart?.cartTotal.subTotal} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t('enrollment:tax')} />
                        <ListItemSecondaryAction>
                            <ListItemText primary={enrollmentStore?.renewalCart?.cartTotal.tax} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t('enrollment:todaystotal')} />
                        <ListItemSecondaryAction>
                            <ListItemText primary={enrollmentStore?.renewalCart?.cartTotal.grandTotal} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                {!loading && localStore._form &&
                    <BillingInfoForm
                        cardFirst={false}
                        existingPayments={cards}
                        translationPrefix={"enrollmember"}
                        allCountries={allCountries}
                        regionsByCountry={regionsByCountry}
                        paymentID={paymentID}
                        handlePaymentMethodChanged={handleExisting}
                        handleCountryChanged={handleCountryChanged}
                        form={localStore._form}
                        onSubmit={processRenewal}
                        submitting={localStore.billingInfoFormSubmitting}
                        buttonText={"Process Renewal"}
                        hideTitle
                        showExpDate
                    />
                }
            </Container>
        </Dialog>
    );
}

export default observer(RenewMembershipModal);