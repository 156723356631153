import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import DialogContent from "@material-ui/core/DialogContent";
import {ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {avatar} from "../../components/styles";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    ...avatar(theme, 1.5),
    description:{
        marginLeft:20
    }
}));

function  ItemDialog({ onHide, item, action, actionTitle}){
    const classes = useStyles();
    const {t} = useTranslation("subscription");

    return <Dialog open={true} onClose={onHide} maxWidth={"sm"} fullWidth>
        <DialogTitle disableTypography >
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Typography>{t("subscription_details")}</Typography>
                <div style={{ cursor: "pointer" }} onClick={onHide}>
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </div>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <ListItem>
                <ListItemAvatar>
                    <Avatar variant={"rounded"} className={classes.avatar} src={item.imageURL}/>
                </ListItemAvatar>
                <ListItemText className={classes.description}
                              primary={item.productName}
                              secondary={<>
                                  <div>{t("price")}: {item.productPrice}</div>
                                  <div>{t("pv")}: {item.productPV}</div>
                              </>}/>

            </ListItem>

            <div dangerouslySetInnerHTML={{ __html: item.productDescription }}></div>

        </DialogContent>

        <DialogActions>
            <Button variant={"contained"} color={"secondary"}
                    onClick={onHide}
                    type="button"
                    className="btn btn-primary" data-dismiss="modal">{t('close')}
            </Button>
            {action && <Button variant={"contained"} color={"primary"}
                    onClick={action}
                    type="button"
                    className="btn btn-primary" data-dismiss="modal">{actionTitle}
            </Button>}
        </DialogActions>

    </Dialog>
}

export  default  observer(ItemDialog)