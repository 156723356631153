import React, {useEffect, useMemo,} from "react";
import { observer } from "mobx-react-lite";
import {Box, Container} from '@material-ui/core';
import menu from './menu.json';
import {useRouteMatch} from "react-router-dom";
import LinksMenu from "./LinksMenu";
import {useStores} from "../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";

function Royal ( {parentMatch} ){
    const match = useRouteMatch();

    const { authStore, shoppingBossStore, supportStore } = useStores();
    const { eAcceleratorValidityLoading  } = shoppingBossStore;
    const { supportInfoLoading } = supportStore;
    const { isShoppingOnlyUser  } = authStore;
    const MainLinks = useMemo(()=>{
         const items = menu.find(x=>x.tag==="userprofile").items
        return isShoppingOnlyUser? items.filter(x=>x.tag !=="step-member"): items
    },[menu, isShoppingOnlyUser])



    useEffect(() => {
        void shoppingBossStore.verifyEAccelerator();
        void supportStore.loadSupportInfo();
    }, []);

    if ( eAcceleratorValidityLoading == null || eAcceleratorValidityLoading || supportInfoLoading == null || supportInfoLoading  )
        return <Container><Box display={"flex"} justifyContent={"center"}><CircularProgress size={40}/></Box></Container>;
    return (
        <Container>
            <>
                <LinksMenu items={MainLinks} showBack={false} parentMatch={match} />
            </>
        </Container>
    );
}

export default observer(Royal);