import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";

const useStyles = makeStyles((theme) => ({
  message: {
    textAlign: "center",
    marginTop: "100px",
    fontSize: "0.9rem",
  },
}));

const ServiceMaintainance = ({ message = "" }) => {
  const classes = useStyles();
  return <div className={classes.message}>{message}</div>;
};

export default observer(ServiceMaintainance);
