import { Container, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useStores } from "../hooks/use-stores";
import { deleteCookie } from "../utils/cookieUtil";
import { setItem } from "../utils/storage";

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    paddingTop: "60px",
  },
  page: {
    backgroundColor: "black",
    height: "100vh",
    color: "white",
  },
  header: {
    height: 58,
    borderBottom: "1px solid #007BFF",
  },
  headerContainer: {
    height: 58,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  headerLogo: {
    height: 27,
    width: 150,
  },
  body: {
    height: "100%",
  },
}));

function AdminRedirect() {
  const [isRoutingReady, setIsRoutingReady] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get("redirect");
  const isLoggingOut = searchParams.get("isLogout");

  const { authStore } = useStores();
  const { currentUser } = authStore;

  useEffect(() => {
    // if there is no "isLogout" params, means this render is the first time loading
    // Send ready message to Admin Portal (parent window) so it can send token back
    if (!isLoggingOut) {
      window.parent.postMessage("rendered", "*");
    } else {
      // if there is "isLogout" params, means this render is for logging out
      authStore.logout(); // logout
      window.parent.postMessage("closed", "*"); // send close signal to Admin Portal to close its iframe dialog
    }
  }, []);

  useEffect(() => {
    const receiveMessage = async (event) => {
      // Prevent messages from outer sources
      if (event.origin !== "https://admin.starfishperks.com") {
        return;
      }

      // if the site is already signed in, logout to remove current token
      if (currentUser) {
        authStore.logout();
      }

      // then use the token sent by Admin Portal instead
      const token = event.data;
      deleteCookie("cart_guid");
      deleteCookie("autoship_cart_guid");
      setItem("access_token", token, 1);
      authStore.setToken(token);
      setIsRoutingReady(true);
    };

    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (authStore.isAuthenticated && isRoutingReady) {
      history.push(redirect);
    }
  }, [authStore.isAuthenticated, isRoutingReady]);

  return (
    <div className={clsx(classes.page)}>
      <div className={clsx(classes.header)}>
        <Container maxWidth={"md"} className={classes.headerContainer}>
          <img src={`/starfish_full.png`} className={classes.headerLogo} />
        </Container>
      </div>
      <div className={classes.body}>
        <div className={classes.progressContainer}>
          <CircularProgress size={90} />
        </div>
      </div>
    </div>
  );
}

export default observer(AdminRedirect);
