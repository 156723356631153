import RestService from "./RestService";

export default class ConventionProgramService extends RestService {
  constructor() {
    super('/api/convention');
  }

  // Accepts date in YYYY/MM format
  async fetchHeaderInfo(date = '') {
    return this.get(`${this.baseUrl}/${date}`);
    // return this.get("https://run.mocky.io/v3/02a640c5-9c1b-4740-a669-63eb7f6a9c4f");
    // return this.get(`http://localhost:3000/api/convention/${date}`);
  }

  // Accepts date in YYYY/MM format
  async fetchBodyInfo(date = '') {
    return this.get(`${this.baseUrl}/details/${date}`);
    // return this.get('https://run.mocky.io/v3/0f1e4804-8900-48ae-80e5-570c549e92a9')
    // return this.get(`http://localhost:3000/api/convention/details/${date}`);

  }
}
