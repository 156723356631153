import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { joinPath } from "../../utils/helpers";
import { useStores } from "../../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import SubscriptionsList from "./SubscriptionsList";
import { useTranslation } from "react-i18next";
import ManageSubscription from "./ManageSubscription";

const useStyles = makeStyles((theme) => ({

    shopWrapper: {
        height: "100%",
        minHeight: "100vh",
        background: "black",
        color: "white"
    },
    spacer: {
        height: 30
    }
}));


export default observer(function ShopIndex() {
    const classes = useStyles();
    const rootMatch = useRouteMatch();


   return <div className={classes.shopWrapper}>
      <Switch>
            <Route path={rootMatch.url} exact render={() => <SubscriptionsList parentMatch={rootMatch} />} />

            <Route path={joinPath( rootMatch.url, "/:categoryID")}
                   render={() => <ManageSubscription parentMatch={rootMatch} />} />
        </Switch>
    </div>;
});