import React from "react";
import { observer} from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import OpenIcon from "@material-ui/icons/OpenInNew";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/use-stores";


const useStyles = makeStyles((theme) => ({
    whiteColor: {
        color: "white"
    },

    root:{
        border: "1px solid #ddd",
        borderRadius: 4,
        width:"100%"
    },
    block:{
        padding: 25,
        borderTop: "solid #ffffff73 1px",
        display:"flex",
        flexDirection:"column",
        rowGap:20
    }


}));



export default observer(function Help() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { commonStore } = useStores();
    const { language } = commonStore;

    return <div className={classes.whiteColor}>
        <Typography variant="h5">{t("policiesAndProcedures")}</Typography>
        <div className={classes.root}>
           <div className={classes.block}>
               <Typography variant="h6">{t("termsAndConditions")}</Typography>
               <Typography variant="body1" hidden>{t("descriptionIndependentMember")}</Typography>
               <div>
                   <Button href={"http://cdn.mainhomepage.com/forms/us_tac_en.pdf"}
                       startIcon={<OpenIcon />}
                       target={"_blank"}
                       variant={"contained"}
                       size={"small"}
                       color={"primary"}>
                   {t("openPDF")}
                </Button>
               </div>
           </div>
            <div className={classes.block}>
                <Typography variant="h6">{t("returnMaterial")}</Typography>
                <Typography variant="body1"  hidden>{t("descriptionRMA")}</Typography>
                <div>
                    <Button href={"http://cdn.mainhomepage.com/forms/us_returnmaterialauthorizationform_en.pdf"}
                        startIcon={<OpenIcon />}
                        target={"_blank"}
                        variant={"contained"}
                        size={"small"}
                        color={"primary"}>
                    {t("openPDF")}
                    </Button>
                </div>
            </div>
            <div className={classes.block}>
                <Typography variant="h6">{t("policiesAndProcedures")}</Typography>
                <Typography variant="body1"  hidden>{t("policiesAndProceduresDescription")}</Typography>
                <div>
                <Button href={"http://cdn.mainhomepage.com/forms/us_policiesandprocedures_en.pdf"}
                        startIcon={<OpenIcon />}
                        target={"_blank"}
                        fullWidth={false}
                        variant={"contained"}
                        size={"small"}
                        color={"primary"}>
                    {t("openPDF")}
                </Button>
                </div>
            </div>
        </div>
    </div>;
});

