import {makeAutoObservable} from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class MenuStore {
    constructor() {
        makeAutoObservable(this);
    }

    headerInfo = null;
    bodyInfo = null;
    purchasingVipTicket = false;

    async purchaseUpgradeTicket() {
        if (this.purchasingVipTicket) return;
        const qty = 1;
        this.purchasingVipTicket = true;

        try {
          const { cartID: cart_guid = '' } = await services.ShoppingCart.addItemToCart2(17292, qty, 'en');
          return { cart_guid };
        }
        catch (e) {
          // error handled by checking cart guid result
        }
        finally {
          this.purchasingVipTicket = false;
        }
      }
}

export default MenuStore;