import React from "react";


export const shopTypes = {
    standard: {
        shopId: "standard",
        name: 'shopping:home.shop',
        cartname: 'CART',
        home:"/shopping",
        cartProp: "userCart",
        cartLoadingProp: "userCartLoading",
        totalsLoadingProp: "cartTotalsLoading",
        totalsProp: "cartTotals",
        cartUrl: '/shopping/cart',
        cartGuidProp: "userCartGuid",
        cartItemsProp: "cartItems"
    },
    autoship: {
        shopId: "autoship",
        name: 'shopping:home.autoship',
        cartname: 'AUTOSHIP CART',
        home:"/shopping/autoship",
        cartProp: "autoshipUserCart",
        cartEffectiveProp: "autoshipEffectiveCart",
        cartLoadingProp: "autoShipCartItemsLoading",
        totalsLoadingProp: "autoShipCartTotalsLoading",
        totalsProp: "autoshipCartTotals",
        cartUrl: '/shopping/autoship/cart',
        cartGuidProp: "autoShipUserCartGuid",
        cartItemsProp:"autoshipCartItems"
    },
    subscriptions: {
        shopId: "subscriptions",
        name: 'shopping:home.subscriptions',
        cartname: 'SUBSCRIPTIONS CART',
        home:"/shopping/subscriptions",
        cartUrl: '/shopping/subscriptions/cart',
        cartProp: "subscriptionUserCart",
        cartLoadingProp: "subscriptionCartLoading",
        totalsLoadingProp: "subscriptionCartTotalsLoading",
        totalsProp: "subscriptionCartTotals",
        cartGuidProp: "subscriptionUserCartGuid",
        cartItemsProp:"subscriptionCartItems"
    },
};

export const ShopContext = React.createContext(
    shopTypes.standard // default value
);