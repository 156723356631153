import React, { useEffect, } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {starFishColor} from "./styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    html: {
        "& a": {
            ...starFishColor(theme)
        }
    }

}));

function Html ({className, Component="div", __html }){
    const classes = useStyles();
    return <Component className={clsx(classes.html, className )} dangerouslySetInnerHTML={{ __html }} />
}
export default Html;