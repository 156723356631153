import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import {updatePostalCodeRules} from "./helpers";


const fields = {
    billFirstName: {
        label: 'enrollment:billingfirstname',
        rules: 'string|required',
        placeholder: 'enrollment:firstname',
    },
    billLastName: {
        label: 'enrollment:billinglastname',
        rules: 'string|required',
        placeholder: 'enrollment:lastname',
    },
    billAddress: {
        label: 'enrollment:address',
        rules: 'string|required|nopobox',
        placeholder: 'enrollment:address',
    },
    billCity: {
        label: 'enrollment:city',
        placeholder: 'enrollment:city',
        rules: 'string|required',
    },
    billRegion: {
        label: 'enrollment:state',
        placeholder: 'enrollment:state',
        rules: 'string|required',
    },
    billPostalCode: {
        label: 'enrollment:zipcode',
        placeholder: 'enrollment:zipcode',
        rules: 'string|required',
    },
    billCountry: {
        label: 'enrollment:country',
        rules: 'string|required',
        placeholder: 'enrollment:country',
    }
};



export default function create(initials, hooks, options={}) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }
    const form  = new MobxReactForm({ fields:f }, { plugins, hooks });

    form.$('billCountry')
    .observe(({ form, field, change }) => {
        updatePostalCodeRules(form, field.value )
    })
    updatePostalCodeRules(form, initials.billCountry )


    if ( options.lockCountry )
        form.$("billCountry").set("disabled", true)

    return form
}

