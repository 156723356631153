import RestService from "./RestService";



export default class StatusService extends RestService {
    constructor() {
        super('/status/services/' )
    }

}
