import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AUDIOCONSTRAINTS = {
  audio: true,
}

/**
 * UseMedia would be a good way to handle audio and video streams, but we need to be able to reinstantiate
 * the audio stream ref because we need to reuse it multiple times. With video, we can do this
 * by navigation away to the review page, but with audio, since it's in a modal, we can't. We have
 * to manually reinit it. And that's why this is a separte hook from useVideo.
 */
const useAudio = () => {
  const { t } = useTranslation();
  const [audioError, setAudioError] = useState(null);

  let streamRef = null;
  const initStream = useCallback(
    async () => {
      try {
        if (
          "mediaDevices" in navigator &&
          "getUserMedia" in navigator.mediaDevices) {
          streamRef = await navigator.mediaDevices.getUserMedia(AUDIOCONSTRAINTS);
        } else {
          setAudioError(t('lifeline:audioRecorder.unsupported'));
        }
      } catch (err) {
        setAudioError(t('lifeline:audioRecorder.unavailable'));
      }
    }
  );

  const register = useCallback(
   async (cb) => {
      await initStream();
      return streamRef;
    }, [initStream]
  );

  return { register, audioError};
}

export default useAudio;