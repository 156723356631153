import React, {useEffect} from "react";
import {  observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import {useStores} from "../../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { joinPath} from "../../utils/helpers";
import {Link as RouterLink, useHistory} from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {getMembersViewByTag} from "../../utils/menuUtils";
import Dialog from "@material-ui/core/Dialog";
import ModalMembersMenu from "../../components/Members/ModalMembersMenu";
import Fab from "@material-ui/core/Fab";
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";


const useStyles = makeStyles((theme) => ({
    mainContent:{
        color: "white",
        backgroundColor: "black",
    },
    root: {
        flexGrow: 1,
        color: "white"
    },
    whiteColor: {
        color: "white"
    },
    grow:{
        flexGrow: 1
    },
    alignLeft:{
        justifyContent: "start"
    },
    block:{
        borderRadius: 20,
        border: "1px solid f50057",
        display: "flex",
        padding:"28px 30px",
        marginBottom: 30,
        marginRight: 30,
        alignItems: "center",
        justifyContent:"center",
        maxHeight:105,
        color:"white",
        textDecoration:"none",
        "&:hover":{
            color:"white",
            textDecoration:"none",
        }
    },
    verticalBlock:{
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        flexDirection:"column"
    },
    verticalLeftGrowBlock:{
        display: "flex",
        alignItems: "start",
        paddingLeft: 20,
        flexGrow:1,
        flexDirection:"column"
    },
    icon: {
        width :50,
        height :50,
    },
    onboardRoot:{
        marginBottom:20
    },
    onboardItems:{
        display: "flex",
        alignItems:"flex-start",
    },
    onboardLink:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        color:"white"

    },
    onboardFab:{
    },
    onboardTitle:{
        textTransform: "capitalize"

    }



}));


export default observer ( function Members ({parentMatch, section, playMedia }) {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const {statStore, menuStore, libraryStore, onboardStore } = useStores();
    const {error, statistics} = statStore;
    const {detailsLoadingFor} = libraryStore;
    const { topLevelLinks } = menuStore.membersMenu;
    const { memberCards, memberCardsLoading } = onboardStore;

    const sectionItem =  topLevelLinks.find(x=> x.tag === section )

    useEffect(()=>{
        ( async ()=>{
            await onboardStore.loadMemberOnboardingCards()
            // await statStore.loadStats()
        })()
    },[])

    const closeModalMenu = ()=>{
        history.push(joinPath( parentMatch.url ) )
    }



    return  (<div className={classes.root}>

                {error ?  <Alert severity="error">{error}</Alert> : null}
                {!statistics  ? <CircularProgress/>
                    :<Grid container justifyContent={"space-between"} >
                       <Grid item container  >
                           <Grid item xs={12} sm={6}><PageTitle>{t("fullOffice")}</PageTitle></Grid>
                           <Grid item container justifyContent={"flex-end"} xs={12} sm={6}>
                           </Grid>

                       </Grid>

                        { (false && memberCards.length>0) && <Grid  item xs={12} container className={classes.onboardRoot} spacing={2}>
                            <Grid item xs={12}><Typography variant="subtitle1">{t("get_started")}</Typography></Grid>

                                {memberCards.map((x,i)=>{
                                    return  <Grid  key={x.subType}  item container xs={3} className={classes.onboardItems}>
                                        <Link component={RouterLink} to={joinPath(parentMatch.url,"onboard", x.subType)}
                                                 className={classes.onboardLink}>
                                        <Fab size="small" color="secondary" aria-label="add" className={classes.onboardFab}>
                                           {x.displayOrder}
                                        </Fab>
                                        <span className={classes.onboardTitle}>{x.title.toLowerCase()}</span>

                                    </Link></Grid>
                                })}
                            </Grid>}

                        { topLevelLinks.map( def=>{
                            const ViewType = getMembersViewByTag(def)

                            return <ViewType key={def.tag}
                                             def={def}
                                             parentMatch={parentMatch}
                                             detailsLoadingFor={detailsLoadingFor}
                                             onPlay={playMedia} />
                        })}
                    </Grid>
                }

                {sectionItem && <Dialog onClose={closeModalMenu} open={!!sectionItem.subsection}>
                    <ModalMembersMenu parentMatch={parentMatch}
                                      onClose={closeModalMenu}
                                      detailsLoadingFor={detailsLoadingFor}
                                      sectionItem={sectionItem}
                                      items={sectionItem.subsection}
                                      onPlay={playMedia}/>
                </Dialog>}
            </div>)

})