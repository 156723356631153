import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, Button, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import WithdrawButton from '../../../components/Wallet/WithdrawButton';
import { useStores } from '../../../hooks/use-stores';
import services from '../../../services';

const WITHDRAW_FEE = 0.015;
const WITHDRAW_FEE_AS_PERCENTAGE = 1.5

const useStyles = makeStyles((theme) => ({
    subcontainer: {
        marginBottom: theme.spacing(4)
    },
    subtitle: {
        fontSize: 13,
        marginBottom: theme.spacing(2.5)
    },
    amountTable: {
        borderRadius: '20px',
        background: 'rgb(28,28,28)',
        padding: theme.spacing(4),
    },
    alignRight: {
        textAlign: 'right'
    },
    tableItem: {
        marginBottom: '16px',
        '& p': {
            fontSize: '14px'
        }
    },
}));

const BTCConfirmWithdrawal = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const { commonStore, statStore } = useStores();
    const satsToWithdraw = statStore.statistics?.wallet?.sats?.balance ?? 0;
    const withdrawalFee = Math.round(satsToWithdraw * WITHDRAW_FEE);
    const withdrawalTotal = Math.max((satsToWithdraw - withdrawalFee).toFixed(2), 0);

    const onConfirmWithdrawal = async () => {
        if (loading) {
            return;
        }

        if (withdrawalTotal <= 0) {
            commonStore.showError(t('wallet:withdrawal.confirm.noSatsError'));
            return;
        }

        setLoading(true);

        try {
            await services.UserProfile.Wallet().withdraw(props.btcDepositAddress);
            statStore.loadStats(); // load stats to refresh sats information
            props.setWithdrawAmountAfterFee(withdrawalTotal);
            history.push('/wallet/withdraw/success');
        } catch (e) {
            const isPending = !!e && (typeof e == 'string' ? e : e.message)?.includes('pending') ||
                !!e && e.response?.data?.message.includes('pending')
            if (isPending) {
                commonStore.showError(t('wallet:withdrawal.confirm.pendingError'));
            } else {
                commonStore.showError(t('wallet:withdrawal.confirm.genericError'));
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!props.btcDepositAddress || statStore.error) {
            history.push('/wallet/withdraw');
        }
    }, []);

    if (!props.btcDepositAddress || statStore.error) {
        return null;
    }

    return (
        <Container>
            <Box style={{ marginBottom: '20px' }}>
                <Button onClick={history.goBack} style={{ textTransform: 'none' }}>
                    <ArrowBackIcon />&nbsp;{t('wallet:withdrawal.confirm.back')}
                </Button>
            </Box>
            <Box className={classes.subcontainer}>
                <Typography variant="h3" className={classes.subtitle}>
                    {t('wallet:withdrawal.depositAddress')}
                </Typography>
                <Box>
                    {props.btcDepositAddress}
                </Box>
            </Box>
            <Box className={classes.amountTable}>
                <Grid container className={classes.tableItem}>
                    <Grid item xs={6}>
                        <Typography classes={classes.tableText}>{t('wallet:withdrawal.confirm.amount')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.alignRight}>{satsToWithdraw} SATS</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.tableItem}>
                    <Grid item xs={6}>
                        <Typography>{t('wallet:withdrawal.confirm.fee', { percent: WITHDRAW_FEE_AS_PERCENTAGE })}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.alignRight}>{withdrawalFee} SATS</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ margin: '10px 0px' }} />
                <Grid container>
                    <Grid item xs={6}>
                        <Typography style={{ fontWeight: 'bold' }}>{t('wallet:withdrawal.confirm.totalAmount')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.alignRight} style={{ fontWeight: 'bold' }}>{withdrawalTotal} SATS</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ padding: '14px' }}>
                <Typography variant="caption">
                    {t('wallet:withdrawal.confirm.warning')}
                </Typography>
            </Box>
            <WithdrawButton onClick={onConfirmWithdrawal} disabled={loading}>
                {t('wallet:withdrawal.confirm.confirmWithdrawal')}
            </WithdrawButton>
        </Container>
    )
}

export default BTCConfirmWithdrawal;