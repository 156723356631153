import RestService from "./RestService";



export default class EnrollmentService extends RestService {
    constructor() {
        super('public/util');
    }

    generateEnrollmentLink(productSuggestion, language = 'en', fromStarfish, uplineUserID) {
        const baseQueryUrl = `/api/enroll/customer/${productSuggestion}/${language}`;
        if (fromStarfish) {
            return this.get(`${baseQueryUrl}?fromStarfish=true${uplineUserID ? `&uplineUserID=${uplineUserID}` : ''}`);
        } else {
            return this.get(`${baseQueryUrl}${uplineUserID ? `?uplineUserID=${uplineUserID}` : ''}`);
        }
    }

    verify(loginGuid) {
        return this.post(`/verify/enroll`, { loginGuid });
    }

    listCountries() {
        return this.get(`/api/v3/enroll/customer/countries`);
    }

    cart(productSuggestion, lang, country) {//change this
        return this.post(`/api/v4/enroll/customer/cart?productSuggestion=${productSuggestion}&lang=${lang}&country=${country}`
            , { productSuggestion, lang, country });
    }

    cartTotal(cartID, country, lang, city = "", zip = "", region = "") {
        return this.post(`/api/v3/enroll/customer/cart/totals?lang=${lang}&cartID=${cartID}&country=${country}`
            , { cartID, lang, country, city, zip, region });
    }

    renewalCart() {
        return this.post(`/api/enroll/member/renewalCart`);
    }

    loadItems(cartID, country, lang) {
        return this.get(`/api/v3/enroll/customer/cart/items?lang=${lang}&cartID=${cartID}&country=${country}`);
    }

    additionalItems(country, lang) { //change this
        return this.get(`/api/v4/enroll/customer/subscriptions?lang=${lang}&country=${country}`);
    }

    addItemToCart(params) {
        return this.put(`/api/v3/enroll/customer/cart/items?lang=${params.lang}`, params);
    }

    removeItemFromCart(params) {
        return this.del(`/api/v3/enroll/customer/cart/items?lang=${params.lang}`, params);
    }

    create(params) {
        return this.post(`/api/v3/enroll/customer`, params);
    }

    enrollSC(params) {
        return this.post(`/api/enroll/customer`, params);
    }

    isMembershipExpired() {
        return this.get(`/api/enroll/member/isExpired`);
    }

    renewMembership(params) {
        return this.post(`/api/enroll/member/renewSubscription`, params);
    }
}
