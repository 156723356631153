import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Link, Typography} from "@material-ui/core";
import {formatNumber} from "../../../utils/helpers";
import Variance from "./Variance";
import clsx from "clsx";



const useStyles = makeStyles((theme) => ({
    grayPanel: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        borderRadius: 20,
        paddingRight: 10,
        "& .MuiTypography-root": {
            opacity: 0.7
        }
    },

    icon: {
        width: 40,
        height: 40
    },

    mainBody: {
        alignItems:"center",
        justifyContent:"center",
        display:"flex",
    },
    withLabel: {
        flexDirection: "row",
        gap:5
    },
    withoutLabel: {
        flexDirection: "column"
    }

}));

function ColoredCardContent({value, varValue, label, reverseColor = false, icon, image, varCustomContent, footer, rightColumn = true}){
    const classes = useStyles()

    return  <Box display={"grid"} gridGap={"5px"} gridTemplateColumns={rightColumn?"64px 3fr 64px":"64px 3fr"} marginBottom={2} marginTop={2}>
            <Box alignSelf={"flex-start"} justifySelf={"center"} padding={1} paddingLeft={2}>
                {image && <img src={image}
                     className={classes.icon}/>}
                {icon &&  <>{icon}</>}
            </Box>
            <Box width={"100%"} display={"flex"} flexDirection={ "column"} alignItems={"center"} alignSelf={"center"} justifySelf={"center"} justifyContent={"center"}>
                {label && <Typography noWrap  align={"center"} >{label}</Typography>}
                <Box className={clsx(classes.mainBody,{
                    [classes.withLabel]: !!label,
                    [classes.withoutLabel]: !label
                })}>
                    {typeof value === "number"
                        ? <Typography variant={"h6"}>{formatNumber(value)}</Typography>
                        : value  && <>{value}</> }
                    {varValue!=null && <Box className={classes.grayPanel}
                         borderRadius={20}>
                        <Typography variant={"body1"}>
                            <strong><Variance amount={varValue} reverseColor={reverseColor}/></strong>
                        </Typography>
                    </Box>}
                    {varCustomContent&& <>{varCustomContent}</>}
                </Box>
                    {footer && <>{footer}</>}

            </Box>
        </Box>
}

export default ColoredCardContent;