import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {observer} from "mobx-react";
import VimeoAlbum from "../../components/video/VimeoAlbum";

const useStyles = makeStyles((theme) => ({
    videoModalContainer: {
        width: '100%',
        minHeight: '300px',
    },
    videoModalContentContainer: {
        width: '100%',
        marginBottom: theme.spacing(1)
    },
    videoModalTitle: {
        maxWidth: '95%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));


const VideoModal = ({ videoModalOpen, onCloseVideoModal, setCurrentVideoInfo, currentVideoInfo, videoModalTitle, relatedVideos, hasVIPTicket, hasFutureTicket, customStringMap }) => {
    const { t } = useTranslation();
    const classes = useStyles();


    if (!currentVideoInfo) {
        return null;
    }

    const closeModal = () => {
        onCloseVideoModal();
    };

    return (
        <Dialog className={classes.videoModalContainer}
            PaperProps={{
                style: {
                    backgroundColor: '#343a40',
                    color: 'white',
                },
            }}
            open={videoModalOpen}
            onClose={closeModal}
            maxWidth="md"
            fullWidth>
            {currentVideoInfo &&
                <DialogTitle display="flex">
                    <Typography className={classes.videoModalTitle} variant="h3">{currentVideoInfo.title}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseVideoModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            }
            <DialogContent className={classes.videoModalContentContainer}>
                <VimeoAlbum  currentVideoInfo={currentVideoInfo}
                             setCurrentVideoInfo={setCurrentVideoInfo}
                             relatedVideos={relatedVideos}
                             hasFutureTicket={hasFutureTicket}
                             hasVIPTicket={hasVIPTicket}
                             videoModalTitle={videoModalTitle}
                             customStringMap={customStringMap}/>
            </DialogContent>
        </Dialog>
    );
};

export default observer(VideoModal);