import {makeAutoObservable, reaction, runInAction} from "mobx";
import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity
} from "../utils/mobx";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import compact from "lodash/compact";
import uniq from "lodash/uniq";

class MyTeamsStore {
    myTeamsLoading = null;
    jumpListLoading = null;
    rankListLoading = null;
    myUserLoading = null;
    detailedUserLoading = null;
    searching = null;
    jumpList = [];
    rankList = [];
    myTeamsInfo = [];
    fullTrail = [];
    searchResults = [];
    rankFilters = [];
    dashboardUserInfo = null;
    detailedUserInfo = null;
    PVPeriodID = null;

    modifyingWatch = false
    modifyingJump = false
    removingJumps = []


    forUser = null
    currentTeam = 1
    showFullData = true
    ranks = []



    constructor(periodsStore) {
        makeAutoObservable(this);
        this.periodsStore = periodsStore;

        reaction(() => this.periodsStore.periods.slice(),
            periods => {
                if (periods.length > 1) {
                    let beforeTimeSwitch = false

                    const today = new Date()
                    if (today.getDate() === 1 && today.getHours() < 2) {
                        beforeTimeSwitch = true
                    }
                    if (today.getDate() < 3 && !beforeTimeSwitch)
                        this.setPVPeriodID(periods[1].PVPeriodID)
                    else
                        this.setPVPeriodID(periods[0].PVPeriodID)
                } else if (periods.length == 1) {
                    this.setPVPeriodID(periods[0].PVPeriodID)
                }
            })

        reaction(
            () => this.forUser,
            forUser => {
                if (forUser) {
                    void this.refresh();
                }
            }
        );


        reaction(
            () => this.PVPeriodID,
            PVPeriodID => {
                if (PVPeriodID) {
                    void this.refresh();
                    this.recalculateRanks()
                }
            }
        );

        reaction(
            () => this.currentTeam,
            currentTeam => {
                if (currentTeam) {
                    void this.loadTeamInfo();
                }
            }
        );
    }

    toggleFilter(item, parent) {
        item.checked = !item.checked
        if (item.checked) {
            if (parent) {//toggle child
                if (parent.radioGroup && parent.children) {
                    parent.children.forEach(x => {
                        if (x.RankID !== item.RankID)
                            x.checked = false
                    })
                } else {
                    parent.checked = false
                }
            } else {//toggle parent
                item.children && item.children.forEach(x => {
                    x.checked = false
                })
            }
        }

    }


    setUser(userID, patch = false) {
        if (patch) {
            if ([22, 82, 61487006, 61848996, 61371202, 61435203, 61314755].includes(userID))
                userID = 61241449
        }
        if (userID !== this.forUser) {
            this.setCurrentTeam(1);
        }
        this.forUser = userID
    }

    setCurrentTeam(value) {
        this.currentTeam = value
    }

    setPVPeriodID(value) {
        this.PVPeriodID = value
    }

    setError(error, type = "myFormsError") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    async refresh() {
        void this.loadTeamInfo();
        void this.loadUserInfo();
    }

    get showMonthEnd() {
        const day = new Date().getDate()
        if (day > 24 || day < 14)
            return true
    }

    get hideMTC() {
        const filterItem = this.ranks.find(x => x.RankID === "mtcTrack")
        return !filterItem || !filterItem.checked
    }

    get hideTerminated() {
        const filterItem = this.ranks.find(x => x.RankID === "term")
        return !filterItem || !filterItem.checked
    }

    get show2LOnly() {
        const filterItem = this.ranks.find(x => x.RankID === "2L")
        return filterItem && filterItem.checked
    }

    get percentNextRank() {
        const filterItem = this.ranks.find(x => x.RankID === "cb")

        const checkedSubItems = filterItem.children.filter(x => x.checked);
        if (checkedSubItems.length === 0) return 0

        return Math.max(checkedSubItems.map(x => Number(x.RankID.replace("cb", ""))))

    }

    findRadiofilter(parentID, yesID, noID) {
        const root = this.ranks.find(x => {
            return x.ID === parentID
        })
        if (!root) return null

        const yes = root.children.find(x => x.RankID === yesID);
        const no = root.children.find(x => x.RankID === noID);

        if (yes?.checked) return "yes"
        if (no?.checked) return "no"
    }

    get coreFilter() {
        return this.findRadiofilter(-14, "yesCore4", "noCore4")
    }

    get crFilter() {
        return this.findRadiofilter(-6, "metCR", "noCR")

    }

    get pboFilter() {
        return this.findRadiofilter(-9, "isPBO", "noPBO")
    }

    get convFilter() {
        return this.findRadiofilter(-10, "hasConv", "noConv")
    }

    get semFilter() {
        return this.findRadiofilter(-12, "hasSem", "noSem")
    }

    get ranksFilter() {
        const ranks = this.ranks.filter(x => {
            return x.type === "rank"
        })
        let result = ""
        ranks.forEach(x => {
            if (x.checked)
                result += x.RankID + ","
            if (x.children) {
                x.children.forEach(y => {
                    if (y.checked)
                        result += y.RankID + ","
                })
            }
        })
        return uniq(compact(result.split(","))).map(Number)
    }

    get subscriptionsFilter() {
        const subscriptionsRoot = this.ranks.find(x => {
            return x.ID === -4
        })

        let result = ""

        subscriptionsRoot.children.forEach(y => {
            if (y.checked)
                result += y.RankID + ","
        })

        return uniq(compact(result.split(",")))
    }


    recalculateRanks(input) {
        if (!input) input = this.rankList
        if (input.length === 0) return []
        let cRankID = 0
        let cGroupIDs = ""
        let cRankName = ""

        const results = []
        const subResults = []

        input.forEach(x => {
            if (x.PVPeriods !== null) {
                if (x.PVPeriods !== 3 && !(x.MinimumTeams > 6)) {
                    if (x.PVPeriods === 1) {
                        if (cGroupIDs !== "") {
                            results[results.length - 1].RankID = cGroupIDs.endsWith(",") ? cGroupIDs.slice(0, -1) : cGroupIDs
                        }
                        cRankID = x.PVRankID
                        cGroupIDs = ""
                        cRankName = x.Name.replace(" " + x.PVPeriods.toString(), "")
                        results.push({
                            ID: cRankID,
                            RankID: x.PVRankID,
                            Name: x.Name,
                            HasChildren: false,
                            checked: false,
                            type: "rank"
                        })
                    }
                    cGroupIDs += x.PVRankID + ","
                    subResults.push({ParentID: cRankID, RankID: x.PVRankID, Name: x.Name, checked: false, type: "rank"})
                }
            }
        })
        if (cGroupIDs != "") {
            results[results.length - 1].RankID = cGroupIDs.endsWith(",") ? cGroupIDs.slice(0, -1) : cGroupIDs
        }

        results.push({ID: -2, RankID: "2L", Name: "Members with 2 Teams", HasChildren: false, checked: false})
        results.push({
            ID: -3,
            RankID: "cb",
            Name: "Close to Brackets",
            HasChildren: true,
            checked: false,
            radioGroup: "cb"
        })
        subResults.push({ParentID: -3, RankID: "cb10", Name: "Within 10%", checked: false})
        subResults.push({ParentID: -3, RankID: "cb20", Name: "Within 20%", checked: false})

        if (this.showFullData) {
            results.push({ID: -4, RankID: "su", Name: "Subscriptions", HasChildren: true, checked: false});

            if (new Date() < new Date("4/1/2015")) {
                subResults.push({ParentID: -4, RankID: "suLLR", Name: "LLR", checked: false});
            }

            subResults.push({ParentID: -4, RankID: "suLT", Name: "Marketing System", checked: false});
            subResults.push({ParentID: -4, RankID: "suMSP", Name: "Marketing System Premium", checked: false});

            if (this.PVPeriodID < 68) {
                subResults.push({ParentID: -4, RankID: "suTA", Name: "Total Access", checked: false});
            }

            if (this.PVPeriodID >= 68) {
                subResults.push({ParentID: -4, RankID: "suSOT", Name: "Standing Order Ticket", checked: false});
                subResults.push({ParentID: -4, RankID: "suAppSup", Name: "Platform", checked: false});
                subResults.push({ParentID: -4, RankID: "suStarfish", Name: "Starfish", checked: false});
                subResults.push({ParentID: -4, RankID: "suAppSupPlus", Name: "Royal Starfish", checked: false});
            }

            // if (this.PVPeriodID < 68) {
            //     results.push({ ID: -5, RankID: "cab", Name: "CAB", HasChildren: true, checked: false });
            //     // Assume dsCAB is an array of objects
            //     for (var i = 0; i < dsCAB.length; i++) {
            //         subResults.push({ ParentID: -5, RankID: "cab" + dsCAB[i].CabID, Name: dsCAB[i].Name, checked: false });
            //     }
            // }
        }

        results.push({
            ID: -6,
            RankID: "metCRp",
            Name: "Met Customer Req.",
            HasChildren: true,
            checked: false,
            radioGroup: "cr"
        });
        subResults.push({ParentID: -6, RankID: "metCR", Name: "Yes", checked: false});
        subResults.push({ParentID: -6, RankID: "noCR", Name: "No", checked: false});

        if (this.PVPeriodID < 68) {
            results.push({ID: -9, RankID: "isPBOp", Name: "PBO", HasChildren: true, checked: false, radioGroup: "pbo"});
            subResults.push({ParentID: -9, RankID: "isPBO", Name: "Yes", checked: false});
            subResults.push({ParentID: -9, RankID: "noPBO", Name: "No", checked: false});
        }

        if (this.showFullData) {
            results.push({
                ID: -10,
                RankID: "hasConvp",
                Name: "Convention",
                HasChildren: true,
                checked: false,
                radioGroup: "convp"
            });
            subResults.push({ParentID: -10, RankID: "hasConv", Name: "Yes", checked: false});
            subResults.push({ParentID: -10, RankID: "noConv", Name: "No", checked: false});

            results.push({
                ID: -12,
                RankID: "hasSemp",
                Name: "Seminar",
                HasChildren: true,
                checked: false,
                radioGroup: "semp"
            });
            subResults.push({ParentID: -12, RankID: "hasSem", Name: "Yes", checked: false});
            subResults.push({ParentID: -12, RankID: "noSem", Name: "No", checked: false});
        }

        results.push({
            ID: -14,
            RankID: "isCore4",
            Name: "Core 4",
            HasChildren: true,
            checked: false,
            radioGroup: "core4"
        });
        subResults.push({ParentID: -14, RankID: "yesCore4", Name: "Yes", checked: false});
        subResults.push({ParentID: -14, RankID: "noCore4", Name: "No", checked: false});

        results.push({
            ID: -15,
            RankID: "mtcTrack",
            Name: "Member to Customer Tracking",
            HasChildren: false,
            checked: false
        });

        // if (RMR.Utility.CurrentLifeMarket !== LIFEMarket.Pacific) {
        //     results.push({ ID: -11, RankID: "mtcTrans", Name: "Member to Customer Transitioned", HasChildren: false, checked: false });
        // }

        results.push({ID: -13, RankID: "term", Name: "Show Terminated", HasChildren: false, checked: false});

        const ParentIDIndex = groupBy(subResults, "ParentID");

        results.forEach(x => {
            x.children = ParentIDIndex[x.ID]
        })

        this.ranks.replace(results)
    }


    loadTeamInfo = generateLoadList(
        "myTeamsInfo",
        this,
        "myTeamsLoading",
        async () => {
            this.fullTrail.replace([])
            if (this.forUser && this.PVPeriodID) {
                const result = await services.MyTeams.getLOSTeams(this.forUser, this.PVPeriodID, this.currentTeam)

                if (result.fullTrail)
                    runInAction(() => {
                        this.fullTrail.replace(result.fullTrail)
                    })
                return result.team;
            }
        },
        "myTeamsInfo"
    );

    
    searchUsersIncludingUpline = generateLoadList(
        "searchUsers",
        this,
        "searching",
        (value) => {
            return services.MyTeams.searchUsers(value, true)
        },
        "searchResults"
    );
    
    searchUsers = generateLoadList(
        "searchUsers",
        this,
        "searching",
        (value) => {
            return services.MyTeams.searchUsers(value, false)
        },
        "searchResults"
    );

    resetSearch() {
        this.searching = null
        this.searchResults.replace([])
    }

    get searchPanelSize() {
        if (this.searchResults.length === 0) {
            return this.searching === null ? 2.5 : 3.5
        }
        return 6
    }


    loadJumpList = generateLoadList(
        "jumpList",
        this,
        "jumpListLoading",
        () => {
            return services.MyTeams.listJumps()
        },
        "jumpList"
    );

    loadRankList = generateLoadList(
        "rankList",
        this,
        "rankListLoading",
        async () => {
            const r = await services.MyTeams.listRanks()
            this.recalculateRanks(r)

            return r
        },
        "rankList"
    );

    loadUserInfo = generateLoadEntity(
        "myUserInfo",
        this,
        "myUserLoading",
        () => {
            if (this.forUser && this.PVPeriodID)
                return services.MyTeams.getUserData(this.forUser, this.PVPeriodID)
        },
        "dashboardUserInfo"
    );

    cleanUserInfo() {
        this.detailedUserInfo = null
    }

    loadDetailedUserInfo = generateLoadEntity(
        "detailedUserInfo",
        this,
        "detailedUserLoading",
        (userID) => {
            return services.MyTeams.getUserData(userID, this.PVPeriodID)
        },
        "detailedUserInfo"
    );

    addWatch = generateCreteEntity("addWatch"
        , this, "modifyingWatch", async (userID) => {
            await services.MyTeams.addWatch(userID);
            return true;

        })

    removeWatch = generateDeleteEntity("removeWatch"
        , this, "modifyingWatch", async (userID) => {
            await services.MyTeams.removeWatch(userID);
            return true;
        })

    addJump = generateCreteEntity("addWatch"
        , this, "modifyingJump", async (userID) => {
            await services.MyTeams.addJump(userID);
            await this.loadJumpList()
            return true;

        })

    removeJump = generateDeleteEntity("removeWatch"
        , this, "removingJumps", async (userID) => {
            await services.MyTeams.removeJump(userID);
            const existing = this.jumpList.find(x => x.LOSUserID === userID)
            if (existing)
                this.jumpList.remove(existing)
            return true;
        })

    selfReportPV = generateCreteEntity("selfReportPV", this, "selfReportPVUpdating",
        async (pv) => {
            await services.MyTeams.selfReportPV( this.PVPeriodID );
    });

}

export default MyTeamsStore;
