import { makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateCreteEntity, generateDeleteEntity, generateLoadEntity, generateUpdateEntity} from "../utils/mobx";
import createSpouseForm from "../forms/spouse";

class SpouseProfileStore {
    constructor(commonStore) {
        makeAutoObservable(this)
        this.commonStore =commonStore
    }

    spouseProfile = null
    spouseProfileLoading = false
    savingSpouseProfile = false
    spouseEditForm = null
    error = null

    setError( error, type = "userProfile") {
        error = extractErrorMessage(error)
        this.error = error
     }

    createEditForm ( onSaved ){
        this.spouseEditForm = createSpouseForm( true,{
            onSuccess: async (form)=>{
                let result;
                try {
                    if (this.spouseProfile.username) {
                        result = await this.updateSpouse(this.dto)
                    } else {
                        result = await this.createSpouse(this.dto)
                    }
                    this.commonStore.success( result.message )

                    if (onSaved)
                        onSaved(result)
                }
                catch(e){
                    this.commonStore.showError( e.response.data.message )
                }
            },
            onError:(form) =>{
                this.setError( form.errors() );
            }
        } )
    }

    loadSpouseProfile = generateLoadEntity( "spouseProfile", this, "spouseProfileLoading"
        , async ()=>{
            let result =  await services.Spouse.fetch()

            runInAction(() => {
                try {
                    this.spouseEditForm.update(result);
                }catch (e) {
                    console.error(e)
                }
            })

            return result
        }, "spouseProfile" )


    updateSpouse = generateUpdateEntity ("spouseProfile", this, "savingSpouseProfile"
        , (values)=>{ return services.Spouse.update(values) }, null, {throwError:true}  )

    createSpouse = generateCreteEntity ("spouseProfile", this, "savingSpouseProfile"
        , (values)=>{ return services.Spouse.create(values) },  null, {throwError:true} )

    deleteSpouse = generateDeleteEntity ("spouseProfile", this, "savingSpouseProfile"
        , (values)=>{ return services.Spouse.delete(values) }, { snackbarError: true, defaultErrorMessage: "There was an error deleting the spouse account."} )


    get dto() {
        let values = this.spouseEditForm.values()
        return {
            spouseEmail: values.email,
            spouseFirstname: values.firstName,
            spouseLastname: values.lastName,
            spousePassword: values.password,
            spousePhone: values.phone,
            spouseUsername: values.username
        }
    }

}

export default SpouseProfileStore