import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import { updatePhoneRules } from "./helpers";


const fields = {
    "firstName": {
        label: 'enrollment:firstname',
        rules: 'string|required',
        placeholder: 'enrollment:firstname',
    },
    "lastName": {
        label: 'enrollment:lastname',
        rules: 'string|required',
        placeholder: 'enrollment:lastname',
    },
    email: {
        label: 'enrollment:email',
        rules: 'string|email|required|uniq_username_enroll',
        placeholder: 'enrollment:email',
        hooks: {
            onChange: (field) => {
                field.value = field.value.trim();
            },
        }
    },
    phone: {
        label: 'enrollment:phone',
        rules: 'string|required',
        placeholder: 'enrollment:phone',
        "extra": "(###) ###-####"
    },
    password: {
        label: 'password',
        placeholder: 'password',
        rules: 'string|required|min:7',
        type: "password"
    },
    confirmPassword: {
        label: 'confirm_password',
        placeholder: 'confirm_password',
        rules: 'required|string|same:password',
        type: "password"
    },
    preferredLanguage: {
        label: 'enrollment:preferredlanguage',
        rules: 'string',
        placeholder: 'enrollment:preferredlanguage',
    },
    country: {
        rules: 'string'
    }
};



export default function create(initials, hooks) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }
    const form = new MobxReactForm({ fields: f }, { plugins, hooks });
    form.$('country')
        .observe(({ form, field, change }) => {
            updatePhoneRules(form, field.value);
        });
    updatePhoneRules(form, initials.country);
    return form;
}

