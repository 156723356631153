import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import {updateCardTypeRules, updatePostalCodeRules} from "./helpers";


const fields = {
    address1: {
        label: 'enrollment:address1',
        rules: 'string|required',
        placeholder: 'enrollment:address1',
    },
    address2: {
        label: 'enrollment:address2',
        rules: 'string',
        placeholder: 'enrollment:address2',
    },
    city: {
        label: 'enrollment:city',
        rules: 'string|required',
        placeholder: 'enrollment:city',
    },
    postalCode: {
        label: 'enrollment:zipcode',
        rules: 'required|string',
        placeholder: 'enrollment:zipcode',
    },
    region: {
        label: 'enrollment:state',
        rules: 'string|required',
        placeholder: 'enrollment:state',
    },
    country: {
        label: 'enrollment:country',
        rules: 'string',
        placeholder: 'enrollment:country',
    }
};



export default function create( initials, hooks ) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }
    const form  = new MobxReactForm({ fields:f }, { plugins, hooks });

    form.$('country')
    .observe(({ form, field, change }) => {
        updatePostalCodeRules(form, field.value, "postalCode" )
    })
    updatePostalCodeRules(form, initials.country, "postalCode")

    return form
}

