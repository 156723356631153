import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class ConventionLeadershipStore {
  conventionLoading = null;
  conventionInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "conventionError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  loadConvention = generateLoadEntity(
    "conventionInfo",
    this,
    "conventionLoading",
    (params) => {
      return services.ConventionLeaderhipService.getConvention(params);
    },
    "conventionInfo"
  );
}

export default ConventionLeadershipStore;
