import Grid from "@material-ui/core/Grid";
import MaterialSelect from "./MaterialSelect";
import MaskedMaterialTextField from "./MaskedMaterialTextField";
import React from "react";
import {observer} from "mobx-react-lite";
import CardExpMonth from "./CardExpMonth";
import CardExpYear from "./CardExpYear";

function CardBlock({form }){
    return <>
        <Grid item xs={12} lg={9}>
            <MaterialSelect
                fullWidth
                margin="normal"
                field={form.$("cardType")}
                options={form.$("cardType").extra || [] }
            />
        </Grid>
        <Grid item xs={12} lg={9}>
            <MaskedMaterialTextField
                fullWidth
                margin="normal"
                id="txtCCNumber"
                field={form.$("cardNumber")}
            />
        </Grid>
        <Grid container item xs={12} lg={9} spacing={2}>

            <CardExpMonth form={form}/>
            <CardExpYear form={form}/>
            <Grid item xs={4} lg={4}>
                <MaskedMaterialTextField
                    fullWidth
                    margin="normal"
                    field={form.$("cardCode")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    </>
}


export  default observer(CardBlock)