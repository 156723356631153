import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { FormControlLabel, FormGroup, TextField, Paper, Container, CircularProgress, Grid } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import IOSSwitch from "../components/IOSSwitch";

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useStores } from "../hooks/use-stores";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";


const useStyles = makeStyles((theme) => ({
    productImage: {
        height: "100%",
        width: "100%",
        borderRadius: "5%",
        transition: "all .3s",
        marginBottom: '12px'
    },
    root: {
        background: '#000 !important',
        color: "#575962",
        height: "100%",
        width: "100%",
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed"
    },
    paper: {
        margin: 2,
        width: 390,
        maxWidth: "98%"
    },
    content: {
        padding: theme.spacing(4),
        paddingTop: 0,

    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#575962"
    },
    actionSection: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    troubles: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        whiteSpace: "nowrap",
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    troublesLink: {
        paddingLeft: theme.spacing(1),
        textDecoration: "none"
    }
}));



export default observer(function SignIn({ theme }) {
    const classes = useStyles();
    const { authStore, commonStore } = useStores();
    const { loginError, inProgress, baseLoad } = authStore;

    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [password, setPassword] = useState("");
    const refLogin = useRef();
    const refPassword = useRef();

    useEffect(() => {
        authStore.setError("");
    }, []);

    const handleRememberMeChange = (e) => {
        setRememberMe(!rememberMe);
    };

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
        setUserNameError(!e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(!e.target.value);
    };
    const focusFirstEmpty = () => {
        if (!refLogin.current.value) {
            refLogin.current.focus();
            return true;
        }
        else if (!refPassword.current.value) {
            refPassword.current.focus();
            return true;
        }
    };
    const handleKey = (e) => {
        if (e.key === 'Enter') {
            if (!focusFirstEmpty()) {
                validateUser();
            }
        }
    };
    const location = useLocation()
    let redirect = new URLSearchParams(location.search).get("redirect")
    const accountRecoveryLink = "/accountrecovery" + (redirect ? "?redirect=" + redirect : "");

    const usernameErrorOrEmpty = useMemo(() => {
        return userNameError || !userName;
    }, [userNameError, userName]);

    const passwordErrorOrEmpty = useMemo(() => {
        return passwordError || !password;
    }, [passwordError, password]);

    const validateUser = async (e) => {
        if (usernameErrorOrEmpty || passwordErrorOrEmpty) {
            setUserNameError(!userName);
            setPasswordError(!password);
            return focusFirstEmpty();
        }
        if (inProgress || baseLoad) return;
        authStore.setError("");
        await authStore.signIn(userName, password, rememberMe);
    };

    const component = () => (<div open={true} className={classes.root}  >
        <Grid container justifyContent={"center"} alignItems={'center'}>
            {
                commonStore.onStarfishOrRoyalStarfish
                    ?
                    <>
                        <Grid item xs={12} md={12} container justifyContent={"center"} alignItems={'center'}>
                            <Grid item xs={6} sm={4} md={2} container justifyContent={"center"} alignItems={'center'}>
                                <img src={`/starfish_full.png`} alt="Product Banner" className={classes.productImage} />
                            </Grid>
                        </Grid>
                    </>
                    : ''
            }
            <Grid item xs={12} md={12} container justifyContent={"center"} alignItems={'center'}>
                <Paper className={classes.paper}>
                    <DialogTitle className={classes.title}>Please Login </DialogTitle>
                    <DialogContent className={classes.content}>
                        {loginError && <Alert severity="error">{loginError}</Alert>}


                        <div id="divLogin" className="login-form">
                            <FormGroup>

                                <TextField
                                    inputRef={refLogin}
                                    fullWidth
                                    error={userNameError}
                                    required
                                    autoComplete={"on"}
                                    type="text"
                                    label="Username"
                                    helperText={userNameError ? "User name is required" : ""}
                                    value={userName}
                                    onKeyPress={e => handleKey(e)}
                                    onChange={handleUserNameChange}

                                />

                                <TextField
                                    inputRef={refPassword}
                                    fullWidth
                                    error={passwordError}
                                    required
                                    autoComplete={"on"}
                                    type="password"
                                    label="Password"
                                    helperText={passwordError ? "Password  is required" : ""}
                                    value={password}
                                    onKeyPress={e => handleKey(e)}
                                    onChange={handlePasswordChange}

                                />

                                <FormControlLabel className={classes.actionSection}
                                    control={<IOSSwitch checked={rememberMe} onChange={handleRememberMeChange} name="rememberme" />}
                                    label="Remember me"
                                />
                                <div className={classes.actionSection}>
                                    <Button color="primary" size="large"
                                        fullWidth variant="contained" onClick={validateUser}>{(inProgress || baseLoad) ? <CircularProgress size={26} /> : "Login"}</Button>
                                </div>

                            </FormGroup>
                            <div className={classes.troubles} >
                                <Typography component={"span"} variant={"body2"} className="text-muted">Having trouble logging in?</Typography>
                                <Typography className={classes.troublesLink}
                                            variant={"body2"}
                                            component={Link}
                                            to={accountRecoveryLink}
                                            color={"primary"}>Get help here.
                                </Typography>
                            </div>
                        </div>
                    </DialogContent>
                </Paper>
            </Grid>
        </Grid>
    </div >);
    if (theme) {
        return <ThemeProvider theme={theme}>{component()}</ThemeProvider>;
    }
    else {
        component();
    }

});