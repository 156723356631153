import React, { useEffect, useMemo, } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from "../../../hooks/use-stores";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Grid, ListItemText, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { joinPath } from "../../../utils/helpers";
import { ThemeProvider } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { ArrowForwardIos } from "@material-ui/icons";
import greenButtonTheme from "../../../themes/greenButton";
import { useHistory, useRouteMatch } from "react-router";
import VerificationStatus from "../../../components/Wallet/VerificationStatus";
import { currencyFormatter } from "../../shoppingboss/components/StyledValueWithCurrency";
import SparkWalletCheckbox from "../SparkWalletCheckbox";
import ErrorModal from "../../../components/ErrorModal";
import services from "../../../services";

const useStyles = makeStyles((theme) => ({

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    addOptions: {
        gap: theme.spacing(2),
    },
    block: {
        borderRadius: 20,
        border: "1px solid #007BFF",
        display: "flex",
        padding: "15px 0 15px 15px",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: 105,
        color: "white",
        flexGrow: 1,
        textDecoration: "none",
        "&:hover": {
            color: "white",
            textDecoration: "none",
        }
    },
    disabled: {
        opacity: 0.4,
    }
}));

const AchSection = ({ url, userCountry, achDisabled, handleDisabledACH }) => {
    const classes = useStyles();
    const { t } = useTranslation("wallet");

    const getForwardingLink = (url, userCountry) => {
        if (achDisabled) {
            return joinPath("/profile/help");
        } else {
            if (userCountry === 'CA') {
                return joinPath(url, "bank-ca");
            } else if (userCountry === 'AU') {
                return joinPath(url, "funds-transfer");
            } else {
                return joinPath(url, "bank");
            }
        }
    };

    const getListItemTitle = (userCountry) => {
        if (userCountry === 'CA') {
            return t('add_money.eTransfer');
        } else if (userCountry === 'AU') {
            return t('add_money.achAustralia');
        } else {
            return t('add_money.ach');
        }
    };

    const getListItemInfo = (userCountry) => {
        if (userCountry === 'CA') {
            return t('add_money.eTransfer_info_ca');
        } else if (userCountry === 'AU') {
            return t('add_money.eTransfer_info');
        } else {
            return t('add_money.ach_info');
        }
    };

    return (
        <Grid onClick={handleDisabledACH} component={Link} to={getForwardingLink(url, userCountry)} item xs={12} className={classes.block}>
            <ListItemText primary={getListItemTitle(userCountry)} secondary={
                <React.Fragment>
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        {getListItemInfo(userCountry)}
                    </Typography>
                    <br />
                    <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        {['CA', 'AU'].includes(userCountry) ? '' : t('add_money.ach_info2')}
                    </Typography>
                </React.Fragment>
            }
            />
            <IconButton><ArrowForwardIos /></IconButton>
        </Grid>);
};

export default observer(function AddEWallet({ initialHasAcceptedSparkTerms, cardStatus, achDisabled }) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation("wallet");
    const match = useRouteMatch();

    const { authStore, identityVerificationStore, shoppingBossStore, commonStore, } = useStores();
    const { eWalletMaximumAllowed, eWalletCanAdd } = shoppingBossStore;
    const {
        loadingIdentityVerification,
        identityVerificationStatus,
    } = identityVerificationStore;
    const { currentUser } = authStore;
    const {
        country: userCountry,
        isStarfish,
        isSuperAppPlus,
    } = currentUser;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorModal, setErrorModal] = React.useState(false);
    const [allowErrorModalToClose, setAllowErrorModalToClose] = React.useState(true);
    const [hasAcceptedSparkTerms, setHasAcceptedSparkTerms] = React.useState(initialHasAcceptedSparkTerms);

    useEffect(() => {
        if (!!isStarfish && !isSuperAppPlus) {
            history.push('/starfish/add_more_money');
        }
    }, [isStarfish]);

    const showErrorModal = React.useCallback((errorMessage, bAllowErrorModalToClose = true) => {
        setErrorMessage(errorMessage);
        setErrorModal(true);
        setAllowErrorModalToClose(bAllowErrorModalToClose);
    }, []);

    const hideErrorModal = () => {
        setErrorMessage("");
        setErrorModal(false);
    };

    const handleFundWithCardClick = () => {
        if (cardStatus.allowCCFunding) {
            return history.push(joinPath(match.url, "card"));
        }
        if (!["US", "CA",].includes(userCountry))
            return showErrorModal("We apologize but the Credit Card deposit option is currently unavailable in your country.");

        if (cardStatus.lastFundedDate && cardStatus.nextAvailableFundingDate) {
            return showErrorModal(<>
                <p>We apologize, but the Credit/Debit Card option is a once-a-month feature.<br />
                    Date of last deposit: {moment(cardStatus.lastFundedDate).format("LL")}.<br />
                    You are eligible to make your next deposit on: {moment(cardStatus.nextAvailableFundingDate).format("LL")}</p>
            </>);
        }
        else {
            if (userCountry && userCountry === 'CA') {
                return showErrorModal("To be eligible for credit card funding, you must be Verified, have 1 successful Interac deposit, and complete a 14-day post-Interac waiting period.");
            } else {
                return showErrorModal("To be eligible for credit card funding, you must be Verified, have 1 successful ACH deposit, and complete a 14-day post-ACH waiting period.");
            }
        }

    };

    const handleDisabledACH = () => {
        if (!!achDisabled) {
            commonStore.showError("We apologize, but your ACH Deposit option is currently unavailable. Please contact customer support for more details.");
        }
    };

    const goToVerify = async () => {
        if (!hasAcceptedSparkTerms) {
            await services.UserProfile.acceptSparkWalletTerms();
            setHasAcceptedSparkTerms(true);
        }
        history.push(joinPath(match.url, "/verify_identity"));
    };

    const [termsAccepted, setTermsAccepted] = React.useState(false);

    useEffect(() => {
        void shoppingBossStore.fetchUserBalance();
        void identityVerificationStore.checkIdentityVerification();
    }, []);

    const isUnverified = useMemo(() => {
        return ["NOACCOUNT", "OPEN"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const canProceed = useMemo(() => {
        // If they have a spark id, we won't show them the checkbox, so they can proceed
        if (hasAcceptedSparkTerms) {
            return true;
        }
        // Otherwise, we show the terms box, so only proceed when the accept the terms
        return termsAccepted;
    }, [hasAcceptedSparkTerms, termsAccepted]);



    return (
        <Grid container >
            <Grid item xs={12}>
                <Typography align={"center"} variant="h2">{shoppingBossStore.balance}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align={"center"} variant="subtitle2" color="textSecondary" disabled >
                    {t("add_money.available_balance")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align={"center"} variant="subtitle2"  >
                    {t("add_money.maximum_allowed")}: {currencyFormatter(eWalletMaximumAllowed * 100)}
                </Typography>
            </Grid>
            <Divider className={classes.divider} />
            {
                userCountry === 'US'
                    ?
                    <>
                        <Grid container item xs={12} alignItems="center" justifyContent="center">
                            <ThemeProvider theme={greenButtonTheme}>
                                {loadingIdentityVerification
                                    ? <CircularProgress />
                                    : <VerificationStatus status={identityVerificationStatus} />}
                            </ThemeProvider>
                        </Grid>
                    </>
                    : ''
            }
            <Divider className={classes.divider} />
            <Grid item xs={12}>
                <Typography align={"center"} variant="body1"  >
                    {t("add_money.can_add")}
                </Typography>
                <Typography align={"center"} variant="subtitle1" gutterBottom >
                    {currencyFormatter(eWalletCanAdd * 100)}
                </Typography>
                <Typography align={"center"} variant="subtitle1" component={"p"} gutterBottom >
                    {t("add_money.how_add")}
                </Typography>
            </Grid>
            <Grid container item xs={12} className={classes.addOptions}>
                {
                    !!["BS", "HT",].includes(userCountry)
                        ? ''
                        :
                        <>
                            <AchSection url={match.url} userCountry={userCountry} achDisabled={achDisabled} handleDisabledACH={handleDisabledACH} />
                        </>
                }
                {userCountry !== 'CA' &&  <Grid onClick={handleFundWithCardClick} item xs={12} className={classes.block}>
                    <ListItemText
                        primary={t('add_money.creditDebit')}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {userCountry === 'CA' ? t('add_money.creditDebit_info.CA') : t('add_money.creditDebit_info.US')}
                                </Typography>
                                <br />
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {t('add_money.creditDebit_info_3')}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                    <IconButton><ArrowForwardIos /></IconButton>
                </Grid>}
                {isUnverified &&
                    <Box style={{ width: '100%', marginTop: '15px' }}>
                        <SparkWalletCheckbox hasAcceptedSparkTerms={hasAcceptedSparkTerms} termsAccepted={termsAccepted} onTermsAccepted={setTermsAccepted} />
                        <Button variant={"contained"} fullWidth color="primary"
                            onClick={goToVerify}
                            disabled={!canProceed}
                        >{loadingIdentityVerification ? <CircularProgress /> : t("verificationActions.required")}
                        </Button>
                    </Box>
                }
            </Grid>
            {!!errorModal
                ? (<ErrorModal allowUserToClose={allowErrorModalToClose} errorTitle={'Sorry'} handleClose={hideErrorModal} open={errorModal} error={errorMessage} />)
                : null
            }
        </Grid>

    );

});