import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Paper,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {accordion} from "../../../components/styles";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {observer} from "mobx-react";



const useStyles = makeStyles((theme) => ({

    coloredCarHeader:{
        height:53,
        display:'flex',
        alignItems: "stretch",
        justifyContent: "center",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    },

    cardTab: {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        borderRadius: 0,
    },
    cardTabActive: {
        backgroundColor: "rgba(255, 255, 255, 0)",
        borderColor: "rgba(255, 255, 255, 0)",
    },

    panel:{
        lineHeight:1.4,
        padding: 24,
        [theme.breakpoints.down('xs')]: {
            padding: 8
        }
    },
    ...accordion(theme)
}));

function AccordionSection({ id, howTos={}, detailsLabel, children }) {
    const classes = useStyles();
    const {t} = useTranslation("myprogress");

    const [tab, setTab ]= useState("qualify")


    const handleTabChange= (tab)=>()=>{
        setTab(tab)
    }

    return <Accordion  classes={{ root: classes.accordion}}>
        <AccordionSummary
            classes={{root: classes.accordionSummary}}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={classes.heading}>{t(`${id}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails      classes={{root: classes.accordionDetails}}>
            <Paper  style={{width:"100%"}} >
                <Box className={classes.coloredCarHeader} style={{ borderBottom: "none"}}>
                    <ButtonGroup fullWidth size="small" aria-label="small outlined button group">
                        <Button
                            startIcon={<i className="fa fa-trophy"></i>}
                            className={clsx(classes.cardTab, {[classes.cardTabActive]: tab === "qualify"})}
                            onClick={handleTabChange("qualify")}>{t("qualify")}</Button>
                        {!!children && <Button
                            startIcon={<i className="fa fa-user"></i>}
                            className={clsx(classes.cardTab, {[classes.cardTabActive]: tab === "details"})}
                            onClick={handleTabChange("details")}>{detailsLabel ? detailsLabel: t(`${id}-detailsTab`)}</Button>}
                    </ButtonGroup>
                </Box>
                {tab === "qualify" && <Box className={classes.panel}
                                           dangerouslySetInnerHTML={ {__html: (howTos && howTos[id] ) ||  "N/A" } }>

                </Box>}
                {!!(tab === "details" && children) &&  <Box className={classes.panel}>
                    {children}
                </Box>}
            </Paper>
        </AccordionDetails>
    </Accordion>
}


export default observer(AccordionSection)