import RestService from "./RestService";


export default class MyProgressService extends RestService {
    constructor() {
        super('/api/myprogress');
    }

    dashboardCustomers(params) {
        return this.get(`${this.baseUrl}/dashboardCustomers`, params);
    }

    calculateBBB(params) {
        return this.get(`${this.baseUrl}/calculateBBB`, params);
    }
    calculateBBBv2(params) {
        return this.get(`${this.baseUrl}/calculateBBBv2`, params);
    }

    calculateOTCA(params) {
        return this.get(`${this.baseUrl}/calculateOTCA`, params);
    }

    calculateSalesAchiever(params) {
        return this.get(`${this.baseUrl}/calculateSalesAchiever`, params);
    }

    calculateSalesAchieverPro(params) {
        return this.get(`${this.baseUrl}/calculateSalesAchieverPro`, params);
    }

    howtos(lang) {
        return this.get(`/api/webtext/${lang}/65d60fa61f78951928fbaf25`);
    }
}
