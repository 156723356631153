import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateCreteEntity, generateLoadEntity, generateLoadList} from "../utils/mobx";

class HelpStore {
    constructor() {
        makeAutoObservable(this)
    }

    walletHelpInfoLoading = false
    walletHelpInfo = null
    sendingSupportRequest = false

    error = null

    setError(error, type = "help") {
        error = extractErrorMessage(error)
        this.error = error
     }


    loadWalletHelpInfo = generateLoadEntity( "loadWalletHelpInfo", this, "walletHelpInfoLoading"
        , (section)=>{
        return services.Help.Wallet().fetch(section)
        }, "walletHelpInfo" )


    sendSupportRequest = generateCreteEntity( "sendSupportRequest", this, "sendingSupportRequest"
        , (data)=>{ return services.Support.SupportCase().create(data) } )


}

export default HelpStore