import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import { useStores } from "../../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import MaterialTextField from "../../../components/inputs/MaterialTextField";
import { useHistory } from "react-router";
import MaterialRadio from "../../../components/inputs/MaterialRadio";
import TextField from "@material-ui/core/TextField";
import PageTitle from "../../../components/PageTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        position: "relative",
        marginBottom: theme.spacing(3),

    },
    logo: {
        marginRight: theme.spacing(1)
    },
    cardIcon: {
        height: 30,
        margin: theme.spacing(1),
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    defaultMark: {
        marginRight: "auto",
        textTransform: "uppercase"
    },
    banner: {
        marginBottom: theme.spacing(1)
    },
    checkImage: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

export default observer(function CardInfoEdit({ parentMatch, hideHeader, onSaved, onCancel }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { authStore, userBillingInfoStore, userProfileStore } = useStores();
    const { bankForm, editingBank, allLoading, error } = userBillingInfoStore;
    const { allCountries, regionsByCountry } = userProfileStore;
    const { currentUser } = authStore;

    const params = useParams();
    const history = useHistory();
    const { bankID: bankID } = params;

    const handleOnSaved = () => {
        if (onSaved)
            onSaved()
        else
            history.push(parentMatch.url);
    };

    const handleCountryChanged = () => {
        if (bankForm && bankForm.$("country").value)
            userProfileStore.loadRegionsByCountry(bankForm.$("country").value);
    };

    const navigateBackToParent = () => {
        if (onCancel)
            onCancel()
        else
            history.push(parentMatch.url);
    };

    useEffect(() => {
        (async () => {
            await userProfileStore.loadAllCountries();
            await userProfileStore.loadRegionsByCountry("US");
            await userBillingInfoStore.loadAll();
            userBillingInfoStore.setEditBankForm(bankID, handleOnSaved);
        })();

    }, []);


    return <>

        {(allLoading || !bankForm)
            ? <CircularProgress />
            : <>
                {!hideHeader && <PageTitle>{bankID ? t("editBankAccount") : t("newBankAccount")}</PageTitle>}
                <Grid className={classes.root} container>
                    <Paper className={classes.section}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Alert severity={"info"} variant={"outlined"}>{t("bankNameExplanation")}</Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialTextField
                                    fullWidth
                                    margin="normal"
                                    className={classes.textField}
                                    field={bankForm.$("friendlyName")} />
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialRadio
                                    fullWidth
                                    margin="normal"
                                    options={["Checking", "Savings"]}
                                    className={classes.textField}
                                    field={bankForm.$("accountType")} />
                            </Grid>

                            {editingBank
                                ? <>
                                    <Grid item xs={12} >
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label={ currentUser.country == 'CA' ? 'Transit and Institution Number (use digits only)' : "ABA / Routing Number"}
                                            InputProps={{ readOnly: true }}
                                            value={editingBank.rn}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="Account Number"
                                            InputProps={{ readOnly: true }}
                                            value={editingBank.an}
                                            className={classes.textField}
                                        />
                                    </Grid>
                                </>
                                : <>
                                    <Grid item xs={12}>
                                        <img className={classes.checkImage} src={`/${currentUser.country == 'CA' ? 'Canada_Check_Sample.jpg'  : 'bankCheck.gif'}`} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MaterialTextField
                                            fullWidth
                                            margin="normal"
                                            field={bankForm.$("routingNumber")}
                                            className={classes.textField}
                                            inputProps={{
                                                maxLength: 9
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MaterialTextField
                                            fullWidth
                                            margin="normal"
                                            field={bankForm.$("accountNumber")}
                                            className={classes.textField}
                                        />
                                    </Grid>

                                </>}


                            <Grid item xs={12} md={6}>
                                <MaterialTextField
                                    fullWidth
                                    margin="normal"
                                    field={bankForm.$("firstName")}
                                    className={classes.textField}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MaterialTextField
                                    fullWidth
                                    margin="normal"
                                    field={bankForm.$("lastName")}
                                    className={classes.textField}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    {error ? <Alert severity="error">{error}</Alert> : null}
                    <Typography variant="body1">By adding this bank I confirm that I'm an authorized signer on this account.</Typography>
                    <div className={classes.buttons}>
                        <span className={classes.defaultMark}>
                        </span>
                        <Button
                            onClick={navigateBackToParent}
                            color="primary" className={classes.textField} >Cancel</Button>

                        <Button variant="contained"
                            onClick={bankForm.onSubmit}
                            color="primary" className={classes.textField} >Save</Button>
                    </div>
                </Grid>
            </>
        }
    </>;
});