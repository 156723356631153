import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import BackspaceIcon from '@material-ui/icons/Backspace';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: 400
    },
    button: {
        display: 'flex',
        width: '33%',
        fontSize: 24,
        justifyContent: 'center',
        alignItems: 'center',
        color: "#007BFF",
        padding: '10px 20px'
    },
    clear: {
        fontSize: 16
    },
    back: {
        fontSize: 22
    }
}));

const Keypad = ({ onKeyPressed, value }) => {
    const classes = useStyles();
    const keypadKeys = [
        [
            {
                code: '1',
                content: '1'
            },
            {
                code: '2',
                content: '2'
            },
            {
                code: '3',
                content: '3'
            }
        ],
        [
            {
                code: '4',
                content: '4'
            },
            {
                code: '5',
                content: '5'
            },
            {
                code: '6',
                content: '6'
            }
        ],
        [
            {
                code: '7',
                content: '7'
            },
            {
                code: '8',
                content: '8'
            },
            {
                code: '9',
                content: '9'
            }
        ],
        [
            {
                code: 'clear',
                content: 'CLEAR'
            },
            {
                code: '0',
                content: '0'
            },
            {
                code: 'back',
                content: <BackspaceIcon />
            }
        ],
    ];

    const onButtonClick = (key) => {
        if (key.code === 'back') {
            if (value)
                onKeyPressed(value.slice(0, -1));
            else
                onKeyPressed(0);
        } else if (key.code === "clear") {
            onKeyPressed(0);
        } else {
            onKeyPressed(value + key.code);
        }
    };

    return (
        <div className={classes.root}>
            <Grid spacing={1}>
                {
                    keypadKeys.map(keyRow => (
                        <Grid container item >
                            {keyRow.map(key => <Button className={clsx(classes.button, classes[key.code])} key={`button-${key.code}`}
                                onClick={() => onButtonClick(key)}
                            >{key.content}
                            </Button>)}
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
};

export default Keypad;