import { makeAutoObservable, runInAction, } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import {
    generateLoadEntity,
    generateLoadList,
} from "../utils/mobx";
import * as _ from 'lodash';

const VALID_BITREFILL_AMOUNTS = [20, 25, 50, 60];
const CAMPAIGN_COUNTRIES = ['US', 'CA'];
export const DEFAULT_COUNTRY = "US";

export const FAVORITES_FILTER = { id: 'favorites', en: 'Favorites', fr: 'Favoris'};

async function getFilteredMerchants(merchants, countryCode, banners,) {
    const filteredMerchants = merchants[countryCode];
    const newBanners = banners.map(merchantInfo => {
        if (merchantInfo.merchant && merchantInfo.merchant.provider === "Bitrefill") {
            const merchant = _.find(filteredMerchants, { name: merchantInfo.merchant.name });
            if (!merchant) return;
            merchantInfo.merchant.id = merchant.id;
            merchantInfo.merchant.staticPrices = [merchant.denomination];
        }
        return merchantInfo;
    });
    return newBanners
        .filter(banner => banner)
        .sort(function (banner1, banner2) {
            // Ascending: sort by displayOrder
            return banner1.displayOrder - banner2.displayOrder;
        });
}

class ShoppingBossStore {
    merchants = {}; // {'CA': [...merchantList], 'US': [...merchantList]}
    merchantsLoading = false;

    countryCode = null;
    categories = [FAVORITES_FILTER]
    currentPage = 'merchants';
    selectedMerchant = null;
    userBalance = null;
    userCountry = null;
    favorites = [];
    usBanners = [];
    caBanners = [];
    favoritesLoading = false;
    togglingFavorites = false;
    bannersLoading = null;
    loadingMerchants = null;
    switchingMerchants = false;

    lifetimeBalance = null;
    perksEarnedLifetime = null;
    merchantInfo = null;
    eCodeInfo = null;
    merchantID = null;
    merchantCountry = null;
    merchantTerms = null;
    checkoutInfo = null;
    userInfo = null;
    checkoutInfoLoading = false;
    giftCards = [];
    completeCheckoutLoading = false;

    eAcceleratorValid = false;
    eAcceleratorValidityLoading = null;

    loadingAlbums = null;
    albums = [];

    constructor(authStore) {
        makeAutoObservable(this);
        this.authStore = authStore
    }

    async getBanners() {
        this.setError(null, "banners")
        this.bannersLoading = true
        try {
            const [{banners}] = await Promise.all([services.ShoppingBoss.starfishBanners()
                , this.getMerchantsByCampaign('US', "getBanners:US")
                , this.getMerchantsByCampaign('CA', "getBanners:CA")])
            const usBanners = await getFilteredMerchants(this.merchants, 'US', banners.filter(merchant => merchant.country === 'US'));
            const caBanners = await getFilteredMerchants(this.merchants, 'CA', banners.filter(merchant => merchant.country === 'CA'));

            //console.log("all banners loaded")
            //DEBUG TO TEST VIMEO
            //usBanners[0].url = "https://player.vimeo.com/video/744758395"
            //usBanners[0].title = "some title"
            runInAction(() => {
                this.usBanners = usBanners;
                this.caBanners = caBanners;
            });
        }
        catch(e) {
            this.setError("Error while loading banners: " + extractErrorMessage(e), "banners")
            if( this.loadingMerchants == null )
                this.loadingMerchants = false
        }
        finally {
            runInAction(() => {
                this.bannersLoading = false

            })
        }

    }

    setError(error, type = "shoppingBoss") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    get eAcceleratorBannerImageUrl() {
        return 'https://firebasestorage.googleapis.com/v0/b/life-info-b7c3e.appspot.com/o/shop%2Fimages%2Fimage1623788648517_1000.jpg?alt=media&token=3427d7cc-c0b6-4c5e-a30d-a0b49b1b688b';
    }

    formatPercent(data) {
        _.forEach(data, (item) => {
            item.percent = parseFloat(item.percent).toFixed(2) + "%";
        });
        return data;
    };

    loaders = {}

    getMerchantsByCampaign = async  (campaign, from) => {
       // console.log("getMerchantsByCampaign", from)
        // Return cached merchants if they exist for campaign
        if (this.merchants[campaign]) {
            return _.uniqBy(this.merchants[campaign], 'id');
        }

        let loader
        if ( !this.loaders [campaign]) {
            console.log("no loader, create  new one", from, campaign)
            let resolver ;
            this.loaders[campaign] =  new Promise((resolve, reject)=>{ resolver = resolve })

            await generateLoadEntity("merchants", this, "merchantsLoading", async () => {
                //console.log("begin getMerchantsByCampaign", campaign)
                const { merchants } = await services.ShoppingBoss.merchantsWithOOS(campaign);

                let newMerchants = { ...this.merchants };
                newMerchants[campaign] = this.formatPercent(merchants.merchants);

                // Fix goofy shopping boss multiple ids
               // console.log("newMerchants", newMerchants, campaign)
                return newMerchants;

            }, "merchants", null, null, {force:true, resolver})()

        }
        else {
           // console.log("existing loader", from)
            loader = this.loaders[campaign]
        }

       // console.log(loader, from)

        const result = await loader
      //  console.log("resolved loader", from)
        return result
    };

    getFavoriteMerchants = generateLoadList("favorites", this, "favoritesLoading", async (options) => {
        const { merchants } = await services.ShoppingBoss.favorites();
        return merchants;
    }, "favorites");

    getCategories = generateLoadList("categories", this, "categoriesLoading", async (options) => {
        const { categories } = await services.ShoppingBoss.categories();
        return [FAVORITES_FILTER, ...categories];
    }, "categories");

    verifyEAccelerator = generateLoadEntity("verifyEAccelerator", this, "eAcceleratorValidityLoading", async (options) => {
        return await services.ShoppingCart.verifyEAccelerator();
    }, "eAcceleratorValid", "alreadyPurchased");

    loadAlbums = generateLoadList("albums", this, "loadingAlbums", async (options) => {
        return await services.ShoppingBoss.albums();
    }, "albums");

    async toggleFavorite(isFavorite, merchantId) {
        try {
            this.togglingFavorites = true;

            if (isFavorite) {
                this.favorites.replace(this.favorites.filter(f => f.id !== merchantId));
                await services.ShoppingBoss.removeFromFavorites(merchantId);
            } else {
                const flattenedMerchants = Object.values(this.merchants).flat();
                this.favorites.push(flattenedMerchants.find(m => m.id === merchantId));
                await services.ShoppingBoss.addToFavorites(merchantId);
            }
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
        } finally {
            this.togglingFavorites = false;
        }
    }

    async fetchUserCountry() {
        try {
            let newCountry = DEFAULT_COUNTRY;
            let profile = await services.UserProfile.list();

            const { country } = profile.address;

            if (country && CAMPAIGN_COUNTRIES.includes(country)) {
                newCountry = country;
            }

            runInAction(() => {
                this.userCountry =  newCountry;

            });
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        }
    }

    async fetchUserBalance() {
        try {
            const result = await services.ShoppingBoss.userBalance();
            const walletStats = await services.UserProfile.getWalletStats();
            if (result.message == 'success') {
                const perksEarnedLifetime = parseFloat(walletStats?.wallet?.giftCards?.perksEarnedLifetime) || 0;
                runInAction(() => {
                    this.userBalance = result.result.balance;
                    this.perksEarnedLifetime = perksEarnedLifetime;
                    this.lifetimeBalance = result.result.cashEarnedLifetime + perksEarnedLifetime;
                });
            } else {
                throw new Error("Unable to fetch user balance");
            }
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        }
    }

    async fetchMerchantInfo() {
        try {
            if (this.selectedMerchant.provider === 'ShoppingBoss' || this.selectedMerchant.provider === 'sparkwallet') {
                const { eCodeInfo, merchantInfo } = await services.ShoppingBoss.merchantInfo(this.selectedMerchant.id);
                runInAction(() => {
                    this.merchantInfo = merchantInfo;
                    this.eCodeInfo = eCodeInfo;
                });
            } else {
                // We're not getting info from the backend for Bitrefill
                // Fill in the ecode info with
                const percentBack = !!this.selectedMerchant.percent ? this.selectedMerchant.percent : '1%';
                runInAction(() => {
                    this.merchantInfo = this.selectedMerchant;
                    this.eCodeInfo = { percent: percentBack, hasRange: false, staticPrices: this.merchantInfo.staticPrices };
                });
            }
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

     async updateMerchants (newCountryCode)  {
         this.loadingMerchants = true
         if( this.merchantsByCountry.length >0)
             this.switchingMerchants = true
         try {
            console.log("updateMerchants", newCountryCode   );
             await Promise.all([
                 this.getMerchantsByCampaign(newCountryCode, "updateMerchants"),
                 this.getFavoriteMerchants(),
                 this.getCategories()
             ]);

         }
         finally {
                console.log("updateMerchants done", newCountryCode   );
                this.countryCode = newCountryCode;
                this.loadingMerchants = false
                this.switchingMerchants = false
         }


    };

    async startCheckout(params) {
        try {
            this.checkoutInfoLoading = true;
            const userInfo = await services.ShoppingBoss.user();
            const { checkoutInfo } = await services.ShoppingBoss.checkoutToken({
                merchantID: params.merchantID,
                eCodeID: params.eCodeID,
                denomination: params.denomination,
                provider: this.selectedMerchant.provider
            });

            runInAction(() => {
                this.merchantCountry = params.merchantCountry; // passed from Review page
                this.merchantTerms = params.merchantTerms;
                this.checkoutInfo = checkoutInfo;
                this.userInfo = userInfo;
                this.merchantID = params.merchantID;
            });
        }
        catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        }
        finally {
            runInAction(() => {
                this.checkoutInfoLoading = false;
            });
        }
    }

    async checkout(params) {
        try {
            this.completeCheckoutLoading = true;

            const response = await services.ShoppingBoss.checkout(params);//{ giftCards: [{giftcardURL: 'https://google.com'}]};
            runInAction(() => {
                this.giftCards = response.giftCards;
            });
        } catch (e) {
            runInAction(() => {
                this.setError(e);
            });
            throw extractErrorMessage(e);
        } finally {
            runInAction(() => {
                this.completeCheckoutLoading = false;
            });
        }
    }

    isValidBitrefillAmount(value) {
        return VALID_BITREFILL_AMOUNTS.includes(value);
    }

    get validBitrefillAmounts() {
        return VALID_BITREFILL_AMOUNTS;
    }

    get maximumAllowed(){
        if (  this.authStore?.currentUser?.country === "CA")
            return 2500;
        return 2000;
    }

    get eWalletMaximumAllowed() {
        return this.maximumAllowed;
    }

    get eWalletCanAdd() {
        return Math.max(this.eWalletMaximumAllowed - this.userBalance, 0);
    }

    get balance() {
        let userBalance = this.userBalance || 0;
        return `$${userBalance.toFixed(2)}`;
    }

    get lifetimePerks() {
        let balance = this.lifetimeBalance || 0;
        return `${balance.toFixed(2)}`;
    }

    get  merchantsByCountry() {
        // console.log( "recalc merchantsByCountry",  Object.keys( this.merchants ) , this.countryCode )
        return this.merchants[this.countryCode] || [];
    }
}

export default ShoppingBossStore;