import React from 'react';
import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkIcon: {
        fontSize: '10rem',
        color: '#007BFF',
        marginBottom: theme.spacing(2)
    },
    successMessage: {
        textAlign: 'center',
    },
    button: {
        padding: theme.spacing(2),
        maxWidth: '400px',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
}));

const Completed = ({lifelineMatch}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    
    const returnToLifeline = () => {
        window.location.href = `${lifelineMatch.url}`
    }

    return (
        <Container className={classes.wrapper}>
            <CheckCircleIcon className={classes.checkIcon} />
            <Typography variant="body1" className={classes.successMessage}>{t('lifeline:createVideo.completed.success')}</Typography>
            <Button onClick={returnToLifeline} color="primary" variant="contained" className={classes.button}>{t('lifeline:createVideo.completed.return')}</Button>
        </Container>
    )
}

export default Completed;