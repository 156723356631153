import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import services from "../services";
import { useStores } from "../hooks/use-stores";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import LanguageIcon from "@material-ui/icons/Language";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BillingInfoForm from "../components/enrollment/billingInfoForm";
import BillingAddressForm from "../components/enrollment/billingAddressForm";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormHelperText from '@material-ui/core/FormHelperText';
import CartView from "../components/enrollment/cartView";
import ItemDialog from "../components/enrollment/itemDialog";
import ErrorModal from '../components/ErrorModal';
import {STARFISH_KIT_EN_SKU, STARFISH_KIT_ES_SKU, STARFISH_KIT_FR_SKU} from "../utils/constants";

const headerHeight = 60;
const navMobileHeight = 50;
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
    },
    logo: {
        width: 50,
        height: 50,
        backgroundColor: 'black',
    },
    header: {
        height: headerHeight
    },
    navigationWrapper: {
        background: "#f8fafd",
        boxShadow: "7px 0 10px #aeaeae;",
        zIndex: 1,
        padding: "0 8px",
    },
    navigation: {
        [theme.breakpoints.down('md')]: {
            height: navMobileHeight,
        },
    },
    contentPanel: {
        position: "relative",
        background: "#ebebeb",
        flexGrow: 1,

        height: "100vh",
        overflow: "scroll",
        [theme.breakpoints.down(1180)]: {
            height: `calc( 100vh - ${headerHeight + navMobileHeight}px )`,
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5),
        },
    },
    contentPanelFade: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(200,200,200, 0.4)"
    },
    referralInfo: {
        fontSize: "11px"
    },
    tabPanel: {
        width: "100%"
    },
    captionWithButton: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"

    },
    termsTitle: {
        padding: 20,
        borderLeft: "5px solid #007BFF",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },

    large: {
        width: theme.spacing(16),
        height: theme.spacing(16)
    }

    , removeConfirmText: {
        marginTop: 10,
        marginBottom: 10,
        textAlign: "center",
        width: "100%"
    }
}));


const pages = {
    "1": "billinginfo",
    "2": "summary"
};
export default (observer(({ component: C, ...rest }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const classes = useStyles();
    const { kitOnlyStore: enrollmentStore, commonStore, userBillingInfoStore, userProfileStore, authStore,statStore } = useStores();
    const { allCountries, regionsByCountry } = userProfileStore;
    const { currentUser } = authStore;    const { cards:creditCards, allLoading } = userBillingInfoStore;
    const { eWalletBalance } = statStore;
    const {
        expired, referralInfo, loadingReferralInfo, loadingCartInfo, loadingAdditionalItems
        , effectiveAdditionalItems, error, creating, activeStep, countries
        , billingInfoForm, billingInfoFormEdit, billingInfoFormEditSubmitting, billingInfoFormSubmitting
        , billingAddressForm, billingAddressFormEdit, billingAddressFormEditSubmitting, billingAddressFormSubmitting
        , cart, cartTotal, oneTimeItems, recurringItems, isStarfishSignup,
        havePhysicalItems, paymentID
    } = enrollmentStore;



    let product = new URLSearchParams(location.search).get("product");
    let upline = new URLSearchParams(location.search).get("upline");
    let uplineName = new URLSearchParams(location.search).get("uplineName");
    let uid = new URLSearchParams(location.search).get("uid");
    let language = new URLSearchParams(location.search).get("language");


    const [title, setTitle] = React.useState(pages[1]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [country, setCountry] = useState("US");
    const [refundAcknowledge, setRefundAcknowledge] = useState(false);
    const [fundEWallet, setFundEWallet] = useState(false);
    const [subscriptionAcknowledge, setSubscriptionAcknowledge] = useState(false);
    const [complete, setComplete] = useState(false);
    const [showEditBillingInfoModal, setShowEditBillingInfoModal] = useState(false);
    const [showEditBillingAddressModal, setShowEditBillingAddressModal] = useState(false);
    const [showItemDetails, setShowItemDetails] = useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorModal, setErrorModal] = React.useState(false);
    const [allowErrorModalToClose, setAllowErrorModalToClose] = React.useState(true);

    useEffect(() => {
        let country = "US";
        if (!!referralInfo)
            country = referralInfo.referralCountry;
        setCountry(country);
        enrollmentStore.initBillingInfoForm(country);

        (async () => {
            await userProfileStore.loadAllCountries();
            await userBillingInfoStore.loadAll();
            await userProfileStore.loadRegionsByCountry(country);
        })();

    }, []);

    useEffect(() => {
        enrollmentStore.setProduct(product);
    }, [product]);

    const cards = useMemo(()=>{
        return [ {paymentID: "ewallet", ewallet:true, balance:eWalletBalance}, ...creditCards]
    },[creditCards, eWalletBalance])

    let selectedCard = cards && cards.find(x => x.paymentID === paymentID);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNextStep = () => {
        enrollmentStore.handleNextStep();
    };

    const handlePrevStep = () => {
        enrollmentStore.handlePrevStep();
    };

    const handlePaymentMethodChanged = (newVal) => {
        enrollmentStore.setPaymentId(newVal);
    };

    const handleFinishEnroll = async () => {

        let dto = {
            id: uid,
            cartGuid: cart.cartID,
            ...({ "address1": "",
                "address2": "", //optional
                "city": "",
                "region": "",
                "country": "",
                "postalCode": ""}),
            fundEWallet,
            ...(!paymentID ? billingInfoForm.values() : {}),
            addressID: 0,
            paymentID,
            useEwallet: paymentID === "ewallet",
            lang: commonStore.language,
            ipAddress: await services.Utility.getIPAddress().then(response => response.ip),
            product,
            referralID: uid
        };
        if (dto.phone && dto.phone.trim().length > 0) {
            dto.phone = "+1" + dto.phone;
        }
        try {
            hideErrorModal();
            await enrollmentStore.create(dto);
            setComplete(true);
        } catch (e) {
            let errorMessage = 'Error processing Customer Enrollment. Please contact support@lifeinfoapp.com';
            if (!!e.response && !!e.response.data && !!e.response.data.message)
                errorMessage = e.response.data.message;
            showErrorModal(errorMessage);
        }
    };

    const showErrorModal = React.useCallback((errorMessage, bAllowErrorModalToClose = true) => {
        setErrorMessage(errorMessage);
        setErrorModal(true);
        setAllowErrorModalToClose(bAllowErrorModalToClose);
    }, []);


    const hideErrorModal = () => {
        setErrorMessage("");
        setErrorModal(false);
    };

    const switchLanguage = (lang) => {
        handleClose();
        commonStore.setLanguage(lang, true);

    };


    useEffect(
        () => {
            setTitle(pages[activeStep]);
        }, [activeStep]);



    useEffect(
        () => {
            if (uid) {
                (async () => {
                    await enrollmentStore.verify(uid);
                })();
            }

        }, [product, uid]);

    useEffect(
        () => {
            if (language) {
                commonStore.setLanguage(language, true);
            }

        }, [language, currentUser]);


    useEffect(
        () => {
            enrollmentStore.resetCart();
            userProfileStore.loadRegionsByCountry(country);
        }, [country]);

    useEffect(
        () => {
            (async () => {
                await enrollmentStore.verify(uid);
            })();
            if (activeStep === "2" && cart === null) {
                (async () => {
                    await  enrollmentStore.loadCart({ product, lang: commonStore.language, country })
                })();
            }
        }, [activeStep]);

    useEffect(
        () => {
            (async () => {
                if (cart) {
                    void loadCartTotal();
                }
            })();

        }, [billingAddressForm, country, cart, oneTimeItems, recurringItems]);


    const loadCartTotal = () => {
        (async () => {
            const billingAddress = !!billingAddressForm && !!billingAddressForm.values() ? billingAddressForm.values() : {};
            await enrollmentStore.loadCartTotal({
                cartGuid: cart.cartID,
                lang: commonStore.language,
                country: billingAddress?.billCountry || country,
                product,
                loginGuid: uid
            });
        })();
    };

    const showAddItemModal = (x) => {
        setAddItemModal(x);
    };
    const hideAddItemModal = (x) => {
        setAddItemModal(null);
    };

    const hideItemDetails = (x) => {
        setShowItemDetails(null);
    };

    const addItemToCart = (x) => {
        (async () => {
            await enrollmentStore.addToCart({
                cartID: cart.cartID,
                productID: x.effectiveProductID || x.productID,
                lang: commonStore.language,
                qty: 1
            });
            hideAddItemModal();
            await enrollmentStore.refreshItems({ cartID: cart.cartID, country, lang: commonStore.language });
        })();
    };




    //====  Billing Info Edit
    const showBillingInfoModal = () => {
        enrollmentStore.initBillingInfoFormEdit(hideBillingInfoModal);
        setShowEditBillingInfoModal(true);
    };

    const hideBillingInfoModal = () => {
        setShowEditBillingInfoModal(false);
        enrollmentStore.closeBillingInfoFormEdit();
    };


    //====  Billing Address Edit
    const showBillingAddressModal = () => {
        enrollmentStore.initBillingAddressFormEdit(hideBillingAddressModal);
        setShowEditBillingAddressModal(true);

    };


    const hideBillingAddressModal = () => {
        setShowEditBillingAddressModal(false);
        enrollmentStore.closeBillingAddressFormEdit();
    };


    const handleIconClick = (item) => {
        setShowItemDetails(item);
    };


    const handleSwitchAlternative = (item, alternative) => {
        enrollmentStore.switchAlternative(item, alternative, country)
    };

    const handleRemoveAlternative = (item, alternative) => {
        enrollmentStore.removeAlternative(item, alternative, country)
    };


    const handleToggleItem = (item, showConfirm) => {
        if (item.removed) {
            return addItemToCart(item);
        }
        const action = (async () => {
            await enrollmentStore.removeFromCart({ cartID: cart.cartID, productID: item.effectiveProductID || item.productID, lang: commonStore.language });
            await enrollmentStore.refreshItems({ cartID: cart.cartID, country, lang: commonStore.language });
        });
        if (item.additionalWarning && product === "topcustomer") //enforce confirm for predefined  items
            showConfirm = true;


        if (showConfirm)
            commonStore.showConfirm(
                <>
                    <Typography variant={"subtitle1"} className={classes.removeConfirmText}> {t("enrollment:removeconfirm", { cartitem: item.productTitle })}</Typography>
                    {(item.additionalWarning && product === "topcustomer") && <Alert variant={"standard"} color={"error"}>{t("enrollment:removecauselossofstatus")}</Alert>}
                </>
                , t("enrollment:remove")
                , t("enrollment:remove")
                , action);
        else
            action();

    };

    const getCountries = () => {
        return countries.map(x => {
            if (product && (product === 'starfish' || product.includes('topcustomer'))) {
                if (['US', 'CA'].includes(x.countryShortCode)) {
                    return <MenuItem key={x.countryShortCode}
                        value={x.countryShortCode}>{x.countryName}</MenuItem>;
                }
            }
            else {
                return <MenuItem key={x.countryShortCode}
                    value={x.countryShortCode}>{x.countryName}</MenuItem>;
            }
        });
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        billingInfoForm.update({ country: e.target.value });

    };

    const handleBillingCountryChanged = (newVal) => {
        if (newVal)
            userProfileStore.loadRegionsByCountry(newVal);
    };

    const patchedOneTimeItems  = useMemo(()=>{
        const kit = oneTimeItems.find(x => x.productSKU === STARFISH_KIT_EN_SKU
            || x.productSKU === STARFISH_KIT_FR_SKU
            || x.productSKU === STARFISH_KIT_ES_SKU);
        if (kit && referralInfo) {
            switch (product){
                case "starfish.kitmember":
                case "starfish.kitupline":
                case "starfish.kitonly":

                case "starfish.kitmember.fr":
                case "starfish.kitupline.fr":
                case "starfish.kitonly.fr":

                case "starfish.kitmember.es":
                case "starfish.kitupline.es":
                case "starfish.kitonly.es":

                    kit.productTitleAjusted  = kit.productTitle + ` (kit will be shipped to ${referralInfo.referralName})`;
                    break;
                default:
                    kit.productTitleAjusted  = kit.productTitle;
            }

        }

        return oneTimeItems;

    },[oneTimeItems, product, referralInfo])


    if (complete)
        return <Container maxWidth={false} disableGutters>
            <Grid container className={classes.root}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <Avatar alt="Logo" src={`/starfish.png`} variant={"rounded"} className={classes.logo} />
                </Grid>
                <Grid container item xs={12} direction="column" alignItems={"center"} justifyContent={"center"} spacing={5}>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h5"}>{t("enrollment:kitonly.congratulations")}</Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}>{t("enrollment:kitonly.congratulations2")}  </Typography></Grid>
                    <Grid item><Typography style={{ "color": "white" }} variant={"h6"}> {t("enrollment:kitonly.congratulations3")} </Typography></Grid>
                    <Grid item>
                        <Button component={"a"} href={`https://my.lifeinfoapp.com/starfish`}
                            variant={"contained"}
                            color={"primary"}>Home</Button>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.header} alignItems={"center"} justifyContent={"center"}>

                </Grid>
            </Grid>
        </Container>;
    if (expired)
        return <Container maxWidth={false} disableGutters>
            <Grid container className={classes.root}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    <Avatar alt="Logo" src={`/starfish.png`} variant={"rounded"} className={classes.logo} />
                </Grid>
                <Grid container item xs={12} className={classes.header} style={{ "color": "white" }} alignItems={"center"} justifyContent={"center"}>
                    {t("enrollment:expired")}
                </Grid>
            </Grid>
        </Container>;


    return <Container maxWidth={false} disableGutters>
        <Grid container className={classes.root}>
            <Grid container item xs={12} md={5} className={classes.navigationWrapper} alignItems={"flex-start"}>
                <Grid container item xs={12} className={classes.header} wrap={"nowrap"}>
                    {referralInfo && <ListItem disableGutters dense>
                        <ListItemAvatar>
                            <Avatar alt="Logo" src={`/starfish.png`} variant={"rounded"} className={classes.logo} />
                        </ListItemAvatar>
                        <div className={classes.referralInfo}>
                            <div><strong>{t("enrollment:referredby")}</strong></div>

                            <React.Fragment>
                                <div>{referralInfo.referralName}</div>
                                <div>{referralInfo.referralEmail}</div>
                                <div>{referralInfo.referralPhone}</div>
                            </React.Fragment>

                        </div>
                    </ListItem>}
                    <div>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <LanguageIcon /><ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={e => {
                                switchLanguage("en");
                            }}>English</MenuItem>
                            <MenuItem onClick={e => {
                                switchLanguage("es");
                            }}>Español</MenuItem>
                            <MenuItem onClick={e => {
                                switchLanguage("fr");
                            }}>Français</MenuItem>
                        </Menu>
                    </div>
                </Grid>
                <Grid container item xs={12} className={classes.navigation} justifyContent={"center"} direction={"column"}>

                    <div>
                        <Typography variant={"h5"}>
                            {activeStep > 1 && <IconButton onClick={handlePrevStep}><KeyboardArrowLeftIcon /></IconButton>}
                            {t(`enrollment:${title}`)}
                        </Typography>
                    </div>
                    {error && <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>}
                </Grid>
            </Grid>
            <Grid container item xs={12} md={7} className={classes.contentPanel}>
                <TabContext value={activeStep}>
                    <TabPanel value={"1"} className={classes.tabPanel}>
                        {allLoading
                            ? <CircularProgress />
                            : <BillingInfoForm existingPayments={cards}
                                               translationPrefix={"enrollmember"}
                                               allCountries={allCountries}
                                               regionsByCountry={regionsByCountry} paymentID={paymentID}
                                               handlePaymentMethodChanged={handlePaymentMethodChanged}
                                               handleCountryChanged={handleBillingCountryChanged}
                                               form={billingInfoForm}
                                               onSubmit={() => { enrollmentStore.submitBillingInfoForm(); }}
                                               submitting={billingInfoFormSubmitting} />}
                    </TabPanel>
                    <TabPanel value={"2"} className={classes.tabPanel}>
                        <Grid container spacing={3} justifyContent={"center"}>
                            {billingInfoForm && <Grid container item xs={12} spacing={3}>
                                <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:billinginfo")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                                onClick={showBillingInfoModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {paymentID === 0 && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardtype2")}{billingInfoForm.values().cardType}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardnumber2")}******** {billingInfoForm.values().cardNumber && billingInfoForm.values().cardNumber.substr(billingInfoForm.values().cardNumber.length - 4)}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardexpiration")}{billingInfoForm.values().cardExpMonth}/{billingInfoForm.values().cardExpYear}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cvv")}{billingInfoForm.values().cardCode}</Typography>
                                        </Grid>
                                    </Grid>}
                                    {selectedCard && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{t("enrollment:cardtype2")}{selectedCard.type} *******{selectedCard.last4}</Typography>
                                        </Grid>

                                    </Grid>}
                                </Grid>
                                {paymentID === 0 && <Grid container item md={12} lg={6}>
                                    <Grid item xs={12} className={classes.captionWithButton}>
                                        <Typography variant={"subtitle2"}>{t("enrollment:billingaddress")}</Typography>
                                        <Button variant={"contained"} color={"primary"} size={"small"}
                                                onClick={showBillingInfoModal}>{t("enrollment:edit")}</Button>
                                    </Grid>
                                    {billingInfoForm && <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billFirstName} {billingInfoForm.values().billLastName}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billAddress}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billCity}, {billingInfoForm.values().billRegion}, {billingInfoForm.values().billPostalCode}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"body2"}>{billingInfoForm.values().billCountry}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>}
                            </Grid>}
                            <Divider />

                            <Grid container item xs={12} lg={12} spacing={3}>
                                {(loadingCartInfo || loadingAdditionalItems)
                                    ? <CircularProgress />
                                    : <CartView
                                        isStarfishSignup={isStarfishSignup}
                                        oneTimeItems={patchedOneTimeItems}
                                        handleToggleItem={handleToggleItem}
                                        handleIconClick={handleIconClick}
                                        handleRemoveAlternative={handleRemoveAlternative}
                                        handleSwitchAlternative={handleSwitchAlternative}
                                        handleAdditionalItemSelect={showAddItemModal}
                                        cartTotal={cartTotal}
                                        effectiveAdditionalItems={effectiveAdditionalItems} />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.termsTitle}>
                                    {t(`enrollment:completekitonlyacknowledge`)}
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox style={{ color: '#007BFF' }} />}
                                    value={refundAcknowledge}
                                    onChange={() => { setRefundAcknowledge(!refundAcknowledge); }}
                                    label={t("enrollment:refundacknowledgelabel")} />
                                <FormHelperText>{t("enrollment:refundacknowledge")}</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <FormHelperText>{t("enrollment:personal_info_disclaimer")}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} lg={7}>
                                {creating
                                    ? <CircularProgress />
                                    : <Button onClick={handleFinishEnroll}
                                        disabled={ !refundAcknowledge || (cart ? cart.cartItems : []).length === 0}
                                        fullWidth variant={"contained"}
                                        color="primary">{t("enrollment:completepurchase")}</Button>}
                            </Grid>
                        </Grid>

                    </TabPanel>
                </TabContext>
                {loadingReferralInfo && <div className={classes.contentPanelFade}><CircularProgress size={50} /></div>}

            </Grid>
        </Grid>
        {showEditBillingInfoModal && <Dialog maxWidth={"md"} open={true} onClose={hideBillingInfoModal}>
            <DialogContent>
                <BillingInfoForm form={billingInfoFormEdit}
                                 allCountries={allCountries}
                                 regionsByCountry={regionsByCountry} paymentID={paymentID}
                                 handlePaymentMethodChanged={handlePaymentMethodChanged}
                                 handleCountryChanged={handleBillingCountryChanged}
                                 existingPayments={cards} translationPrefix={"enrollmember"} />
            </DialogContent>
            <DialogActions>
                {billingInfoFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideBillingInfoModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitBillingInfoEditForm();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {showEditBillingAddressModal && <Dialog maxWidth={"md"} open={true} onClose={hideBillingAddressModal}>
            <DialogContent>
                <BillingAddressForm form={billingAddressFormEdit}
                    allCountries={allCountries}
                    regionsByCountry={regionsByCountry}
                    handleCountryChanged={handleBillingCountryChanged} />
            </DialogContent>
            <DialogActions>
                {billingAddressFormEditSubmitting
                    ? <CircularProgress />
                    : <>
                        <Button onClick={hideBillingAddressModal} variant={"contained"}
                            color="secondary">{t("enrollment:cancel")}</Button>

                        <Button onClick={() => {
                            enrollmentStore.submitBillingAddressEditForm();
                            void loadCartTotal();
                        }} variant={"contained"}
                            color="primary">{t("enrollment:update")}</Button>
                    </>}
            </DialogActions>
        </Dialog>}
        {showItemDetails && <ItemDialog item={showItemDetails} hideItemModal={hideItemDetails} />}
        {!!errorModal
            ? (<ErrorModal allowUserToClose={allowErrorModalToClose} errorTitle={'Error'} handleClose={hideErrorModal} open={errorModal} error={errorMessage} />)
            : null
        }    </Container>;

}));