import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {
    Box,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import moment from "moment/moment";
import {useStores} from "../../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MembersDataTable from "./components/MembersDataTable";
import CustomersDataTable from "./components/CustomersDataTable";
import {starFishColor} from "../../components/styles";
import SummaryRows from "./components/SummaryRows";
import {reaction} from "mobx";

const useStyles = makeStyles((theme) => ({
       accordionSummary: {
        ...starFishColor(theme, "backgroundColor"),
        "&.Mui-expanded": {
            margin: 0,
            minHeight: 50
        },
        "& .MuiAccordionSummary-content": {
            margin: "11px 0"
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "11px 0"
        }
    },

}));

function MyVolumePage() {
    const classes = useStyles();
    const {t} = useTranslation("myvolume");
    const {authStore, commonStore, myVolumeStore, periodsStore} = useStores();
    const {periodsLoading} = periodsStore;

    const {currentUser } = authStore;
    const {PVPeriodID, volumeData, volumeDataLoading
        , currentPeriodMemberSubtotal
        , currentPeriodCustomerSubtotal
        , currentPeriodMemberShippingTax
        , currentPeriodCustomerShippingTax
        ,currentPeriodMemberTotal
        ,currentPeriodCustomerTotal
        ,currentPeriodMemberPV
        ,currentPeriodCustomerPV,
        currentIsMemberList,
        currentIsCustomerOrDirectCustomerList,
        SAPercent,
        currentPendingCustomerList,
        currentPendingCustomerSubtotal,
        currentPendingCustomerShippingTax,
        currentPendingCustomerTotal,
        currentPendingCustomerPV
    } = myVolumeStore;

    useEffect(() => {

        return reaction(
            () => myVolumeStore.PVPeriodID,
            PVPeriodID => {
                if (PVPeriodID) {
                    void myVolumeStore.loadVolumeData();
                }
            }
        );
    }, []);

    useEffect(() => {
        if (currentUser) {
            (async () => {
                await periodsStore.loadPeriods(currentUser.userID);
                await myVolumeStore.loadVolumeData();
            })();
        }
    }, [currentUser])


    const handleChangePeriod = (e) => {
        myVolumeStore.setPVPeriodID(e.target.value)
    }

    const showPendingInfoPopup = (e)=>{
        e.stopPropagation();
        e.preventDefault();

        commonStore.showConfirm( <p className={classes.popupText}>{t("pending_description")} </p>
            , "OK"
            , t("pending_description-title")
            , null
            , null
            , undefined
            , { hideHeader:false })
    }

    return <>
        {periodsLoading
            ? <></>
            : <>
                <Box display={"flex"} alignItems={"center"} style={{gap:16}}>
                    <Typography variant={"subtitle1"}>{t("my_volume")}</Typography>
                    <Select variant={"outlined"} value={PVPeriodID} onChange={handleChangePeriod}>
                        {periodsStore.periods.map(period => {
                            return <MenuItem key={period.PVPeriodID}
                                             value={period.PVPeriodID}>{moment.utc(period.StartDate).format("MMMM yyyy")}</MenuItem>
                        })}
                    </Select>
                </Box>
            </>}

        { (volumeDataLoading || periodsLoading )
            &&
            <Box alignItems="center" justifyContent="center" display="flex" minHeight={120}> <CircularProgress/> </Box>}

        { volumeDataLoading != null && !volumeDataLoading && volumeData
            ? <><Box display={"flex"} justifyContent={"flex-end"}>
                <SummaryRows t={t} showHeader={false} pv={currentPeriodMemberPV + currentPeriodCustomerPV}
                                subTotal={currentPeriodMemberSubtotal + currentPeriodCustomerSubtotal}
                                shippingTax={currentPeriodMemberShippingTax + currentPeriodCustomerShippingTax}
                                total={currentPeriodMemberTotal + currentPeriodCustomerTotal}
                />
            </Box>
<br/>
<br/>

                <Accordion defaultExpanded>
                    <AccordionSummary
                        color="primary" className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>{t("members")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MembersDataTable data={currentIsMemberList}
                                            subTotal={currentPeriodMemberSubtotal}
                                            shippingTax={currentPeriodMemberShippingTax}
                                            total={currentPeriodMemberTotal}
                                            pv={currentPeriodMemberPV}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        color="primary" className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>{t("customers")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomersDataTable data={currentIsCustomerOrDirectCustomerList}
                                            subTotal={currentPeriodCustomerSubtotal}
                                            shippingTax={currentPeriodCustomerShippingTax}
                                            total={currentPeriodCustomerTotal}
                                            pv={currentPeriodCustomerPV}
                                            SAPercent={SAPercent}/>
                                                
                    </AccordionDetails>
                    {currentIsCustomerOrDirectCustomerList && currentIsCustomerOrDirectCustomerList.length > 0 && (
                            <p style={{padding:10}}> {t("no_customer_requirements")}</p>
                    )}

                </Accordion>
                <Accordion>
                    <AccordionSummary
                        color="primary" className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>{t("pending")}
                        </Typography>
                        <InfoIcon onClick={showPendingInfoPopup} style={{cursor: "pointer", marginLeft:10 }}/>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomersDataTable data={currentPendingCustomerList}
                                            subTotal={currentPendingCustomerSubtotal}
                                            shippingTax={currentPendingCustomerShippingTax}
                                            total={currentPendingCustomerTotal}
                                            pv={currentPendingCustomerPV}
                                            SAPercent={SAPercent}/>
                    </AccordionDetails>
                </Accordion>
            </>
            : null
        }


    </>

}


export default observer(MyVolumePage)