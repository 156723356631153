import { makeAutoObservable, reaction, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import shippingAddressForm from "../forms/shippingAddress";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList, generateLoadList2,
    generateUpdateEntity
} from "../utils/mobx";
import { getCookie, deleteCookie, setCookie } from "../utils/cookieUtil";

class ShareStore {
    constructor() {
        makeAutoObservable(this);

    }

    checkingCode = {};

    //interface IShareLife {
    //     messageType: string; //matches types above
    //     mediaURL: string;
    //     shopURL: string; //only populated for marketplace products (parentCategory: 790)
    //     senderInfo: ISenderInfo;
    // }
    //
    // interface ISenderInfo {
    //     senderEmail: string;
    //     senderDisplayName: string;
    //     senderPhone: string;
    // }
    shareDetails = {};

    error = null;

    setError(error, type = "") {
        this.error = extractErrorMessage(error);
    }

    async loadShareDetails (code ) {
        if (this.checkingCode[code] || this.shareDetails[code])
            return this.shareDetails[code]
        this.checkingCode[code] = true
        try {
            let result =  await services.Share.getShareDetails(code);
            runInAction(() => {
                this.shareDetails[code] = result
            })
            return true
        } catch (e) {
            this.setError(e)
            return false
        } finally {
            runInAction(() => {
                this.checkingCode[code] = false
            })
        }
    }
}

export default ShareStore;