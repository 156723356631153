import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import ReactAudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import {Avatar, CircularProgress, ListItemAvatar, ListItemText, makeStyles} from '@material-ui/core';
import Stop from "@material-ui/icons/HighlightOffOutlined";
import Fullscreen from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Forward15 from "../icons/Forward15";
import Rewind15 from "../icons/Rewind15";
import Play from "../icons/Play";
import Pause from "../icons/Pause";
import '../css/audioplayer.scss';
import { useStores } from '../hooks/use-stores';
import {observer, useLocalObservable} from "mobx-react-lite";
import clsx from 'clsx';
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ShareIcon from "@material-ui/icons/Share";
import {joinPath} from "../utils/helpers";
import Fab from "@material-ui/core/Fab";


const useStyles = makeStyles((theme) => ({

    expandedPlayerContainer:{
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        minHeight: 400,
        backgroundColor: "#3579f6"
    },

    playerContainer: {
        // position: "absolute",
        //  top: "-58px",
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        height: "56px",
        zIndex: 2000,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: "flex",
        justifyContent: "stretch",
        alignItems: "stretch",
    },
    starfishBackground: {
        backgroundColor: '#3579f6!important',
        '& .rhap_main-controls': {
            backgroundColor: '#3579f6!important'
        }
    },
    phoneStop: {
        position: "absolute",
        right: 5,
        top: 25,
        width: '30px',
        height: '30px'
    },

    stopFullscreen: {
        position: "absolute",
        right: 8,
        top: 8,
        width: '30px',
        height: '30px'
    },

    phoneFullscreen: {
        position: "absolute",
        right: 5,
        top: 55,
        width: '30px',
        height: '30px'
    },

    desktopFullscreen: {
        position: "absolute",
        left: 8,
        top: 8,
        width: '30px',
        height: '30px'
    },
    moveUp: {
        '& .rhap_container': {
            bottom: '56px',
            '@media all and (display-mode: standalone)': {
                bottom: '80px',
            }
        }
    },
    audioTitle: {
        whiteSpace: "nowrap",
        color: "white",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: 14,
        maxWidth: "200px",
        [theme.breakpoints.up(701)]: {
            maxWidth: "200px",
        },
        [theme.breakpoints.up(1001)]: {
            maxWidth: "300px",
        }
    },
    audioSpeaker: {
        whiteSpace: "nowrap",
        color: "white",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: 12,
        [theme.breakpoints.up(701)]: {
            width: "200px",
        },
        [theme.breakpoints.up(1001)]: {
            width: "300px",
        }
    },
    audioPlayer:{
            backgroundColor: "#1d1d1d",
                maxWidth: 1132,
            [theme.breakpoints.down(700)]: {
                height: 120,
                backgroundColor: "rgb(71,119,238)"
            }

        },
    audioPlayerFullScreen: {
            backgroundColor: "#1d1d1d",
                maxWidth: 1132,
            [theme.breakpoints.down(700)]: {
                backgroundColor: "rgb(71,119,238)"
            }
    },
    audioTrackInfo: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 0,
        [theme.breakpoints.down(700)]: {
            marginTop: 25,
            //marginBottom: 5,
        }
    },
    audioTrackInfoFullScreen: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: 20,
        paddingRight: 0,
        marginTop: 40,
        [theme.breakpoints.down(700)]: {
            //marginTop: 25,
            //marginBottom: 5,
        }
    },

    listItemFullScreen: {
        position:"relative"
    },
    largePict: {
        width: 40,
        height: 40
    },
    largePictFullScreen:{
        width: 192,
        height: 192
    },
    smallPict: {
        width: 32,
        height: 32
    },
    favoriteButton:{
        color: "white",
        position: "absolute",
        top: 0,
        right: 0
    },

    playbackRate: (props)=>({
        position: "absolute",
        color: "white",
        right: props.fullscreen? 10: "initial",
        left: !props.fullscreen? 10: "initial",
        minWidth: "initial",
        bottom: props.fullscreen? -5:  0,

        [theme.breakpoints.down(700)]: (props) => ({
            bottom: props.fullscreen? -5:  10,
            left: !props.fullscreen? 25: "initial",
        })



    })

}));

const AudioPlayer = ({ isPhone, isStarfish, enableModeSwitch = true }) => {


    const [fullscreen, setFullscreen] = React.useState(true);
    const { authStore, audioStore, libraryStore } = useStores();
    const {currentUser} = authStore;
    const {audiosFavorites} = libraryStore;
    const { audioPaused, mediaURL, mediaTitle, mediaSpeakers, mediaImageURL } = audioStore;
    const classes = useStyles({isStarfish, fullscreen});
    const history = useHistory();

    const changeRef = useCallback((element)=>{
        audioStore.setElement(element);
    },[])


    const toggleFullscreen = () => {
        audioStore.storeSwitchPlayerPositionRestoreTime()
        setFullscreen((prev) => !prev);
    }

    useEffect(()=>{
        audioStore.restartIfNeed()
        return ()=>{
            audioStore.pauseMedia()
        }
    },[])


    const localStore = useLocalObservable(() => ({
        get isFavorite() {
            if (audiosFavorites.length > 0 && audioStore.mediaID !== null )
                return  audiosFavorites.find( (item)=>item.mediaID === audioStore.mediaID )
        }
    }));

    const handleToggleFavorite = () =>{
            if (localStore.isFavorite){
            libraryStore.removeFavorite(audioStore.mediaID)
        } else {
            libraryStore.addFavorite(audioStore.mediaID, audioStore.media)
        }
    }

    const modifyingFavorite = libraryStore.audiosFavoritesCreating
        || libraryStore.audiosFavoritesRemoving.includes(audioStore.mediaID)

    const toggleProgressRate = () => {
        audioStore.toggleProgressRate()
    }


    const openSendOut = () => {

        //audioStore.cre
        let prefix;
        if ( authStore.subscriptions.hasSuperAppPlus ){
            prefix = "/royalstarfish"
        } else if (  authStore.isStarfish  ){
            prefix = "/starfish"
        }
        audioStore.setSendOutMedia(audioStore.media)
        setFullscreen(false)
        history.push( joinPath( prefix,  "/members/mspremium/create_product_sendout") )
    }

    if (!mediaURL ) {
        return null;
    }
    const  player = <ReactAudioPlayer autoPlay={!audioPaused}
                                      showJumpControls={true}
                                      progressJumpSteps={{ backward: 15000, forward: 15000 }}
                                      src={mediaURL}
                                      loop={false}
                                      className={
                                          clsx(
                                              isStarfish && classes.starfishBackground
                                              , {
                                                  [classes.audioPlayer]: !fullscreen,
                                                  [classes.audioPlayerFullScreen]: fullscreen,
                                              }
                                          )}
                                      onPause={e => { audioStore.setAudioPaused(true); }}
                                      onPlay={e => { audioStore.setAudioPaused(false); }}
                                      onListen={e => { audioStore.setAudioProgress(e); }}
                                      onLoadedData={e => { audioStore.setStartPosition() }}


                                      layout={fullscreen?"stacked-reverse": "stacked"}
                                      customAdditionalControls={[]}
                                      customProgressBarSection={ [RHAP_UI.CURRENT_TIME, RHAP_UI.DURATION ]}
                                      showDownloadProgress={false}
                                      customControlsSection={[
                                          <Stop className={clsx({
                                              [classes.phoneStop]: isPhone || fullscreen,
                                              [classes.stopFullscreen]: fullscreen,


                                          } )} onClick={e => { audioStore.stopAudio(); }} />,
                                          ...(enableModeSwitch
                                                ? [ (fullscreen
                                                      ? <FullscreenExit className={classes.desktopFullscreen} onClick={toggleFullscreen}/>
                                                      : <Fullscreen className={clsx(isPhone && classes.phoneFullscreen)} onClick={toggleFullscreen} />)]
                                                : []
                                          )
                                          ,
                                          <Button  className={classes.playbackRate} size="small" onClick={toggleProgressRate}>
                                                x{audioStore.playbackRate}
                                          </Button>,
                                          <div className={ clsx({ [classes.audioTrackInfo]:!fullscreen
                                              ,[classes.audioTrackInfoFullScreen]: fullscreen } ) }>
                                              {mediaImageURL && <ListItemAvatar className={
                                                  clsx({ [classes.listItem]:!fullscreen
                                                      ,[classes.listItemFullScreen]: fullscreen } )}>
                                                  <Avatar variant="rounded"
                                                          className={
                                                              clsx({ [classes.largePict]:!fullscreen
                                                                  ,[classes.largePictFullScreen]: fullscreen } ) }
                                                          alt={` `}
                                                          src={`${mediaImageURL}`}
                                                  />
                                                  { fullscreen && audioStore.mediaID !==null &&
                                                      <>
                                                      {modifyingFavorite
                                                          ? <CircularProgress size={40} className={classes.favoriteButton} />
                                                          : <IconButton
                                                              onClick={handleToggleFavorite}
                                                              className={classes.favoriteButton}
                                                          >
                                                              { localStore.isFavorite? <Favorite/> :<FavoriteBorder/>}
                                                          </IconButton>
                                                      }
                                                      </>}
                                              </ListItemAvatar>}
                                              <ListItemText classes={{ primary: classes.audioTitle, secondary: classes.audioSpeaker }}
                                                            primary={mediaTitle} secondary={mediaSpeakers}

                                              />
                                              {fullscreen &&  currentUser?.isMember && <Button style={{margin:10}} size="small" variant="contained"
                                              color="primary" onClick={openSendOut}
                                              ><ShareIcon/> Create Sendout </Button>}
                                          </div>,
                                          ...(fullscreen?[RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION ]: [RHAP_UI.PROGRESS_BAR ]),
                                          RHAP_UI.MAIN_CONTROLS
                                      ]}
                                      customIcons={{
                                          rewind: <Rewind15 style={{ fontSize: "40px" }} />,
                                          forward: <Forward15 style={{ fontSize: "40px" }} />,
                                          play: <Play style={{ fontSize: "38px" }} />,
                                          pause: <Pause style={{ fontSize: "38px" }} />,
                                      }}
                                      ref={changeRef} />

    return fullscreen ?<Dialog open={fullscreen} maxWidth={"sm"} fullWidth onClose={toggleFullscreen}>
        <div className={clsx(classes.expandedPlayerContainer,
           "rhap_fullscreen"
        )}>
            {player}
        </div>
    </Dialog>: (
        <div className={clsx(classes.playerContainer,
            isStarfish && classes.moveUp,     "rhap_small"
        )}>
            {player}
        </div>
    );
};

export default observer(AudioPlayer);