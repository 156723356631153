import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Paper,
    Grid,
    IconButton,
    Divider,
    InputBase,
    TextField,
    Box,
    FormControl,
    FormLabel,
    RadioGroup, FormControlLabel, Radio, DialogActions, CircularProgress
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PeopleIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/PhoneIphone";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Alert from '@material-ui/lab/Alert';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useStores } from "../hooks/use-stores";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/CheckCircle";
import {useHistory, useLocation} from "react-router";
import {Link} from "react-router-dom";
import greenButtonTheme from "../themes/greenButton";
import MaskedMaterialTextField from "../components/inputs/MaskedMaterialTextField";
import NumberFormatCustom from "../components/inputs/NumberFormatCustom";


const useStyles = makeStyles((theme) => ({
    productImage: {
        height: "100%",
        width: "100%",
        borderRadius: "5%",
        transition: "all .3s",
        marginBottom: '12px'
    },
    root: {
        background: '#000 !important',
        color: "#575962",
        height: "100%",
        width: "100%",
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed"
    },
    paper: {
        display:"flex",
        flexDirection:  "column",
        margin: 2,
        width: 390,
        maxWidth: "98%",
        minHeight:380,
    },
    content: (props)=>({
        padding: theme.spacing(4),
        paddingTop: 0,
        flex:1,
        display: "flex",
        flexDirection: "column",
        gap: 15,
        alignItems: props.method === "allDone" ? "center" : "initial",
        justifyContent: props.method === "allDone" ? "center" : "initial",
    }),
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#575962"
    },
    actionSection: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    troubles: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        whiteSpace: "nowrap",
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    troublesLink: {
        paddingLeft: theme.spacing(1),
        textDecoration: "none"
    },
    divider: {
        height: 28,
        margin: 4,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    inputPaper:{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    }
}));



export default observer(function Recovery({ theme }) {

    const history = useHistory();
    const { authStore, commonStore, recoveryStore } = useStores();

    const [method, setMethod] = useState(null);
    const [channel, setChannel] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [phone, setPhone] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [validationError, setValidationError] = useState("");
    const [verificationCode, setVerificationCode] = useState("");

    const {passwordResetToken, sendingCode, verifyingCode,settingPassword, codeVerified, recoveryComplete, verificationTarget, error } = recoveryStore
    const classes = useStyles({method});

    useEffect(() => {
        recoveryStore.reset();
    }, []);

    const handleSelectMethod = method =>  (e) => {
        if (!method){
            setChannel(null);
            setEmail("");
            setValidationError("");
            setVerificationCode("");
            setPhone("");
            setAccountNo("");
            recoveryStore.reset();
        }
        setMethod(method);
    };

    const handleChangeChannel = (event) => {
        setChannel(event.target.value);
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    }


    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleChangePasswordConfirmation = (event) => {

        setPasswordConfirmation(event.target.value)
    }


    const handleChangeCode = (event) => {
        //replace non digits:
        const value = event.target.value.replace(/\D/g,'');
        setVerificationCode(value);
    }



    const handleKey = (e) => {
        if (e.key === 'Enter') {
            handleAction();
        }
    };

    const handleVerifyCode = async () => {
        if ( passwordResetToken && method === "verifyCode" ) {
            if ( verificationCode.length === 6 )
                void recoveryStore.verifyCode(verificationCode)
            else
                 setValidationError("Please enter a valid 6-digit code.")
        }
    }

    const handleSetPassword = async () => {
        if ( passwordResetToken && method === "setPassword" ){
            if ( password !== passwordConfirmation )
                return setValidationError("Passwords do not match.")
            else if (  password.length < 8 )
                return setValidationError("Password must be at least 8 characters.")
            else
                void recoveryStore.setPassword(password, passwordConfirmation)

        }

    }


    useEffect(() => {
            if (passwordResetToken) {
                setMethod("verifyCode")
            } },
        [passwordResetToken]);


    useEffect(() => {
        setValidationError(null)},
        [method]);


    useEffect(() => {
            if (codeVerified) {
                setMethod("setPassword")
            } },
        [codeVerified]);

    useEffect(() => {
            if (recoveryComplete) {
                setMethod("allDone")
            } },
        [recoveryComplete]);

    const handleAction = async () => {
        if (method === "email") {
            void recoveryStore.sendEmail(email)
        }
        if (method === "lifeID") {
            void recoveryStore.sendAccountNumber(accountNo, channel)
        }
        if (method === "phone") {
            void recoveryStore.sendSMS(phone)
        }
        if(method === "verifyCode") {
            void handleVerifyCode();

        }
        if(method === "setPassword") {
            void handleSetPassword();
        }
    }

    const location = useLocation()
    let redirect = new URLSearchParams(location.search).get("redirect")
    const SignInLink = "/signin" + (redirect ? "?redirect=" + redirect : "");

    const handleBackToSignIn = async () => {
        setMethod(null);
        recoveryStore.reset();
        history.push(SignInLink)

    }

    const component = () => (<div open={true} className={classes.root}  >
        <Grid container justifyContent={"center"} alignItems={'center'}>
            {
                commonStore.onStarfishOrRoyalStarfish
                    ?
                    <>
                        <Grid item xs={12} md={12} container justifyContent={"center"} alignItems={'center'}>
                            <Grid item xs={6} sm={4} md={2} container justifyContent={"center"} alignItems={'center'}>
                                <img src={`/starfish_full.png`} alt="Product Banner" className={classes.productImage} />
                            </Grid>
                        </Grid>
                    </>
                    : ''
            }
            <Grid item xs={12} md={12} container justifyContent={"center"} alignItems={'center'}>
                <Paper className={classes.paper}>
                    <DialogTitle className={classes.title}>
                        {method && method!=="allDone" && <IconButton onClick={handleSelectMethod(null)}><KeyboardBackspace/></IconButton>}
                        { method!=="allDone" &&  ( passwordResetToken
                            ?  method === "setPassword" ? "Password Reset": "Verification Code"
                            : "Forgot Password")}</DialogTitle>
                    <DialogContent className={classes.content}>
                        {(error || validationError) && <Alert severity="error">{error|| validationError}</Alert>}
                        {passwordResetToken && method==="verifyCode" && <Typography  style={{marginBottom:25}}>
                            Unique one-time code was sent to  <strong>{verificationTarget}</strong>
                        </Typography>}
                        {!method
                            && <>
                                <Typography>How would you like to reset your password?</Typography>
                                <Button  onClick={handleSelectMethod("email")} fullWidth style={{marginBottom:5, marginTop:5}} variant={"contained"} size={"large"} color={"primary"} startIcon={<EmailIcon/>}>Using Your Email</Button>
                                <Button onClick={handleSelectMethod("lifeID")} fullWidth style={{marginBottom:5, marginTop:5}} variant={"contained"} size={"large"} color={"primary"} startIcon={<PeopleIcon/>}>Using Your Account#</Button>
                                <Button onClick={handleSelectMethod("phone")} fullWidth style={{marginBottom:5, marginTop:5}} variant={"contained"} size={"large"} color={"primary"} startIcon={<PhoneIcon/>}>Using Your Phone Number</Button>

                                <div className={classes.troubles} >
                                    <Typography className={classes.troublesLink}
                                                variant={"body2"}
                                                component={Link}
                                                to={SignInLink}
                                                color={"primary"}>Back to Sign In
                                    </Typography>
                                </div>
                            </>}

                        {method === "email"
                            && <>
                                <TextField autoFocus
                                           value={email}
                                           onChange={handleChangeEmail}
                                           onKeyDown={handleKey}
                                           name={"email"}
                                           variant={"outlined"}  InputProps={{ startAdornment:<>
                                                                                            <EmailIcon />
                                                                                            <Divider className={classes.divider} orientation="vertical" />
                                                                                            </>}}
                                           fullWidth placeholder={"Your email"} type={"email"} required />
                            </>}
                        {method === "lifeID"
                            && <>
                                <TextField autoFocus
                                           inputProps={ { maxLength:8}}
                                           onKeyDown={handleKey}
                                           id={"txtLifeNumber"}
                                           name={"txtLifeNumber"}
                                           value={accountNo}
                                             onChange={e => setAccountNo(e.target.value)}
                                           variant={"outlined"}  InputProps={{ startAdornment:<>
                                        <PeopleIcon />
                                        <Divider className={classes.divider} orientation="vertical" />
                                    </>}} fullWidth placeholder={"Your Account#"} required />

                                <Typography>We will send a unique one-time code to reset your password. Send this code to:</Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup  value={channel} onChange={handleChangeChannel}>
                                        <FormControlLabel value="phonenumber" control={<Radio />} label="Phone number" />
                                        <FormControlLabel value="email" control={<Radio />} label="Email address" />
                                    </RadioGroup>
                                </FormControl>
                            </>}
                        {method === "phone"
                            && <>
                                <TextField autoFocus
                                           onChange={handleChangePhone}
                                           onKeyDown={handleKey}
                                           value={phone}
                                           inputProps={{ mask: "(###) ###-####"}}
                                           variant={"outlined"}  InputProps={{ inputComponent: NumberFormatCustom,
                                           startAdornment:<><PhoneIcon /><Divider className={classes.divider} orientation="vertical" /></>}}
                                           fullWidth placeholder={"Your phone"} required />
                            </>}

                        {method === "verifyCode"
                            && <>
                                <TextField autoFocus
                                           inputProps={ { maxLength:6, style: {
                                                   fontSize: "2rem",
                                                   letterSpacing: "1rem",
                                                   padding: "0",
                                                   paddingLeft:"1rem",
                                                   height: "1.8em"}}}
                                           placeholder={'000000'}
                                           onChange={handleChangeCode}
                                           onKeyDown={handleKey}
                                           value={verificationCode}
                                           variant={"outlined"} fullWidth />
                            </>}

                        {method === "setPassword"
                            && <>
                                <TextField autoFocus
                                           type={"password"}
                                           label={"Choose Your New Password"}
                                           placeholder={'Password'}
                                           onChange={handleChangePassword}
                                           onKeyDown={handleKey}
                                           value={password}fullWidth />

                                <TextField
                                           type={"password"}
                                           label={"Re-enter Your New Password"}
                                           placeholder={'Password Confirmation'}
                                           onChange={handleChangePasswordConfirmation}
                                           onKeyDown={handleKey}
                                           value={passwordConfirmation} fullWidth />
                            </>}

                        {method === "allDone"
                            && <>
                                <ThemeProvider theme={greenButtonTheme}>
                                    <DoneIcon fontSize={"large"} color={"secondary"}/>
                                </ThemeProvider>
                               <Typography variant={"h6"}>Password reset successfully</Typography>

                            </>}


                    </DialogContent>
                    <DialogActions>
                        {(method && !passwordResetToken) &&  <Button  disabled={sendingCode}
                                             startIcon={sendingCode && <CircularProgress size={26} />}
                                             variant={"contained"} color={"primary"} onClick={handleAction}>Send Code</Button>}

                        {(method === "verifyCode" && passwordResetToken) &&  <Button  disabled={verifyingCode}
                                             startIcon={verifyingCode && <CircularProgress size={26} />}
                                             variant={"contained"} color={"primary"} onClick={handleVerifyCode}>Verify Code</Button>}

                        {(method === "setPassword" && passwordResetToken) &&  <Button  disabled={settingPassword}
                                             startIcon={settingPassword && <CircularProgress size={26} />}
                                             variant={"contained"} color={"primary"} onClick={handleSetPassword}>Set Password</Button>}

                        {(method === "allDone" ) &&  <Button
                                             startIcon={sendingCode && <CircularProgress size={26} />}
                                             fullWidth
                                             variant={"contained"} color={"primary"} onClick={handleBackToSignIn}>Sign In</Button>}
                    </DialogActions>
                </Paper>
            </Grid>
        </Grid>
    </div >);
    if (theme) {
        return <ThemeProvider theme={theme}>{component()}</ThemeProvider>;
    }
    else {
        component();
    }

});