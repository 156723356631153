import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, CircularProgress, Container, Divider, FormControl, Input, makeStyles, MenuItem, Select } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import LanguageIcon from '@material-ui/icons/Language';
import services from '../../services';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {useStores} from "../../hooks/use-stores";
import {observer} from "mobx-react";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        minHeight: '100vh',
        overflow: 'auto',
        justifyContent: "center",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: '20%',
        maxWidth: '100%',
        padding: 0,
        '& > *': {
            fontFamily: "'Barlow', sans-serif"
        },
    },
    progressContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
    },
    information: {
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, .3)',
        marginTop: theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            maxWidth: "600px",
        }
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20
    },
    divider: {
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    mainContent: {
        maxWidth: '600px !important',
        backgroundColor: '#000000e6',
        color: '#f8f9fa !important',
        padding: '1.25rem'
    },
    langIcon: {
        verticalAlign: 'middle',
        paddingTop: '4px',
        paddingRight: '1px',
        color: 'white'
    },
    mainBody: {
        gap: '1rem',
        fontSize: '1.2rem'
    },
    title: {
        fontFamily: `'Bebas Neue', cursive`,
        fontSize: '4rem',
        marginBottom: '.25rem',
        marginTop: '0px'
    },
    formLabel: {
        color: '#f8f9fa !important',
        textAlign: 'left',
        marginBottom: '-8px',
        fontSize: '1rem'
    },
    formInput: {
        fontSize: '1rem',
        height: 40,
        background: 'white',
        color: 'black',
        padding: '5px',
        borderRadius: '10px'
    },
    signInButton: {
        color: 'white',
        width: '100%',
        backgroundColor: '#007BFF',
        padding: '10px',
        borderRadius: '25px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#007BFF',
        }
    },
    icon: {
        fill: 'white',
    },

}));

const ContainerWithBackground = (props) => {
    return (
        <Container
            className={props.className}
            style={{ backgroundImage: `url(${props.backgroundImage})` }}
        >{props.children}
        </Container>
    );
};

const ExResponse = {
    bg_image: 'https://resources.lifeinfoapp.com/public/conventionprogram/mol_2022_mainbg1.jpg',
    loginbutton_active: true,
    en: {
        live_convention_dates: 'October 13-15th, 2023',
        live_convention_location_html: 'Broadbent Arena at the Kentucky Expo Center - Louisville, KY 40209<BR>Utah Valley Convention Center - Provo, UT 84601 EY Centre - Ottawa, ON K1V 2N6',
        title: 'LIFE UNIVERSITY',
        virtual_event_dates: 'Event Dates are Coming Soon!',
    },
    fr: {
        live_convention_dates: 'October 13-15th, 2023',
        live_convention_location_html: 'Broadbent Arena at the Kentucky Expo Center - Louisville, KY 40209<BR>Utah Valley Convention Center - Provo, UT 84601 EY Centre - Ottawa, ON K1V 2N6',
        title: 'LIFE UNIVERSITY',
        virtual_event_dates: 'Les dates des événements arrivent bientôt !'
    }
}

const ConventionProgramLogin = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const {authStore, commonStore} = useStores();

    const {inProgress, currentUser } = authStore
    const { appLoaded } = commonStore

    const [error, setError] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect( ()=>{
        if ( currentUser )
            history.push("/conventionprogram/main")
    }, [currentUser])

    const load = async () => {
        setLoading(true);

        try {
            const data = await services.ConventionProgramServicePublic.fetchConventionProgramLoginInfo();
            setData(data);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    const attemptSignin = async () => {
        setLoginError(false);
        await authStore.signIn( username, password, false )
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <ContainerWithBackground className={classes.container} backgroundImage={loading ? undefined : data?.bg_image}>
            {( loading || !appLoaded) ? (
                <Box className={classes.progressContainer}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Card className={classes.information}>
                        {!data &&
                            <Container className={classes.errorContainer} maxWidth="sm">
                                <Alert severity="error">{t('conventionProgramLogin:error.notFound')}</Alert>
                            </Container>
                        }
                        {
                            data && <div>
                                <Card
                                    className={classes.mainContent}
                                >
                                    <div style={{ textAlign: 'right' }}>
                                        <Select
                                            value={selectedLanguage}
                                            onChange={(e) => setSelectedLanguage(e.target.value)}
                                            disableUnderline
                                            inputProps={{
                                                classes: {
                                                    icon: classes.icon,
                                                },
                                                MenuProps: {
                                                    MenuListProps: {
                                                        style: {
                                                            backgroundColor: 'black',
                                                            color: 'white'
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <MenuItem value={'en'}>
                                                <Box display="flex" alignItems="center" style={{ color: 'white' }}>
                                                    <span className={classes.langIcon}>
                                                        <LanguageIcon fontSize='small' />
                                                    </span>
                                                    <span>{t('conventionProgramLogin:English')}</span>
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value={'fr'}>
                                                <Box display="flex" alignItems="center" style={{ color: 'white' }}>
                                                    <span className={classes.langIcon}>
                                                        <LanguageIcon fontSize='small' />
                                                    </span>
                                                    <span>{t('conventionProgramLogin:French')}</span>
                                                </Box>
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <Box
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Box display="flex" flexDirection="column" className={classes.mainBody}>
                                            <h3
                                                className={classes.title}
                                            >
                                                {data[selectedLanguage].title}
                                            </h3>
                                            <Box display="flex" flexDirection="column">
                                                <strong>{t('conventionProgramLogin:liveConvention')}</strong>
                                                {data[selectedLanguage].live_convention_dates}
                                            </Box>
                                            <Box display="flex" flexDirection="column" dangerouslySetInnerHTML={{ __html: data[selectedLanguage].live_convention_location_html }} />
                                            <Box display="flex" flexDirection="column">
                                                <strong>{t('conventionProgramLogin:virtualEvent')}</strong>
                                                {data[selectedLanguage].virtual_event_dates}
                                            </Box>
                                            <div>
                                                {
                                                    data.loginbutton_active &&
                                                    <div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Divider className={classes.divider} />
                                                            <div style={{ marginTop: '.5rem' }}>
                                                                {loginError && <Alert severity="error">{t('conventionProgramLogin:invalidPass')}</Alert>}
                                                            </div>
                                                            <FormControl style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                                                                <label className={classes.formLabel} htmlFor="username">{t('conventionProgramLogin:username')}</label>
                                                                <Input disableUnderline className={classes.formInput} id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                                            </FormControl>
                                                            <FormControl style={{ width: '100%', marginBottom: '1rem' }}>
                                                                <label className={classes.formLabel} htmlFor="password">{t('conventionProgramLogin:password')}</label>
                                                                <Input type="password" disableUnderline className={classes.formInput} id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                            </FormControl>
                                                            <Button loading={inProgress} onClick={attemptSignin} className={classes.signInButton}>
                                                                {t('conventionProgramLogin:signIn')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Box>
                                    </Box>
                                </Card>
                            </div>
                        }
                    </Card>
                </>
            )}
        </ContainerWithBackground >
    );
};

export default observer(ConventionProgramLogin);
