import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useStores } from '../../../hooks/use-stores';
import AudioRecorder from '../Recorders/AudioRecorder';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        maxWidth: 500,
        marginBottom: theme.spacing(2),
        padding: 0
    },
    button: {
        color: 'white'
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    heading: {
        textAlign: 'center',
        fontWeight: 'bold'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        minHeight: 200
    }
}));

const RecordAudio = ({ setIsRecording }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { authStore, lifelineStore } = useStores();

    const onFinishedRecordingAudio = (audioFile) => {
        lifelineStore.audioFile = audioFile;
    }

    return (
        <Container className={classes.wrapper}>
            <Grid xs={12} container alignItems="center" justifyContent="center" direction="column">
                <Grid item xs={12}>
                    <Typography variant="body1">{t('lifeline:recordAudio.audioTag')}</Typography>
                    <br />
                    <AudioRecorder onRecord={setIsRecording} maxRecordingTimeInSeconds={lifelineStore.audioDuration(authStore.currentUser.userID)} onFinished={onFinishedRecordingAudio} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default RecordAudio;