import * as React from 'react'
import {observer} from 'mobx-react-lite'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Suggestion from "./Suggestion";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


export default observer(({
                             field,
                             className,
                             InputLabelProps,
                             margin,
                             options,
                             labelAccessor,
                             valueAccessor,
                             fullWidth,
                             suggestion,
                             suggestionPrefix,
                             onChange,
                             ...rest
                         }) => {
    const applySuggestion = ()=>{
        field.onChange(suggestion)
    }
    valueAccessor = valueAccessor || ((x)=>x.shortCode)
    labelAccessor = labelAccessor || ((x)=>x.name)

    const handleOnChnage = (e)=>{
        field.onChange(e)
        if ( onChange )
            onChange(e.target.value)
    }

    return (
        <FormControl fullWidth={fullWidth} margin={margin} className={className}>
            <InputLabel error ={!suggestion && field.error} {...InputLabelProps} >{field.label}</InputLabel>


            <Select
                fullWidth
                {...field.bind()}
                onChange={handleOnChnage}
                {...rest}>
                {options.map(x=>{
                    return <MenuItem key={valueAccessor(x)} value={valueAccessor(x)}>{labelAccessor(x)}</MenuItem>
                })}
            </Select>
            <FormHelperText  error ={!suggestion && field.error} >
                {suggestion
                    ? ( <Suggestion suggestion={suggestion} suggestionPrefix={suggestionPrefix} onApply={applySuggestion} /> )
                    : (field.error)  }
            </FormHelperText>
        </FormControl>
    )
})