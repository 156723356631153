import { makeAutoObservable, reaction, runInAction } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import shippingAddressForm from "../forms/shippingAddress";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList, generateLoadList2,
    generateUpdateEntity
} from "../utils/mobx";
import { getCookie, deleteCookie, setCookie } from "../utils/cookieUtil";

class RecoveryStore {

    sendingCode= false;
    verifyingCode= false;
    settingPassword= false;
    passwordResetToken = null;
    verificationTarget= null;
    codeVerified= null;
    recoveryComplete= null;

    constructor() {
        makeAutoObservable(this);

    }


    reset(){
        this.passwordResetToken = null;
        this.recoveryComplete = null;
        this.codeVerified = null;
        this.verificationTarget = null;
        this.error = null;
    }

    verifyCode = generateCreteEntity("verifyCode", this, "verifyingCode",async (code)=>{
        const result = await services.Auth.verifyCode(code, { headers: { "Authorization": `Bearer ${this.passwordResetToken}` } })
        return result && result.message
    }, "codeVerified" );

    setPassword = generateCreteEntity("setPassword", this, "settingPassword",async (password)=>{
        const result = await services.Auth.resetPassword(password, { headers: { "Authorization": `Bearer ${this.passwordResetToken}` } })
        return result && result.message
    }, "recoveryComplete" );


    sendEmail = generateCreteEntity("sendEmail", this, "sendingCode",async (email)=>{
        this.verificationTarget = email;
        const result =  await services.Auth.sendVerificationEmail(email)
        return result.password_reset_token
    }, "passwordResetToken" );

    sendSMS = generateCreteEntity("sendSMS", this, "sendingCode",async (phone)=>{
        this.verificationTarget = phone;
        const result = await services.Auth.sendVerificationText(phone)
        return result.password_reset_token
    }, "passwordResetToken" );

    sendAccountNumber = generateCreteEntity("sendAccount", this, "sendingCode",async (accountId, via)=>{
        const contacts =  await services.Auth.contactInfo(accountId)
        runInAction(() => {
            this.verificationTarget = contacts[via];
        })

        if ( via === "email" ) {
            const result = await   services.Auth.sendVerificationEmail(null, accountId)
            return result.password_reset_token
        }
        else {
            const result = await  services.Auth.sendVerificationText(null, accountId)
            return result.password_reset_token
        }

    }, "passwordResetToken" );

    setError(error, type = "") {
        this.error = extractErrorMessage(error);
    }

}

export default RecoveryStore;