import React from "react";
import {observer} from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, DialogActions, DialogContent, Grid} from "@material-ui/core";
import {useLocalObservable} from "mobx-react-lite";
import FormDef from "../../forms/enrollCustomerShop";
import MaterialSelect from "../../components/inputs/MaterialSelect";
import MaterialTextField from "../../components/inputs/MaterialTextField";
import services from "../../services";
import {useStores} from "../../hooks/use-stores";
import {extractErrorMessage} from "../../utils/helpers";
import {useHistory} from "react-router";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setCookie} from "../../utils/cookieUtil";


function CreateShopAccount({open, onClose, shopOptions}) {

    const {authStore, commonStore, shopStore} = useStores()
    const history = useHistory();

    console.log(commonStore.language )
    const store = useLocalObservable(() => ({
        formSubmitting: false,
        error: null,
        _form: new FormDef(
            { lang: commonStore.language, country: authStore.guestCountry },
            {
                onSuccess: async function onSuccess ( form ) {
                    try {
                        const result = await  services.Enrollment.enrollSC( { ...form.values(), cartGuid: shopStore.userCartGuid });

                        if (result && result.access_token ) {
                            setCookie("cart_guid", shopStore.userCartGuid);//preserve cart guid
                            authStore.setToken( result.access_token, true )
                            await commonStore.setLanguage( form.$("lang").value )
                            history.push(shopOptions.cartUrl)
                            handleClose();
                        }
                    } catch (e) {
                        store.setError( extractErrorMessage(e) )
                    }
                    finally {
                        store.formSubmitting = false;
                    }
                },
                onError: function (form) {
                    store.formSubmitting = false;
                }

            }
        ),
        setError(error) {
            this.error = error;
        },
        submit(e) {
            this.formSubmitting = true;
            this._form.onSubmit(e);
        },
        clear(){
            this._form.clear();
            this._form.reset();
            this._form.showErrors(false);
        }
    }));

    const form = store._form;


    const handleClose = () => {
        store.clear();
        onClose();
    }

  return (
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
          <DialogTitle>To Proceed, Please Create a Free Account or if you have an account
              <Button href={authStore.loginUrl} style={{marginLeft: 16}}
                        color="primary"
                        variant={"contained"} >Login</Button> </DialogTitle>
          <DialogContent>
              <Grid container spacing={3} justifyContent={"center"} alignItems={"center"}>
                  <Grid item xs={12} lg={9}>
                      <MaterialTextField
                          fullWidth
                          margin="normal"
                          field={form.$("firstName")}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                      <MaterialTextField
                          fullWidth
                          margin="normal"
                          field={form.$("lastName")}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                      <MaterialTextField
                          fullWidth
                          margin="normal"
                          field={form.$("email")}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                      <MaterialTextField
                          fullWidth
                          margin="normal"
                          field={form.$("password")}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                      <MaterialTextField
                          fullWidth
                          margin="normal"
                          translationPrefix="enrollment"
                          field={form.$("confirmPassword")}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                     <MaterialSelect
                          fullWidth
                          label="Language"
                          margin="normal"
                          field={form.$("country")}
                          options={[
                          {value:"US",label:"United States"},
                          {value:"CA",label:"Canada"} ]} />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                      <MaterialSelect
                          fullWidth
                          field={form.$("lang")}
                          options={[
                              {value:"en",label:"English"},
                              {value:"fr",label:"Français"} ]}
                      />
                  </Grid>

              </Grid>

          </DialogContent>
          <DialogActions>
               {store.error && <Alert severity={"error"} variant={"outlined"}>{store.error}</Alert>}
                <Button onClick={handleClose} color="secondary" variant={"contained"}>
                    Cancel
                </Button>
                <Button onClick={store.submit} color="primary"
                        variant={"contained"}
                        disabled={store.formSubmitting}
                        startIcon={ store.formSubmitting?<CircularProgress/>:null }
                >
                    Create
                </Button>
          </DialogActions>
      </Dialog>
  );
}

export default observer(CreateShopAccount);