import React from 'react';
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import CreateHome from './CreateHome';
import ReviewVideo from './ReviewVideo';
import FinalizeVideo from './FinalizeVideo';
import ChoosePath from './ChoosePath';
import Completed from './Completed';
import RecordAudio from './RecordAudio';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

const CreateVideoFlow = ({lifelineMatch}) => {
    const classes = useStyles();

    return (
        <Container className={classes.wrapper} maxWidth="md">
            <Switch>
                <Route
                    path={`${lifelineMatch.url}/reviewVideo`}
                    render={() => {
                        return <ReviewVideo lifelineMatch={lifelineMatch} />;
                    }}
                />
                <Route
                    path={`${lifelineMatch.url}/finalizeVideo`}
                    render={() => {
                        return <FinalizeVideo lifelineMatch={lifelineMatch} />;
                    }}
                />
                <Route
                    path={`${lifelineMatch.url}/createVideo`}
                    render={() => {
                        return <CreateHome lifelineMatch={lifelineMatch} />;
                    }}
                />
                <Route
                    path={`${lifelineMatch.url}/completed`}
                    render={() => {
                        return <Completed lifelineMatch={lifelineMatch} />;
                    }}
                />
                <Route
                    path={'*'}
                    render={() => {
                        return <ChoosePath lifelineMatch={lifelineMatch} />;
                    }}
                />   
            </Switch>
        </Container>
    )

};

export default CreateVideoFlow;