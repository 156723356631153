import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Container } from '@material-ui/core';
import menu from '../menu.json';
import LinksMenu from "../LinksMenu";
import GenericSharePage from "./GenericSharePage";
import { useRouteMatch } from "react-router";
import { joinPath } from "../../../utils/helpers";

const ShareHome = ({ parentMatch }) => {
    const MainLinks = useMemo(() => {
        const items = menu.find(x => x.tag === "sharestarfish").items
        return items
    }, [menu]);

    return (<LinksMenu
        items={MainLinks}
        showBack={false}
        parentMatch={parentMatch}
        collapsePath
    />
    )
}

const Share = ({ parentMatch }) => {
    const match = useRouteMatch()

    console.log(joinPath(match.path, "/sharelinks/:pageId?"))
    return (
        <Container>
            <Switch>
                <Route
                    path={joinPath(match.path, "/sharelinks/:pageId?")}
                    render={() => <GenericSharePage />}
                />
                    {/* The URL modification in the Links Menu expects the route to 
                    be defined using /starfish or /royalstarfish, 
                    not /starfish/x or /royalstarfish/x */}
                <Route
                    render={() => <ShareHome parentMatch={parentMatch} />}
                />
            </Switch>
        </Container>
    );
}

export default observer(Share);