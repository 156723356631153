import React, { useEffect, useState } from "react";
import {observer} from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useStores} from "../../hooks/use-stores";
import PageTitle from "../../components/PageTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Card, CardActions, CardHeader} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {joinPath} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import {actions, card, statusStyles} from "../../components/styles";
import clsx from "clsx";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import ItemDialog from "./ItemDialog";


const useStyles = makeStyles((theme) => ({

    shopWrapper: {
        height: "100%",
        minHeight: "100vh",
        background: "black",
        color: "white"
    },
    description:{
        marginLeft:20
    },
    ...card(theme),
    ...actions(theme),
    ...statusStyles(theme)
}));



export default observer(function SubscriptionsList({ parentMatch }) {
    const classes = useStyles();
    const {t} = useTranslation("subscription");
    const { subscriptionStore, shopStore } = useStores();
    const [learnMoreFor, setLearnMoreFor] = useState();
    const [subscribeTo, setSubscribeTo] = useState();
    //const { mySubscriptionsLoading } = subscriptionStore;
    const { availableSubscriptions, mySubscriptions,subscriptionInfoLoading } = shopStore;

    useEffect(() => {
        shopStore.loadSubscriptionInfo();
    }, []);

    const learnMore = (sub)=>{
        setLearnMoreFor(sub)
    }
    const hideLearnMore = ()=>{
        setLearnMoreFor(null)
    }

    const subscribe = (sub)=>{
        setSubscribeTo(sub)
    }
    const hideSubscribe = ()=>{
        setSubscribeTo(null)
    }

    const handleCheckout = () => {
        (async ()=>{
            let guid = await subscriptionStore.getCheckoutGuid(subscribeTo.productID);
            if ( guid ) {
                let url = `${window.location.origin}/checkout?is_subscription=true&cart_guid=${guid}`;
                window.location.replace(url);
            }
        })()
    }


    return <>
        {subscriptionInfoLoading
        ? <CircularProgress />
        : <>
            <PageTitle>Subscriptions</PageTitle>
            <Grid container spacing={4}>
            {(mySubscriptions.length>0)
                && <Grid container item direction={"column"}>
                <Grid  item><Typography variant="h5">My Subscriptions</Typography></Grid>
                <Grid  item container spacing={2}>
                    {mySubscriptions.map(s=>{
                        return <Grid container item xs={12} sm={6} >
                                <Card  className={classes.card}>
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" variant={"rounded"} className={classes.avatar} src={s.imageURL}/>
                                        }
                                        title={s.productName}
                                        subheader={<>
                                            <div className={clsx(classes.status
                                                , {
                                                    [classes.statusActive]: (s.previousStatus==="Active"),
                                                    [classes.statusDeclined]: (s.previousStatus!=="Active"),
                                                } )}>
                                                {s.previousStatus}
                                            </div>
                                        </>}
                                    />
                                    <CardContent>
                                        <div>{t("price")}: {s.productPrice}</div>
                                        <div>{t("last_payment_received")}: {moment (s.prevOrderDate).format("LL")}</div>
                                    </CardContent>
                                    <CardActions className={classes.actions}>
                                        <Button variant={"contained"}
                                                color={"primary"}
                                                href={joinPath(parentMatch.url, s.categoryID)}>Manage</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                    })}

                </Grid>

            </Grid>}

            {(availableSubscriptions.length>0)
                && <Grid container item direction={"column"}>
                <Grid  item><Typography variant="h5">Available Subscriptions</Typography></Grid>
                <Grid  item container spacing={2}>
                    {availableSubscriptions.map(s=>{
                        return <Grid container item xs={12} sm={6} md={4} >
                                <Card className={classes.card}>
                                    <CardHeader
                                        avatar={
                                            <Avatar variant={"rounded"} aria-label="recipe" variant={"rounded"} className={classes.avatar} src={s.imageURL}/>

                                        }
                                        title={s.productName}
                                        subheader={ <Button size={"small"} variant={"text"} onClick={e=>{ learnMore(s) }} >Learn More</Button> }
                                    />
                                    <CardActions className={classes.actions}>
                                        <Button variant={"contained"} color={"primary"} onClick={e=>{ subscribe(s) }}>Subscribe</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                    })}

                </Grid>
            </Grid>}
            </Grid>

        </>}
        {learnMoreFor && <ItemDialog onHide={hideLearnMore} item={learnMoreFor} />}
        {subscribeTo && <ItemDialog onHide={hideSubscribe} item={subscribeTo}
                                    action={ handleCheckout }
                                    actionTitle={t("proceed_to_checkout")} />}
    </>
    })