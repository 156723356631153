import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStores} from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import OwlCarousel from "react-owl-carousel2";
import 'react-owl-carousel2/lib/styles.css';
import '../css/owl.scss';
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root:{
        color:"white",
        position: "relative",
        background:"rgba(10,10,10, 0.8)",
        padding:50,
        border: "1px solid rgba(190,190,190, 0.3)",
        borderRadius: 4,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column"
    },
    navContainer:{
        position: "absolute",
        bottom: 0,
        width: "100%",
        display:"flex",
        justifyContent:"space-between",
        padding:10
    },

    cardBody:{
        height:518,
        paddingTop: 230,
        display:"flex",
        flexDirection:"column",
        alignItems:"center"

    },
    divider:{
        width:200,
        backgroundColor: "rgba(255, 255, 255, 0.92)"
    },
    description:{
        whiteSpace:"initial",
        textAlign:"center",
        padding:10

    },
    playerContainer:{
        width:"100%",
        height:"100%",
        margin:"auto",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position:"relative"
    },
    previewCloseButton:{
        position:"absolute",
        top:50,
        right:50
    },

    player:{
        width:"100%",
        height:480,
        [theme.breakpoints.down('md')]: {
            height: 320
            },
    },
    feature:{
        height:100,
        display:"inline-flex"

    },
    featurePart:{
        width:100,
        padding:10,
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
    },
    featureTitle:{
        fontSize:11,
        paddingBottom:15
    },
    currentPlan:{
        display:"flex",
        justifyContent:"space-between",
        backgroundColor:  "rgba(110,110,110, 0.7)",
        borderRadius:4
    },
    planName:{
         padding: "15px 40px"
    },
    planLabel:{
        padding: "15px 40px",
        textTransform:"uppercase"
    },
    sub:{
        width:"100%",
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        borderRadius:4,
        margin:"10px 20px 5px 20px",
        padding: 20,
        cursor: "pointer"
    },
    subText:{

    },
    cant_upgrade_super_app:{
        margin:20
    }

}));



function ShoppingBossOnboardingModal({handleCloseOnBoarding}){
    const ref = React.useRef("onboard");
    const classes = useStyles();
    const {t} = useTranslation();
    const {onboardStore, commonStore} = useStores();
    const {shoppingBossOnboardingLoading, shoppingBossOnboarding, shoppingBossFeatures, shoppingBossAvailableSubs} = onboardStore;
    const { title, subtitle} = shoppingBossOnboarding;
    const history = useHistory();


    useEffect(()=>{
        onboardStore.loadShoppingBossOnboardingInfo()
    }, []);

    const handleSubscription = (item)=>{
        let tag =   item.tag || (item.title.replace(/\+/g, " plus") ).replace(/\s+/g, "_").toLowerCase()
        history.push(`onboarding/${tag}`)
        handleCloseOnBoarding();
    }


    const options = {
        items: 1,
        nav: false,
        center: true,
        //autoWidth:true,
        rewind: true,
        autoplay: true,
    };


    return <Dialog open={true}  maxWidth={"sm"}  fullWidth onClose={handleCloseOnBoarding}><div className={classes.root}>
        <Typography variant={"h5"}>{title}</Typography>
        <Typography variant={"subtitle1"}>{subtitle}</Typography>
        {shoppingBossOnboardingLoading ?<CircularProgress/>:
            ((shoppingBossFeatures.length>0) && <OwlCarousel ref={ref} options={options} >
                {shoppingBossFeatures.map((feature, i)=>{
                    return <div className={classes.feature} key={"feature-"+i}>
                        {feature.map(x=>{
                            return <div key={"feature-"+i+"-"+x.title} className={classes.featurePart}  style={{color: x.color, backgroundColor: x.bgcolor}}>
                                <Typography variant={"subtitle2"} className={classes.featureTitle}>{x.title}</Typography>
                                <Typography variant={"body2"}>{x.text}</Typography>
                            </div>
                        })}
                    </div>
                })}
            </OwlCarousel>)}
        <Typography variant={"subtitle1"} className={classes.cant_upgrade_super_app}>{t("cant_upgrade_super_app")}</Typography>
        <div className={classes.currentPlan}>
            <span className={classes.planName}>Life Info App</span>
            <span className={classes.planLabel}>Current Plan</span>
        </div>
        {shoppingBossAvailableSubs.map(sub=>{
            return <div className={classes.sub}  style={{color: sub.color, backgroundColor: sub.bgcolor}} onClick={()=>{ handleSubscription( sub )}}>
                <Typography variant={"subtitle1"}>{sub.title}</Typography>
                <div className={classes.subText}>{sub.text}</div>
            </div>
        })}
    </div> </Dialog>
}


export default observer(ShoppingBossOnboardingModal)