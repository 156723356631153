import RestService from "./RestService";



export default class MemberEnrollmentService extends RestService {
    constructor() {
        super('/api/v2/enroll/member');
    }

    getEnrollmentFeeQual() {
        return this.get(`${this.baseUrl}/enrollmentwaiver`);
    }

    cart(lang) {
        return this.post(`/api/v3/enroll/member/cart?productSuggestion=suggested&lang=${lang}`);
    }

    cartTotal(cartID, lang) {
        return this.post(`${this.baseUrl}/cart/totals?lang=${lang}`
            , { cartID, lang });
    }

    loadItems(cartID, lang) {
        return this.get(`${this.baseUrl}/cart/items?cartID=${cartID}&lang=${lang}`);
    }

    additionalItems(country, lang) {
        return this.get(`${this.baseUrl}/subscriptions/available?lang=${lang}`);
    }

    addItemToCart(params) {
        return this.put(`${this.baseUrl}/cart/items?lang=${params.lang}`, params);
    }

    removeItemFromCart(params) {
        return this.del(`${this.baseUrl}/cart/items?lang=${params.lang}`, params);
    }

    create(params) {
        return this.post(this.baseUrl, params);
    }

    checkIfPersonalInfoNumberIsTaken(params) {
        return this.post(`${this.baseUrl}/pid/validate`, params);
    }
}
