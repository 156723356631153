import {makeAutoObservable} from 'mobx'
import services from "../services"
import {flatten, uniqBy} from "lodash";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadList} from "../utils/mobx";

class SalesAchieverAudiosStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    series = []
    ppsSeries = []
    seriesLoading = false
    mediaType = false

    error = null

    setError(error, type = "series") {
        error = extractErrorMessage(error)
        this.error = error
    }

    loadSeries = generateLoadList( "series", this, "seriesLoading"
        , async (id)=>{
            if ( this.libraryStore.library.length ===0 )
                await this.libraryStore.loadLibrary();
            try {

                let pps = await services.Library.details(18981)
                if (pps){
                    this.ppsSeries.replace(pps[0].files)
                }
            }
            catch(e){
            }
            return services.Library.details(17553)
        }, "series", "[0].files")



}

export default SalesAchieverAudiosStore