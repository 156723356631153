import React from "react";
import {Button, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";
import {useHistory} from "react-router-dom";
import {joinPath} from "../../utils/helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    borderRight:{
        borderRight: `1px solid ${theme.palette.text.secondary}`,
        [theme.breakpoints.down("xs")]: {
            borderRight: "none"
        }
    },
    circleImg:{
        width: 106,
        height: 106
    },

    marginBottom:{
        marginBottom: theme.spacing(2)
    },
    italic:{
        fontStyle: "italic"
    }

}));


function Core4({parentMatch}){

    const classes = useStyles();
    const history = useHistory();
    const {authStore, commonStore, videoStore} = useStores();
    const {t} = useTranslation("starfish");
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down("xs"));

    const showVideo  = ()=>{
        videoStore.setMedia( {
            url: commonStore.language === "fr" ? "467397964":"463590147",
            title: commonStore.language === "fr" ? "Êtes-vous Core 4?":"Are You Core 4?",
            vimeo: true } )
    }


    const handleRoyal = ()=>{
        if( authStore.currentUser.isSuperAppPlus ) {
            commonStore.showConfirm( "You are currently subscribed to Royal"
                , "OK"
                , "Already subscribed",  ()=>{}, null );
        }
        else
            history.push( "/starfish/profile/subscriptions" )
    }
    const handleMarketing = ()=>{
        history.push( joinPath(  parentMatch.url, "/members/marketing_systems" ))
    }

    const handleSOT = ()=>{
        if ( authStore.subscriptions.hasSOT )
            commonStore.showConfirm( "You are currently subscribed to Standing Order Ticket"
                , "OK"
                , "Already subscribed",  ()=>{}, null );
        else
             history.push( joinPath(  parentMatch.url, "/members/sot" ))
    }

    const handleConventionTickets = ()=>{
        history.push( joinPath(  parentMatch.url, "/members/events/leadership%20convention" ))
    }

    return <div>
        <section className={clsx( classes.section)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/core4-image1-fr.jpg`
                    : `/img/core4-image1-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={classes.textContainer} style={{width:"100%"}}>
                <Grid container spacing={3}>
                    <Grid container  item xs={12} sm={5}
                          justifyContent={isPhone?"flex-start":"flex-end"}
                          alignItems={"flex-start"}
                          className={clsx(classes.borderRight, { [classes.marginBottom]: isPhone  }) }>
                        <Typography align={isPhone?"left":"right"} className={clsx( classes.headTextNoTopMargin)} variant="h3">{t("core4.what_is_core4_pre")}<span className={classes.linkColor}>{t("core4.what_is_core4")}</span>{t("core4.what_is_core4_post")}</Typography>
                        <Typography align={isPhone?"left":"right"}  className={classes.mainText}  variant="body1">{t("core4.what_is_core4_description")}</Typography>
                        <Button color="primary" variant="contained" onClick={showVideo}>
                            {t("powerplayer.watch_video")}</Button>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                         <Typography align="left" className={classes.mainText}  variant="body1">{t("core4.what_is_core4_description2")}</Typography>
                    </Grid>
                </Grid>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <img
                src={ commonStore.language === "fr"
                    ? `/img/core4-image2-fr.jpg`
                    : `/img/core4-image2-en.jpg` }
                className={classes.middleImage} role="img"/>
        </section>
        <section className={clsx( classes.section)}>
            <div className={clsx( classes.textContainer)}>
                <Typography align="center" className={classes.headText}  variant="h3">{t("core4.how_to_qualify_pre")}<span className={classes.linkColor}>{t("core4.how_to_qualify")}</span></Typography>
            </div>
        </section>

        <section className={clsx( classes.section)}>
            <Grid container spacing={3}>
                <Grid container item xs={12} sm={6} md={3} direction={"column"} alignItems="center">
                    <img src={`/img/circle1.png`} role="img" className={classes.circleImg}/>
                    <Typography align="center" className={classes.headText} variant="h5">{t("core4.circle1_head_pre")}<span className={classes.linkColor}>{t("core4.circle1_head")}</span>{t("core4.circle1_head_post")}</Typography>
                    <Typography style={{flex:1}} align="center" className={classes.mainText} variant="body1">{t("core4.circle1_text")}</Typography>
                    <Button style={{marginBottom:40}} color="primary" variant="contained" onClick={handleRoyal}>
                        {t("core4.subscribe")}</Button>
                </Grid>
                <Grid container item xs={12} sm={6} md={3} direction={"column"} alignItems="center">
                    <img src={`/img/circle2.png`} role="img" className={classes.circleImg}/>
                    <Typography align="center" className={classes.headText} variant="h5">{t("core4.circle2_head_pre")}<span className={classes.linkColor}>{t("core4.circle2_head")}</span>{t("core4.circle2_head_post")}</Typography>
                    <Typography style={{flex:1}} align="center" className={classes.mainText} variant="body1">{t("core4.circle2_text")}</Typography>
                    <Button style={{marginBottom:40}} color="primary" variant="contained" onClick={handleMarketing}>
                        {t("core4.subscribe")}</Button>
                </Grid>
                <Grid container item xs={12} sm={6} md={3} direction={"column"} alignItems="center">
                    <img src={`/img/circle3.png`} role="img" className={classes.circleImg}/>
                    <Typography align="center" className={classes.headText} variant="h5">{t("core4.circle3_head_pre")}<span className={classes.linkColor}>{t("core4.circle3_head")}</span>{t("core4.circle3_head_post")}</Typography>
                    <Typography style={{flex:1}} align="center" className={classes.mainText} variant="body1">{t("core4.circle3_text")}</Typography>
                    <Button style={{marginBottom:40}} color="primary" variant="contained" onClick={handleSOT}>
                        {t("core4.subscribe")}</Button>
                </Grid>
                <Grid container item xs={12} sm={6} md={3} direction={"column"} alignItems="center">
                    <img src={`/img/circle4.png`} role="img" className={classes.circleImg}/>
                    <Typography align="center" className={classes.headText} variant="h5">{t("core4.circle4_head_pre")}<span className={classes.linkColor}>{t("core4.circle4_head")}</span>{t("core4.circle4_head_post")}</Typography>
                    <Typography style={{flex:1}} align="center" className={classes.mainText} variant="body1">{t("core4.circle4_text")}</Typography>
                    <Button style={{marginBottom:40}} color="primary" variant="contained" onClick={handleConventionTickets}>
                        {t("core4.purchase_ticket")}</Button>
                </Grid>
                <Grid container item xs={12} >
                    <Typography style={{flex:1}} align="center" className={clsx( classes.mainText, classes.italic )} variant="body1">{t("core4.asterix_text")}</Typography>
                </Grid>
            </Grid>
        </section>






        <section className={clsx( classes.section)}>
            {' '}
        </section>
        <section className={clsx( classes.section)}>
        {' '}
        </section>

    </div>
}

export default Core4