import { makeAutoObservable } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import cardForm, { billingFields, expirationFields } from "../forms/card";
import bankForm, { bankFields, billingFields as bankBillingFields } from "../forms/bank";
import pick from "lodash/pick";
import { generateCreteEntity, generateDeleteEntity, generateLoadList, generateUpdateEntity } from "../utils/mobx";

class UserShippingInfoStore {
    constructor(autStore, commonStore) {
        makeAutoObservable(this);
        this.authStore = autStore;
        this.commonStore = commonStore;
    }

    allPayments = [];
    removingPayments = [];
    updateDefaultCard = false;
    allLoading = false;
    error = null;
    cardForm = null;
    bankForm = null;
    editingCard = null;
    editingBank = null;

    setError(error, type = "userProfile") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    setEditCardForm(paymentID, onSaved) {
        let card;
        this.editingCard = null;
        if (paymentID)
            card = this.cards.find(x => parseInt(paymentID) === x.paymentID);
        this.cardForm = new cardForm(!card, {
            onSuccess: async (form) => {
                let result;
                let values = form.values();
                values.primary = !!values.primary;  // convert to boolean
                try {
                    if (this.editingCard) {
                        result = await services.UserProfile.BillingService().update({
                            ...pick(values
                                , ...Object.keys({ ...billingFields, ...expirationFields })), paymentID
                        });
                        if (result.message)
                            this.commonStore.success(result.message);
                    } else {
                        result = await services.UserProfile.BillingService().create(values);
                        if (result.message)
                            this.commonStore.success(result.message);
                    }
                    if (onSaved)
                        onSaved(result);
                }
                catch (e) {
                    this.setError(e);
                }
            }
            ,
            onError: (form) => {
                this.setError(form.errors());
            }
        });
        if (card) {
            this.editingCard = card;
            this.cardForm.update({ ...card, zip: card.postalCode });
        }
        else {
            this.cardForm.update({ cardType: "Visa", country: "US" });
        }

    }

    setEditBankForm(bankID, onSaved) {
        let bank;
        this.editingBank = null;
        if (bankID)
            bank = this.banks.find(x => parseInt(bankID) === x.id);

        this.bankForm = new bankForm(!bank, bank?.country || this.authStore.country, {
            onSuccess: async (form) => {
                let result;
                let values = form.values();
                try {
                    if (this.editingBank) {
                        result = await services.UserProfile.BankService().update({
                            ...pick(values
                                , ...Object.keys({ ...bankBillingFields, ...bankFields })), paymentID: bankID
                        });
                        if (result.message)
                            this.commonStore.success(result.message);
                    } else {
                        result = await services.UserProfile.BankService().create(values);
                        if (result.message)
                            this.commonStore.success(result.message);
                    }
                    if (onSaved)
                        onSaved(result);
                }
                catch (e) {
                    this.setError(e);
                }
            }
            ,
            onError: (form) => {
                this.setError(form.errors());
            }
        });
        if (bank) {
            this.editingBank = bank;
            this.bankForm.update({ ...bank, zip: bank.postalCode, accountType: bank.type });
        }
        else {
            this.bankForm.update({ country: "US" });
        }

    }



    get cards() {
        return this.allPayments.length > 0 ? this.allPayments[0].cards : [];
    }

    get banks() {
        return this.allPayments.length > 1 ? this.allPayments[1].accounts : [];
    }

    loadAll = generateLoadList("loadAll", this, "allLoading"
        , () => { return services.UserProfile.BillingService().fetch("all"); }
        , "allPayments");


    removePayment = generateDeleteEntity("payment", this, "removingPayments", (paymentID) => {
        return services.UserProfile.BillingService().delete({ paymentID });
    });

    setDefaultCard = generateUpdateEntity("setDefaultCard", this, "updateDefaultCard", (paymentID) => {
        return services.UserProfile.BillingService().update({ paymentID }, "use");
    });


    makeDeposit = generateCreteEntity("deposit", this, "makingDeposits", (selectedCard, depositAmount) => {
        return services.UserProfile.Wallet().deposit(selectedCard, depositAmount);
    });

    makeBankDeposit = generateCreteEntity("deposit", this, "makingBankDeposits",
        async (selectedBank, depositAmount) => {
            const response = await services.UserProfile.Wallet().depositBank(selectedBank, depositAmount, this.authStore.loginGuid);
            if ( Number(response.statusCode) === 0) {
                this.setError(response.message);
                return;
            } else {
                return response;
            }
        });

    makeBankDepositCA = generateCreteEntity("depositCA", this, "makingBankDepositsCA",
        async (depositAmount) => {
            const response = await services.UserProfile.Wallet().depositBankCA(depositAmount, this.authStore.currentUser.userID);
            if ( Number(response?.statusCode) === 0) {
                this.setError(response?.message);
                return;
            } else {
                return response;
            }
        });

}

export default UserShippingInfoStore;