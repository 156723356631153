import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList} from "../utils/mobx";
import {makeAutoObservable, reaction} from "mobx";
import sumBy from "lodash/sumBy";


class EventsScheduleStore {
    error = null;
    dataLoading= null;

    data = null

    constructor(periodsStore, authStore) {
        makeAutoObservable(this);
        this.authStore = authStore;


    }

    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }


    loadData = generateLoadEntity(  "incomeData",
        this,
        "dataLoading",
        ({country,region,  openDateFrom, openDateTo, seminarDateFrom, seminarDateTo}) => {
            return services.Events.list( {country,region,  openDateFrom, openDateTo, seminarDateFrom, seminarDateTo} );
        },
        "data")



}

export default EventsScheduleStore;