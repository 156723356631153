import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Home from './Home/';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    minHeight: "100vh",
    background: "white",
    padding: 0
  },
}));

const Lifeline = observer(() => {
  const lifelineMatch = useRouteMatch();
  const classes = useStyles();

  return (
    <Container className={classes.wrapper} maxWidth="md">
      <Switch>
        <Route
          path={"*"}
          render={() => {
            return <Home lifelineMatch={lifelineMatch} />;
          }}
        />
      </Switch>
    </Container>
  )
});

export default Lifeline;