import React, { useCallback, useState } from 'react';
import { Button, Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, makeStyles } from "@material-ui/core";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AudioVideoPlayer from './AudioVideoPlayer';
import { useTranslation } from 'react-i18next';
import { ORRIN_ID } from '../../../stores/lifelineStore';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: 'black',
    display: 'grid',
    width: '100%',
    border: "1px solid #474747",
    background: "#ffffff",
    padding: 0,
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
      gridTemplateColumns: "3fr 2fr",
      gridTemplateRows: ".25fr 1.75fr",
      gridTemplateAreas: `
                        'video header'
                        'video comment'
                        `,
      padding: 0,
    },
  },
  postHeader: {
    display: 'flex',
    borderBottom: "1px solid rgba(var(--ce3,239,239,239),1)",
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridArea: 'header',
      marginBottom: theme.spacing(1.5)
    }
  },
  videoPlayer: {
    width: '100%',
    backgroundColor: 'black',
    [theme.breakpoints.up('md')]: {
      gridArea: 'video',
      flexShrink: 0
    }
  },
  commentContainer: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    maxHeight: '200px',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      gridArea: 'comment',
      width: '100%',
      maxHeight: '300px',
    }
  },
  headerButton: {
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      color: 'gray'
    }
  },
  image: {
    width: "100%",
  },
  commentUsername: {
    fontWeight: "bold",
  },
  headerIcon: {
    fontSize: '30px'
  },
}));

export const useUserCardStyles = makeStyles(theme => ({
  wrapper: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "min-content auto",
    gridGap: 12,
    alignItems: "center",
    width: "100%",
  },
  typography: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  nameWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const UserInfoCard = ({ profilePhoto, userName, postDate }) => {
  const classes = useUserCardStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.nameWrapper}>
        <Typography variant="subtitle2" className={classes.typography}>
          {userName}
        </Typography>
        <Typography
          variant="body2"
          className={classes.typography}
        >
          {postDate}
        </Typography>
      </div>
    </div>
  );
};

const Post = ({ videoData, onMarkMessage, onArchive, onDelete, onRespond }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [listened, setListened] = useState(videoData.ListenedTo);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const postDate = videoData.Stamp;
  const userName = `${videoData.FirstName} ${videoData.LastName}`;
  const profilePhoto = videoData.userPictureURL;
  const comments = videoData.Tags?.filter(tag => tag.TagType == 'text').filter(c => c && c.Tag.trim() != '');

  const getMainVideo = () => {
    const videoFileObject = videoData.Files.find(files => files.ID === -1);
    return videoFileObject;
  };

  const getAudio = () => {
    let audioFiles = [];
    if (videoData?.Tags?.length) {
      audioFiles = videoData.Tags.filter(files => files.TagType === 'audio');
      const orrinIndex = audioFiles.findIndex(file => file.UserID == ORRIN_ID);

      // Move Orrin's audio to the front
      if (orrinIndex != null || orrinIndex >= 0) {
        let orrins = audioFiles.splice(orrinIndex, 1)[0];
        if (orrins) {
          audioFiles.unshift(orrins);
        }
      }
    }

    return audioFiles;
  };

  const onRespondWithReplyID = (type) => {
    onRespond(type, videoData.ReplyUserID);
  };

  const markMessage = () => {
    const newListened = !listened;
    setListened(newListened);
    onMarkMessage(videoData.ID, newListened);
  };

  // Specific case - call this when the video is played so the message is shown as 'listened to'
  const markPlayed = () => {
    if (!listened) {
      markMessage(videoData.ID, true);
    }
  };

  const openArchiveModal = useCallback(() => {
    setArchiveModalOpen(true);
  }, [archiveModalOpen]);

  const closeArchiveModal = useCallback(() => {
    setArchiveModalOpen(false);
  }, [archiveModalOpen]);

  const openDeleteModal = useCallback(() => {
    setDeleteModalOpen(true);
  }, [deleteModalOpen]);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false);
  }, [deleteModalOpen]);

  const decodeTag = (tag) => {
    try {
      return decodeURI(tag);
    } catch {
      return decodeURI(encodeURI(tag));
    }
  };

  return (
    <>
      <Container
        className={classes.wrapper}
      >
        <div className={classes.postHeader}>
          <UserInfoCard profilePhoto={profilePhoto} userName={userName} postDate={postDate} />
          {
            /* List type 2 is archived and we don't want to show these controls for archived */
            videoData.ListType !== 2 &&
            <Box display="flex" justifyContent="flex-end">
              <IconButton className={classes.headerButton} onClick={openArchiveModal}>
                <BookmarkIcon className={classes.headerIcon} />
              </IconButton>
              <IconButton className={classes.headerButton} onClick={markMessage}>
                {listened ? <CheckBoxIcon className={classes.headerIcon} /> : <img height="30" src="/lifeline/new.png" />}
              </IconButton>
            </Box>
          }
        </div>
        <div className={classes.videoPlayer}>
          <AudioVideoPlayer 
            onPlayed={markPlayed} 
            thumbnail={videoData.FileName} 
            video={getMainVideo()} 
            audio={getAudio()} 
            openDeleteModal={openDeleteModal}
            onRespond={onRespondWithReplyID} />
        </div>
        {comments.length > 0 &&
          <div className={classes.commentContainer}>
            {comments.map((comment) => (
              <div key={comment.ID}>
                <Typography
                  variant="subtitle2"
                  component="span"
                  className={classes.commentUsername}
                >
                  {comment.FirstName} {comment.LastName}
                </Typography>{" "}
                <Typography variant="body2" component="span">
                  {decodeTag(comment.Tag)}
                </Typography>
              </div>
            ))}
          </div>
        }
        <Dialog open={archiveModalOpen}>
          <DialogTitle>{t('lifeline:post.confirmation')}</DialogTitle>
          <DialogContent>
            {t('lifeline:post.moveToArchive')}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onArchive(videoData.ID)}>{t('lifeline:post.proceed')}</Button>
            <Button onClick={closeArchiveModal}>{t('lifeline:post.cancel')}</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteModalOpen}>
          <DialogTitle>{t('lifeline:post.delete')}</DialogTitle>
          <DialogContent>
            {t('lifeline:post.deleteConfirm')}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onDelete(videoData.ID)}>{t('lifeline:post.proceed')}</Button>
            <Button onClick={closeDeleteModal}>{t('lifeline:post.cancel')}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default Post;
