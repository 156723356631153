import React from 'react';
import {Route, Redirect} from 'react-router';
import {observer} from "mobx-react-lite";
import {useStores} from "../hooks/use-stores";

function PrivateRoute({component: Component, render, notLoggedUrl, ...rest}) {
    const {authStore} = useStores()
    if (!notLoggedUrl)
        notLoggedUrl = authStore.loginUrl


    return (<Route
        {...rest}
        render={(props) => {
            if( notLoggedUrl && ( notLoggedUrl.startsWith("http://")||notLoggedUrl.startsWith("https://")))
            {
                window.location = notLoggedUrl;
                return
            }
            if (authStore.isConventionGuest )
                return <Redirect to={"/conventionprogram/2022/06"}/>

            return ( authStore.isAuthenticated === true && authStore.token != null )
                ? (Component?<Component {...props} {...rest} />:render(rest))
                : <Redirect to={notLoggedUrl}/>

        }}
    />)
}

export default observer(PrivateRoute)