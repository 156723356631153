import React, { useEffect, } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { useStores } from "../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, useRouteMatch } from "react-router-dom";
import {starFishColor} from "../components/styles";
import Html from "../components/Html";

const useStyles = makeStyles((theme) => ({
    body: {
        color: "white",
        backgroundColor: "#333",
        padding: 10
    },

    title: {
        color: "#333",
        backgroundColor: "#f5f5f5"
    }

}));

export default observer(function News({fromModule}) {
    const classes = useStyles();
    const { newsStore } = useStores();
    const params = useParams();
    const { id } = params;
    const { newsPost, newsPostLoading, error } = newsStore;

    useEffect(() => {
        if (id) {
            void newsStore.loadNewsPost({ id, fromModule });
        }
    }, [id]);

    return <div className={classes.whiteColor}>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {newsPostLoading ? <CircularProgress />
            : (newsPost && <>
                <div className={classes.title}><Typography variant="h6">{newsPost.title}</Typography></div>
                <Html className={classes.body} __html={newsPost.fullArticle} />

            </>)}

    </div>;
});