import {makeAutoObservable} from 'mobx';

class SeminarWebinarStore {
    constructor() {
        makeAutoObservable(this);
    }

    info = null;
    details = null;
}

export default SeminarWebinarStore;