import { makeAutoObservable } from 'mobx';
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity,
    generateLoadList, generateUpdateEntity,
} from "../utils/mobx";
import sumBy from "lodash/sumBy";


class CustomersStore {
    constructor( authStore) {
        makeAutoObservable(this);
        this.authStore = authStore;
    }

    myCustomersLoading = false;
    myCustomers = [];
    error = null;
    sendingSeminarInvite = []
    sendingPlatformInvite = []
    sendingRoyalInvite = []
    updatingCustomerToMemberEnrollStatusCall = []
    loadingDownlineCustomer = false
    lookupDownlineCustomerError = null

    downlineCustomer = null

    setError(error, type) {
        error = extractErrorMessage(error)
        switch (type) {
            case 'lookupDownlineCustomer':
                this.lookupDownlineCustomerError = error;
                this.error = null;
                break;
            default:
                this.error = error
                break;
        }


    }

    get summary(){
        if ( !this.myCustomers || this.myCustomers.length === 0 ) return null;

        return {totalCustomerPV: sumBy(this.myCustomers, "Subscription")
            , totalReferralPV: sumBy(this.myCustomers, "CustomerPV")
            , numberOfCustomers:sumBy(this.myCustomers, "NumCustomers")}
    }

    loadMyCustomers =      generateLoadList("myCustomers", this, "myCustomersLoading"
        , async () => {
            let items = await services.Members.customers();
            return Array.isArray(items) ? items : [];
        }, "myCustomers");

    sendSeminarInvite =      generateUpdateEntity("sendSeminarInvite", this, "sendingSeminarInvite"
        , async (email, lang) => {
            return services.Members.sendSeminarInvite(email, lang);

        } );

    sendPlatformInvite =      generateUpdateEntity("sendPlatformInvite", this, "sendingPlatformInvite"
        , async (userID, lang) => {
            return services.Members.sendPlatformInvite(userID, lang);
        } );

    sendRoyalInvite =      generateUpdateEntity("sendRoyalInvite", this, "sendingRoyalInvite"
        , async (userID, lang) => {
            return services.Members.sendRoyalInvite(userID, lang);
        } );


    updateCustomerToMemberEnrollStatusCall =      generateUpdateEntity("updateCustomerToMemberEnrollStatusCall", this, "updatingCustomerToMemberEnrollStatusCall"
        , async (customerLifeNo ) => {
            return services.Members.updateCustomerToMemberEnrollStatusCall(customerLifeNo);
        } );

    lookupDownlineCustomer =      generateLoadEntity("lookupDownlineCustomer", this, "loadingDownlineCustomer"
        , async (emailString) => {
            this.downlineCustomer = null;
            return await services.Members.lookupDownlineCustomer(this.authStore.currentUser.userID, emailString);

        }, "downlineCustomer", "[0]");
}

export default CustomersStore;