import {
    Box,
    Button,
    CircularProgress, DialogActions, DialogContent, DialogTitle,
    Divider, FormHelperText, Link, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import Alert from "@material-ui/lab/Alert";
import { maxBy } from "lodash";
import orderBy from "lodash/orderBy";
import { observer, useLocalObservable } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MaterialSelect from "../../components/inputs/MaterialSelect";
import MaterialTextField from "../../components/inputs/MaterialTextField";
import IncomeAllocationFormDef from "../../forms/incomeAllocation";
import { useStores } from "../../hooks/use-stores";
import AddBankModal from "../Wallet/eWallet/AddBankModal";

function IncomeAllocation() {
    const {commonStore, incomeAllocationStore, userBillingInfoStore, userProfileStore } = useStores();
    const {t} = useTranslation("incomeallocation");
    const {
      incomeAllocations,
      incomeAllocationsLoading,
      error,
      incomeAllocationsDeleting,
      incomeAllocationsUpdating,
      incomeAllocationsCreating,
      incomeAllocationsLT,
      incomeAllocationsLTLoading,
      incomeAllocationsLTUpdating
    } = incomeAllocationStore;
    const { banks, allLoading } = userBillingInfoStore;
    const [showBankModal, setShowBankModal] = React.useState(false);

    const [bankRequiredError, setBankRequiredError] = React.useState(false);
    useEffect(() => {
        (async () => {
          await userProfileStore.createEditForm(() => {
            commonStore.success("User Profile updated");
          });
          await userProfileStore.loadUserProfile();
        })();
        void incomeAllocationStore.loadIncomeAllocation();
        void incomeAllocationStore.loadIncomeAllocationLT();
        void userBillingInfoStore.loadAll()
    }, []);

    const { userProfile, userProfileLoading } = userProfileStore;
    const speakerContract = userProfile?.speakerContract;

    const store = useLocalObservable(() => ({
        selectedBank:-1,
        editAllocation: null,
        isLT: false,
        _form: new IncomeAllocationFormDef(
            {
                onSuccess: async () => {
                    try {
                        if( store._form.values().id === -99  ){
                            if ( store.selectedBank === -1 ){
                                setBankRequiredError(true);
                            }
                            else {
                                const  created = store.isLT ? await incomeAllocationStore.createAllocationLT(
                                    {...store._form.values(), id:  store.selectedBank === "eWallet" ? -1 : store.selectedBank }
                                ) : await incomeAllocationStore.createAllocation(
                                    {...store._form.values(), id:  store.selectedBank === "eWallet" ? -1 : store.selectedBank }
                                );
                                if ( created ) {
                                    void incomeAllocationStore.loadIncomeAllocation()
                                    void incomeAllocationStore.loadIncomeAllocationLT();
                                    store.closeEdit();
                                }
                            }
                        }
                        else if(  store._form.values().id === -50 ){

                            const  created = store.isLT ?  await incomeAllocationStore.createAllocationLT(
                                {...store._form.values() }
                            ): await incomeAllocationStore.createAllocation(
                                {...store._form.values() }
                            );
                            if ( created ) {
                                void incomeAllocationStore.loadIncomeAllocation()
                                void incomeAllocationStore.loadIncomeAllocationLT()
                                store.closeEdit();
                            }

                        }
                        else {
                            const  updated = store.isLT ? await incomeAllocationStore.updateAllocationLT(
                                store._form.values().id,
                                { ...store._form.values() }
                            ): await incomeAllocationStore.updateAllocation(
                                store._form.values().id,
                                { ...store._form.values() }
                            );
                            store.closeEdit();
                            if ( updated ) {
                                void incomeAllocationStore.loadIncomeAllocation()
                                void incomeAllocationStore.loadIncomeAllocationLT()
                                store.closeEdit();
                            }
                        }
                    } catch (e) {

                    }
                },
                onError: (form) => {
                    this.formSubmitting = false;
                }

            }
        ),
        submit(e) {
            this.formSubmitting = true;
            this._form.onSubmit(e);
        },
        get error() {
            return this._form.error
        },
        clear(){
            this._form.clear();
            this._form.reset();
            this._form.showErrors(false);
        },
        update(values) {
            store._form.update(values);
        },
        setSelectedBank(value){
            this.selectedBank = value;
        },
        setEditAllocation(value){
            this.editAllocation = value;
        },
        closeEdit () {
            this.setEditAllocation(null)
            this.clear();
            this.setSelectedBank(-1)
        },
        get AGODonationEnabled(){
            return incomeAllocations.find(x=>x.id === -50) != null;
        },
        setIsLT(value){
            this.isLT = value;
        },
    }));



    const handleAddNew = () => {
        store.clear();
        store.setIsLT(false);
        incomeAllocationStore.setError(null);
        setBankRequiredError(false);
        store.update({id: -99, priority: (maxBy(incomeAllocations, "priority")?.priority || 0)  + 1, type: 1})
        store.setEditAllocation(store._form);
    }
    const handleAddNewLT = () => {
        store.clear();
        store.setIsLT(true);
        incomeAllocationStore.setError(null);
        setBankRequiredError(false);
        store.update({id: -99, priority: (maxBy(incomeAllocationsLT, "priority")?.priority || 0)  + 1, type: 1})
        store.setEditAllocation(store._form);
    }

    const handleAddDonation = () => {
        store.clear();
        incomeAllocationStore.setError(null);
        setBankRequiredError(false);
        store.update({id: -50, priority: 0, type: 1})
        store.setEditAllocation(store._form);
    }

    const handleEdit = (allocation) => ()=> {
        store.setIsLT(false);
        store.clear();
        incomeAllocationStore.setError(null);
        setBankRequiredError(false);
        store.update(allocation)
        if (allocation.id !== -1)
            store.setSelectedBank(allocation.id);
        store.setEditAllocation(store._form);
    }
    const handleEditLT = (allocation) => ()=> {
        store.setIsLT(true);
        store.clear();
        incomeAllocationStore.setError(null);
        setBankRequiredError(false);
        store.update(allocation)
        if (allocation.id !== -1)
            store.setSelectedBank(allocation.id);
        store.setEditAllocation(store._form);
    }
    const handleDelete = (allocation) => ()=> {
        commonStore.showDeleteConfirm("Allocation", allocation.friendlyName, async ()=>{
            await incomeAllocationStore.deleteAllocation(allocation.id);
            void incomeAllocationStore.loadIncomeAllocation();
        })

    }
    const handleDeleteLT = (allocation) => ()=> {
        commonStore.showDeleteConfirm("Allocation", allocation.friendlyName, async ()=>{
            await incomeAllocationStore.deleteAllocationLT(allocation.id);
            void incomeAllocationStore.loadIncomeAllocationLT();
        })

    }

    const handleSave = (e) => {
        store.submit(e);
    }

    const handleHidePaymentEdit = () => {
        setShowBankModal(false);
        setBankRequiredError(false);
    };

    const handleSavedPaymentEdit = () => {
        void userBillingInfoStore.loadAll();
        setShowBankModal(false);
    };

    const handleBankChange = (e) => {
        if (e.target.value === -90) {
            setShowBankModal(true);
        }

        if (e.target.value !== "") {
            setBankRequiredError(false);
            store.setSelectedBank( e.target.value );
        }
    }

    return (
        <div>
            <ul style={{lineHeight: 1.5}}>
                <li>
                    {t("description1")}
                </li>
                <li>
                    {t("description2")}
                </li>
                <li>
                    {t("description3")}
                </li>
                <li>
                    {t("description4")}
                </li>
                <li>
                    {t("description5")}
                </li>
                <li>
                    {t("description6")}
                </li>
            </ul>
            <br/>
            <Divider/>
            {!store.AGODonationEnabled && <><Box marginTop={2} marginBottom={2}>{t("donationDescription")}
                <Link href={"https://www.allgraceoutreach.com"} target={"_blank"}>www.allgraceoutreach.com</Link> <Button
                            size={'small'} color={"primary"} variant={"contained"} onClick={handleAddDonation} >Donate</Button>

                    </Box>
                <Divider/>
                </>
            }
            {incomeAllocationsLoading === null || incomeAllocationsLoading || incomeAllocationsLTLoading || incomeAllocationsLoading === null || userProfileLoading
                ? <CircularProgress/>
                : <>
                    <br/>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Priority</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderBy(incomeAllocations,"priority").map((incomeAllocation, index) => (
                                <TableRow key={index}>
                                    <TableCell>{incomeAllocation.id === -50 ? "" : incomeAllocation.priority}</TableCell>
                                    <TableCell>
                                        {incomeAllocation.friendlyName}<br/>
                                        Amount:
                                        {incomeAllocation.type === 1 ? `$${incomeAllocation.value}` : `${incomeAllocation.value}%`}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            ( incomeAllocationsDeleting.includes(incomeAllocation.id)
                                        || incomeAllocationsUpdating.includes(incomeAllocation.id))
                                            ? <CircularProgress/>
                                                : <>
                                                    <Button onClick={handleEdit(incomeAllocation)} variant={"contained"} color={"primary"}
                                                            size={"small"}>Edit </Button>{" "}
                                                    {incomeAllocation.id !== -1 &&
                                                        <Button onClick={handleDelete(incomeAllocation)} variant={"contained"} color={"primary"}
                                                                size={"small"}>Delete</Button>}
                                                </>
                                        }

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <br/>
                    <Button fullWidth onClick={handleAddNew} variant={"contained"} color={"primary"} size={"small"}>Add</Button>

                    {!speakerContract !== true && 
                    <>                    
                        <br/>
                        <p style={{marginTop: '32px', fontSize: '18px', fontWeight: 'bold'}}>LT Income Allocation:</p>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Priority</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderBy(incomeAllocationsLT,"priority").map((incomeAllocation, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{incomeAllocation.id === -50 ? "" : incomeAllocation.priority}</TableCell>
                                        <TableCell>
                                            {incomeAllocation.friendlyName}<br/>
                                            Amount:
                                            {incomeAllocation.type === 1 ? `$${incomeAllocation.value}` : `${incomeAllocation.value}%`}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                ( incomeAllocationsDeleting.includes(incomeAllocation.id)
                                            || incomeAllocationsLTUpdating.includes(incomeAllocation.id))
                                                ? <CircularProgress/>
                                                    : <>
                                                        <Button onClick={handleEditLT(incomeAllocation)} variant={"contained"} color={"primary"}
                                                                size={"small"}>Edit </Button>{" "}
                                                        {incomeAllocation.id !== -1 &&
                                                            <Button onClick={handleDeleteLT(incomeAllocation)} variant={"contained"} color={"primary"}
                                                                    size={"small"}>Delete</Button>}
                                                    </>
                                            }

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <br/>
                        <Button fullWidth onClick={handleAddNewLT} variant={"contained"} color={"primary"} size={"small"}>Add</Button>
                    </>}                    
                </>}

            {store.editAllocation && <Dialog onClose={store.closeEdit} open={store.editAllocation} maxWidth={"sm"} fullWidth>

                <DialogTitle>
                    {store.editAllocation.$("id").value === -99
                        ? "Add New"
                        : store.editAllocation.$("id").value === -50 ? "AGO Donation": "Edit"}</DialogTitle>
                <DialogContent>
                       {error ? <Alert severity="error">{error}</Alert> : null}
                       {(store.editAllocation.$("id").value  !== -1
                           && store.editAllocation.$("id").value !== -50) && <>
                           {allLoading
                               ? <CircularProgress />
                               : <FormControl fullWidth error={bankRequiredError} disabled={store.editAllocation.$("id").value !== -99} >
                                   <Select fullWidth value={store.selectedBank} onChange={handleBankChange}>
                                       <MenuItem value={-1}>{t("wallet:add_money.bank.select_one")}</MenuItem>
                                       <MenuItem value={"eWallet"}>eWallet</MenuItem>
                                       {banks.map((bank, index) => {
                                           return <MenuItem value={bank.id}
                                                            key={bank.id}>{`${bank.friendlyName}`}</MenuItem>;
                                       })}
                                       <MenuItem value={-90}>{t("wallet:add_money.bank.add_new")}</MenuItem>
                                   </Select>
                                   {bankRequiredError && <FormHelperText >Bank account is required.</FormHelperText>}
                               </FormControl>}
                       </>}


                      {store.editAllocation.$("id").value !== -50
                        &&   <MaterialTextField field={store.editAllocation.$("priority")}    margin={"normal"}  />}
                       <MaterialSelect field={store.editAllocation.$("type")}
                                       margin={"normal"}
                                       options={[
                                           {value: 1, label: "Fixed ($)" },
                                           {value: 2, label: "Percent (%)" }]} />
                       <MaterialTextField field={store.editAllocation.$("value")} margin={"normal"} type={"number"}/>
                </DialogContent>
                <DialogActions>
                    {(incomeAllocationsUpdating.includes(store.editAllocation.$("id").value) || incomeAllocationsLTUpdating.includes(store.editAllocation.$("id").value) || incomeAllocationsCreating )
                        ? <CircularProgress />
                        : <>
                        <Button onClick={store.closeEdit} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </>}
                </DialogActions>
            </Dialog>}
            <AddBankModal showBankModal={showBankModal}
                          handleHidePaymentEdit={handleHidePaymentEdit}
                          handleSavedPaymentEdit={handleSavedPaymentEdit} />
        </div>
    );
}

export default observer(IncomeAllocation);