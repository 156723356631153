import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Container, Grid, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReplayIcon from '@material-ui/icons/Replay';
import { useStores } from '../../../hooks/use-stores';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        padding: 0,
    },
    heading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

        fontWeight: 'bold',
        fontSize: '2rem',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem'
        }
    },
    video: {
        width: '100%',
        height: 'auto',
        maxWidth: 1080,
        maxHeight: '40vh',
        backgroundColor: 'black'
    },
    navigationButtons: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'stretch',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        '& > button': {
            width: '40%'
        },
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            marginTop: theme.spacing(3),
            padding: `${theme.spacing(2)}px 0px`,
        }
    },
    nextButton: {
        color: 'green',
        border: '1px solid green',
    }
}));

const ReviewVideo = ({ lifelineMatch }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const videoRef = useRef();

    const [videoData, setVideoData] = useState(null);
    const { commonStore, lifelineStore } = useStores();

    const videoSrc = () => {
        if (!videoData) {
            let url = lifelineStore.videoFile;
            if (url) {
                setVideoData(URL.createObjectURL(url))
            } else {
                commonStore.showError(t('lifeline:createVideo.review.videoFail'))
            }
        }
    }

    const rerecord = () => {
        lifelineStore.videoFile = null;
        history.push(`${lifelineMatch.url}/choosePath`);
    }

    const nextStep = () => {
        history.push(`${lifelineMatch.url}/finalizeVideo`);
    }

    useEffect(() => {
        videoSrc();
    }, [])

    useEffect(() => {
         // to be extra safe, load the video; this is not needed in modern browsers but in case someone uses some rando bs router like DDG
         if (videoData) {
            videoRef.current?.load();
         }
    }, [videoData])

    if (!lifelineStore.videoFile) {
        history.push(`${lifelineMatch.url}/createVideo`);
        return null;
    }

    return (
        <Container className={classes.wrapper}>
            <video preload="metadata" playsInline ref={videoRef} width="300" height="300" className={classes.video} src={videoData} controls  />
            <Grid container xs={12} direction="column" style={{ flexGrow: '1', marginTop: '20px' }}>
                <Grid container xs={12} justifyContent="space-between" alignItems="flex-end" className={classes.navigationButtons}>
                    <Button variant="outlined" color="secondary" onClick={rerecord}>
                        <ReplayIcon style={{ marginRight: '8px' }} />
                        <Typography variant="body2">{t('lifeline:createVideo.review.recordAgain')}</Typography>
                    </Button>
                    <Button className={classes.nextButton} onClick={nextStep}>
                        {t('lifeline:createVideo.review.next')}<ArrowForwardIcon />
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ReviewVideo;