import {observer} from "mobx-react";
import Persona from "persona";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/persona.scss';
import {useStores} from "../../../hooks/use-stores";
import {handleBack} from "../../../utils/helpers";
import {useHistory} from "react-router";
import {CircularProgress} from "@material-ui/core";
import IFrame from "../../../components/IFrame";

const useStyles = makeStyles((theme) => ({

    root: {
        height: '100%',
    }
}));

function VerifyIdentity() {
    const classes = useStyles();
    const {authStore, identityVerificationStore } = useStores();
    const history = useHistory();
    const { loadingIdentityVerification, loadingIdentityVerificationUrl, verificationLinkUrl } = identityVerificationStore;



    useEffect(() => {
        (async () => {
          await Promise.all([
              identityVerificationStore.checkIdentityVerification(),
              identityVerificationStore.getIdentityVerificationUrl()])
        })()

    }, []);


    return ( ( loadingIdentityVerification == null || loadingIdentityVerification)
            || (( loadingIdentityVerificationUrl == null || loadingIdentityVerificationUrl) ) )
        ?<CircularProgress/>
        : <>
           <IFrame url={verificationLinkUrl}/>
        </>
}

export default observer(VerifyIdentity)