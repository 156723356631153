import {AppBar as MUIAppBar, Box, CircularProgress} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import Vimeo from "@u-wave/react-vimeo";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useMemo} from "react";
import {useStores} from "../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import {starFishColor} from "../components/styles";
import {observer} from "mobx-react";
import VimeoAlbum from "../components/video/VimeoAlbum";


const useStyles = makeStyles((theme) => ({
    videoModalContainer: {
        minWidth: "40vw",
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        outline: "none",
        alignItems: "center",
        width: "100%",
        background: `black`,
        borderWidth: 2,
        borderStyle: "solid",
        ...starFishColor(theme, "borderColor"),

        [theme.breakpoints.up(700)]: {
            minWidth: 500,
        },

        [theme.breakpoints.up(900)]: {
            minWidth: 700,
        },

        [theme.breakpoints.up(1200)]: {
            minWidth: 1000,
        },
        [theme.breakpoints.down("sm")]: {
            height: "90dvh"
        },
    },
    videoModalHeader: {
        ...starFishColor(theme, "borderColor"),
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
    },
    videoTitle:{
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        borderRadius: ".4rem",
        maxHeight: "70vh",
        minHeight: "40vh",
        width: "100%"
    },
}));

function VideoModal({isPhone}){
    const {  videoStore } = useStores();
    const { vimeoAlbum, loadingAlbumData, mediaURL: vimeoID, mediaTitle: videoTitle, mediaSpeakers: videoSpeakers } = videoStore;
    const classes = useStyles({ isPhone });
    const [currentVideoInfo, setCurrentVideoInfo] = React.useState(null);


    useEffect(() => {
        if (vimeoAlbum) {
            setCurrentVideoInfo(vimeoAlbum[0]);
        }
    },[vimeoAlbum])

    const title = useMemo(() => {
        let title = ""
        if ( currentVideoInfo ) title= currentVideoInfo.title;
        else if ( vimeoAlbum ) title = vimeoAlbum.videos[0].title;
        else title= videoTitle;
        return title;
    },[vimeoAlbum, currentVideoInfo, videoTitle])


    return <Dialog fullScreen={isPhone} scroll={"body"} maxWidth={false}
                   open={vimeoID != null || vimeoAlbum!=null || loadingAlbumData}
                   onClose={e => { videoStore.closeMedia(); }}>
        <div className={classes.videoModalContainer} >
            <MUIAppBar className={classes.videoModalHeader} color={"transparent"} position={"static"} >
                <Toolbar>
                    <Box display="flex" justifyContent="flex-start" flexDirection="column" width="100%" >
                        <Typography variant="h6" className={classes.videoTitle}>{title}</Typography>
                        <Typography variant={"subtitle1"}>{videoSpeakers}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" >
                        <IconButton edge="start" color="inherit" onClick={e => { videoStore.closeMedia(); }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>

                </Toolbar>
            </MUIAppBar>
            {loadingAlbumData && <CircularProgress/> }
            {vimeoAlbum
                ? <>
                    <VimeoAlbum  currentVideoInfo={currentVideoInfo || vimeoAlbum.videos[0]}
                                 setCurrentVideoInfo={setCurrentVideoInfo}
                                 relatedVideos={vimeoAlbum.videos}
                                 videoModalTitle={vimeoAlbum.title|| vimeoAlbum.albumName }/>

                </>
                :<div className={clsx(classes.modalContent, "embed-responsive")}>
                        <Vimeo id="frameVideo" className="embed-responsive-item" allow="fullscreen"
                               video={vimeoID} />

                    </div>}
        </div>

    </Dialog>
}


export default observer(VideoModal);