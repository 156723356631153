import Menu from "./Menu";
import {computed, makeObservable} from "mobx";

class MembersMenu extends Menu{


    constructor( items, libraryStore, packsStore ) {
        super(items, libraryStore, packsStore )
        makeObservable(this, {
            exploreLifeItem: computed
        })
    }

    get exploreLifeItem(){
        return this.topLevelLinks && this.topLevelLinks.find(x=> x.tag=== "explorelife")
    }

}

export default MembersMenu