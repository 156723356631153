import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    alertTitle: {
        paddingBottom: `${theme.spacing(2)}px`,
    },
    alert: {
        minHeight: `${theme.spacing(15)}px`,
        minWidth: `${theme.spacing(46)}px`,
        width: `${theme.spacing(66)}px`,
        [theme.breakpoints.down('xs')]: {
            minHeight: `${theme.spacing(15)}px`,
            minWidth: `initial`,
            width: `initial`,
        },
    },
    alertCloseButton: {
        position: 'absolute',
        top: `-${theme.spacing(1)}px`,
        right: `-${theme.spacing(0)}px`,
    }
}));

export default observer(({ allowUserToClose = true, errorTitle, handleClose, open, error }) => {
    const classes = useStyles();
    return (
        <Dialog onClose={!allowUserToClose ? null : handleClose} maxWidth={"md"} aria-labelledby="alert-dialog" open={open}>
            <Alert variant="outlined" severity="error" className={classes.alert}>
                <AlertTitle className={classes.alertTitle}>{errorTitle}
                    {
                        !allowUserToClose
                            ? null
                            : (
                                <IconButton
                                    aria-label="close"
                                    className={classes.alertCloseButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )
                    }
                </AlertTitle>
                {error}
            </Alert>
        </Dialog>
    );
});