import * as React from 'react'
import { observer } from 'mobx-react-lite'
import {useTranslation} from "react-i18next";
import MuiPhoneNumber from 'material-ui-phone-number';

export default observer(({
  field,
    ...rest
}) => {
    const {t} = useTranslation();
    let inputProps = { ...rest.inputProps, ...( (field.extra && field.extra.inputProps)  || {}) }
    return <div>
        <MuiPhoneNumber error={!!field.error} helperText={t(field.error)} fullWidth
                   {...field.bind()}
                   label={t(field.label)}
                   placeholder={t(field.placeholder)}
                   inputProps={inputProps}
                   {...rest}
        />
    </div>
}
)