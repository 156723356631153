import React from 'react';
import { Box, styled, Typography } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useStores } from '../../../hooks/use-stores';

const Wrapper = styled(Box)((props) => ({
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'left',
    backgroundColor: props.backgroundColor,
    borderRadius: 20,
    width: '100%',
    maxWidth: '440px',
    padding: '22px',
    paddingBottom: '6px',
    height: '200px',
    [props.theme.breakpoints.up('sm')]: {
        height: '240px',
    }
}));

const Image = styled('div')(({ theme }) => ({
    maxWidth: '200px',
    width: '100%',
    height: '200px'
}));

const CardDenomination = styled(Typography)((props) => ({
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: -2,
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '22px',
    }
}));

const MerchantName = styled(Typography)((props) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: '0 10px',
    color: props.fontColor,
    fontSize: 12,
    whiteSpace: 'nowrap',
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    }
}));

const CardNumberLabel = styled(Box)((props) => ({
    color: props.fontColor,
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '3px',
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    }
}));

const GiftCardNumberWrapper = styled(Box)((props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%'
}));

const GiftCardNumber = styled(Box)((props) => ({
    letterSpacing: '1px',
    color: 'black',
    padding: '14px',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgb(243, 243,243)',
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '18px',
    }
}));

const PinWrapper = styled(Box)((props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '10px',
    color: 'black',
    width: '100%',
}));

const PinHeader = styled(Typography)((props) => ({
    fontSize: '12px',
    marginBottom: -2
}));

const Pin = styled(Typography)((props) => ({
    fontWeight: 'bold'
}));

const GiftCardImage = (props) => {
    const { commonStore } = useStores();

    return (
        <Wrapper backgroundColor={props.backgroundColor}>
            <Box display="flex" height="50px" width="100%" alignItems={'center'} minWidth="0">
                <Image style={{
                    width: '100%',
                    backgroundImage: `url("${props.logo}")`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '30px',
                    width: '50px'
                }}
                />
                <Box style={{ padding: '0px 6px', flexGrow: 1, }}>
                    <MerchantName fontColor={props.fontColor}>{props.merchantName}</MerchantName>
                </Box>
                {props.amount && props.currency &&
                    <Box style={{ color: props.fontColor }}>
                        <CardDenomination>{props.amount}</CardDenomination>
                        <Box>
                            {!props.hideCurrencyCode && <Typography style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'right' }}>{props.currency}</Typography>}
                        </Box>
                    </Box>
                }
            </Box>
            <GiftCardNumberWrapper>
                {
                    props.useGiftCardNumber &&
                    <Box>
                        <Box>
                            <CardNumberLabel fontColor={props.fontColor}>
                                Gift card code
                            </CardNumberLabel>
                            <CopyToClipboard text={props.giftCardNumber || "XXXX XXXX XXXX XXXX"}
                                onCopy={() => commonStore.success("Link copied")}>
                                <GiftCardNumber>{props.giftCardNumber || "XXXX XXXX XXXX XXXX"}</GiftCardNumber>
                            </CopyToClipboard>
                        </Box>
                        {props.pin && <PinWrapper>
                            <PinHeader>Pin</PinHeader>
                            <Pin>{props.pin}</Pin>
                        </PinWrapper>
                        }
                    </Box>
                }
            </GiftCardNumberWrapper>
        </Wrapper>
    )
}

export default GiftCardImage;