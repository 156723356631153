import React, {useEffect} from "react";
import {observer} from "mobx-react";


function Signature({onChange}) {

    const canvasRef = React.useRef(null);
    const elementRef = React.useRef(null);
    const [signaturePad, setSignaturePad] = React.useState(null);

    useEffect( ()=>{
        if( !canvasRef.current || !elementRef.current ) return;
        // eslint-disable-next-line no-undef
        const $el = $(elementRef.current);
        // eslint-disable-next-line no-undef
        const $canvas = $(canvasRef.current);
        $canvas.attr("width", $el.width()-2)
        const pad = $el.signaturePad({drawOnly: true
            , defaultAction:"drawIt"
            , lineTop:65
            , onDrawEnd: ( )=>{ handleChange(pad)}
            , onDraw: ( )=>{ handleChange(pad)}
        });
        setSignaturePad(pad);

    },[canvasRef, elementRef]   )


    const handleChange = (pad) => {
            onChange(pad.getSignature());
    }
    const handleClear = () => {
            onChange(signaturePad.getSignature());
    }

    return <form method="post" action="" className="sigPad" ref={elementRef} >

            <div className="sig sigWrapper">
                <canvas className="pad"  height="78" ref={canvasRef}/>
            </div>
            <ul className="sigNav">
                <li className="clearButton" onClick={handleClear}><a href="#clear">Clear</a></li>
            </ul>
    </form>
}

export default observer( Signature )