
import IdLessRestService from "./IdLessRestService";


export default class SendoutService extends IdLessRestService {
    constructor() {
        super('/api/share');
    }

    async getShareDetails(code) {
        return this.post(`${this.baseUrl}/buildpage`, { msgID: code })
        //

        //STUB:
        await new Promise((resolve, reject) => {
            setTimeout(resolve, 1000)
        })

        if (code === "11111111") {
            return { messageType: 7, mediaURL: "528290854", shopURL: "shopURL", senderInfo: { senderEmail: "test@test.com", senderDisplayName: "Test ", senderPhone: "242423123142" } }
        }
        else {
            throw new Error("Code not valid")
        }
    }

    async getStarfishShareVideos() {
        return this.get(`${this.baseUrl}/starfishvideos`);
    }

    //"P" = Prezi, "V" = Marketing Videos, "O" = Open Meeting, "T" = Testimonials
    async getShareLinksByType(type) {
        return this.get(`${this.baseUrl}/sharelinks?type=${type}`);
    }

    async generateVideoShareLinks(vimeoId, videoTitle) {
        return this.post(`${this.baseUrl}/sharevideo`, {
            vimeoId,
            videoTitle
        });
    }

    async generatePreziLink(preziId, preziTitle) {
        return this.post(`${this.baseUrl}/shareprezi`,
            {
                preziId,
                preziTitle
            }
        );
    }
}
