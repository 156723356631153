import RestService from "./RestService";


class WalletHelpService extends RestService {
    constructor(parentService) {
        if (parentService) {
                super(`${parentService.baseUrl}/wallet`)
        }
        else
            throw new Error("Can be only nested service")
    }
}

export default class HelpService extends RestService {
    constructor() {
        super('/api/help' )
    }

    Wallet(){
        return new WalletHelpService( this )
    }
}
