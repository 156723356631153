import {getCookie, setCookie, deleteCookie} from './cookieUtil';

let storageType = import.meta.env.REACT_APP_STORAGE_TYPE || "cookies"

console.log(storageType)

export function getItem(key){
    switch (storageType) {
        case "cookies":
            return getCookie(key)
        case "localStorage":
        default:
            return window.localStorage.getItem(key)

    }
}
export function setItem(key, value, days){
    switch (storageType) {
        case "cookies":
            return setCookie(key, value, days);
        case "localStorage":
        default:
            return window.localStorage.setItem(key, value)

    }
}
export function removeItem(key){
    switch (storageType) {
        case "cookies":
         return deleteCookie(key);
        case "localStorage":
        default:
            return window.localStorage.removeItem(key)

    }
}