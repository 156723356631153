import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import { updatePhoneRules } from "./helpers";


const fields = {
    "firstName": {
        label: 'enrollment:firstname',
        rules: 'string|required',
        placeholder: 'enrollment:firstname',
    },
    "lastName": {
        label: 'enrollment:lastname',
        rules: 'string|required',
        placeholder: 'enrollment:lastname',
    },
    email: {
        label: 'enrollment:email',
        rules: 'string|email|required|uniq_username_enroll',
        placeholder: 'enrollment:email',
        hooks: {
            onChange: (field) => {
                field.value = field.value.trim();
            },
        }
    },
    password: {
        label: 'password',
        placeholder: 'password',
        rules: 'string|required|min:8',
        type: "password"
    },
    confirmPassword: {
        label: 'confirm_password',
        placeholder: 'confirm_password',
        rules: 'required|string|same:password',
        type: "password"
    },
    lang: {
        label: 'enrollment:preferredlanguage',
        rules: 'required|string',
        placeholder: 'enrollment:preferredlanguage',
    },
    country: {
        rules: 'required|string',
        label: 'enrollment:country',
        placeholder: 'enrollment:country',
    }
};



export default function create(initials, hooks) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }

    console.log( f )

    const form = new MobxReactForm({ fields: f }, { plugins, hooks });
    return form;
}

