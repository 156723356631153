import React, {useEffect} from "react"
import {observer} from "mobx-react";
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Typography
    ,
    Table, TableHead, TableBody, TableRow, TableCell, Select, MenuItem
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useTranslation} from "react-i18next";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import services from '../../services';
import {extractErrorMessage, formatNumber} from "../../utils/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorMessage from "../Lifeline/CreateVideo/ErrorMessage";
import {useStores} from "../../hooks/use-stores";
import {starFishColor} from "../../components/styles";
import AnnualBonusTable from "./components/AnnualBonusTable";


const useStyles = makeStyles((theme) => ({
    select:{
        margin: 2
    },
    accordionSummary: {
        ...starFishColor(theme, "backgroundColor"),
        "&.Mui-expanded": {
            margin: 0,
            minHeight: 50
        },
        "& .MuiAccordionSummary-content": {
            margin: "11px 0"
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "11px 0"
        }
    },

}));


function CumulativeCustomerBonus() {

    const {t} = useTranslation("customers");
    const classes = useStyles();
    const {authStore} = useStores();
    const {currentUser} = authStore;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [data, setData] = React.useState(null);
    const quarter = Math.ceil( new Date().getMonth() /3)
    const year = new Date().getFullYear()

    useEffect(()=>{

       void (async () => {
            if( currentUser ) {
                try {

                    setLoading(true)
                    const res = await services.MyTeams.getCumulativeUserData()
                    setData(res)
                } catch (e) {
                    setError("Failed to load data: " + extractErrorMessage(e))
                } finally {
                    setLoading(false)
                }
            }
            })();

    },[currentUser])

    if (loading)
        return <CircularProgress/>

    return <>
        <ErrorMessage message={error}></ErrorMessage>
        <Accordion defaultExpanded>
            <AccordionSummary
                color="primary" className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {t("cumulative_customer_bonus")}
            </AccordionSummary>
            <AccordionDetails style={{flexDirection:"column"}}>
                <Box justifyContent={"center"}  display={"flex"}>
                    <Typography>{t("cumulative_customer_bonus_title", {year:data && data.Year})} { formatNumber( data && data.PVCustomer, 2)} </Typography>
                </Box>
                <Box marginTop={3} marginBottom={3}  display={"flex"}>
                    <Typography>{t("cumulative_customer_bonus_description")}</Typography>
                </Box>
                <Box justifyContent={"center"}  display={"flex"}>
                    <AnnualBonusTable/>
                </Box>
            </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
            <AccordionSummary
                color="primary" className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {t("customer_pool_bonus")}
            </AccordionSummary>
            <AccordionDetails style={{flexDirection:"column"}}>
                <Box justifyContent={"center"} display={"flex"} alignItems={"center"} style={{gap:"4px"}}>
                    <Typography>{t("customer_pool_bonus_title_pre")}</Typography>
                   <Typography><strong>{year}-Q{quarter}</strong></Typography>
                    <Typography> {t("customer_pool_bonus_title_post")}  { formatNumber( data && data?.quarterData?.CustomerPV, 2)}  </Typography>
                </Box>
                <Box marginTop={3} marginBottom={3} >
                    <Typography>{t("customer_pool_bonus_description")} </Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    </>

}


export default observer(CumulativeCustomerBonus)