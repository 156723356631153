import IdLessRestService from "./IdLessRestService";


export default class AutoShipService extends IdLessRestService {
    constructor() {
        super('/api/subscriptions');
    }

    createUserCart(){
        return this.get(`${this.baseUrl}/cart`);
    }

    loadSubscriptionInfo() {
        return this.get(`${this.baseUrl}`,);
    }

    loadMySubscription(categoryID) {
        return this.get(`${this.baseUrl}/${categoryID}`,);
    }

    loadManageSubscription() {
        return this.get(`${this.baseUrl}/manage`,);
    }

    getUserCart(cartGuid) {
        return this.get(`${this.baseUrl}/cart/${cartGuid}/items`,);
    }


    getCartTotals(cartGuid) {
        return this.get(`${this.baseUrl}/cart/${cartGuid}/totals`);
    }

    updatePayment(categoryID, paymentID) {
        return this.put(`${this.baseUrl}/${categoryID}/payment`, {paymentID});
    }

    cancelSubscription(categoryID) {
        return this.del(`${this.baseUrl}/${categoryID}`);
    }

    renewSubscription(categoryID, orderId) {
        return this.post(`${this.baseUrl}/${categoryID}/renew`, {orderId});
    }

    reactivateSubscription(categoryID) {
        return this.post(`${this.baseUrl}/${categoryID}/reactivate`);
    }

    updateSubscription(categoryID, values) {
        return this.put(`${this.baseUrl}/${categoryID}`, values );
    }

    addItemToCart({ cartID, productID, qty }) {
        return this.put(`${this.baseUrl}/cart/${cartID}/items/`, { productID, qty });
    }

    deleteCartItem({ cartID, productID }) {
        return this.del(`${this.baseUrl}/cart/${cartID}/items`, { productID });
    }

    updateCartItem({ cartID, productID, qty }) {
        return this.put(`${this.baseUrl}/cart/${cartID}/items`, { productID, qty });
    }

    getProductSKUbyCategory(categoryID){
        return this.get(`${this.baseUrl}/redirect/${categoryID}`);
    }

}
