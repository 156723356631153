import OwlCarousel from "react-owl-carousel2";
import React from "react";
import {observer} from "mobx-react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {lineClamp} from "../../../../utils/styles";


const useStyles = makeStyles((theme) => ({

    productAdditionalBlock: {
        height: 160,
        textAlign:"center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer"
    },
    additionalImg: {
        width: "100px!important",
        height: 100
    },
    title:{
        paddingTop: 70,
        paddingBottom: 20
    },
    lineClamp: {
        ...lineClamp,
        fontSize: 14
    },
}))


const AdditionalProductsCarousel = ({ t, parentCategory, additionalProducts, options, onAdditionalProductHandler }) => {

    const classes = useStyles();
    if (parentCategory === 'subscription' || additionalProducts.length < 1) return ('')

    return (
        <>
            <Typography variant={"h5"} className={classes.title}>{t('shopping:description.additionalproducts')}</Typography>
            <OwlCarousel
                options={options}
            >
                {
                    additionalProducts.map((product => {
                        return (
                            <div className={classes.productAdditionalBlock} key={product.productSKU}>
                                <img
                                    alt="additional products"
                                    onClick={() => onAdditionalProductHandler(product)}
                                    key={product.imageURL}
                                    className={classes.additionalImg}
                                    src={product.imageURL}
                                />
                                <p className ={clsx(classes.lineClamp)}>
                                    {product.productName}
                                </p>
                            </ div>
                        )
                    }))
                }
            </OwlCarousel>
        </>
    )
}




export  default observer(AdditionalProductsCarousel)