import {Badge, Box, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useTransition} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {formatNumber} from "../../../utils/helpers";
import moment from "moment";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import SummaryRows from "./SummaryRows";



const useStyles = makeStyles((theme) => ({
    summaryCell:{
        backgroundColor:"black",
        border:0,
        paddingTop: 0,
        paddingBottom: 0
    },
    summaryCellFirst:{
        paddingTop: 10,
    },
    noWrap:{
        whiteSpace: "nowrap"
    },
    summaryCellLast:{
        paddingBottom: 10
    },
    badge: {
        padding: '0 4px',
        "& .MuiBadge-badge": {
            position: "static",
            transform: "unset",
            transformOrigin: "unset",
        }
    },
}));


function CustomersDataTable({data, subTotal, shippingTax, total, pv, SAPercent}) {
    const classes = useStyles();
    const {t} = useTranslation("myvolume");

    if ( !data || data.length === 0 )
        return <Box display="flex" justifyContent={"center"} flex={1}>
            {t("no_activity")}
        </Box>


    return     <Table stickyHeader  size="small"  style={{backgroundColor:"#424242"}}>
        <TableHead>
            <TableRow>
                <TableCell>{t("date")}</TableCell>
                <TableCell>{t("name")}</TableCell>
                <TableCell>{t("type")}</TableCell>
                <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>{t("subtotal")}{" "}($)</TableCell>
                <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>{t("shtx")}{" "}($)</TableCell>
                <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>{t("total")}{" "}($)</TableCell>
                <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>{t("pv")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((row) => {
                return <TableRow key={row.UserID}>
                    <TableCell>{moment( new Date(row.OrderDate)).format('MM/DD/yy')}</TableCell>
                    <TableCell>{row.TransactionName}</TableCell>
                    <TableCell>{row.Name} ({row.UserID})</TableCell>
                    <TableCell align={"right"} >{row.Total ===0? "-":  `${formatNumber(row.Subtotal,2)}`}</TableCell>
                    <TableCell align={"right"}>{row.Total ===0? "-":  `${formatNumber(row.Tax+row.Shipping ,2)}`}</TableCell>
                    <TableCell align={"right"}>{row.Total ===0? "-":  `${formatNumber(row.Total,2)}`}</TableCell>
                    <TableCell align={"right"} className={classes.noWrap}> {row.IsNewRetailSales === 1 ? <Badge badgeContent={`${SAPercent}%`} color="secondary" className={classes.badge}/>: null } {formatNumber(row.PV, 2)}</TableCell>
                </TableRow>
            })}


            <TableRow>
                <TableCell colSpan={7} align={"right"} >
                    <SummaryRows size={"small"} t={t} subTotal={subTotal} total={total} pv={pv} shippingTax={shippingTax} />
                </TableCell>
            </TableRow>
        </TableBody>

    </Table>
}

export  default CustomersDataTable;