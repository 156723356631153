import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class TopTenStore {
    constructor() {
        makeAutoObservable(this)
    }


    topTenInfoLoading = null
    topTenInfo = null

    error = null

    setError(error, type = "topTen") {
        error = extractErrorMessage(error)
        this.error = error
     }


    loadInfo = generateLoadEntity( "topTenInfo", this, "topTenInfoLoading"
        , ()=>{ return services.TopTen.list() }, "topTenInfo" )




}

export default TopTenStore