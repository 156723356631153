import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CartSection from "./cartSection";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({

    cartSummary: {
        width: "100%",
        backgroundColor: "#f8f8f8",
        padding: 7
    },

    itemCheckBox: {
        marginLeft: -32,
        minWidth: "auto"
    },
    thumb: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(9),
            height: theme.spacing(9),
        },
    },

    lineClamp2: {
        "-webkit-line-clamp": "2 !important"
    },

    lineClamp: {
        margin: "5px 0",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        lineClamp: 2,
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.3em",
        fontSize: 12,
        textAlign: "center"
    },

    additionalSubscriptions: {
        display: "flex",
        flexWrap: "wrap",
        padding: theme.spacing(1)
    },
    additionalSubscription: {
        // marginRight: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        padding: "20px 10px",
        paddingBottom: 5,
        background: "white",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        cursor: "pointer",
        height: "100%"
    },

    large: {
        width: theme.spacing(16),
        height: theme.spacing(16)
    }


}));


function CartView({ oneTimeItems, recurringItems, handleToggleItem, handleIconClick
    , handleAdditionalItemSelect, handleSwitchAlternative, handleRemoveAlternative, cartTotal
    , effectiveAdditionalItems, showRenewal
    , removingItemToCart, addingItemsToCart
    , renewPrice, renewDiscount
    , autoRenewal, setAutoRenewal, creditNote
    , isStarfishSignup = false }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [showAditionalItems, setShowAditionalItems] = useState(false);
    return <>
        <Typography variant={"subtitle1"}>{t("enrollment:ordersummary")}</Typography>
        <Paper className={classes.cartSummary} elevation={0}>
            <CartSection items={oneTimeItems}
                onIconClick={handleIconClick}
                removingItemToCart={removingItemToCart}
                         addingItemsToCart={addingItemsToCart}
                isStarfishSignup={isStarfishSignup}
                title={t("enrollment:onetimepurchase")}
                onSwitchAlternative={handleSwitchAlternative}
                onRemoveAlternative={handleRemoveAlternative}
                onDelete={handleToggleItem} />
            {showRenewal && <>
                <FormControlLabel control={<Checkbox style={{ color: '#007BFF' }} />}
                    value={autoRenewal}
                    checked={autoRenewal}
                    onChange={() => { setAutoRenewal(!autoRenewal); }}
                    label={t("enrollmember:autorenewal")} />
                <Typography variant={"body2"}>
                    {t("enrollmember:inordertokeepalife")}
                    &nbsp;{renewPrice && renewPrice}&nbsp;
                    {t("enrollmember:butbyregistering")}
                    &nbsp;{renewDiscount && renewDiscount}&nbsp;
                    {t("enrollmember:therenewalwillprocess")}
                </Typography>
            </>}
            <br />
            <Divider />
            <br />
            {recurringItems && <>
                <CartSection items={recurringItems}
                             removingItemToCart={removingItemToCart}
                             addingItemsToCart={addingItemsToCart}
                onIconClick={handleIconClick}
                isStarfishSignup={isStarfishSignup}
                title={isStarfishSignup ? t("enrollment:yearlysubscription") : t("enrollment:monthlysubscription")}
                onDelete={handleToggleItem} />
                <Divider />
            </>}

            {cartTotal &&
                <>
                    <List dense>
                        {creditNote && <ListItem>
                            <ListItemText primary={<strong>{t(creditNote.title)}</strong>}
                                          primaryTypographyProps={{
                                              color:"primary"

                                          }}
                                           />
                            <ListItemSecondaryAction>
                                <ListItemText primary={<strong>{creditNote.amount}</strong>}
                                          primaryTypographyProps={{
                                    color:"primary"

                                }} />
                            </ListItemSecondaryAction>
                        </ListItem>}
                        <ListItem>
                            <ListItemText primary={t("enrollment:subtotal")} />
                            <ListItemSecondaryAction>
                                <ListItemText primary={cartTotal.subTotal} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        {(cartTotal.displayShipping === 1 && cartTotal.shipping) && <ListItem>
                            <ListItemText primary={t("enrollment:shipping")} />
                            <ListItemSecondaryAction>
                                <ListItemText primary={cartTotal.shipping} />
                            </ListItemSecondaryAction>
                        </ListItem>}
                        <ListItem>
                            <ListItemText primary={t("enrollment:tax")} />
                            <ListItemSecondaryAction>
                                <ListItemText primary={cartTotal.tax} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={t("enrollment:todaystotal")} />
                            <ListItemSecondaryAction>
                                <ListItemText primary={cartTotal.grandTotal} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </>
            }
        </Paper>
        <Grid container item xs={12} justifyContent={"flex-start"}>
            {
                effectiveAdditionalItems && effectiveAdditionalItems.length
                    ?
                    <>
                        <Grid container item xs={12} justifyContent={"flex-start"}>
                            <Button onClick={e => {
                                setShowAditionalItems(!showAditionalItems);
                            }} variant={"contained"}
                                color="primary">
                                {!showAditionalItems ? t("enrollment:availablesubscriptions") : t("enrollment:hideavailablesubscriptions")}
                            </Button>
                        </Grid>
                        <div className={classes.additionalSubscriptions}>
                            {showAditionalItems
                                && <Grid container item spacing={2}>
                                    {effectiveAdditionalItems.map(x => {
                                        return <Grid item xs={6} sm={3} md={3} lg={2}>
                                            <div className={classes.additionalSubscription}
                                                onClick={e => {
                                                    handleAdditionalItemSelect(x);
                                                }}>
                                                <Avatar src={x.imageURL}
                                                    variant={"square"}></Avatar>
                                                <span
                                                    className={clsx(classes.lineClamp, classes.lineClamp2)}>{x.productTitle}</span>
                                            </div>
                                        </Grid>;
                                    })}
                                </Grid>}
                        </div>
                    </>
                    : ''
            }
        </Grid>
    </>;
}


export default observer(CartView);