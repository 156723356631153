import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../hooks/use-stores";
import ErrorMessage from "../Lifeline/CreateVideo/ErrorMessage";
import {TabContext, TabPanel} from "@material-ui/lab";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CarIcon from '@material-ui/icons/DirectionsCar';
import EmailIcon from '@material-ui/icons/Email';
import TicketIcon from '@material-ui/icons/LocalActivity';

import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel, ListItem, ListItemAvatar, ListItemText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import authStore from "../../stores/authStore";
import {DatePicker} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {useHistory} from "react-router";
import clsx from "clsx";

function htmlDecode(input){
    var e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 -5px"
    },
    table: {
        "& .MuiTableCell-sizeSmall": {
            padding: "6px 6px"
        }
    },
    tab: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    details: {
        display: "flex",
        gap: 16,
        [theme.breakpoints.down(880)]: {
            flexDirection: "column"
        }
    },
    rowRoot: {
        '& > *': {
            borderBottom: 'unset',
        },
    },

    smallScreen: {
        display: "none",
        [theme.breakpoints.down(1450)]: {
            display: "table-cell",
        }
    },
    largeScreen: {
        display: "none",
        [theme.breakpoints.up(1450)]: {
            display: "table-cell",
        }
    },
    mediumScreen: {
        display: "none",
        [theme.breakpoints.up(700)]: {
            display: "table-cell",
        }
    },
    subIcon: {
        width: 40,
        height: 40,
        margin: 10
    },
    subText: {},
    summary: {
        width: "100%",
        padding: 20
    },
    expandCell: {
        padding: 8
    },
    middleDetailsCell: {
        margin: 24,
        marginTop: 0,
        [theme.breakpoints.down(880)]: {
            margin: 0,
            marginBottom: 24
        }
    }
}));


const SearchPanel= ({country, changeCountry, regions, region, changeRegion, dateFrom, changeDateFrom, dateTo, changeDateTo  })=>{
    const classes = useStyles();

    return  <Grid container>
        <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl} style={{minWidth: 140}}>
                <InputLabel shrink={true} htmlFor="country-open">Country</InputLabel>
                <Select label={"Country"}
                        inputProps={{
                            name: 'country',
                            id: 'country-open',
                        }}
                        value={country}
                        onChange={changeCountry}>
                    <MenuItem value={"US"}>United States</MenuItem>
                    <MenuItem value={"CA"}>Canada</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl} style={{minWidth: 140}}>
                <InputLabel shrink={true} htmlFor="region-open">Region</InputLabel>
                <Select label={"Region"}
                        inputProps={{
                            name: 'region',
                            id: 'region-open',
                        }}

                        value={region}
                        onChange={changeRegion}>
                    <MenuItem value={"all"}>All</MenuItem>
                    {regions && regions.map(x=>{
                        return <MenuItem key={x.shortCode} value={x.shortCode}>{x.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
            <TextField
                id="date"
                label="Date From"
                type="date"
                value={moment( dateFrom ).format("YYYY-MM-DD")}
                onChange={changeDateFrom}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    style:{  colorScheme: "dark"}
                }}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
            <TextField
                id="date"
                label="Date To"
                type="date"
                value={moment( dateTo ).format("YYYY-MM-DD")}
                onChange={changeDateTo}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}

                inputProps={{
                    style:{  colorScheme: "dark"}
                }}
            />
        </Grid>

    </Grid>
}


const Row = observer ( function Row({ row, showTickets=false }) {
    const {t} = useTranslation("customers");
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();
    const {authStore} = useStores();
    let prefix;
    if ( authStore.subscriptions.hasSuperAppPlus ){
        prefix = "/royalstarfish"
    } else if (  authStore.isStarfish  ){
        prefix = "/starfish"
    }

    return (
        <React.Fragment>
            <TableRow className={classes.rowRoot}>
                <TableCell className={classes.expandCell}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{moment(row.eventDate).format("LL")}</TableCell>
                <TableCell>{row.eventCity+ ', ' + row.state}</TableCell>
                <TableCell>{row.speakerName}</TableCell>
                <TableCell>{row.speaker2Name}</TableCell>
                <TableCell>
                    <IconButton target={"_blank"} href={"https://maps.google.com/?daddr=" + row.eventAddress + ' ' + row.eventCity + ', ' + row.state + ' ' + row.eventZip}>
                    <CarIcon/>
                    </IconButton>
                    <IconButton target={"_blank"}  href={"sms:?&body=" + row.eventAddress + ' ' + row.eventCity + ', ' + row.state + ' ' + row.eventZip} >
                        <EmailIcon/>
                    </IconButton>
                    {showTickets &&
                        <IconButton onClick={ e=>{ history.push( prefix + "/members/events/purchase_tickets")}}>
                        <TicketIcon/>
                    </IconButton>}

                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>

                        <Grid container>
                            <Grid item sm={12} md={9}>
                                <strong>{row.eventVenue}</strong><br/>
                                {row.eventAddress}<br/>
                                {row.eventCity}, {row.state} {row.eventZip} <br/><br/>

                                Date: {moment.utc(row.eventDate).format("MM/DD/YYYY")}<br/>
                                Time: {moment.utc(row.eventDate).format("hh:mm a")}<br/>
                                Cost: ${row.eventCost} (USD)<br/><br/>

                                Please remember: Outside food and beverage are not permitted. No children under 12
                                allowed,
                                including infants. Thank you.<br/>
                                <br/>
                            </Grid>
                            <Grid item sm={12} md={3}>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        target={"_blank"}
                                        href={"https://maps.google.com/?daddr=" + row.eventAddress + ' ' + row.eventCity + ', ' + row.state + ' ' + row.eventZip}
                                        startIcon={<CarIcon/>}>{t("directions")}</Button>
                                {" "}
                                <Button variant={"contained"}
                                        color={"primary"}
                                        target={"_blank"}  href={"sms:?&body=" + row.eventAddress + ' ' + row.eventCity + ', ' + row.state + ' ' + row.eventZip}
                                        startIcon={<EmailIcon/>}>{t("share")}</Button> {" "}
                                {showTickets && <Button variant={"contained"}
                                        color={"primary"}
                                       onClick={ e=>{ history.push( prefix + "/members/events/purchase_tickets")}}
                                        startIcon={<TicketIcon/>}>{t("buy_tickets")}</Button>}
                            </Grid>
                            <Grid item xs={12}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src={row.speaker1PictureURL}>
                                            {row.speakerName?.substring(0, 1)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={row.speakerName} secondary={<div dangerouslySetInnerHTML={{__html:htmlDecode(row.speaker1Bio) }}></div>}/>
                                </ListItem>

                                {row.speaker1LastName !== row.speaker2LastName && <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src={row.speaker2PictureURL} >
                                            {row.speaker2FirstName?.substring(0,1)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={row.speaker2FirstName+ " " + row.speaker2LastName } secondary={<div dangerouslySetInnerHTML={{__html:htmlDecode(row.speaker2Bio) }}></div>}/>
                                </ListItem>}
                            </Grid>




                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
})



const EventsSchedule = () => {
    const classes = useStyles();
    const {t} = useTranslation("events");
    const {authStore, eventsScheduleStore, commonStore, userProfileStore} = useStores();
    const {error, data, dataLoading} = eventsScheduleStore;
    const [effectiveTab, setEffectiveTab] = React.useState("open");
    const [country, setCountry] = React.useState(authStore.currentUser?.country || "US")
    const [region, setRegion] = React.useState(authStore.currentUser?.address?.region || "")
    const [openDateFrom, setOpenDateFrom] = React.useState(null)
    const [openDateTo, setOpenDateTo] = React.useState(null)
    const [seminarDateFrom, setSeminarDateFrom] = React.useState(null)
    const [seminarDateTo, setSeminarDateTo] = React.useState(null)
    const [initialized, setInitialized] = React.useState(false)

    useEffect(() => {
        userProfileStore.loadRegionsByCountry(country);
    }, []);

    const regions = useMemo(
      ()=>{
          return userProfileStore.regionsByCountry[country]
      }, [userProfileStore.regionsByCountryLoading, userProfileStore.regionsByCountry, country]
    )


    const texts = useMemo(() => {
        if (!data) return null;
        return data?.definition[commonStore.language];
    }, [data?.definition, commonStore.language]);


    useEffect(() => {
        void eventsScheduleStore.loadData( {country, region, openDateFrom, openDateTo, seminarDateFrom, seminarDateTo} );
    }, [country, region, openDateFrom, openDateTo, seminarDateFrom, seminarDateTo ]);

    useEffect(() => {
        if (data && !initialized) {
          setOpenDateFrom(new Date(data.openStart) )
          setOpenDateTo(new Date(data.openEnd))
          setSeminarDateFrom(new Date(data.seminarStart))
          setSeminarDateTo(new Date(data.seminarEnd))
          setInitialized(true);


        }

    },[data])

    const handleTabChange = (event, newValue) => {
        setEffectiveTab(newValue)
    }

    const changeCountry = (e)=>{
         setCountry(e.target.value)
         setRegion("all")
         if( userProfileStore.regionsByCountry[country].length === 0)
            userProfileStore.loadRegionsByCountry(e.target.value);
    }

    const changeRegion = (e)=>{
        setRegion(e.target.value)
    }

    const showPendingInfoPopup = (type) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        let text;
        switch (type) {
            case "open":
                text = texts?.whatisopenmeeting;
                break;
            case "seminar":
                text = texts?.whatareseminars;
                break;
            case "special":
                text = texts?.whatarespecialevents;
                break;
        }

        commonStore.showConfirm(<p className={clsx("html", classes.popupText)} dangerouslySetInnerHTML={{__html:text}}></p>
            , "Close"
            , t("whatis_" + type)
            , null
            , null
            , undefined
            , {hideHeader: false})
    }

    return  !initialized
        ? <CircularProgress/>
    :<>
        <ErrorMessage message={error}></ErrorMessage>
        <Box className={"html"} dangerouslySetInnerHTML={{
            __html: texts?.text
        }}>

        </Box>


        <TabContext value={effectiveTab}>
            <Tabs onChange={handleTabChange} value={effectiveTab} color={"primary"} indicatorColor="primary">
                <Tab color={"primary"} label={<Box whiteSpace={"nowrap"} display={"flex"} alignItems={"center"}>
                    {t("open")}
                    <InfoIcon onClick={showPendingInfoPopup("open")} style={{cursor: "pointer", marginLeft: 10}}/>
                </Box>} value="open" selected={"open" === effectiveTab}/>
                <Tab color={"primary"} label={<Box whiteSpace={"nowrap"} display={"flex"} alignItems={"center"}>
                    {t("seminar")}
                    <InfoIcon onClick={showPendingInfoPopup("seminar")} style={{cursor: "pointer", marginLeft: 10}}/>
                </Box>
                } value="seminar" selected={"seminar" === effectiveTab}/>
                <Tab color={"primary"} label={<Box whiteSpace={"nowrap"} display={"flex"} alignItems={"center"}>
                    {t("special")}
                    <InfoIcon onClick={showPendingInfoPopup("special")} style={{cursor: "pointer", marginLeft: 10}}/>
                </Box>} value="special" selected={"special" === effectiveTab}/>
            </Tabs>

            <TabPanel value={"open"} className={classes.tab}>
                {dataLoading
                    ? <CircularProgress/>
                : <TableContainer>
                        <SearchPanel
                            country={country}
                            changeCountry={changeCountry}
                            regions={regions}
                            region={region}
                            changeRegion={changeRegion}
                            dateFrom={openDateFrom}
                            changeDateFrom={(e)=>setOpenDateFrom(e.target.value)}
                            dateTo={openDateTo}
                            changeDateTo={(e)=>setOpenDateTo(e.target.value)}
                        />
                    <br/>   <br/>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={30}/>
                                <TableCell>Date</TableCell>
                                <TableCell>{t("location")}</TableCell>
                                <TableCell>{t("speaker1")}</TableCell>
                                <TableCell>{t("speaker2")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.openEvents && data.openEvents.map((x) => <Row row={x}></Row>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </TabPanel>
            <TabPanel value={"seminar"}>
                <SearchPanel
                    country={country}
                    changeCountry={changeCountry}
                    region={region}
                    regions={regions}
                    changeRegion={changeRegion}
                    dateFrom={seminarDateFrom}
                    changeDateFrom={(e)=>setSeminarDateFrom(e.target.value)}
                    dateTo={seminarDateTo}
                    changeDateTo={(e)=>setSeminarDateTo(e.target.value)}
                />
                <br/> <br/>
                {dataLoading
                    ? <CircularProgress/>
                    :<TableContainer>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={30}/>
                                <TableCell>Date</TableCell>
                                <TableCell>{t("location")}</TableCell>
                                <TableCell>{t("speaker1")}</TableCell>
                                <TableCell>{t("speaker2")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.seminarEvents && data.seminarEvents.map((x) => <Row row={x} showTickets={true}></Row>)}
                        </TableBody>
                    </Table>
                </TableContainer>}

            </TabPanel>
            <TabPanel value={"special"}>
                <Box className={"html"} dangerouslySetInnerHTML={
                    {__html: texts?.specialeventtext}
                }></Box>
            </TabPanel>

        </TabContext>
    </>


}


export default observer(EventsSchedule);