import {makeAutoObservable} from 'mobx'
import services from "../services"
import {flatten, uniqBy} from "lodash";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadList} from "../utils/mobx";

class SpeakerStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    series = []
    seriesLoading = false
    mediaType = false
    speakerId = null

    error = null

    setError(error, type = "series") {
        error = extractErrorMessage(error)
        this.error = error
    }

    setMediaType( mediaType ){
        this.mediaType = mediaType
    }


    loadSeries = generateLoadList( "series", this, "seriesLoading"
        , async (id)=>{
            if ( this.libraryStore.library.length ===0 )
                await this.libraryStore.loadLibrary();
            this.speakerId = id;
            return services.Library.loadSpeaker(id)
        }, "series", "media")



    get audios(){
        if (!this.series)  return []
        return this.series.filter( x=> x.mediaType==="audio" && ( !x.files || x.files.length === 0) )

    }

    get videos(){
        if (!this.series)  return []
        return this.series.filter( x=> x.mediaType==="video" && ( !x.files || x.files.length === 0) )

    }

    get books(){
        if (!this.series)  return []
        return this.series.filter( x=> x.mediaType==="book" && ( !x.files || x.files.length === 0) )
    }

    get packs(){
        if (!this.series)  return []
        return this.series.filter( x=>  ( x.files &&  x.files.length > 0) )
    }


    get coSpeakers(){
        if (!this.series)  return []
        return uniqBy( flatten( this.series.map(x=>(x.speakers||[])) ), 'speakerID').filter(x=>x.speakerID!=this.speakerId)
    }



    get sections(){
        let result =[];
        if ( this.audios.length>0 )
            result.push( { mediaType:"audio", title :"Audio"})
        if ( this.videos.length>0 )
            result.push( { mediaType:"video", title :"Video"})
        if ( this.books.length>0 )
            result.push( { mediaType:"book", title :"eBook"})
        if ( this.packs.length>0 )
            result.push( { mediaType:"pack", title :"Packs"})
        if ( this.coSpeakers.length>0 )
            result.push( { mediaType:"speaker", title :"Co-Speakers"})
        return result
    }

}

export default SpeakerStore