import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Redirect, useParams} from "react-router";
import services from "../../services";
import {extractErrorMessage, joinPath} from "../../utils/helpers";
import {Alert} from "@material-ui/lab";
import {CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";


function SubscriptionCategoryRedirect({shoppingMatch}){
    const params = useParams();
    const categoryID = useMemo( ()=>{
        return parseInt(params.categoryID, 10);
    }, [params])

    const [loading, setLoading] = React.useState(false)
    const [productSKU, setProductSKU] = React.useState(null)
    const [error, setError] = React.useState(null)
    const fetchIdRef = React.useRef(0)

    const fetchData = React.useCallback( async (categoryId)=>{
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current
        setLoading(true)
        setProductSKU( null )
        try {
            let result = await services.Subscription.getProductSKUbyCategory(categoryId)
            if (fetchId === fetchIdRef.current) {
                setLoading( false )
                if ( result.productSKU )
                    setProductSKU( result.productSKU )
                else {
                    setProductSKU(null)
                    setError("Already subscribed to that option" )
                }
            }
        }
        catch(e){
            if (fetchId === fetchIdRef.current) {
                setLoading( false )
                setProductSKU( null )
                setError( extractErrorMessage( e ) )
            }
        }
        finally{
            setLoading( false )
        }
    }, [])



    useEffect(()=>{
        if ( categoryID )
            fetchData(categoryID)
    },[categoryID])

    return <>
        {(!loading && productSKU) && <Redirect to={joinPath(shoppingMatch.url, "/product-detail", productSKU )} /> }
        { error  && <Alert color={"error"} variant={"filled"} action={
            <Button
                variant="contained"
                color={"primary"} href={joinPath(shoppingMatch.url, "manage")}>Manage Subscriptions</Button>
        }>{error} </Alert>}
        {loading && <CircularProgress/> }
    </>

}

export default observer(SubscriptionCategoryRedirect)