import React from "react";
import {Box, TableCell, TableRow} from "@material-ui/core";
import clsx from "clsx";
import {formatNumber} from "../../../utils/helpers";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    summaryGrid:(props)=>({
        fontWeight: "bold",
        display: "inline-grid",
        gridTemplateColumns: "2fr 1fr",
        gridGap: "0.5em",
        fontSize: props.size==="small"?"1em":"2em",
    }),
    "header":{
        gridColumnStart: 1,
        gridColumnEnd: 3,
        justifySelf: "center",
    },
    "label":{
        textAlign: "right",
        paddingRight: "1em",
        justifySelf:"end"
    },
    "value":{
        textAlign: "center",
        paddingRight: "1em",
        justifySelf:"end"
    },
}))


function SummaryRows ({ t, subTotal, shippingTax, total, pv, size="large", showHeader=true }){
    const classes = useStyles({size:"small"});
    return <>
        <Box className={classes.summaryGrid}>
            {showHeader && <span className={classes.header}>{t("transaction_summary")}</span>}
            <span className={classes.label}>{t("subtotal")} ($):</span>
            <span className={classes.value}>{formatNumber(subTotal, 2)}</span>
            <span className={classes.label}>{t("shtx")} ($):</span>
            <span className={classes.value}>{formatNumber(shippingTax, 2)}</span>
            <span className={classes.label}>{t("total")} ($):</span>
            <span className={classes.value}>{formatNumber( total, 2 )}</span>
            <span className={classes.label}>{t("point_value")} (PV):</span>
            <span className={classes.value}>{formatNumber( pv, 2 )}</span>
        </Box>
    </>
}

export default SummaryRows;