import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {useStores} from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import OwlCarousel from "react-owl-carousel2";
import 'react-owl-carousel2/lib/styles.css';
import '../css/owl.scss';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Button from "@material-ui/core/Button";
import {useLocalObservable} from "mobx-react-lite";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import last from "lodash/last";
import first from "lodash/first";
import url from "url";

const useStyles = makeStyles((theme) => ({
    root:{
        color:"white",
        width:290,
        height:518,
        position: "relative"
    },
    navContainer:{
        position: "absolute",
        bottom: 0,
        width: "100%",
        display:"flex",
        justifyContent:"space-between",
        padding:10
    },

    cardBody:{
        height:518,
        paddingTop: 230,
        display:"flex",
        flexDirection:"column",
        alignItems:"center"

    },
    divider:{
        width:200,
        backgroundColor: "rgba(255, 255, 255, 0.92)"
    },
    description:{
        whiteSpace:"initial",
        textAlign:"center",
        padding:10

    },
    playerContainer:{
        width:"100%",
        height:"100%",
        margin:"auto",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position:"relative"
    },
    previewCloseButton:{
        position:"absolute",
        top:20,
        right:20
    },

    player:{

        [theme.breakpoints.up('md')]: {
            height:480
            },
    }
}));



function CustomerOnboardingModal({}){
    const ref = React.useRef("onboard");
    const classes = useStyles();
    const {onboardStore} = useStores();
    const [mediaURL, setMediaURL]= useState(null);
    const {cardsLoading, cards} = onboardStore;
    const theme = useTheme();

    const isSM= useMediaQuery(theme.breakpoints.down('sm'));
    const isXs= useMediaQuery(theme.breakpoints.down('xs'));

    const width = useMemo(()=>{
        if ( isXs ) return window.innerWidth

    }, [isXs])

    const height = useMemo(()=>{
        if ( width ) return width/1.33
    }, [width])


    useEffect(()=>{
        onboardStore.loadCards()
    }, []);

    const localStore = useLocalObservable(() => ({
        position: 0,
        cards,

        setPosition(value){
            this.position = value
        },

        get isFirst() {
            if (this.cards.length===0) return true;
            return this.position === 0
        },

        get isLast() {
            if (this.cards.length===0) return true;
            return this.position === (this.cards.length-1)
        }
    }));

    const options = {
        items: 1,
        nav: false,
        center: true,
        //autoWidth:true,
        rewind: true,
        autoplay: true,
    };

    const events = {
        onDragged: function(event) {},
        onChanged: function(event) {
           if( event.item.count >0)
               localStore.setPosition ( event.item.index )
        }
    };
    const handleWatchVideo =(item) =>{
        let videoUrl = item.mediaURL
        let parsedUrl = url.parse(item.mediaURL)
        if ( parsedUrl.pathname.toLowerCase().endsWith(".mp4") ){
            let id = first( last( parsedUrl.pathname.split("/") ).split(".") )
            videoUrl = `https://player.vimeo.com/video/${id}?app_id=122963`
        }
        setMediaURL(videoUrl)
    }

    const closePreview =(item) =>{
        setMediaURL(null)
    }



    return <div className={classes.root}>
        {cardsLoading ?<CircularProgress/>:
            ((cards.length>0) && <OwlCarousel ref={ref} options={options} events={events} className="dots-over" >
                {cards.map((card,i)=>{
                    return <div className={classes.card} key={`card-${i}`} >
                        <div className={classes.cardBody} style={{backgroundImage:`url(${card.imageURL})`}}>
                            <Typography  variant="h5">{card.title}</Typography>
                            <Divider className={classes.divider}/>
                            <div className={classes.description}>{card.description}</div>

                            { card.mediaURL &&<Button onClick={e=>{ handleWatchVideo(card)}}
                                variant= "text"
                                className={classes.button}
                                startIcon={<PlayArrow />}
                            >Watch video</Button>}
                        </div>
                    </div>
                })}
            </OwlCarousel>)}
        <div className={classes.navContainer}>
            { ( localStore.isFirst) ? <span></span>: <Button variant={"text"} onClick={() => ref.current.prev()}>prev</Button>}
            { ( localStore.isLast ) ? <span></span>: <Button variant={"text"} onClick={() => ref.current.next()}>next</Button>}
        </div>
        {mediaURL && <Dialog open={true}  fullScreen={ true } onClose={closePreview}>
            <div className={classes.playerContainer}>
                <IconButton
                    aria-label="close"
                    className={classes.previewCloseButton}
                    onClick={closePreview}
                >
                    <CloseIcon />
                </IconButton>
                <iframe className={classes.player}
                        title="Video Preview"
                        id="iframeId"
                        width={width?width:"100%"}
                        height={height}
                        frameBorder={0}
                        allow="autoplay; fullscreen"
                        allowfullscreen
                        src={mediaURL}></iframe>
            </div>

        </Dialog>
   }
    </div>
}


export default observer(CustomerOnboardingModal)