import React from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../utils/styles";
import clsx from "clsx";
import {observer} from "mobx-react";
import {useShopType} from "../../../containers/shop/useShopType";
import {joinPath} from "../../../utils/helpers";


const useStyles = makeStyles((theme) => ({
  category:{
    width:"100%",
    height: 140,
    borderRadius: 12,
    cursor: "pointer",
  },
  professional: {
    backgroundColor: colors.colorCategoryProfessional
  },

financial: {
  backgroundColor: colors.colorCategoryFinancial
},

personal: {
  backgroundColor: colors.colorCategoryPersonal
},

subscription:{
  backgroundColor: colors.colorCategorySubscription
},

marketplace: {
  backgroundColor: colors.colorCategoryMarketplace
},

merchandise: {
  backgroundColor: colors.colorCategoryMerchandise
},

promogear: {
  backgroundColor: colors.colorCategoryPromogear,
},

salesaid: {
  backgroundColor: colors.colorCategorySalesaid
},

lifetraining: {
  backgroundColor: colors.colorCategoryLifetraining
},

autoshippro: {
  backgroundColor: colors.colorCategoryAutoship
}
}))


function  HomeListContainerItem({category, onAutoShipClick }) {
  const history =  useHistory()
  const classes =  useStyles();
  const {  t } = useTranslation();
  const shopParams = useShopType()


  const onMainCategoryClick = (category) => {
    if (category.tag === "autoshippro") {
      return onAutoShipClick()
    }

    if (category.parentCategoryID === null) {
      window.location.href = 'https://www.costore.com/life?oib=1';
      return
    }
    history.push( joinPath( shopParams.home, `category/${category.parentCategoryID}`))
  }


    if (!category.hidden) {
      return (
        <Grid
            container item
          md={4}
          xs={6}
          key={category.parentCategoryName}
        >
          <Grid   container item justifyContent={"center"} alignItems={"center"} onClick={() => onMainCategoryClick(category)}
            className={clsx( classes.category,  classes[category.parentCategoryName])}
          >
            <h3>{t(`shopping:categories.${category.parentCategoryName}`)}</h3>
          </Grid>
        </Grid>
      )
    }
    return ''

}

export default  observer(HomeListContainerItem)