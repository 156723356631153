import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        padding: 20,
        borderRadius: 5,
        outline: "none",
        textAlign: 'center'
    }
}))

const InfoModal = ({ open, onClose, title, message, confirmButtonText }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            {title &&
                <DialogTitle>
                    {title}
                </DialogTitle>
            }
            {message &&
                <DialogContent className={classes.modalContainer}>
                    {message}
                </DialogContent>
            }
            <DialogActions>
                <Button
                    fullWidth
                    color={"primary"}
                    variant={"contained"}
                    onClick={onClose} >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InfoModal;