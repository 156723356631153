import {Table, TableBody, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "./TableCell";
import {formatNumberOrZero} from "../../../utils/helpers";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {accordion} from "../../../components/styles";


const useStyles = makeStyles((theme) => ({
    smallerCenteredTable: {
        width: "60%",

        [theme.breakpoints.down('lg')]: {
            width: "70%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    }

}));

function BonusTable({dataSet, qualified, t, showPercent = true, showPV = true}) {

    const classes = useStyles();

    return <>
        {dataSet &&
            <Table className={classes.smallerCenteredTable} stickyHeader size="small" style={{marginBottom: 20}}>
                <TableHead>
                    <TableRow>
                        {showPV && <TableCell align={"right"}>{t('pv')}</TableCell>}
                        {showPercent && <TableCell align={"right"}>{t('bonus_percentage')}</TableCell>}
                        <TableCell align={"right"}>{t('bonus_amount')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!qualified
                        ? <TableRow>
                            <TableCell colSpan={3} align={"center"}>{t('not_qualified')}</TableCell>
                        </TableRow>
                        : dataSet.map((item, index) => {
                            return <TableRow key={index}>
                                {showPV && <TableCell align={"right"}>{formatNumberOrZero(item.GroupPV, 2)}</TableCell>}
                                {showPercent && <TableCell
                                    align={"right"}>{formatNumberOrZero(item.BonusPercent * 100, 2)}%</TableCell>}
                                <TableCell align={"right"}>${formatNumberOrZero(item.Bonus, 2)}</TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>}
    </>
}

export default BonusTable;