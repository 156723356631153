import RestService from "./RestService";

export default class ResetPasswordService extends RestService {
  constructor() {
    super("/accountrecovery");
  }

  async validateToken(params) {
    return this.post(`${this.baseUrl}/validateResetPasswordToken`, params);
  }

  async resetPassword(params) {
    return this.post(`${this.baseUrl}/resetPasswordByToken`, params);
  }
}
