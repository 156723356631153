import RestService from "./RestService";



export default class BaseLoad extends RestService {
    constructor() {
        super('/api/lifeapp' )
    }

}
