import React from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import greetingTime from "greeting-time";
import {useTranslation} from "react-i18next";
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({

    balanceLabel: {
        fontSize: 11,
        color: "white",
        textAlign: "center",
        whiteSpace:"nowrap"
    },
    balance: {
        paddingTop: 12,
        fontSize: 19,
        color: "white",
        textAlign: "center",
        [theme.breakpoints.down(340)]: {
            fontSize: 14,
        }
    },

    greeting:{
        fontSize: 22,
        textAlign: "center",
        borderBottom: "1px solid white",
        padding: 12
    },
    description:{
        textAlign: "center",
        fontSize:10,
        fontStyle:"italic",
        cursor:"pointer",
        borderLeft:"none !important",
    },
    line:{

    },
    box:{
        padding: "10px 0",
        minWidth: "70px",
        "& >div":{
            padding:10,
            borderLeft:"solid 1px rgba(230, 230, 230, 0.4)",
            borderBottom:"solid 1px rgba(230, 230, 230, 0.4)"
        },
        "& >div:nth-child(odd)":{
            borderLeft:"none"
        },
        "& >div:nth-child(1n+3)":{
            borderBottom:"none"
        }
    },
}));

export default observer(({user, statistics, currentBalance, lifetimePerks, isPhone, variant="line"}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step) => {
        console.log(step)
        setActiveStep(step);
    };

    const mdSize = (variant==="line")? 3 : 6
    const panelClass = (variant==="box")? classes.box: classes.line

    return <>
        {user&& <Grid item xs={12} className={classes.greeting} >
            {t(greetingTime(new Date()))} {user.firstName}
        </Grid>}
        <SwipeableViews   axis='x'
                          index={activeStep}
                          onChangeIndex={handleStepChange}
                          enableMouseEvents
                        style={{height: isPhone ? '100%' : 'auto'}}>
            <Grid container className={panelClass}>
                <Grid item sm={mdSize} xs={4} >
                    <div className={classes.balance}>
                        {currentBalance}
                    </div>
                    <div className={classes.balanceLabel}>
                        Available Balance
                    </div>
                </Grid>
                <Grid item sm={mdSize} xs={4}>
                    <div className={classes.balance}>
                        {lifetimePerks}
                    </div>
                    <div className={classes.balanceLabel}>
                    Lifetime Perks
                    </div>
                </Grid>
                <Grid item sm={12} xs={4} >
                        <div className={classes.balance}>
                            {Math.trunc(statistics.wallet.sats.balance)}
                        </div>
                        <div className={classes.balanceLabel}>
                            sats
                        </div>
                    </Grid>
                <Grid item xs={12} className={classes.description} onClick={e=>{handleStepChange(1)}}>
                    Click to view lifetime amounts
                </Grid>
            </Grid>

            <Grid container className={panelClass}>
                <Grid item sm={mdSize} xs={4} >
                    <div className={classes.balance}>
                        {currentBalance}
                    </div>
                    <div className={classes.balanceLabel}>
                        Available Balance
                    </div>
                </Grid>
                <Grid item  sm={mdSize} xs={4}>
                    <div className={classes.balance}>
                        {lifetimePerks}
                    </div>
                    <div className={classes.balanceLabel}>
                    Lifetime Perks
                    </div>
                </Grid>
                <Grid item  sm={12} xs={4} >
                    <div className={classes.balance}>
                        {Math.trunc(statistics.wallet.sats.earnedLifetime)}
                    </div>
                    <div className={classes.balanceLabel}>
                        sats
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.description} onClick={e=>{handleStepChange(0)}}>
                    Click to view current amounts
                </Grid>
            </Grid>

        </SwipeableViews>
    </>
})


