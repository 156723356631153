import {makeAutoObservable} from 'mobx'
import services from "../services"
import {flatten, uniqBy} from "lodash";
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadList} from "../utils/mobx";
import {isAudio, isBook, isVideo, isPack} from "../utils/mediaUtils";

class SeriesStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    series = []
    seriesLoading = false
    mediaType = false

    error = null

    setError(error, type = "series") {
        error = extractErrorMessage(error)
        this.error = error
    }

    setMediaType( mediaType ){
        this.mediaType = mediaType
    }

    get freeAudiosForMSBasic(){
       return this.audios.filter( x=>x.seriesValue ==="LT" ).slice(0,8)
    }

    get freeAudiosForMSBasicSKUs(){
        return this.freeAudiosForMSBasic.map( x=>x.sku )
    }

    loadSeries = generateLoadList( "series", this, "seriesLoading"
        , async (id)=>{
            if ( this.libraryStore.library.length ===0 )
                await this.libraryStore.loadLibrary();
            return services.Library.loadSeries(id)
        }, "series", "media")



    get audios(){
        if (!this.series)  return []
        return this.series.filter( isAudio )

    }

    get videos(){
        if (!this.series)  return []
        return this.series.filter( isVideo )

    }

    get books(){
        if (!this.series)  return []
        return this.series.filter( isBook )
    }

    get speakers(){
        if (!this.series)  return []
        return uniqBy( flatten( this.series.map(x=>(x.speakers||[])) ), 'speakerID')
    }

    get packs(){
        if (!this.series)  return []
        return this.series.filter( isPack )
    }

    get sections(){
        let result =[];
        if ( this.audios.length>0 )
            result.push( { mediaType:"audio", title :"Audio"})
        if ( this.videos.length>0 )
            result.push( { mediaType:"video", title :"Video"})
        if ( this.books.length>0 )
            result.push( { mediaType:"book", title :"eBook"})
        if ( this.packs.length>0 )
            result.push( { mediaType:"pack", title :"Packs"})
        if ( this.speakers.length>0 )
            result.push( { mediaType:"speaker", title :"Speakers"})
        return result
    }

}

export default SeriesStore