import RestService from "./RestService";



export default class News extends RestService {
    constructor() {
        super('/api/lifeapp/news');
    }

    async getNews(fromModule) {
        if (fromModule) {
            return this.get(`${this.baseUrl}/?type=${fromModule}`);
        } else {
            return this.get(`${this.baseUrl}/`);
        }
    }

    async getDetails(id, fromModule) {
        if (fromModule) {
            return this.get(`${this.baseUrl}/${id}?type=${fromModule}`);
        } else {
            return this.get(`${this.baseUrl}/${id}`);
        }
    }
}
