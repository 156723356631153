import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link, Typography} from "@material-ui/core";
import {formatNumber} from "../../../utils/helpers";
import Variance from "./Variance";



const useStyles = makeStyles((theme) => ({
    cellText:{
        alignSelf: "center",
        lineHeight: "25px",
        cursor: "pointer"
    },
    cellNumber:{
        alignSelf: "center",
        justifySelf:"flex-end",
        lineHeight: "25px",
    },

}));

function PanelRow({handleProcessedClick, label, value, varValue, reverseColor = false}){
    const classes = useStyles();

    return <>
        <Link  className={classes.cellText} onClick={handleProcessedClick}><Typography noWrap variant={"body1"}>{label}</Typography></Link>
        <Typography className={classes.cellNumber} variant={"body1"}>{formatNumber(value)}</Typography>
        <Typography className={classes.cellNumber} variant={"body1"}>
            {varValue && <Variance amount={varValue} reverseColor={reverseColor}/>}
        </Typography>

    </>
}

export default PanelRow;