import RestService from "./RestService";

export default class ConventionProgramServicePublic extends RestService {
  constructor() {
    super('/public/convention');
  }

  async fetchConventionProgramLoginInfo() {
    return this.get(`${this.baseUrl}/site`);
  }
}
