import IdLessRestService from "./IdLessRestService";


export default class UsernameService extends IdLessRestService {
    constructor(parentService) {
        if (parentService) {
           super(`${parentService.baseUrl}/username`)
        }
        else
            throw new Error("Can be only nested service")
    }

    async validateUserName(username ){

        return this.get(`${this.baseUrl}/validate`, {username })
    }


    async validateUserNameEnroll(username ){

        return this.post(`/public/enroll/customer/email/validate`, {email:username })
    }
}
