import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';


export default observer(({
    field,
    ...rest
}) => {
    const { t } = useTranslation();
    return <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth
                openTo={"year"}
                views={["year", "month", "date"]}
                disableToolbar
                variant="inline"
                margin="normal"
                placeholder="DD.MM.YYYY"
                format="DD.MM.yyyy"
                label={t(field.label)}
                value={field.value || null}
                onChange={field.onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    </div>;
}
);
