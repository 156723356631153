import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import IFrame from "../../components/IFrame";
import { joinPath } from "../../utils/helpers";
import UserProfile from "./UserProfile";
import ShippingInfo from "./ShippingInfo";
import BillingInfo from "./BillingInfo";
import Security from "./Security";
import Help from "./Help";
import Policies from "./Policies";
import Transactions from "./Transactions";
import RegistrationInfo from "./RegistrationInfo";

export default observer(function Wallet() {
    const profileMatch = useRouteMatch();

console.log(joinPath(profileMatch.url, `personalinfo`))
    return <Switch>

        <Route path={joinPath(profileMatch.url, `personalinfo`)} render={props => {
            return <UserProfile />;
        }} />

        <Route path={joinPath(profileMatch.url, `shippinginfo`)} render={props => {
            return <ShippingInfo />;
        }} />

        <Route path={joinPath(profileMatch.url, `billinginfo`)} render={props => {
            return <BillingInfo />;
        }} />

        <Route path={joinPath(profileMatch.url, `security`)} render={props => {
            return <Security />;
        }} />

        <Route path={joinPath(profileMatch.url, `transactionhistory`)} render={props => {
            return <Transactions />;
        }} />
        <Route path={joinPath(profileMatch.url, `transactions`)} render={props => {
            return <Transactions />;
        }} />

        <Route path={joinPath(profileMatch.url, `help`)} render={props => {
            return <Help />;
        }} />
        <Route path={joinPath(profileMatch.url, `policies`)} render={props => {
            return <Policies />;
        }} />
        {/* <Route path={joinPath(profileMatch.url, `transactions`)} render={props => {
            return <IFrame tag={"profile/transactions"} />;
        }} /> */}
        <Route path={joinPath( profileMatch.url, `registrationinfo`)}  render={props=>{
            return <RegistrationInfo  />
        }} />
        <Route path={joinPath(profileMatch.url, `registrationinfo`)} render={props => {
            return <IFrame tag={"profile/registrationinfo"} />;
        }} />
        <Route path={joinPath(profileMatch.url, `mytrips`)} render={props => {
            return <IFrame tag={"profile/mytrips"} />;
        }} />
        <Route path={joinPath(profileMatch.url, `subscriptions`)} render={props => {
            return <IFrame tag={"profile/subscriptions"} />;
        }} />
        <Route path={joinPath(profileMatch.url, `policies`)} render={props => {
            return <IFrame tag={"profile/policies"} />;
        }} />
        <Route path={joinPath(profileMatch.url, `help`)} render={props => {
            return <IFrame tag={"profile/help"} />;
        }} />

    </Switch>;
});