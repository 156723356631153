import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useStores} from "../hooks/use-stores";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    root:  (props) => ({
        display: 'block',
        padding: (props.forIframe && !props.isRoot) ?theme.spacing(2):0,
        marginBottom: ((props.isNativeApp||props.forIframe ) && !props.isRoot)?0:theme.spacing(2),
    })
}));

export default ({isRoot, forIframe, children})=> {
    const { commonStore } = useStores();
    const { isNativeApp } = commonStore;
    const classes = useStyles({ isNativeApp,forIframe, isRoot });
   return <Typography variant="h6" className={classes.root} noWrap>{children}</Typography>
}