import BaseService from "./BaseService";

class Auth extends BaseService {

    verify() {
        return this.post('/verify')
    }

    verifyConventionGuest() {
        return this.post('/verify/convention/guest')
    }

    async signIn(username, password){

        return this.post("/signin", {username, password})
    }

    async conventionSignIn(username, password) {
        return this.post("/signin/conventionNew", { username, password }, { redirect: 'follow' })
    }

    async AndroidSignIn(loginGuid){
        return this.post("/signin/android", {}, {headers: { "X-Authorization": `Bearer ${loginGuid}` }})
    }

    checkGuest( guestToken ) {
        return this.post('/verify/guest', {}, {headers: { "Authorization": `Bearer ${guestToken}` }} )
    }


    async sendVerificationEmail(email, lifenumber) {
        return this.post("/accountrecovery/sendVerificationEmail", {email, lifenumber})
    }

    async sendVerificationText(phonenumber, lifenumber) {
        return this.post("/accountrecovery/sendVerificationText", {phonenumber, lifenumber})
    }
   async contactInfo(accountId ) {
        return this.get(`/accountrecovery/contactInfo/${accountId}` )
    }

    async verifyCode(verificationCode, options ) {
        return this.post("/accountrecovery/verifyCode", {verificationCode}, options)
    }

    async resetPassword(newPassword, options ) {
        return this.post("/accountrecovery/resetPassword", {newPassword}, options)
    }

    async getLTAIToken(){
        return this.get("/api/members/ltai/getLTAIURL")
    }

}

export default Auth
