import React, {useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useStores} from "../../hooks/use-stores";
import PageTitle from "../../components/PageTitle";
import {Card, CardActions, CardHeader, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button"
import {useParams} from "react-router-dom";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {actions, card, statusStyles} from "../../components/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import compact from "lodash/compact";
import range from "lodash/range";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ShippingInfoList from "../Profile/ShippingInfo/ShippingInfoList";
import BillingInfoList from "../Profile/BillingInfo/BillingInfoList";
import {Alert} from "@material-ui/lab";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {cardLogo} from "../../utils/helpers";


const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
        minHeight: "100vh",
        background: "black",
        color: "white"
    },
    ...card(theme),
    ...actions(theme),
    ...statusStyles(theme),
    dropDown: {
        marginLeft: 10,
        marginRight: 10,
    },

}));



export default observer(function SubscriptionsList({ parentMatch }) {
    const classes = useStyles();
    const {t} = useTranslation("subscription");
    const { commonStore, subscriptionStore, userBillingInfoStore } = useStores();
    const { categoryID } = useParams();
    const { loadingMySubscriptions, mySubscription, renewingSubscription, reactivatingSubscription,
        updateAddressError, updatePaymentError, updateSubscriptionError, error } = subscriptionStore;
    const { cards, allLoading:cardsLoading } = userBillingInfoStore;
    const [renewalDay, setRenewalDay] = useState();
    const [digitalQty, setDigitalQty] = useState();
    const [physicalQty, setPhysicalQty] = useState();
    const [showUpdateDetails, setShowUpdateDetails] = useState(false);
    const [showEditAddressModal, setShowEditAddressModal] = useState(false);
    const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
    const [selectedAddressID, setSelectedAddressID] = useState();
    const [addressEditing, setAddressEditing] = useState();

    const [selectedPaymentID, setSelectedPaymentID] = useState();
    const [paymentEditing, setPaymentEditing] = useState();


    useEffect(() => {
        (async () => {
            await userBillingInfoStore.loadAll();
        })();
    }, []);

    useEffect(() => {
        if ( categoryID )
            subscriptionStore.loadMySubscriptionInfo(categoryID);
    }, [categoryID]);

    useEffect(() => {
        if ( mySubscription ) {
            setRenewalDay(new Date(mySubscription.info.OrderDate).getDate())
            setDigitalQty(mySubscription.info.QtyDigital)
            setPhysicalQty(mySubscription.info.QtyPhysical)
        }
    }, [mySubscription]);


    const effectivePaymentMethod = useMemo( ()=>{
        if ( mySubscription && cards )
            return cards.find( x=>x.paymentID === mySubscription.info.BillingAddressID )
    }, [cards,mySubscription] )

    const effectivePaymentMethodLogo = useMemo( ()=>{
        if ( mySubscription )
            return effectivePaymentMethod && cardLogo(effectivePaymentMethod.type)
    }, [effectivePaymentMethod] )


    const canRenew = useMemo( ()=>{
        if ( mySubscription && mySubscription.info )
           return  mySubscription.info.PreviousStatus===10
    },[mySubscription])


    const canReactivate = useMemo( ()=>{
        if ( mySubscription && mySubscription.info )
           return ( mySubscription.info.QtyPhysicalCancelled + mySubscription.info.QtyDigitalCancelled )>0
    },[mySubscription])


    const hasPhysical = useMemo( ()=>{
        if ( mySubscription )
            return mySubscription.info.ProductIDPhysical>0
    },[mySubscription])

    const hasDigital = useMemo( ()=>{
        if ( mySubscription )
            return mySubscription.info.ProductIDDigital>0
    },[mySubscription])

    const currentBilling = useMemo( ()=>{
        if ( mySubscription && mySubscription.info.BillingAddressID===-1 )
            return "eWallet"
    },[mySubscription])

    const handleShowUpdateDetails = () => {
        setShowUpdateDetails(true)
    }
    const handleCloseUpdateDetails = () => {
        setShowUpdateDetails(false)
    }

    const handleUpdateDetails = async () => {
        let result = await subscriptionStore.update( categoryID, {physicalQty, digitalQty, renewalDay} )
        if ( result ) {
            if ( typeof  result === "string")
                commonStore.success(result)
            handleCloseUpdateDetails()
            if (categoryID)
                subscriptionStore.loadMySubscriptionInfo(categoryID);
        }
    }

    const handleRunDayChange = (e) => {
        setRenewalDay(e.target.value);
    };

    const handleDigitalQtyChange = (e) => {
        setDigitalQty(e.target.value);
    };

    const handlePhysicalQtyChange = (e) => {
        setPhysicalQty(e.target.value);
    };

    const handleCancelSubscription = () => {
        commonStore.showConfirm(
            t("cancel_confirm.description"),
            t("cancel_confirm.action"),
            t("cancel_confirm.title"),
            ()=>{subscriptionStore.cancelSubscription(categoryID)},
            t("cancel_confirm.cancel")
        )
    };


    const handleRenewSubscription = () => {
        if ( mySubscription )
            commonStore.showConfirm(
                t("renew_confirm.description"),
                t("renew_confirm.action"),
                t("renew_confirm.title"),
                ()=>{
                    let orderId = mySubscription.info.PreviousOrderID;
                    if ( mySubscription.info.OrderDate
                        && mySubscription.info.ShipDate
                        && (mySubscription.info.OrderDate > mySubscription.info.ShipDate) )
                        orderId = mySubscription.info.OrderDate;
                    subscriptionStore.renewSubscription(categoryID, orderId)
                },
                t("renew_confirm.cancel")
            )
    };

    const handleReactivateSubscription = () => {
        if ( mySubscription ) {
            const renewDate  = new Date ( mySubscription.info.OrderDate )
            let renewAt;
            for(let i = 1; i<26; i++ )
            {
                if (new Date(renewDate.getFullYear(), renewDate.getMonth(), i) > new Date() )
                    renewAt = new Date(renewDate.getFullYear(), renewDate.getMonth(), i)
            }
            if ( !renewAt)
                renewAt = new Date(renewDate.getFullYear(), renewDate.getMonth(), 26 )

            commonStore.showConfirm(
                t("reactivate_confirm.description", {
                    price: mySubscription.info.Price
                    , subscription: mySubscription.info.CategoryName
                    , date: moment(renewAt).format("MMMM d")
                }),
                t("reactivate_confirm.action"),
                t("reactivate_confirm.title"),
                () => {
                    subscriptionStore.reactivateSubscription(categoryID)
                },
                t("reactivate_confirm.cancel")
                , {htmlDescription: true}
            )
        }
    };

    const handleOpenShippingEdit = () => {
        setShowEditAddressModal(true);
    };
    const handleHideShippingEdit = () => {
        setShowEditAddressModal(false);
        handleAddressSelected(null)
        handleAddressEditing(null)
    };

    const handleAddressSelected = (addressID) => {
        setSelectedAddressID(addressID)
    };

    const handleAddressEditing = (addressID) => {
        setAddressEditing(addressID!=null)
    };

    const handleUpdateAddress = async () => {
        let result  = await subscriptionStore.updateAddress(categoryID, selectedAddressID)
        if ( result ) {
            if ( typeof  result === "string")
                commonStore.success(result)
            handleHideShippingEdit()
            if (categoryID)
                subscriptionStore.loadMySubscriptionInfo(categoryID);
        }
    };

    const handleOpenBillingEdit = () => {
        setShowEditPaymentModal(true);
        handlePaymentSelected(null)
        handlePaymentEditing(null)
    };

    const handleHidePaymentEdit = () => {
        setShowEditPaymentModal(false);
    };

    const handlePaymentSelected = (payID) => {
        setSelectedPaymentID(payID)
    };

    const handlePaymentEditing = (payID) => {
        setPaymentEditing(payID!=null)
    };

    const handleUpdatePayment = async () => {
        let result  = await subscriptionStore.updatePayment(categoryID, selectedPaymentID)
        if ( result ) {
            if ( typeof  result === "string")
                commonStore.success(result)
            handleHidePaymentEdit()
            if (categoryID)
                subscriptionStore.loadMySubscriptionInfo(categoryID);
        }
    };


    if ( loadingMySubscriptions ===  true)
        return <CircularProgress />
    if ( loadingMySubscriptions ===  false)
        return <>
            <PageTitle>{t("manage_subscription")}</PageTitle>
            {error && <Alert variant={"filled"} color={"error"}>{error}</Alert>}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card  className={classes.card}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}
                                    variant={"rounded"}
                                    src={mySubscription.info.ImageURL}/>
                        }
                        title={mySubscription.info.CategoryName}
                        subheader={ <div className={clsx(classes.status
                            , {
                                [classes.statusActive]: (mySubscription.info.PreviousStatus!==10),
                                [classes.statusDeclined]: (mySubscription.info.PreviousStatus===10),
                            } )}>
                            {(mySubscription.info.PreviousStatus===10)?t("declined"):t("active")}
                        </div>}
                    />
                    <CardContent>
                        <div>{t("price")}: ${mySubscription.info.Price}</div>
                        <div>{t("last_payment_received")}: {moment (mySubscription.info.PreviousOrderDate).format("LL")}</div>
                    </CardContent>
                    <CardActions className={classes.actions} >
                        {(canRenew)&&
                        <Button disabled={renewingSubscription} variant={"contained"} onClick={handleRenewSubscription}
                             color={"primary"} startIcon={ renewingSubscription?<CircularProgress/>:null }>{t("renew")}</Button>}
                        {(canReactivate)&&
                        <Button disabled={reactivatingSubscription} variant={"contained"} onClick={handleReactivateSubscription}
                             color={"primary"} startIcon={ reactivatingSubscription?<CircularProgress/>:null }>{t("reactivate")}</Button>}


                        <Button variant={"contained"} onClick={handleCancelSubscription}
                                color={"secondary"}>{t("cancel_subscription")}</Button>
                    </CardActions>
                </Card>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6} className={classes.cardWrapper} >
                        <Card className={classes.card}>
                            <CardActionArea className={classes.cardMainArea}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h4">
                                        {t("shopping:autoship.shipping.title")}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="h4">
                                        {mySubscription.info.ShippingAddressName}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {mySubscription.info.ShippingAddress1} {mySubscription.info.ShippingAddress2}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {compact([mySubscription.info.ShippingCity, mySubscription.info.ShippingState]).join(", ")} {mySubscription.info.ShippingZip}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {mySubscription.info.ShippingCountry}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button onClick={handleOpenShippingEdit} size="small"
                                        variant={"contained"}
                                        color="primary">
                                    Edit
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={6} className={classes.cardWrapper} >
                        {<Card className={classes.card}>
                            <CardActionArea className={classes.cardMainArea}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h4">
                                        {t("shopping:autoship.billing.title")}
                                    </Typography>

                                    { (mySubscription.info.BillingAddressID !== -1  && cardsLoading)
                                        ?<CircularProgress/>
                                        :<Typography gutterBottom variant="body2" component="h4">
                                            {mySubscription.info.BillingAddressID === -1 && t("shopping:autoship.billing.ewallet")}
                                            { effectivePaymentMethod
                                                && <ListItem>
                                                {effectivePaymentMethodLogo && <ListItemAvatar>
                                                    <img className={classes.logo} src={effectivePaymentMethodLogo} />
                                                </ListItemAvatar>}
                                                <ListItemText primary={`************${effectivePaymentMethod.last4}`}
                                                              secondary={`Expires ${effectivePaymentMethod.expMonth | "00"}/${effectivePaymentMethod.expYear | "00"}`} />
                                            </ListItem>}
                                        </Typography>}
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button onClick={handleOpenBillingEdit} size="small"
                                              variant={"contained"}
                                              color="primary">
                                        Edit
                                </Button>
                            </CardActions>
                        </Card>}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Card  className={classes.card}>
                    <CardHeader
                        title={t("subscription_details")}
                    />
                    <CardContent>
                        {showUpdateDetails
                            ? <>
                                {updateSubscriptionError && <Alert variant={"filled"} color={"error"}>{updateSubscriptionError}</Alert>}
                                <div>{t("next_renewal_date")}:
                                    <Select onChange={handleRunDayChange} className={classes.dropDown}
                                            value={renewalDay} >
                                        {(range(1,31)).map(x => {
                                            return <MenuItem key={x} value={x}>{x}</MenuItem>;
                                        })}
                                    </Select>
                                </div>
                                {false && hasDigital && <>
                                    <div>{t("digital_qty")}:
                                        <Select onChange={handleDigitalQtyChange} className={classes.dropDown}
                                                                         value={digitalQty} >
                                            {([0,1]).map(x => {
                                                return <MenuItem key={x} value={x}>{x}</MenuItem>;
                                            })}
                                        </Select>
                                    </div>
                                </>}
                                {false && hasPhysical && <>
                                    <div>{t("physical_qty")}:
                                        <Select onChange={handlePhysicalQtyChange} className={classes.dropDown}
                                                value={physicalQty} >
                                            {([0,1]).map(x => {
                                                return <MenuItem key={x} value={x}>{x}</MenuItem>;
                                            })}
                                        </Select>
                                    </div>
                                </>}
                            </>
                            :<>
                                <div>{t("next_renewal_date")}: {moment(mySubscription.info.OrderDate).format("LL")}</div>
                                <div>{t("upcoming")} {t("pay_by")}:{currentBilling}</div>
                                {hasDigital && <>
                                    <div>{t("digital_qty")}: {mySubscription.info.QtyDigital}</div>
                                </>}
                                {hasPhysical && <>
                                    <div>{t("physical_qty")}: {mySubscription.info.QtyPhysical}</div>
                                </>}

                            </>
                        }

                    </CardContent>
                    <CardActions className={classes.actions} >
                        {showUpdateDetails
                            ? <>
                                <Button variant={"contained"} onClick={handleCloseUpdateDetails}
                                        color={"secondary"}>{t("cancel")}</Button>
                                <Button variant={"contained"} onClick={handleUpdateDetails}
                                        color={"primary"}>{t("update")}</Button>
                            </>
                            :<>
                                <Button variant={"contained"} onClick={handleShowUpdateDetails}
                                        color={"secondary"}>{t("change")}</Button>
                            </>
                        }
                    </CardActions>
                </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card  className={classes.card}>
                        <CardHeader
                            title={t("transactions")}
                        />
                        <CardContent>
                            <Typography variant={"caption"}>{t("previous")}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("date")}</TableCell>
                                        <TableCell >{t("paid_by")}</TableCell>
                                        <TableCell >{t("status")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mySubscription.transactions.map(x=>{
                                        return <TableRow>
                                            <TableCell>{moment (x.OrderDate).format("L")}</TableCell>
                                            <TableCell >{ ["eWallet", "SafetyPay"].includes(x.CardType)
                                                ?  x.CardType: `Card ending in ${x.Last4}` }</TableCell>
                                            <TableCell >{x.IsDeclined?t("declined"):"approved"}</TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>


                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {showEditAddressModal && <Dialog maxWidth={"md"} fullWidth open={true} onClose={handleHideShippingEdit}>
                <DialogContent>
                    {updateAddressError && <Alert variant={"filled"} color={"error"}>{updateAddressError}</Alert>}
                    <ShippingInfoList allowSelect onSelect={handleAddressSelected} onEdit={handleAddressEditing}/>
                </DialogContent>
                {!addressEditing && <DialogActions>
                    <Button onClick={handleHideShippingEdit} variant={"contained"}
                                    color="secondary">{t("cancel")}</Button>

                            <Button disabled={selectedAddressID==null} onClick={handleUpdateAddress}
                                    variant={"contained"}
                                    color="primary">{t("update")}</Button>

                </DialogActions>}
            </Dialog>}

            {showEditPaymentModal && <Dialog maxWidth={"md"} fullWidth open={true} onClose={handleHidePaymentEdit}>
                <DialogContent>
                    {updatePaymentError && <Alert variant={"filled"} color={"error"}>{updatePaymentError}</Alert>}
                    <BillingInfoList allowSelect onlyCards showEwallet
                                     onSelect={handlePaymentSelected}
                                     onEdit={handlePaymentEditing}/>
                </DialogContent>
                {!paymentEditing && <DialogActions>
                    <Button onClick={handleHidePaymentEdit} variant={"contained"}
                                    color="secondary">{t("cancel")}</Button>

                            <Button disabled={selectedPaymentID==null} onClick={handleUpdatePayment}
                                    variant={"contained"}
                                    color="primary">{t("update")}</Button>

                </DialogActions>}
            </Dialog>}


        </>

    return null

    })