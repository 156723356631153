import React, {useEffect} from 'react'
import {
    CircularProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Select,
    Stepper,
    Step,
    StepLabel,
    DialogActions,
    Button,
    Dialog,
    DialogContent,
    Checkbox,
    TextField,
    Box,
    Fab,
    MenuItem,
    Tooltip, Paper, TableContainer
} from "@material-ui/core";
import {makeStyles, ThemeProvider, withStyles} from '@material-ui/core/styles';
import {useStores} from "../../../hooks/use-stores";
import {observer} from "mobx-react";
import moment from "moment";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {starFishColor} from "../../../components/styles";
import StepConnector from '@material-ui/core/StepConnector';
import clsx from "clsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import HelpIcon from "@material-ui/icons/HelpOutline"
import greenButtonTheme from "../../../themes/greenButton";

const useStyles = makeStyles({
    input:{
        marginBottom: "20px",

    },
    previewRecipients:{
        maxHeight: 200,
        overflow: "auto",
        border: "solid #dfdfdf 1px",
        padding: "5px 5px 0px 5px",
        borderRadius: 5
    },
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#eee',
        zIndex: 1,
        color: 'rgba(0,123,255,0.39)',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        ...starFishColor(),
        backgroundColor: "white",
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor:"#007BFF",
        color: "white",
    }
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor:"#007BFF"
        },
    },
    completed: {
        '& $line': {
            backgroundColor: "#007BFF"
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: 'white',
        borderRadius: 1,
    },
})(StepConnector);


function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <i className="fa fa-2x fa-users" />,
        2: <i className="fa fa-2x fa-pencil" />,
        3: <i className="fa fa-2x fa-search" />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

function UsersList ({context, usersDialogFor, hideUsersDialog, onSendMessages}){
    const {dashboardStore, authStore} = useStores();
    const [open, setOpen] = React.useState(true);
    const {t} = useTranslation("dashboard");
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [ t("Members"), t("Compose Message"), t("Preview Message") ];
    const [selected, setSelected] = React.useState([]);
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [from, setFrom] = React.useState("info@mainhomepage.com");
    const classes = useStyles();


    useEffect(() => {

        if ( authStore.currentUser.isLeader )
            dashboardStore.loadUsers(context)

        if ( context ) {
            setSubject(t(context.modalTitle))
            let txtMessage;
            switch (context.sub) {
                case"ms":
                    txtMessage = t("msDeclinedEmailSubject");
                    break;
                case"sot":
                    txtMessage = t("sotDeclinedEmailSubject");
                    break;
                case"tpd":
                    txtMessage = t("tpdDeclinedEmailSubject");
                    break;
                case"ffs":
                    txtMessage = t("ffsDeclinedEmailSubject");
                    break;
                case"ppr":
                    txtMessage = t("pprDeclinedEmailSubject");
                    break;
                case"mspremium":
                    txtMessage = t("ffsDeclinedEmailSubject");
                    break;
                case"appsuper":
                    txtMessage = t("appSuperDeclinedEmailSubject");
                    break;
                case"appsuperplus":
                    txtMessage = t("appSuperPlusDeclinedEmailSubject");
                    break;
                default:
                    txtMessage = "";
                    break;
            }
            setMessage(txtMessage)
        }


    },[context,  authStore.currentUser ])

    const showStepper = ( context &&  !(context.status === 40 || context.status === 60 || context.status===400 ))

    const { users, usersLoading } = dashboardStore;

    const handleNextStep = () => {
        setActiveStep( prevActiveStep =>Math.min( 2,  prevActiveStep + 1));
    }
    const handlePrevStep = () => {
        setActiveStep(prevActiveStep=> Math.max(0, prevActiveStep - 1));
    }


    const setStep = (step) =>()=> {
        setActiveStep(step);
    }

    const handleSendMessages = () => {
        onSendMessages(selected, from, subject, message)
    }


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.UserID);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, UserID) => {
        const selectedIndex = selected.indexOf(UserID);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, UserID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const selectedUsers = users.filter( user => selected.includes( user.UserID )  )


    const rowCount = users.length;
    const numSelected = selected.length;

    return <Dialog open={!!usersDialogFor} onClose={hideUsersDialog} maxWidth={"md"} fullWidth   scroll={"paper"} >
                <DialogTitle>
                    {context && t(context.modalTitle)  || "Notify Members"}
                </DialogTitle>
                <DialogContent dividers>
                    { !authStore.currentUser.isLeader
                        ? <Typography>{t("onlyForLeader")}</Typography>
                        : <>
                            { usersLoading
                                ? <CircularProgress/>
                                : <>
                                    <>
                                        { showStepper &&
                                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                                {steps.map((label, index) => (
                                                    <Step key={label} onClick={setStep(index)}>
                                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>}
                                        {activeStep === 0 && <>
                                            {open && <Alert variant={"filled"}  onClose={() => { setOpen(false); }} color={"info"}>
                                                {t("usersListInfo")}
                                            </Alert>}
                                            <Paper className={classes.root}>
                                                <TableContainer className={classes.container}>
                                                     <Table stickyHeader  size="small">
                                            <TableHead>
                                                <TableRow>
                                                    { showStepper && <TableCell>
                                                        <Checkbox
                                                            color={"primary"}
                                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                                            checked={rowCount > 0 && numSelected === rowCount}
                                                            onChange={handleSelectAllClick}
                                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                                        />
                                                    </TableCell>}
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Phone</TableCell>
                                                    <TableCell>Order Date</TableCell>
                                                    <TableCell>Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users.map((row) => {
                                                    const isItemSelected = selected.indexOf(row.UserID) !== -1;
                                                    return <TableRow key={row.UserID}>
                                                        {showStepper && <TableCell>
                                                            <Checkbox
                                                                color={"primary"}
                                                                checked={isItemSelected}
                                                                onClick={(event) => handleClick(event, row.UserID)}
                                                            />
                                                        </TableCell>}
                                                        <TableCell component="th" scope="row">
                                                            {row.FirstName} {row.LastName}
                                                        </TableCell>
                                                        <TableCell>{row.Email}</TableCell>
                                                        <TableCell>{row.Phone}</TableCell>
                                                        <TableCell>{moment(row.OrderDate).format('MM/DD/yy')}</TableCell>
                                                        <TableCell>{row.RoleName}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>

                                        </Table>
                                                </TableContainer>
                                            </Paper>
                                        </>}
                                        {activeStep === 1 && <Typography>
                                           <Box display={"flex"} className={classes.input}>
                                               <Select label={"From"} fullWidth value={from}>
                                                    <MenuItem value={"info@mainhomepage.com"}>info@mainhomepage.com</MenuItem>
                                                </Select>
                                                <Tooltip  title={t("from_help")}><HelpIcon color={"primary"} /></Tooltip>
                                           </Box>
                                            <TextField className={classes.input} label={"Subject"} fullWidth value={subject} onChange={e=>{ setSubject(e.target.value) }} />
                                            <TextField className={classes.input} label={"Message"} multiline value={message} onChange={e=>{ setMessage(e.target.value) }} minRows={8} fullWidth/>
                                        </Typography>}
                                        {activeStep === 2 &&<>
                                            <Typography>Preview Message</Typography>
                                            <Box className={classes.previewRecipients}>
                                                <ul>
                                                    {selectedUsers.map( user => <li key={user.UserID}>{user.FirstName} {user.LastName} - {user.Email}</li>)}
                                                </ul>
                                            </Box>
                                            <br/>
                                            <Typography>{t("subject")}: </Typography>
                                            <Typography variant={"caption"}>{subject}</Typography>
                                            <br/>   <br/>
                                            <Typography>{t("message")}: </Typography>
                                            <Typography variant={"caption"}>{message}</Typography>

                                        </>}
                                    </>
                                </> }
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    { showStepper
                        ? <>
                            <Button variant={"text"} onClick={handlePrevStep} >Prev</Button>
                            {activeStep === 2
                                ?  <ThemeProvider theme={greenButtonTheme}>
                                    <Button color={"secondary"} variant={"contained"} onClick={handleSendMessages} >Send Messages</Button>
                                </ThemeProvider>
                                : <Button color={"primary"} variant={"contained"} onClick={handleNextStep} >Next</Button>}
                        </>
                        : <Button color={"secondary"} variant={"contained"} onClick={hideUsersDialog} >Close</Button>}
                </DialogActions>
            </Dialog>
}

export default observer(UsersList)