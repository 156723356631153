import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import MaterialTextField from "../inputs/MaterialTextField";
import MaterialSelect from "../inputs/MaterialSelect";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import MaskedMaterialTextField from "../inputs/MaskedMaterialTextField";

function EnrollmentAddressForm({ form, regionsByCountry, allCountries, handleCountryChanged, onSubmit }) {
    const { t } = useTranslation();
    return form ? <Grid container spacing={3} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} lg={7}>
            <MaterialSelect
                fullWidth
                label="Language"
                margin="normal"
                labelAccessor={(x => x.countryName)}
                valueAccessor={(x => x.countryShortCode)}
                field={form.$("country")}
                options={allCountries}
                onChange={handleCountryChanged} />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                field={form.$("address1")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                field={form.$("address2")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialTextField
                fullWidth
                margin="normal"
                field={form.$("city")}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={12} lg={7}>
            {(form.$("postalCode").extra
                && form.$("postalCode").extra.mask)
                ? <MaskedMaterialTextField
                    margin="normal"
                    field={form.$("postalCode")}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                : <MaterialTextField
                    fullWidth
                    margin="normal"
                    field={form.$("postalCode")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            }
        </Grid>
        <Grid item xs={12} lg={7}>
            <MaterialSelect
                fullWidth
                label="Language"
                margin="normal"
                labelAccessor={(x => x.name)}
                valueAccessor={(x => x.shortCode)}
                field={form.$("region")}
                options={regionsByCountry[form.$("country").value] || []} />
        </Grid>
        {onSubmit && <Grid item xs={12} lg={7}>
            <Button onClick={onSubmit} fullWidth variant={"contained"}
                color="primary">{t("enrollment:continue")}</Button>

        </Grid>}
    </Grid> : null;
}

export default observer(EnrollmentAddressForm);