import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useStores } from "../hooks/use-stores";
import { observer } from "mobx-react-lite";
import Vimeo from "@u-wave/react-vimeo";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";


function KYCModal() {
    const { commonStore } = useStores();
    let { kycPopup } = commonStore;
    const { t } = useTranslation();
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down("xs"));


    const closeKYCPopup = ()=>{
        commonStore.hideKYCPopup(false);
    }

    return (
        kycPopup && <Dialog open={true} maxWidth={"sm"} fullWidth fullScreen={isPhone} >
            <DialogTitle>{t('shoppingBoss:merchants.kycPopup.title')}</DialogTitle>
            <DialogContent>
                <p>
                    {t('shoppingBoss:merchants.kycPopup.content')}
                </p>
                <Vimeo video={818502861} responsive/>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={closeKYCPopup}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default observer(KYCModal);