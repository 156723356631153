import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Typography,
    makeStyles,
    Container,
} from "@material-ui/core";
import Send from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    mainNav: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    logoImg: {
        width: '75px',
        verticalAlign: 'middle',
        [theme.breakpoints.up('md')]: {
            width: '100px',
        },
    },
    appBar: {
        backgroundColor: 'inherit',
        color: 'black',
        boxShadow: 'none'
    },
    navlinks: {
        '& > button': {
            color: 'white',
            backgroundColor: 'black',
            textDecoration: "none",
            borderRadius: '20px',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        },

    },
    logoWrapper: {
        flexWrap: 'nowrap',
        marginRight: '.2rem',
        [theme.breakpoints.up('sm')]: {
            marginRight: '1rem',
        },
    },
    sendNewMessage: {
        display: 'flex',
        fontSize: '.6rem',
        whiteSpace: "pre-line",
        [theme.breakpoints.up('md')]: {
            fontSize: '.8rem',
            display: 'block'
        },
    },
    deleteMessages: {
        color: 'red',
        fontSize: '.8rem',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
    }
}));

const Header = ({ onSendNewMessage, onDeleteAllMessages }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Box className={classes.mainNav}>
                <Box variant="h4" className={classes.logoWrapper}>
                    <img src={`/team_talk.png`} className={classes.logoImg} alt="Life Line logo" />
                </Box>
                <Box className={classes.navlinks}>
                    <Button style={{ borderRadius: '5px' }} onClick={onSendNewMessage} variant="contained">
                        <Send style={{ marginRight: '8px' }} />
                        <Typography className={classes.sendNewMessage} variant="body2">{t('lifeline:home.sendNewMessage')}</Typography>
                    </Button>
                </Box>
            </Box>
            {onDeleteAllMessages  && <Box style={{ textAlign: 'center', alignSelf: 'center' }}>
                <a href="#" onClick={onDeleteAllMessages} className={classes.deleteMessages}>Delete All Messages</a>
            </Box>}
        </Container>
    );
};

export default Header;