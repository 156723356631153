import React, { useLayoutEffect } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import PostElement from './Post';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    }
  },
  appBar: {
    backgroundColor: 'inherit',
    color: 'black',
    boxShadow: 'none'
  },
  navBarMessage: {
    display: 'none',
    fontSize: '.7rem',
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      display: 'block'
    },
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
}));

const PostGrid = ({ onScrollDown, loading, videos, onMarkMessage, onArchive, onDelete, onRespond }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const scrollListener = () => {
    const scrollTop = (document.documentElement
      && document.documentElement.scrollTop)
      || document.body.scrollTop;

    const scrollHeight = (document.documentElement
      && document.documentElement.scrollHeight)
      || document.body.scrollHeight;

    if (scrollTop + window.innerHeight + 100 >= scrollHeight) {
      onScrollDown();
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  return (
    <Container className={classes.container}>
      {
        videos?.length > 0 ?
          videos.map((video) => (
            <PostElement
              key={video.ID}
              videoData={video}
              onMarkMessage={onMarkMessage}
              onArchive={onArchive}
              onDelete={onDelete}
              onRespond={(type) => onRespond(video.ID, video.ReplyUserID, video.LastName, video.ReplyLastName, video.ReplyFirstName, type)}
            />
          )) :
          (!loading && <Alert severity="info">{t('lifeline:home.noMessages')}</Alert>)
      }
    </Container>
  )
};

export default PostGrid;
