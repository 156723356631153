import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        secondary: {
            contrastText: "white",
            dark: "#36744d",
            main: "#1db954",
            light: "#69e396"
        },
    },
});

export default theme