import IdLessRestService from "./IdLessRestService";


export default class AutoShipService extends IdLessRestService {
    constructor() {
        super('/api/autoship');
    }


    updateAutoship(cartID, runDay) {
        return this.put(`${this.baseUrl}`, { cartID, runDay });
    }


    updatePaymentID(paymentID, last4) {
        return this.put(`${this.baseUrl}/billing`, { paymentID, last4 });
    }

    addItemToCart({ cartID, productID, qty }) {
        return this.put(`${this.baseUrl}/cart/items/${cartID}`, { productID, qty });
    }


    deleteCartItem({ cartID, productID }) {
        return this.del(`${this.baseUrl}/cart/items/${cartID}`, { productID });
    }

    updateCartItem({ cartID, productID, qty }) {
        return this.put(`${this.baseUrl}/cart/items/${cartID}`, { productID, qty });
    }

    getAutoshipProducts({ language }) {
        return this.get(this.baseUrl);
    }

    createAutoShipUserCart() {
        return this.get(`${this.baseUrl}/cart`);
    }

    getUserCart(cartGuid) {
        return this.get(`${this.baseUrl}/cart/items/${cartGuid}`,);
    }

    loadCurrentAutoShip(cartGuid) {
        return this.get(`${this.baseUrl}/items/user`,);
    }

    loadCurrentAutoShipInfo(cartGuid) {
        return this.get(`${this.baseUrl}/info/user`,);
    }

    getCartTotals(cartGuid) {
        return this.get(`${this.baseUrl}/cart/totals/${cartGuid}`);
    }

    updateShipping(values) {
        return this.put(`${this.baseUrl}/address/shipping`, values);
    }
}
