import React from 'react';

import BreadCrumb from './BreadCrumb/BreadCrumb';
import Navbar from './Navbar/Navbar';
import {observer} from "mobx-react";
import {useLocation} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import {useShopType} from "../../../containers/shop/useShopType";


const useStyles = makeStyles((theme) => ({
}))


function Header({ autoship, standalone, subscriptions, shoppingMatch, parentCategory, childCategory, isGuest, disableCountryDDL }) {
    const location = useLocation()
    const classes = useStyles()
    return (
      <>
        <Navbar autoship={autoship} standalone={standalone} subscriptions={subscriptions} isGuest={isGuest} disableCountryDDL={disableCountryDDL}  shoppingMatch={shoppingMatch} />
          {(parentCategory && !standalone)
             && <BreadCrumb
            parentCategory={parentCategory}
            childCategory={childCategory}
            shoppingMatch={shoppingMatch}
          />}
      </>
    )
}

export default observer(Header);