import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import enLang from 'validatorjs/src/lang/en';
import frLang from 'validatorjs/src/lang/fr';
import { asyncRules } from "./asyncRules";
import { syncRules } from "./syncRules";


const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
            validator.setMessages('en', enLang);
            validator.setMessages('fr', frLang);
            // here we can access the `validatorjs` instance (plugin) and we
            // can add the rules using the `registerAsyncRule()` method.
            Object.keys(asyncRules).forEach((key) =>
                validator.registerAsync(key, asyncRules[key]));

            Object.keys(syncRules).forEach((key) =>
                validator.register(key, syncRules[key].func, syncRules[key].message ));
            const messages = validator.getMessages('en');
            messages.required = 'This field is required.';
            messages.integer = "The :attribute must be a whole number.";
            validator.setMessages('en', messages);
        }
    }),
};

export default plugins;