import plugins from './plugins';
import MobxReactForm from "mobx-react-form";


const fields = {

    addressName: {
        label: 'Full Name',
        rules: 'string|required',
        placeholder: 'Full Name',
    },
    address1: {
        label: 'Address 1',
        rules: 'string|required',
        placeholder: 'Address 1',
    },
    address2: {
        label: 'Address 2',
        rules: 'string',
        placeholder: 'Address 2',
    },
    city: {
        label: 'City',
        rules: 'string|required',
        placeholder: 'City',
    },
    zip: {
        label: 'Zip',
        rules: 'required|string|between:1,10',
        placeholder: 'Zip',
    },
    region: {
        label: 'State / Province',
        rules: 'string|required',
        placeholder: 'State / Province',
    },
    country: {
        label: 'Country',
        rules: 'string',
        placeholder: 'Country',
    },
    primaryAddress: {
        rules: 'boolean'
    }
};



export default function create(hooks, initials) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }
    return new MobxReactForm({  fields:f  }, { plugins, hooks });
}

