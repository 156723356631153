import {createTheme} from "@material-ui/core/styles";
import baseProps from "./baseTheme";

const theme = createTheme({
    ...baseProps(true),
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 992,
            lg: 1380,
            xl: 1920,
        },
    },
});

export default theme