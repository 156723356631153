import {action, computed, makeObservable, observable} from 'mobx'
import {extractErrorMessage, getUniqKey } from "../../utils/helpers";
import {patchShoppingItem, patchShoppingItems, getItemBanner, getItemUrl, getSubItems, isCurrentItemExternal } from "../../utils/menuUtils";

class Menu {
    menu = null
    path = ""
    error = null

    constructor( items, libraryStore, packsStore ) {
        patchShoppingItems( items&& items.section )
        this.menu = items

        makeObservable(this, {
            menu: observable,
            path: observable,
            error: observable,
            setError: action,
            setPath: action,
            currentBanner: computed,
            currentItem: computed,
            parentItem: computed,
            favorites: computed,
            currentItemUrl: computed,
            currentItemSKU: computed,
            isCurrentItemExternal: computed,
            isCurrentItemOpensWithoutIframe: computed,
            parentPath: computed,
            currentLinks: computed,
            topLevelLinks: computed,
        })
        this.libraryStore = libraryStore
        this.packsStore = packsStore

    }




    setError(error, type = "menu") {
        error = extractErrorMessage(error)
        this.error = error
    }

    setPath(path) {
        //console.log( "setPath", path)
        this.path = path
    }

    get currentBanner(){
        const banner =  getItemBanner( this.currentItem )
        if (!banner) return banner
        banner.forEach((step, index) => {
            if ( step.mediaID )
                step.url = "playmedia://" + step.mediaID
            // DEBUG:
            //if( index === 1 )
            //    step.url = "playmedia://20576"
        })

        return banner;
    }



    get currentItem()  {
        if (!this.menu ) return null
        if ( !this.path )
            return  this.menu;
        let parts = this.path.split("/")


        let currentLevel = this.menu
        //check promo banner clicked
        if ( parts.length ===1 ){
            const promobanner = this.menu.section.find( x=> getUniqKey(x) ==="promobanner" )
            if ( promobanner ) {
                const bannerItem = promobanner.banner.find(x=>getUniqKey(x) ===parts[0])
                if ( bannerItem )
                    return bannerItem
            }
        }

        let currentLevelRest = null

        for ( let i=0; i<parts.length; i++ ){
            currentLevelRest = null;
            if ( !currentLevel ) return null;
            const subItems = currentLevel.section || currentLevel.subsection
            if ( !subItems || subItems.length === 0 ) {
                if( currentLevel.url ){
                    try {
                        if ( currentLevel.shopping ) {
                            currentLevelRest = parts.slice(i).join("/")
                            break
                        }
                        const url = new URL(currentLevel.url)
                        if (url.hostname && url.hostname === "shop.lifeinfoapp.com") {
                            currentLevelRest = parts.slice(i).join("/")
                            break
                        }
                    }
                    catch(e){
                        console.error(e)
                        debugger
                    }
                }
                return null;
            }
            currentLevel =  (currentLevel.section || currentLevel.subsection).find( x=> getUniqKey(x) ===parts[i] )
        }
        patchShoppingItem( currentLevel )

        return  { ...currentLevel, rest: currentLevelRest}
    }

    get parentItem()  {
        if ( !this.path )
            return  null;
        let parts = this.path.split("/")

        let currentLevel = this.menu
        let currentLevelRest = null
        for ( let i=0; i<parts.length-1; i++ ){
            currentLevelRest = null;
            if ( !currentLevel ) return null;
            if ( !(currentLevel.section || currentLevel.subsection) ) {

                if (currentLevel.url.startsWith("/shopping") ) {
                    currentLevelRest= parts.slice(i).join("/")
                    break
                }
                return null;
            }
            currentLevel =  (currentLevel.section || currentLevel.subsection||[]).find( x=> getUniqKey(x) ===parts[i] )
        }
        return  {...currentLevel, rest:currentLevelRest}
    }


    get favorites(){
        if ( !this.menu ) return []
        let favRoot = this.menu.section.find( x=> getUniqKey(x) ==="myfavorites" )
        return favRoot&& favRoot.subsection

    }


    get currentItemUrl(){
        return getItemUrl(this.currentItem)
    }

    get isCurrentItemExternal(){
        return isCurrentItemExternal(this.currentItem)
    }
     get currentItemSKU(){
         if ( !this.currentItemUrl )
             return null
         if ( this.currentItemUrl.startsWith("playmedia://") ){
            return this.currentItemUrl.replace("playmedia://", "")
         }
    }


    get isCurrentItemOpensWithoutIframe(){
        if (!this.currentItem ) return false;
        return this.currentItem.tag === "thingor2travel" ||  this.currentItem.tag === "thingor2resorts" || this.currentItem.tag === "coupons" || this.currentItem.tag === "shareproduct"
    }

    get parentPath()  {
        if ( !this.path )
            return  null;
        let parts = this.path.split("/")
        parts.pop()
        let parentPath = parts.join("/")
        if ( parentPath.startsWith("/") ) return parentPath
        return `/${parentPath}`
    }


    get currentLinks()  {
        return getSubItems(this.currentItem)
    }

    get topLevelLinks()  {
        return getSubItems(this.menu)
    }

}

export default Menu