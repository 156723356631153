import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class MyFormsStore {
  myFormsLoading = null;
  myFormsInfo = null;

  searchFormsLoading = null;
  searchFormInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "myFormsError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  loadForm = generateLoadEntity(
    "myFormsInfo",
    this,
    "myFormsLoading",
    (params) => {
      return services.MyForms.list(params);
    },
    "myFormsInfo"
  );

  searchForm = generateLoadEntity(
    "searchFormInfo",
    this,
    "searchFormsLoading",
    (params) => {
      return services.MyForms.searchForm(params);
    },
    "searchFormInfo"
  );
}

export default MyFormsStore;
