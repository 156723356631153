import React from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { substituteWithComponent } from '../../utils/helpers';

const SparkWalletCheckbox = ({ termsAccepted, onTermsAccepted, hasAcceptedSparkTerms }) => {
    const { t } = useTranslation("wallet");

    if (!!hasAcceptedSparkTerms) {
        return null;
    }

    return (
        <Box display={"flex"} flexGrow={1} alignItems={"flex-end"} style={{ width: '100%', marginBottom: '10px' }}>
            <FormControl>
                <FormControlLabel control={<Checkbox />}
                    value={termsAccepted}
                    onChange={() => {
                        onTermsAccepted(!termsAccepted);
                    }}
                    label={<>
                        {t("terms_text1")}<br />
                        {substituteWithComponent(t("terms_text2"), "%TERMS%", <Link href="https://sparkwallet.io/terms-conditions" target="_blank">{t("terms_and_conditions")}</Link>)}
                    </>}
                />
            </FormControl>
        </Box>
    );
};

export default SparkWalletCheckbox;
