import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import { Box, CircularProgress, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { useStores } from '../../../hooks/use-stores';
import WithdrawButton from '../../../components/Wallet/WithdrawButton';

const useStyles = makeStyles((theme) => ({
    subcontainer: {
        marginBottom: theme.spacing(4)
    },
    subtitle: {
        fontSize: 13,
        marginBottom: theme.spacing(1.5)
    },
    satsBalance: {
        fontWeight: 'bold',
        padding: '10px 0px'
    },
    addressWarning: {
        color: 'red',
    },
    bottomButtonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '0px 18px'
    }
}));

const BTCWithdrawalHome = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [depositAddress, setDepositAddress] = useState('');

    const { commonStore, statStore } = useStores();

    const fetchSatsBalance = async () => {
        try {
            await statStore.loadStats();
        } finally {
            setLoading(false)
        }
    }

    // TODO extract this into a util
    const isValidBTCAddress = (str) => {
        const regex = new RegExp(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/);
        return regex.test(str)
    }

    const onContinue = () => {
        if (isValidBTCAddress(depositAddress)) {
            props.setBtcDepositAddress(depositAddress);
            history.push(`/wallet/withdraw/confirm`);
        } else {
            commonStore.warn(t('wallet:withdrawal.home.addressError'));
        }
    }

    const onDepositAddressChanged = (e) => {
        setDepositAddress(e.target.value);
    }

    useEffect(() => {
        fetchSatsBalance()
    }, [])

    return (
        <Container>
            {loading ? <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box> :
                <>
                    {statStore.error && <Box>
                        <Alert severity="error">
                            {t('wallet:withdrawal.home.loadingError')}
                        </Alert>
                    </Box>
                    }
                    {
                        !loading &&
                        <>
                            <Box className={classes.subcontainer}>
                                <Typography variant="h3" className={classes.subtitle}>
                                    {t('wallet:withdrawal.home.amountToWithdraw')}
                                </Typography>
                                <Box>
                                    <Typography variant="body1" className={classes.satsBalance}>
                                        {Math.trunc(statStore.statistics?.wallet?.sats?.balance ?? 0)} SATS
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.subcontainer}>
                                <Typography variant="h3" className={classes.subtitle}>
                                    {t('wallet:withdrawal.depositAddress')}
                                </Typography>
                                <Box>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        value={depositAddress}
                                        onChange={onDepositAddressChanged}
                                        InputProps={{ style: { borderRadius: '20px', border: '1px solid rgb(5, 73, 255)' } }}
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.subcontainer}>
                                <Typography variant="caption" className={classes.addressWarning}>{t('wallet:withdrawal.home.warning')}</Typography>
                            </Box>
                            <WithdrawButton className={classes.button} onClick={onContinue} disabled={statStore.error}>
                                {t('global:continue')}
                            </WithdrawButton>
                        </>
                    }
                </>
            }
        </Container>
    )
}

export default BTCWithdrawalHome;