import React, {useState} from "react";
import {observer} from "mobx-react";



const truncateText = (text, showFullText) => {
    let truncatedText;
    if (text) {
        text.length > 700 ? truncatedText = text.substring(0, 560) + '...'
            : truncatedText = text;

        if ( showFullText) {
            truncatedText = text
        }
    }
    return truncatedText
}

const ProductDescription = ({ t, productDescription, width }) => {
    const [showFullText, setShowFullText] = useState(false)
    return (
        <div className="product-description__summary">
            {
                productDescription.length > 1 &&
                !(productDescription.includes('<p>&nbsp;</p>'))
                    ? (
                        <>
                            <h3 className="product-description__title">{t('shopping:description.productdescription')}</h3>
                            <p style={{ color: '#fff' }} dangerouslySetInnerHTML={{
                                __html: width < 500 ? truncateText(productDescription, showFullText) : productDescription
                            }} />
                        </>
                    ) : ('')
            }
            {
                productDescription.length > 700 ? (
                    showFullText ? (
                        <p id="show-more-link" onClick={() => setShowFullText( false )}>
                            {width < 500 ? 'Show less' : ''}
                        </p>
                    ) : (
                        <p id="show-more-link" onClick={() => {
                            setShowFullText( true )
                        }}>{width < 500 ? 'Show more' : ''}</p>
                    )
                ) : ('')
            }
        </div>
    )
}


export default observer(ProductDescription)