import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Box, Button, CircularProgress, Container, Grid, Select, LinearProgress, MenuItem, TextField, makeStyles, Typography, Chip, ListItem, List, Dialog, DialogContent } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStores } from '../../../hooks/use-stores';
import services from '../../../services';
import { ORRIN_ID } from '../../../stores/lifelineStore';
import RecordAudio from './RecordAudio';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    maxWidth: 500,
    marginBottom: theme.spacing(2),
    padding: 0,
    color: 'black'
  },
  content: {
    margin: '0 auto',
    maxWidth: '800px',
    padding: theme.spacing(2)
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  heading: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem'
    }
  },
  formListItem: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectBg: {
    backgroundColor: 'white',
    "& .Mui-selected": {
      backgroundColor: "darkgray",
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "lightgray",
    },

  },
  select: {
    color: 'black',
    borderBottom: '1px solid black',
    backgroundColor: 'white',
    '& .MuiSvgIcon-root': {
      color: 'black'
    },
  },
  selectItem: {
    color: 'black'
  },
  submitButton: {
    color: 'white',
    backgroundColor: '#00a4e3',
    width: '100%',
    maxWidth: '300px',
    '&:hover': {
      backgroundColor: 'rgba(0, 164, 227, .7)'
    }
  },
  formItem: {
    fontWeight: 'bold'
  },
  chipList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: .5,
    margin: 0,
    '& >li': {
      width: 'max-content'
    }
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  checkIcon: {
    fontSize: '10rem',
    color: '#007BFF',
    marginBottom: theme.spacing(2)
  }, successMessage: {
    fontWeight: 'bold',
    textAlign: 'center',
  },

}));

const FinalizeVideo = ({ lifelineMatch }) => {
  const { t } = useTranslation();
  const { authStore, commonStore, lifelineStore } = useStores();
  const history = useHistory();

  const classes = useStyles();

  const [loading, setLoading] = useState(lifelineStore.loadingBaseline);
  const [recording, setIsRecording] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [error, setError] = useState(null);
  const [sendingTo, setSendingTo] = useState(null);
  const [showDistributionLists, setShowDistributionLists] = useState(false);
  const [distributionLists, setDistributionLists] = useState([])
  const [tag, setTag] = useState('');
  const [options, setOptions] = useState(lifelineStore.baseline);
  const [uploadProgress, setUploadProgress] = useState(0)

  const { responseType, respondingToMessageID } = lifelineStore;

  const isOrrin = authStore.currentUser.userID == ORRIN_ID

  const setAudience = (e) => {
    setSendingTo(e.target.value)
    if (e.target.value == -1) {
      setShowDistributionLists(true);
      setDistributionLists([]);
    } else {
      // When we change the audience to something other
      // than Dist List (id of -1), we want to hide the dist lists if they're shown
      if (showDistributionLists) {
        setShowDistributionLists(false)
      }
    }
  }

  const updateDistribution = (e) => {
    if (!distributionLists.find(listItem => listItem.ID == e.target.value?.ID)) {
      setDistributionLists([...distributionLists, e.target.value])
    }
  }

  const removeFromDistributionLists = (idToRemove) => {
    const listWithoutProvidedId = distributionLists.filter(listItem => listItem.ID !== idToRemove);
    setDistributionLists(listWithoutProvidedId);
  }

  /* Baseline is unique in that it is triggered to load when the initial Lifeline page is loaded
    It can possibly still be loading if the user gets here, especially if their baseline is
     large like Orrin's. On load, we'll check this function (thanks to the useEffect below).
     99% of the time, this should be enough. Loading will be false and we can continue.
     However, in some cases, the user could get here while we're still loading the baseline.
     If we're still loading, return. This function will run again when the loading is complete
  */
  const getBaselineOptions = () => {
    if (lifelineStore.loadingBaseline) {
      return;
    }

    if (lifelineStore.baseline) {
      setOptions(lifelineStore.baseline);
      if (isOrrin) {
        setSendingTo(0); // Orrin is a special case
      } else if (responseType == 'passUp' || responseType == null) {
        const upline = lifelineStore.baseline[0].sendToList.find(list => list.name == 'Upline');
        setSendingTo(upline.Value)
      } else if (responseType == 'passDown') {
        setSendingTo(0); // passing to entire downline is always '0'
      } else if (responseType == 'passDownToGroup') {
        setSendingTo(-1); // passing to a single group is always -1
        setShowDistributionLists(true);
      } else if (responseType === 'reply') {
        setSendingTo(lifelineStore.replyUserID);
      }
    } else {
      setError(t('lifeline:createVideo.finalize.baselineFail'))
    }

    setLoading(false);
  }

  const updateUploadProgress = (event) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  }

  const showSuccessMessage = () => {
    setShowSuccessDialog(true);

    setTimeout(() => {
      window.location.href = `${lifelineMatch.url}`
    }, 1500);

    setTimeout(() => {
      cleanup();
    }, 4000);
  };

  const cleanup = () => {
    if (lifelineStore.videoFile) {
      URL.revokeObjectURL(lifelineStore.videoFile);
      lifelineStore.videoFile = null;
    }
  }

  const uploadLifeline = async () => {
    setError(null);

    if (sendingTo == null) {
      commonStore.warn(t('lifeline:createVideo.finalize.noAudience'));
      return;
    }

    if (sendingTo == -1 && distributionLists.length == 0) {
      commonStore.warn(t('lifeline:createVideo.finalize.noDistList'));
      return;
    }

    // Here we're formatting the sendTo as the API expects it
    let formattedSendTo = '';
    if (responseType === 'reply') {
      // a simple reply requires just the id of the user you're replying to
      formattedSendTo = lifelineStore.replyUserID;
    } else if (showDistributionLists) {
      // sendto is comma separated list of ids when selecting multiple dist lists
      formattedSendTo = distributionLists.map(st => st.ID).join(',')
    } else {
      // otherwise you've selected a specific id that's a single value
      formattedSendTo = sendingTo;
    }

    let formData = new FormData();

    // if this is a response, we're passing it, so call the api
    if (responseType) {
      if (!respondingToMessageID) {
        commonStore.warn(t('lifeline:createVideo.finalize.invalidMessage'))
        return;
      }

      if (lifelineStore.audioFile) {
        formData.append('audio', lifelineStore.audioFile)
      }

      formData.append('voicemailID', respondingToMessageID)
    } else {
      // if this not a response, this is a new lifeline, so check for the required video
      if (!lifelineStore.videoFile) {
        // we check this on load, but again here for sanity
        return;
      }

      formData.append('video', lifelineStore.videoFile);
    }

    // Add common data
    formData.append('tag', tag)
    formData.append('forSpouse', lifelineStore.forSpouse)
    formData.append('sendTo', formattedSendTo)

    try {
      lifelineStore.uploading = true;

      // this is a response, so pass it
      if (responseType) {
        await services.LifelineService.passMessage(formData, updateUploadProgress);
      } else {
        await services.LifelineService.sendVideoMessage(formData, updateUploadProgress);
      }

      // originally would navigate to '/completed', but instead showing a 
      showSuccessMessage();
      return;
    } catch (e) {
      commonStore.showError(t('lifeline:createVideo.finalize.fail'));
    } finally {
      lifelineStore.uploading = false;
    }
  }

  useEffect(() => {
    getBaselineOptions();
  }, [lifelineStore.loadingBaseline]);


  // If this is not a response, the video should be valid.
  if (!responseType && !(lifelineStore.videoFile)) {
    history.push(lifelineMatch.url);
    return null;
  }

  return (
    <Container className={classes.container}>
      <Dialog open={showSuccessDialog} fullScreen>
        <DialogContent className={classes.dialog}>
          <CheckCircleIcon className={classes.checkIcon} />
          <Typography variant="body1" className={classes.successMessage}>{t('lifeline:createVideo.completed.success')}</Typography>
        </DialogContent>
      </Dialog>
      {loading ? <Box className={classes.progressContainer}><CircularProgress /></Box> :
        lifelineStore.uploading ?
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ width: '100%', height: '100%' }}>
            <Typography variant="h3">{uploadProgress}%</Typography>
            <Box style={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
            <Typography variant="body1" style={{ marginTop: '10px' }}>{t('lifeline:createVideo.finalize.uploading')}</Typography>
          </Box> :
          <>
            <Grid xs={12} container justifyContent="center" alignItems="center">
              {error ?
                <Alert severity="error">{error}</Alert>
                :
                <>
                  {/* If there is a response type (ie it's not a brand new message), and the user can record audio, let them record */}
                  {responseType && lifelineStore?.settings?.CanSendVoiceTag &&
                    <RecordAudio setIsRecording={setIsRecording} />
                  }
                  <Grid className={classes.formListItem} container item xs={12} justifyContent="center" alignItems="center">
                    <Grid item xs={12} container justifyContent="left">
                      <Typography className={classes.formItem} variant="body1"> {responseType ? t('lifeline:createVideo.finalize.sendMessageTo') : t('lifeline:createVideo.finalize.audience')}</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={6}>
                        {
                          responseType == 'reply' ? <span>{lifelineStore.replyName}</span> :
                            <Select
                              className={classes.select}
                              value={sendingTo}
                              onChange={setAudience}
                              MenuProps={{ classes: { list: classes.selectBg } }}
                              >
                              {options[0]?.sendToList?.map((sendTo) =>
                                <MenuItem key={sendTo.id} className={classes.selectItem} value={sendTo.Value}>{sendTo.name}</MenuItem>
                              )}
                            </Select>
                        }
                      </Grid>
                      <Grid item xs={6}>
                        {!isOrrin && <Typography variant="body1" style={{ float: 'right' }}>{(responseType == 'passUp' || responseType == null) && options[2]?.lifelineConfig?.uplineDisplayName}</Typography>}
                      </Grid>
                    </Grid>
                    {
                      showDistributionLists &&
                      <Grid className={classes.formListItem} container item xs={12} justifyContent="center">
                        <Grid item xs={12} container justifyContent="flex-start">
                          <Typography className={classes.formItem} variant="body1">{t('lifeline:createVideo.finalize.distList')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            // multiple
                            displayEmpty
                            renderValue={() => t('lifeline:createVideo.finalize.choose')}//(value) => (value.length > 0 ? value.map(v => v.Name).join(', ') : 'Select One')}
                            className={classes.select}
                            value={''}
                            onChange={updateDistribution}
                            MenuProps={{ classes: { list: classes.selectBg } }}
                            >
                            {options[1]?.distributionList?.map((distList) =>
                              <MenuItem key={distList.ID} className={classes.selectItem} value={distList}>{distList.Name}</MenuItem>
                            )}
                          </Select>
                          <List className={classes.chipList}>
                            {distributionLists.map((data) => {
                              return (
                                <ListItem key={data.ID}>
                                  <Chip
                                    label={data.Name}
                                    onDelete={() => removeFromDistributionLists(data.ID)}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                  <Grid className={classes.formListItem} container item xs={12} justifyContent="center" alignItems="center">
                    <Grid item xs={12} container justifyContent="flex-start">
                      <Typography className={classes.formItem} variant="body1">{t('lifeline:createVideo.finalize.comments')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder="Your comment here"
                        multiline
                        rows={2}
                        maxRows={4}
                        onChange={(e) => setTag(e.target.value)}
                        style={{ width: '100%' }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ style: { color: 'black', minHeight: '50px', padding: '10px', border: '1px solid black', borderRadius: '10px' } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.formListItem} container item xs={12} justifyContent="center">
                    <Button disabled={lifelineStore.uploading || recording} className={classes.submitButton} onClick={uploadLifeline}>{t('lifeline:createVideo.finalize.upload')}</Button>
                  </Grid>
                </>
              }
          </Grid>
          </>
      }
    </Container >
  )
}

export default observer(FinalizeVideo);
