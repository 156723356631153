import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class TopGunStore {
    constructor() {
        makeAutoObservable(this)
    }


    topGunInfoLoading = null
    topGunInfo = null

    error = null

    setError(error, type = "topGun") {
        error = extractErrorMessage(error)
        this.error = error
     }


    loadInfo = generateLoadEntity( "topGunInfo", this, "topGunInfoLoading"
        , (lang)=>{ return services.TopGun.fetch(lang) }, "topGunInfo" )




}

export default TopGunStore