import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {useStores} from "../../../hooks/use-stores";
import {useTranslation} from "react-i18next";


function UplineSearch({handleUserSelectFromSearch, show, onlyId=true, enableUplineSearch}){
    const {t} = useTranslation("teams");
    const [searchValue, setSearchValue] = React.useState("");

    const {myTeamsStore} = useStores();

     const { searching, searchResults} = myTeamsStore
    useEffect(() => {
        if (!show)
            setSearchValue("")
    }, [show]);

    const handleSearchStart = () => {
        if (enableUplineSearch) {
            myTeamsStore.searchUsersIncludingUpline(searchValue);
        } else {
            myTeamsStore.searchUsers(searchValue);
        }
        
    }

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };


    const handleKey = (e) => {
        if (e.key === 'Enter') {
            if (searchValue)
                handleSearchStart()
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return <Box padding={2}>
        <OutlinedInput
            id="filled-adornment-password"
            type={'text'}
            value={searchValue}
            margin={"dense"}
            fullWidth
            autoFocus
            onChange={handleSearchChange}
            onKeyUp={e => handleKey(e)}
            placeholder={t("searchPlaceholder")}
            endAdornment={
                <InputAdornment position="end">
                    {searching
                        ? <CircularProgress size={20}/>
                        : <IconButton
                            disabled={searchValue === "" || searching}
                            aria-label="toggle password visibility"
                            onClick={handleSearchStart}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        ><SearchIcon/></IconButton>}
                </InputAdornment>
            }
        />
        {searching === false && searchResults.length === 0 &&
            <Typography>{t("notfound")}</Typography>}

        {searchResults.map((searchResItem) => (
            <MenuItem key={searchResItem.LOSUserID} onClick={() => {
                handleUserSelectFromSearch( onlyId ? searchResItem.UserID: {
                    userID:searchResItem.UserID
                    , userName: `${searchResItem.FirstName} ${searchResItem.LastName}` } )
            }}>
                {searchResItem.FirstName} {searchResItem.LastName}
            </MenuItem>))}

    </Box>

}


export default observer( UplineSearch);