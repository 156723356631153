import RestService from "./RestService";


export default class UtilityService extends RestService {
    constructor() {
        super('/public/util');
    }


    async getAllCountries(code) {
        return this.get(`${this.baseUrl}/countrylist`);
    }

    async getRegionByCountry(country) {
        return this.get(`${this.baseUrl}/regions/${country}`);
    }

    async verifyAddess(address) {
        return this.post(`${this.baseUrl}/address/verify`, address);
    }

    async getIPAddress(address) {
        return this.get(`https://api.bigdatacloud.net/data/ip-geolocation-with-confidence?key=36882e08c9db4e5b874fb563093338aa`);
    }
}
