import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import IFrame from "../../components/IFrame";
import { joinPath } from "../../utils/helpers";
import SeminarWebinar from "./SeminarWebinar";

export default observer(function Wallet() {
    const eventsMatch = useRouteMatch();

    return <Switch>

        <Route path={joinPath(eventsMatch.url, `schedule`)} render={props => {
            return <IFrame tag={"events/schedule"} />
        }} />
        <Route path={joinPath(eventsMatch.url, `purchase_tickets`)} render={props => {
            return <IFrame tag={"events/purchase_tickets"} />
        }} />
        <Route path={joinPath(eventsMatch.url, `seminar_webinar/:year?/:month?`)} render={props => {
            return <SeminarWebinar />
        }} />
        <Route path={joinPath(eventsMatch.url, `pay-per-view`)} render={props => {
            return <IFrame tag={"events/pay-per-view"} />
        }} />


    </Switch>
})