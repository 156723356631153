import React from "react";
import {  observer } from "mobx-react-lite";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import { joinPath} from "../../../utils/helpers";
import BillingInfoList from "./BillingInfoList";
import CardInfoEdit from "./CardInfoEdit";
import BankInfoEdit from "./BankInfoEdit";


export default observer ( function ShippingInfoRoot () {
    const billingInfoMatch = useRouteMatch();

    return  <Switch>
        <Route path={joinPath( billingInfoMatch.url, `/card/create`)}  render={props=>{
            return <CardInfoEdit parentMatch={billingInfoMatch}/>
        }} />
        <Route path={joinPath( billingInfoMatch.url, `/card/:cardID/edit`)}  render={props=>{
            return <CardInfoEdit  parentMatch={billingInfoMatch} />
        }} />

        <Route path={joinPath( billingInfoMatch.url, `/bank/create`)}  render={props=>{
            return <BankInfoEdit parentMatch={billingInfoMatch}/>
        }} />
        <Route path={joinPath( billingInfoMatch.url, `/bank/:bankID/edit`)}  render={props=>{
            return <BankInfoEdit  parentMatch={billingInfoMatch} />
        }} />

        <Route path={joinPath( billingInfoMatch.url, `view_all`)} render={props=>{
            return <BillingInfoList parentMatch={billingInfoMatch}/>
        }} />
        <Route path={joinPath( billingInfoMatch.url, `view_add_remove`)} render={props=>{
            return <BillingInfoList parentMatch={billingInfoMatch}/>
        }} />
        <Route path={billingInfoMatch.url}  render={props=>{
            return <BillingInfoList parentMatch={billingInfoMatch}/>
        }} />



    </Switch>
})