import {makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity, generateLoadList, generateUpdateEntity} from "../utils/mobx";

function transformOldGiftCard(card){
    return {
        giftCardID:card.orderNumberDetail,
        merchantName: card.merchant,
        logo: card.logo,
        amount: card.denomination,
        orderDate: card.orderDate,
        deliveryURL: card.cardURL,
        balanceCheck: card.balanceCheck,
        orderNumberDetail: card.orderNumberDetail,
        isLegacy: true,
        archived: card.archived,
    }
}
class GiftCardsStore {
    constructor(commonStore) {
        makeAutoObservable(this)
        this.commonStore = commonStore
    }


    activeGiftCardsLoading = null
    archivedGiftCardsLoading = null
    activeGiftCards = []
    archivedGiftCards = []
    giftCardsUpdating = []
    notesLoading = false
    notesUpdating = []
    cardsNotes = {}

    error = null

    setError(error, type = "support") {
        error = extractErrorMessage(error)
        this.error = error
     }

    async loadGiftCards (){
        await Promise.all([ this.loadAllNotes(),  this.loadActiveGiftCards(), this.loadArchivedGiftCards()]);
    }

    setItemDraftNotes(item, notes){
        item.noteDraft = notes;
    }


    loadActiveGiftCards = generateLoadList( "giftCards", this, "activeGiftCardsLoading"
        , async ()=>{
            let legacyCards
            try{
                legacyCards = await  services.GiftCards.list("ACTIVE")
            }catch(e){}
            const userCards = await services.GiftCards.listUserCards("ACTIVE")
            return [ ...( userCards? userCards.giftCards || [] :[]), ...(legacyCards?legacyCards.user || [] :[]).map(transformOldGiftCard)]

        }, "activeGiftCards",  )


    loadArchivedGiftCards = generateLoadList( "giftCards", this, "archivedGiftCardsLoading"
        , async ()=>{
            let legacyCards
            try{
                legacyCards = await  services.GiftCards.list("ARCHIVED")
            }catch(e){}
            const userCards = await services.GiftCards.listUserCards("ARCHIVED")
            return [ ...( userCards? userCards.giftCards || [] :[]), ...(legacyCards?legacyCards.user || []:[]).map(transformOldGiftCard)]

        }, "archivedGiftCards")


    async toggleGiftCardStatus(item){
        const result =await this.updateGiftCardStatus(item);
        if ( !result ) return
        item.archived = !item.archived;
        if ( item.archived ){
            this.activeGiftCards.remove( item )
            this.archivedGiftCards.push( item )
            this.commonStore.success(this.commonStore.i18n.t("gift_cards:archived_done"))
        }
        else{
            this.archivedGiftCards.remove( item )
            this.activeGiftCards.push( item )
            this.commonStore.success(this.commonStore.i18n.t("gift_cards:activated_done"))
        }
        return true
    }

    updateGiftCardStatus = generateUpdateEntity( "giftCards", this, "giftCardsUpdating"
        , async (selectedItem)=>{
             let r;
             if (selectedItem.isLegacy)
                 r = await services.GiftCards.updateGiftCardStatusLegacy(!selectedItem.archived? "ARCHIVED":"ACTIVE", selectedItem.orderNumberDetail)
            else
                 r = await  services.GiftCards.updateGiftCardStatus(!selectedItem.archived? "ARCHIVED":"ACTIVE", selectedItem.giftCardID )
            return r
        }, null, {keyGetter: (item)=>item.giftCardID } )


    loadNotes = generateLoadEntity( "loadNotes", this, "notesLoading",
        async (giftCard)=>{
            const result = await services.GiftCards.notes(giftCard.giftCardID)
            runInAction(()=>{
                giftCard.notes = result.notes
            })
        });

    loadAllNotes = generateLoadEntity( "loadNotes", this, "notesLoading",
        async ()=>{
            return  await services.GiftCards.notes()
        },  "cardsNotes");

    updateNotes = generateUpdateEntity( "updateNotes", this, "notesUpdating",
        async (giftCardID, notes)=>{
            await services.GiftCards.updateNotes(giftCardID, notes)
            runInAction(()=>{
                this.cardsNotes[giftCardID] = {...this.cardsNotes[giftCardID], notes}
            })
        });

    checkBalance = generateLoadEntity( "giftCards", this, "archivedGiftCardsLoading"
        , async (item)=>{
            try {
                item.balanceChecking = true
                const result = await services.GiftCards.balance(item.orderNumberDetail)
                runInAction(()=>{
                    item.balance = result
                })
            }
            finally {
                runInAction(()=>{
                    item.balanceChecking = false
                })
            }

        }, "archivedGiftCards", "user" )




}

export default GiftCardsStore