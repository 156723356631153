import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { EmailOutlined, DraftsOutlined, KeyboardArrowRightOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { useStores } from "../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useRouteMatch, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    whiteColor: {
        color: "white"
    },

}));

export default observer(function News({ fromModule = null }) {
    const classes = useStyles();
    const { newsStore } = useStores();
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { news, newsLoading, error } = newsStore;

    useEffect(() => {
        (async () => {
            await newsStore.loadNews(fromModule);
        })();
    }, []);


    return <div className={classes.whiteColor}>
        <Typography variant="h5">{t("global:announcements")}</Typography>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {newsLoading ? <CircularProgress />
            : <List>
                {news.map(n => {
                    return <>
                        <ListItem key={n.newsID} button component={Link} to={`${match.url}/${n.newsID}`} state={{ fromModule }}>
                            <ListItemIcon className={classes.whiteColor}>
                                {n.isNew ? <EmailOutlined /> : <DraftsOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={n.date} secondary={n.title}
                                classes={{
                                    primary: classes.whiteColor, // class name, e.g. `classes-nesting-root-x`
                                    secondary: classes.whiteColor, // class name, e.g. `classes-nesting-label-x`
                                }}
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <KeyboardArrowRightOutlined className={classes.whiteColor} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </>;
                })}

            </List>}

    </div>;
});