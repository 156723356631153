import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const packageNamesRed = [
    { key: 'PkgAGO', name: 'AGO' },
    { key: 'PkgBOM', name: 'BOM' },
    { key: 'PkgEdge', name: 'Edge' },
    { key: 'PkgFF', name: 'FF' },
    { key: 'PkgFFS', name: 'FFS' },
    { key: 'PkgFreedom', name: 'Free' },
    { key: 'PkgOpen', name: 'Libr' },
    { key: 'PkgLIFE', name: 'Life' },
    { key: 'PkgSeminar', name: 'Live' },
    { key: 'PkgLLR', name: 'LLR' },
    { key: 'PkgLLRC', name: 'LLRC' },
    { key: 'PkgRascal', name: 'RR' },
    { key: 'PkgTA', name: 'TA' },
    { key: 'PkgTPD', name: 'TPD' },
    { key: 'PkgTS', name: 'TS' },
    { key: 'PkgWH', name: 'WH' },
    { key: 'PkgMIX', name: 'MIX' },
    { key: 'PkgPremLife', name: 'PremLife' },
    { key: 'PkgAppSuperPlus', name: 'Royal' },
    { key: 'PkgAppStandard', name: 'App Standard' },
    { key: 'PkgAppSuper', name: 'Platform' },
];

const packageNamesBlue = [
    { key: 'PkgLT', name: 'MS' },
    { key: 'PkgSOT', name: 'SOT' },
    { key: 'PkgMSP', name: 'MSP' },
];

const useStyles = makeStyles((theme) => ({

  red:{
      color: "#d83634"
  },
  blue:{
      color: "#00a4e3"
  }
}))


function UserSubscriptions({user}){
    const classes = useStyles();

    const redPackages = packageNamesRed
    .filter((pckg) => user[pckg.key] > 0)
    .map((pckg) => pckg.name)
    .join('/');
    const bluePackages = packageNamesBlue
    .filter((pckg) => user[pckg.key] > 0)
    .map((pckg) => pckg.name)
    .join('/');

    return (
        <div>
            Subscription:{' '}{' '}{' '}
            <span className={classes.red}>{redPackages}</span>{' '}{' '}{' '}
            <span className={classes.blue}>{bluePackages}</span>
        </div>
    );
}


export default UserSubscriptions