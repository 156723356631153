import React, {useEffect, useMemo,} from "react";
import { observer } from "mobx-react-lite";
import {CircularProgress, Container} from '@material-ui/core';
import menu from './menu.json';
import {Switch, useRouteMatch} from "react-router-dom";
import LinksMenu from "./LinksMenu";
import {Route} from "react-router";
import {joinPath} from "../../utils/helpers";
import Onboard from "../Members/Onboard";
import {useStores} from "../../hooks/use-stores";

function Royal ( {parentMatch} ){
    const {bannerStore}  = useStores();
    const {bannersLoading}  = bannerStore;
    const match = useRouteMatch();
    const MainLinks = useMemo(()=>{
         const items = menu.find(x=>x.tag==="royal").items
        return items
    },[menu])

    useEffect(()=>{
            if (!bannersLoading &&  bannerStore.royalBanner.length === 0 ) {
                void bannerStore.loadBanners("royal")
        } }, [] )


    return (
        <Container>
            { bannersLoading
                ?<></>
                : <>
                <Switch>
                    <Route
                        path={joinPath(match.url, `/explorelife/welcome`)}
                        render={() => {
                            return <Onboard parentMatch={match} tagAsParam={"getstarted"} navigation={"inplace"}
                                            showLogoImage={false}  showBottomTitle isStarfish/>
                        }}
                    />

                    <Route render={props => {
                        return  <LinksMenu items={MainLinks} showBack={false} />
                    }}/>
                </Switch>
            </>}
        </Container>
    );
}

export default observer(Royal);