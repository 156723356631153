import {AppBar, Box, Container} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import {Link} from "react-router-dom";
import {handleBack, joinPath} from "../utils/helpers";
import ShareIcon from "@material-ui/icons/Share";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import {observer} from "mobx-react";
import {Route, Switch, useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AudioPlayer from "./AudioPlayer";
import {useStores} from "../hooks/use-stores";
import {useShopType} from "../containers/shop/useShopType";
import ShopIcon from "@material-ui/icons/ShoppingCart";


const useStyles = makeStyles((theme) => ({
    appBarRoot: {
        position: "fixed",
        height: 58,
        backgroundColor: "black",
        borderBottom: "1px solid #f50057"
    },
    appBarContainer: {
        height: 58
    },
    appbarIcons: {
        whiteSpace: "nowrap"
    },
    appbarToolbar: {
        height: "100%"
    },
    smallAvatar: {
        width: 30,
        height: 30
    },

    whiteIcon: {
        color: "white"
    },
    grow: {
        flexGrow: 1,
    },
    appLogo: {
        paddingLeft: theme.spacing(1),
        whiteSpace: "nowrap",
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    appLogoImg: {
        height: 27,
        width: 27,
    },
    appLogoText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "35px",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    backButton: {
        flex: 1,
        minWidth: "fit-content"
    },

    rightCell: {
        flex: 1,
        minWidth: 100
    },
    simpleTitle: {
        flex: "1 1 auto",
        textAlign: "center",
        textTransform: "uppercase",

    },

}));

function SimpleAppBar({classes, title}) {
    const history = useHistory();

    return <Toolbar className={classes.appbarToolbar}>
        <Box display="flex" width="100%" alignItems={"center"}>
            <Button  onClick={()=> { handleBack(history) } } className={classes.backButton} size="small" startIcon={<ArrowBackIcon/>}>Back</Button>
            <div className={classes.simpleTitle}>{title}</div>
            <div className={classes.rightCell}/>
        </Box>
    </Toolbar>
}


function DefaultAppBar({
                           classes,
                           handleDrawerOpen,
                           currentUser,
                           prefix,
                           isPhone,
                           isDesktop,
                           open,
                           notificationsCounter,
                           handleBellMenuOpen,
                           handleUserMenuOpen,
                           cartItems
                       }) {
    return <Container maxWidth={"md"} className={classes.appBarContainer}>
        <Toolbar disableGutters variant="dense" className={classes.appbarToolbar}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                    [classes.hide]: isDesktop || open,
                })}
            >
                <MenuIcon/>
            </IconButton>
            <span className={classes.appLogo}>
                        <img src={"/logo.png"} className={classes.appLogoImg}/>
                        <span className={classes.appLogoText}>Life</span>
                    </span>
            <div className={`${classes.grow}`}>
            </div>
            <AudioPlayer isPhone={isPhone} />
            <div className={classes.appbarIcons}>
                {( cartItems && cartItems>0 ) ? <Link to={"/shop/cart"}><Badge badgeContent={cartItems} color="secondary">
                    <ShopIcon className={classes.whiteIcon} />
                </Badge></Link>: null }
                <IconButton color="inherit">
                    <Link to={joinPath(prefix, "share")}>
                        <ShareIcon className={classes.whiteIcon}/>
                    </Link>
                </IconButton>
                <IconButton aria-label={`show ${notificationsCounter} new notifications`} color="inherit"
                            onClick={handleBellMenuOpen}>
                    <Badge badgeContent={notificationsCounter} color="secondary">
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
                <IconButton color="inherit" onClick={handleUserMenuOpen}>
                    <Avatar src={currentUser.photoURL} variant={"square"} className={classes.smallAvatar}/>
                </IconButton>
            </div>

        </Toolbar>
    </Container>
}

function AppBarContainer({isNativeApp, isDesktop, ...props}) {
    const classes = useStyles({isNativeApp});
    const { shopStore } = useStores();
    const shopOptions = useShopType()
    const {cartGuidProp, cartItemsProp} = shopOptions
    const  cartItems = shopStore[cartItemsProp]

    return <AppBar color={"secondary"} position="static" classes={{root: classes.appBarRoot}}>
        <Switch>
            {!isDesktop &&
                <Route path="/wallet/ewallet/add_more_money"
                                  render={() => <SimpleAppBar classes={classes} title={"Add Money"}/>}/>

                }
            <Route render={() => <DefaultAppBar classes={classes} cartItems={cartItems} {...{isDesktop, isNativeApp}} {...props}/>}/>
        </Switch>

    </AppBar>
}

export default observer(AppBarContainer)