import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        width: '100%',
        padding: 0
    },
    backButton: {
        cursor: 'pointer'
    },
    buttonText: {
        fontSize: 14,
        color: '#007BFF',
        textAlign: 'left',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    }
}));

const Header = ({ buttonText, onBackButtonPressed }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.header} alignItems="center">
            {onBackButtonPressed &&
                <Box onClick={onBackButtonPressed} display="flex" className={classes.backButton}>
                    <div><FontAwesomeIcon icon={faChevronLeft} size="14px" fontWeight={'bold'} style={{marginRight: '8px'}} /></div>
                    <Typography className={classes.buttonText} variant="body1">{buttonText || t('shoppingBoss:header.back')}</Typography>
                </Box>
            }
        </Box>
    )
}

export default Header;