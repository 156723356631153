import {makeAutoObservable, runInAction} from 'mobx'
import services from "../services"
import {extractErrorMessage, patchCDN} from "../utils/helpers";
import {generateUpdateEntity} from "../utils/mobx";

class AudioStore {
    constructor(libraryStore, authStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
        this.setAuthStore  = (authStore)=>{  this.authStore = authStore }
    }

    element = null
    media = null
    sendOutMedia = null
    audioPaused = false
    markingProgress = false
    audioProgress = 0
    currentTime = 0
    completeMarked = {}
    playbackRate = 1


    setError(error, type = "audioPlayer") {
        error = extractErrorMessage(error)
        this.error = error
     }

     setElement(element){
        this.element = element
         this.restartIfNeed()
     }

     setPlaybackRate(rate){
        this.playbackRate = rate
        if ( this.element?.audio?.current )
            this.element.audio.current.playbackRate = rate
     }

    toggleProgressRate(){
        if ( this.playbackRate === 1 )
            this.setPlaybackRate(1.5)
        else if ( this.playbackRate === 1.5 )
            this.setPlaybackRate(2)
        else
            this.setPlaybackRate(1)
    }


    setAudioPaused(value){
        if ( !value ){
            if (!this.audioProgressTimer ){
                this.audioProgressTimer = setInterval( ()=>{ this.markProgress() }, 1*60*1000 ) //fix update period here
            }
        }
        else {
            this.markProgress()
            if (this.audioProgressTimer) {

                clearInterval(this.audioProgressTimer)
                this.audioProgressTimer = null
            }

        }
        this.audioPaused = value
    }

    markProgress  = generateUpdateEntity( "markProgress", this, "markingProgress", async ()=>{
        if ( this.element?.audio?.current )
            if ( this.element.audio.current.currentTime > 0 ) {
                let isComplete = Math.round( 100* this.element.audio.current.currentTime/ ( this.element.audio.current.duration - 2*60 ) )>=100
                await services.Library.audioProgress(this.mediaID, Math.round(this.element.audio.current.currentTime),
                    isComplete )
                if ( isComplete)
                    this.completeMarked[this.mediaID] = true
            }
    }, null, {throwError:true} )

    setAudioProgress(e){
        if ( this.element?.audio?.current ) {
            let isComplete = Math.round(100 * this.element.audio.current.currentTime / (this.element.audio.current.duration - 2 * 60)) >= 100
            if (isComplete && !this.completeMarked[this.mediaID]) {
                this.markProgress()
            }
            this.audioProgress = Math.round(100 * e.currentTarget.currentTime / e.currentTarget.duration);
            this.currentTime = e.currentTarget.currentTime
        }
    }


    stopAudio(  ){
        this.media = null;
        this.audioProgress = 0;
        this.currentTime = 0;
        this.audioPaused = false
    }

    pauseMedia(  ){
        if ( this.element?.audio?.current ) {
            this.element.audio.current.pause();
        }

    }

    restartIfNeed(  ){
        if ( this.element?.audio?.current  && this.media && !this.audioPaused ) {
            this.element.audio.current.play();
            if ( this.currentTime )
                this.element.audio.current.currentTime = this.currentTime
            if ( this.element?.audio?.current )
                this.element.audio.current.playbackRate = this.playbackRate

        }
    }

    async storeSwitchPlayerPositionRestoreTime( ){
        this.switchPlayerPositionRestoreTime  = this.element.audio.current.currentTime
    }
    async setStartPosition( ){
        if ( !this.element ) return
        this.updateTotalTime(this.mediaID, this.element.audio.current.duration)

        if ( this.libraryStore.playedIndex[this.mediaID])
            this.libraryStore.playedIndex[this.mediaID].totalMediaTime= this.element.audio.current.duration

        let restoreTime =   this.switchPlayerPositionRestoreTime

        if( !restoreTime )
            restoreTime = this.libraryStore.playedIndex[this.mediaID] &&  this.libraryStore.playedIndex[this.mediaID].resumeTime

        if ( restoreTime){
            this.element.audio.current.currentTime = restoreTime
        }

        this.switchPlayerPositionRestoreTime = null

    }


     updateTotalTime = generateUpdateEntity( "updateTotalTime", this, null,
         async (mediaID, duration )=>{
                return services.Library.audioTotalTime(mediaID, Math.round(duration) )
            }
     )

    async setSendOutMedia( media ){
        this.sendOutMedia = media
    }
    async setMedia( media ){
        //if ( media.mediaType !== "audio") return
        if ( media.mediaID === this.mediaID ){
            if (this.audioPaused ) {
                this.element.audio.current.play();
            }
            else
                this.element.audio.current.pause();
            return
        }
        this.audioPaused = false
        if ( media.direct ){
            this.media = media
            this.audioProgress = 0;
            this.currentTime = 0;
        }else {
            this.libraryStore.detailsLoadingFor.push(media.mediaID)
            try {
                let details = await services.Library.details(media.mediaID)
                if (details) {

                    runInAction(() => {
                        this.media = details[0];
                        this.audioProgress = 0;
                    })
                }
            } finally {
                runInAction(() => {
                    this.libraryStore.detailsLoadingFor.remove(media.mediaID)
                })
            }
        }
        //console.log( details )
    }
    get isPlayerVisible(){
        return this.element && !!this.mediaID;
    }

    get mediaURL(){
        const url = this.media && this.media.mediaURL;
        return patchCDN(url)

    }
    get mediaID(){
        return this.media && this.media.mediaID;
    }
    get canBuy(){
        return this.media && !this.media.hasPurchased;
    }
    get mediaTitle(){
        return this.media && this.media.title;
    }
    get mediaImageURL(){
        return this.media && this.media.imageURL;
    }
    get mediaSpeakers(){
        return this.media && (this.media.speakers||[]).map(sp=>sp.speakerName).join(",")
    }


}

export default AudioStore