import React, {useEffect} from "react";
import {  observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import {useStores} from "../hooks/use-stores";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useRouteMatch, Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {joinPath, patchCDN} from "../utils/helpers";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ThumbMediaList from "../components/ThumbMediaList";
import IconButton from "@material-ui/core/IconButton";
import debounce from "lodash/debounce";
import services from "../services";
import MediaList from "../components/MediaList";
import Favorite from "@material-ui/icons/Favorite";
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root:{
        color:"white",
        width:"100%",
    },
    margin:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },

    colorWhite:{
        color:"white",
    },
    grid: {

        "&:after":{
            content: '""',
            flex: "auto"
        }
    },

    btnSeries:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        color: "white",
        textDecoration:"none"
    },


    seriesIcon: {
        borderRadius: "50%",
        width: "100%"
    },
    lineClamp:{
        margin: "5px 0",
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.5em",
    },

    notchedOutline:{
        borderColor: "white"
    },
    favoritesPlaceholder:{
        display:"flex",
        width:120,
        height:120,
        borderRadius: 3,
        fontStyle: "italic",
        color: "#999",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",
    }
}));

export default observer ( function News () {
    const classes = useStyles();
    const {t} = useTranslation();

    const { authStore, libraryStore, salesAchieverAudiosStore, allTimesGreatStore, rascalTunesStore, mediaHandlers } = useStores();
    const match = useRouteMatch();
    const {series, speakers, libraryLoading, recentlyPlayed, recentlyPurchased, audiosFavorites, searchString, error } = libraryStore;
    const {series: salesAchieverSeries, ppsSeries } = salesAchieverAudiosStore;
    const { allTimesSeries } = allTimesGreatStore;
    const { rascalTunesSeries } = rascalTunesStore;
    const [loading, setLoading] = React.useState(false)
    const [searchResults, setSearchResults] = React.useState(null)
    const [searchError, setSearchError] = React.useState(null)
    const fetchIdRef = React.useRef(0)


    useEffect(()=>{
        ( async ()=>{
            await Promise.all([ libraryStore.loadLibrary()
                , salesAchieverAudiosStore.loadSeries()
                , allTimesGreatStore.loadSeries()
                , rascalTunesStore.loadSeries()
            ])
        })()
    },[])


    const fetchData = React.useCallback( debounce( async (searchString)=>{
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current
        setSearchError(null)
        if ( !searchString || searchString.length < 2 ){
            setLoading(false)
            setSearchResults ( null )
            return
        }
        setLoading(true)
        try {
            let result = await services.Library.search(searchString)


            if (fetchId === fetchIdRef.current) {
                if ( result && result.media ){
                    setSearchResults ( result.media )
                }
                else
                    setSearchResults ( [] )

            }
        }
        catch(e){
            if (fetchId === fetchIdRef.current) {
                setSearchError(e.message)
            }
        }
        finally{
            setLoading(false)
        }
    }, 500 ), [])

    useEffect(()=>{
        fetchData(searchString)
    },[fetchData, searchString])

    return <div className={classes.root}>
        <Typography variant="h5">Media Library</Typography>
        {error ?  <Alert severity="error">{error}</Alert> : null}


        <FormControl fullWidth className={classes.margin} variant="outlined">
            <OutlinedInput classes={{notchedOutline:classes.notchedOutline, input:classes.colorWhite}}
                id="outlined-adornment-amount"
                placeholder={"Search..."}
                value={searchString}
                autoComplete={"off"}
                onChange={e=>{ libraryStore.setSearchString(e.target.value) }}
                endAdornment={(
                    searchString && <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={e=>{libraryStore.setSearchString("") }}
                        ><CancelIcon /></IconButton>
                    </InputAdornment>)}
                startAdornment={<InputAdornment className={classes.colorWhite} position="start"><SearchIcon/></InputAdornment>}

            />
        </FormControl>

        { (libraryLoading  || loading ) ? <CircularProgress/>
            :<>
                {searchError&& <Alert variant={"filled"} severity={"error"}>{searchError}</Alert>}
                {searchResults
                    ?<>
                        {( searchResults && searchResults.length>0 )
                            ? <MediaList items={searchResults}
                                         onAudioPlay={mediaHandlers.handleAudioPlay}
                                         onOpenBook={mediaHandlers.handleBookOpen}
                                         onVideoOpen={mediaHandlers.handleVideoOpen}/>
                            : <Alert variant={"filled"} severity={"warning"}>Nothing Found</Alert>
                        }
                     </>
                    :<> { authStore.currentUser.isMember && salesAchieverSeries &&
                    <>
                        <Typography variant="h5">{t("global:salesAchieverAudios")}</Typography>
                        <ThumbMediaList items={salesAchieverSeries}
                                        onAudioPlay={mediaHandlers.handleAudioPlay}
                                        onAudioPause={mediaHandlers.handleAudioPause}
                                        forceType={"audio"}
                        />

                    </>}
                    { authStore.currentUser.isMember && ppsSeries &&
                    <>
                        <Typography variant="h5">Power Player Series</Typography>
                        <ThumbMediaList items={ppsSeries}
                                        onAudioPlay={mediaHandlers.handleAudioPlay}
                                        onAudioPause={mediaHandlers.handleAudioPause}
                                        forceType={"audio"}
                        />

                    </>}

                        { ( recentlyPurchased  && recentlyPurchased.length>0 )&&
                        <>
                            <Typography variant="h5">Recently Released</Typography>
                            <ThumbMediaList items={recentlyPurchased}
                                            onAudioPlay={mediaHandlers.handleAudioPlay}
                                            onOpenBook={mediaHandlers.handleBookOpen}
                                            onVideoOpen={mediaHandlers.handleVideoOpen}
                            />

                        </>}
                        { ( recentlyPlayed && recentlyPlayed.length>0 )&&
                        <>
                            <Typography variant="h5">Recently Played</Typography>
                            <ThumbMediaList items={recentlyPlayed}
                                            onAudioPlay={mediaHandlers.handleAudioPlay}
                                            onOpenBook={mediaHandlers.handleBookOpen}
                                            onVideoOpen={mediaHandlers.handleVideoOpen}
                            />

                        </>}

                        { ( allTimesSeries && allTimesSeries.length>0 )&&
                            <>
                                <Typography variant="h5">All-Time Greats</Typography>
                                <ThumbMediaList items={allTimesSeries}
                                                onAudioPlay={mediaHandlers.handleAudioPlay}
                                                onOpenBook={mediaHandlers.handleBookOpen}
                                                onVideoOpen={mediaHandlers.handleVideoOpen}
                                />

                            </>}

                        { ( rascalTunesSeries && rascalTunesSeries.length>0 )&&
                            <>
                                <Typography variant="h5">Rascal Tunes</Typography>
                                <ThumbMediaList items={rascalTunesSeries}
                                                onAudioPlay={mediaHandlers.handleAudioPlay}
                                                onOpenBook={mediaHandlers.handleBookOpen}
                                                onVideoOpen={mediaHandlers.handleVideoOpen}
                                />

                            </>}


                            <>
                                <Typography variant="h5" style={{display:"flex", alignItems:"center"}}>Favorites
                                    <Favorite style={{marginLeft:10, color:"red"}}/> </Typography>
                                { ( audiosFavorites && audiosFavorites.length>0 )
                                    ? <ThumbMediaList items={audiosFavorites}
                                                      onAudioPlay={mediaHandlers.handleAudioPlay}
                                                      onOpenBook={mediaHandlers.handleBookOpen}
                                                      onVideoOpen={mediaHandlers.handleVideoOpen}
                                    />
                                    : <Box className={classes.favoritesPlaceholder}>
                                        <Typography variant="body1">You have no favorite audios yet</Typography>
                                    </Box> }


                            </>

                        <Typography variant="h5">Browse By Categories</Typography>
                        <Grid spacing={3} container direction="row" alignItems="flex-start" justifyContent="space-between" className={classes.grid}>
                            {series.map( s=>{
                                return <Grid key={s.seriesValue} item xs={4} sm={2} md={2} lg={2}>
                                    <Link to={`${joinPath( match.url, "series", s.seriesValue )}`} className={classes.btnSeries}>
                                        <img className={classes.seriesIcon}
                                             src={`https://resources.lifeinfoapp.com/public/icon/library/${s.imageURL || s.seriesValue}.png`}/>
                                        <span className={classes.lineClamp}>{s.seriesName}</span>
                                    </Link>
                                </Grid>
                            })}
                        </Grid>
                        <Typography variant="h5">Browse By Speakers</Typography>
                        <Grid spacing={3} container direction="row" alignItems="flex-start" justifyContent="space-between" className={classes.grid}>
                            {speakers.map( s=>{
                                return <Grid key={s.speakerID} item xs={4} sm={2} md={2} lg={2}>
                                    <Link to={`${joinPath( match.url, "speakers", s.speakerID )}`} className={classes.btnSeries}>
                                        <img className={classes.seriesIcon}
                                             src={ patchCDN( s.speakerImage )}/>
                                        <span className={classes.lineClamp}>{s.speakerName}</span>
                                    </Link>
                                </Grid>
                            })}

                        </Grid>
                    </>}


            </>}

    </div>
})