import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons';
import {makeStyles} from "@material-ui/core/styles";
import {observer} from "mobx-react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from "@material-ui/core/Link";
import {joinPath} from "../../../../utils/helpers";

const useStyles = makeStyles((theme) => ({
    root:{
        backgroundColor: "#111",
        borderLeft: "1px solid #000000",
        paddingLeft: 22,
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 20
    },
    link:{
            textDecoration: "underline"
    }
}))


function BreadCrumb({shoppingMatch, parentCategory, childCategory, homeUrl, homeItems, items }){
//console.log("BreadCrumb", shoppingMatch, parentCategory, childCategory)
    const classes = useStyles()
    const { t } =useTranslation();
    return (
        <div className={classes.root}>
            <Breadcrumbs separator={<NavigateNextIcon color={"primary"} fontSize="small" />} aria-label="breadcrumb">
                {homeItems
                    ? homeItems.map((item, index) => <Link  component={RouterLink}  key={item.url + "_" + index} to={item.url}>{item.label}</Link>)
                    : <Link component={RouterLink} to={homeUrl || shoppingMatch.url}>
                        <FontAwesomeIcon data-dismiss="modal" icon={faShoppingBag} size="1x" />
                    </Link>
                }
                {parentCategory && <Link component={RouterLink}  className={classes.link}
                                         to={
                                             (parentCategory?.parentCategoryID===790 && homeUrl)
                                                 ? joinPath( homeUrl, `/royal/marketplaceproducts`)
                                                 : joinPath( shoppingMatch.url, `category/${parentCategory.parentCategoryID}`)}>
                    {t(`shopping:categories.${parentCategory.parentCategoryName}`)}
                </Link>}
                {childCategory && <Link component={RouterLink} className={classes.link}
                                        to={joinPath( shoppingMatch.url, `category/${parentCategory.parentCategoryID}/sub-category/${childCategory.childCategoryID}`)}>
                    {t(`shopping:subcategories.${childCategory.childCategoryName}`)}
                </Link>}
                {items
                    ? items.map((item, index) => <Link  component={RouterLink}  key={item.url + "_" + index} to={item.url}>{item.label}</Link>)
                    : null
                }
            </Breadcrumbs>
        </div>

    )

}

export default observer(BreadCrumb)



