import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
    },
}));

const Progress = () => {
    const classes = useStyles();

    return (
        <Box className={classes.progressContainer}>
            <CircularProgress />
        </Box>);
}

export default Progress;