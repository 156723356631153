import { Button, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStores } from "../../hooks/use-stores";
import { getMonths } from "./PVTransfer";
import BookKitSelect, { bookKitKey } from "./components/BookKitSelect";
import UserSearch from "./components/UserSearch";
import { ContentPageStyles } from "./styles";

const bookKitTransferDocId = "666c1a8db75d7b28468f71e1";

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  pageLoading: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  page: { padding: "0 16px 32px 16px" },
  submitButton: { marginTop: "20px" },
  inputLabel: {
    width: "200px",
    lineHeight: "40px",
    margin: "0 0 12px 0",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      margin: "0",
    },
  },
  labelWrapper: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    height: "40px",
    marginBottom: "20px",
  },
  label: {
    width: "fit-content",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
    },
  },
  select: {
    "& .MuiSelect-select": {
      padding: "10.5px 36px 10.5px 16px",
    },
  },
}));

function BookKitTransfer() {
  const classes = useStyles();
  const { authStore, webTextStore, pvTransferStore, commonStore } = useStores();
  const { webTextInfo, webTextInfoLoading } = webTextStore;
  const { pvTransferInfo, pvTransferInfoLoading } = pvTransferStore;
  const { currentUser } = authStore;
  const { language } = commonStore;

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [toastMessage, setToastMessage] = React.useState(null);

  const now = new Date();

  const availableMonths = getMonths({
    time: now,
    isLeader: currentUser.isLeader,
  });

  const [transferMonth, setTransferMonth] = useState(
    availableMonths[availableMonths.length - 1].value
  );

  const appliedMonth = [1, 2, 3].includes(now.getDate())
    ? transferMonth
    : `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

  // After fetching currentUser data, get local data from currentUser's userID
  useEffect(() => {
    if (currentUser) {
      const localKey = `${bookKitKey}-${currentUser.userID}`;
      const localData = localStorage.getItem(localKey);
      if (localData) {
        setSelectedProducts(JSON.parse(localData));
      } else {
        localStorage.setItem(localKey, JSON.stringify([]));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!pvTransferInfoLoading) {
      if (pvTransferInfo?.message) {
        setSelectedProducts([]);
        localStorage.setItem(
          `${bookKitKey}-${currentUser.userID}`,
          JSON.stringify([])
        );
      }
    }
  }, [pvTransferInfoLoading]);

  // Fetching webtext data one time (at initial start)
  useEffect(() => {
    if (!webTextInfoLoading) {
      void webTextStore.getWebTextByLanguageAndId([
        currentUser.preferredLanguage,
        bookKitTransferDocId,
      ]);
    }
  }, []);

  if (webTextInfoLoading) {
    return (
      <div className={classes.pageLoading}>
        <CircularProgress size={90} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.page)}>
      <div dangerouslySetInnerHTML={{ __html: webTextInfo?.text }} />

      <label className={clsx(classes.labelWrapper)}>
        <span className={clsx(classes.label)}>Sales Month:</span>
        <Select
          className={clsx(classes.select)}
          labelId="month-select-label"
          value={transferMonth}
          onChange={(e) => setTransferMonth(e.target.value)}
          variant="outlined"
        >
          {availableMonths.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </label>

      <UserSearch
        pvTransferInfoLoading={pvTransferInfoLoading}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        labelClassName={classes.inputLabel}
        toAccountLabel={"Customer Account:"}
        isCustomerSearch={true}
      />
      <BookKitSelect
        pvTransferInfoLoading={pvTransferInfoLoading}
        selectedUser={selectedUser}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        labelClassName={classes.inputLabel}
      />

      <Button
        disabled={
          pvTransferInfoLoading ||
          selectedProducts.length === 0 ||
          selectedProducts.find((product) => product.Qty <= 0)
        }
        className={classes.submitButton}
        variant="contained"
        color="primary"
        onClick={async () => {
          const data = {
            selectedProducts,
            appliedMonth: appliedMonth,
            sender: {
              name: `${currentUser.firstName} ${currentUser.lastName}`,
              lang: language,
              email: currentUser.email,
              userId: currentUser.userID,
            },
            isBookKitTransfer: true,
          };
          await pvTransferStore.transferPV(data);
          setToastMessage("Sales Recorded.");
        }}
      >
        Record Sale
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!toastMessage}
        autoHideDuration={2500}
        onClose={() => setToastMessage(null)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setToastMessage(null)}
          severity="success"
        >
          {toastMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default observer(BookKitTransfer);
