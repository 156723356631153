import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import ServiceMaintainance from "../../../components/ServiceMaintainance";
import { useStores } from "../../../hooks/use-stores";
import Progress from "../../shoppingboss/components/Progress";

const MarketPlaceService = ({ serviceTag = "", component }) => {
  const { serviceStatusStore, commonStore } = useStores();

  useEffect(() => {
    void serviceStatusStore.checkStatus(serviceTag);
  }, []);

  const { serviceStatusInfo, serviceStatusLoading } = serviceStatusStore;

  if (serviceStatusLoading) {
    return <Progress />;
  }

  const { language } = commonStore;
  const serviceDownMessage =
    (language === "fr"
      ? serviceStatusInfo?.fr.message
      : serviceStatusInfo?.en.message) || "";

  return serviceStatusInfo?.isDown ? (
    <ServiceMaintainance message={serviceDownMessage} />
  ) : (
    component
  );
};

export default observer(MarketPlaceService);
