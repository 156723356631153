import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useStores} from "../../hooks/use-stores";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import {
    Box,
    Button,
    CircularProgress,
    Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    ListItem,
    ListItemText,
    Paper,
    TextField
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabContext, TabPanel} from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import {ThemeProvider} from "@material-ui/styles";
import greenButtonTheme from "../../themes/greenButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const formatterUSD = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

function formatterStatus(status, t  ){
    switch (status){
        case 1:
            return t('pending');
        case 2:
            return t('declined');
        case 3:
            return t('convertedToMember');

        default:
            return ""
    }
}

const useStyles = makeStyles((theme) => ({
    root:{
        margin: "0 -5px"
    },
    table:{
        "& .MuiTableCell-sizeSmall":{
            padding: "6px 6px"
        }
    },
    tab:{
      paddingLeft:0,
      paddingRight:0,
    },
    details:{
      display: "flex",
        gap:16,
        [theme.breakpoints.down(880)]: {
            flexDirection:"column"
        }
    },
    rowRoot: {
        '& > *': {
            borderBottom: 'unset',
        },
    },

    smallScreen:{
        display:"none",
        [theme.breakpoints.down(1450)]: {
            display:"table-cell",
        }
    },
    largeScreen:{
        display:"none",
        [theme.breakpoints.up(1450)]: {
            display:"table-cell",
        }
    },
    mediumScreen:{
        display:"none",
        [theme.breakpoints.up(700)]: {
            display:"table-cell",
        }
    },
    subIcon:{
        width:40,
        height:40,
        margin:10
    },
    subText:{

    },
    summary:{
        width: "100%",
        padding:20
    },
    expandCell:{
        padding:8
    },
    middleDetailsCell:{
        margin: 24,
        marginTop: 0,
        [theme.breakpoints.down(880)]: {
            margin: 0,
            marginBottom: 24
        }
    }
}));

function PVFormatter(value){

    return `${value} PV`

}


const Row = observer ( function Row({ row, currentUser }) {
    const {t} = useTranslation("customers");
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const {customersStore, commonStore } = useStores();
    const {sendingSeminarInvite, sendingPlatformInvite, sendingRoyalInvite} = customersStore;


    const sendSeminarInvite =  async (e, email, lang)=>{
        e.preventDefault();
        e.stopPropagation();
        let result = await customersStore.sendSeminarInvite(email, lang)
        if (result){
            commonStore.success("Invitation sent")
        }
    }

    const sendPlatformInvite =  async (e, userID, lang)=>{

        e.preventDefault();
        e.stopPropagation();
        let result = await customersStore.sendPlatformInvite(userID, lang)
        if (result){
            commonStore.success("Invitation sent")
        }
    }
    const sendRoyalInvite =  async (e, userID, lang)=>{

        e.preventDefault();
        e.stopPropagation();
        let result = await customersStore.sendRoyalInvite(userID, lang)
        if (result){
            commonStore.success("Invitation sent")
        }
    }

    const subscriptionsFormatter = useMemo( ()=>{
        if ( currentUser.isMember || currentUser.isMemberToCustomer )
            return PVFormatter
        else return formatterUSD.format

    },[currentUser] )


    return (
        <React.Fragment>
            <TableRow className={classes.rowRoot}>
                <TableCell className={classes.expandCell}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.largeScreen}>{row.UserID}</TableCell>
                <TableCell className={classes.smallScreen}>{row.FirstName} {row.LastName}<br/>({row.UserID}) </TableCell>
                <TableCell className={classes.largeScreen}>{row.FirstName}</TableCell>
                <TableCell className={classes.largeScreen}>{row.LastName}</TableCell>
                <TableCell className={classes.largeScreen}> {row.Email}</TableCell>
                <TableCell className={classes.largeScreen}>{row.Phone}</TableCell>
                <TableCell className={classes.largeScreen}>{subscriptionsFormatter(row.Subscription)}</TableCell>
                <TableCell className={classes.mediumScreen}>{moment(row.CreatedDate).format("LL")}</TableCell>
                <TableCell className={classes.mediumScreen}>{formatterStatus( row.MemberEnrollStatus, t)}</TableCell>

                <TableCell align="right" >
                    <Box display="flex" flexDirection={"column"} style={{ gap:3}} alignItems={"center"}>
                    {sendingRoyalInvite.includes(row.UserID)
                        ? <CircularProgress size={20}/>
                        : <Button fullWidth style={{whiteSpace: "nowrap", fontSize:11}}   variant="contained" color={"primary"} size={"small"}
                                  onClick={e=>{  sendRoyalInvite(e, row.UserID, row.PrefLang ) }}>
                            {t("sendRoyalInvite")}
                        </Button>}
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>

                        <Box className={classes.details}>
                                <Box  flexDirection="column" marginBottom={3} >
                                    <Typography variant={"h6"}>{t("customerInfo")}</Typography>
                                    <Typography variant={"body2"}>{t("lifeNo")}: {row.UserID}</Typography>
                                    <Typography variant={"body2"}>{t("email")}: {row.Email}</Typography>
                                    <Typography variant={"body2"}>{t("phone")}: {row.Phone}</Typography>
                                    <Typography variant={"body2"}>{t("address")}: {row.Street} {row.City} {row.Region} {row.Zip} </Typography>
                                </Box>
                                <Box flexDirection="column" className={classes.middleDetailsCell}>
                                    <Typography variant={"body2"}>{t("enrollDate")}: {moment(row.CreatedDate).format("LL")}</Typography>
                                    <Typography variant={"body2"}>{t("status")}: {formatterStatus( row.MemberEnrollStatus, t)}</Typography>
                                </Box>
                                <Box flexDirection="column" marginBottom={3}>
                                    <Typography variant={"h6"}>{t("subscriptionDetails")}</Typography>
                                    <Grid container item direction="column">
                                        {(!row.subscriptions || row.subscriptions.length ===0) && <Typography variant={"body2"}>{t("noSubscriptions")}</Typography>}
                                        <Typography variant={"body2"}>{t("totalCustomerPV")}: {subscriptionsFormatter(row.Subscription)}</Typography>
                                        <Typography variant={"body2"}>{t("totalReferralPV")}: {row.CustomerPV} PV</Typography>
                                        <Typography variant={"body2"}>{t("numberOfReferrals")}: {row.NumCustomers}</Typography>
                                    </Grid>
                                </Box>
                                {(row.subscriptions && row.subscriptions.length >0) && <Grid container item sm={12}>
                                    {row.subscriptions.map(sub=>{
                                        return  <Grid key={sub.key} container item xs={12} sm={6} md={4} lg={3} alignItems={"center"} >
                                            <div >
                                                <Avatar className={classes.subIcon} src={sub.icon} />
                                            </div>
                                            <div className={classes.subText}>
                                                <span>{sub.name}</span>
                                                <br />
                                                <span>{t("quantity")}: {row[sub.key]}</span>
                                            </div>
                                        </Grid>
                                    })}
                                </Grid>}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
})


export default observer ( function CurrentCustomers ({parentMatch }) {
    const classes = useStyles();
    const {t} = useTranslation("customers");
    const {customersStore, authStore, commonStore } = useStores();
    const [open, setOpen] = React.useState(-1);
    const {myCustomers, myCustomersLoading, downlineCustomer, summary,
        updatingCustomerToMemberEnrollStatusCall, loadingDownlineCustomer, error, lookupDownlineCustomerError} = customersStore;
    const {currentUser} = authStore;
    const [effectiveTab, setEffectiveTab] = React.useState("current");
    const [lookupValue, setLookupValue] = React.useState("");
    const [showNotEligible, setShowNotEligible] = React.useState(false);
    const [inviteSent, setInviteSent] = React.useState(false);

    useEffect(() => {
        customersStore.loadMyCustomers();
    }, []);



    const handleTabChange =  (event, newValue)=>{
        setEffectiveTab( newValue )
    }

    const handleLookupValue =  (e)=>{
        setLookupValue(e.target.value)
    }

    const lookupDownlineCustomer =  ()=>{
        if ( lookupValue )  {
            setInviteSent(false)
            customersStore.lookupDownlineCustomer(lookupValue)
        }
    }
    const sendInvite =  async ()=>{

        if ( !downlineCustomer ) return;

        var todayDate = new Date();

        // Customer must have been enrolled for at least 2 days before they can be invited to become a Member
        // So set eligible Member invite date to 2 days after Customer enrollment date
        var eligibleMemberInviteDate = new Date(downlineCustomer. enrollDate);
        eligibleMemberInviteDate.setDate(eligibleMemberInviteDate.getDate() + 2);
        if ( todayDate >= eligibleMemberInviteDate) {
            await customersStore.updateCustomerToMemberEnrollStatusCall(downlineCustomer.customerLifeNo)
            setInviteSent(true)
        }
        else {
           setShowNotEligible(true)
        }
    }

    const handleCloseNotEligible = ()=>{
        setShowNotEligible(false)
    }



    const handleKey = (e) => {
        if(e.key === 'Enter') {
            lookupDownlineCustomer()

        }
    };
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down(700));

    return  (<div className={classes.root}>
        {error ? <Alert severity="error" variant={"filled"}  >{error}</Alert> : null}

        <TabContext value={effectiveTab}>
            <Tabs onChange={handleTabChange} value={effectiveTab} color={"primary"}    indicatorColor="primary" >
                <Tab color={"primary"} label={t("currentCustomers")}   value="current" selected={"current"===effectiveTab} />
                <Tab color={"primary"} label={t(isPhone?"downlineCustomerLookupShort":"downlineCustomerLookup")}  value="downline" selected={"downline"===effectiveTab}  />
            </Tabs>

        <TabPanel value={"current"} className={classes.tab} >
            <TableContainer>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={30}/>
                            <TableCell className={classes.largeScreen}>Life #</TableCell>
                            <TableCell className={classes.smallScreen}>{t("name")}</TableCell>
                            <TableCell className={classes.largeScreen}>{t("firstName")}</TableCell>
                            <TableCell className={classes.largeScreen}>{t("lastName")}</TableCell>
                            <TableCell className={classes.largeScreen}>{t("email")}</TableCell>
                            <TableCell className={classes.largeScreen}>{t("phone")}</TableCell>
                            <TableCell className={classes.largeScreen}>{t("global:subscriptions")}</TableCell>
                            <TableCell className={classes.mediumScreen}>{t("enrollDate")}</TableCell>
                            <TableCell className={classes.mediumScreen}>{t("status")}</TableCell>
                            <TableCell width={200}>{t("action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {myCustomersLoading
                            ? <TableRow>
                                <TableCell colSpan={11}>
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                                        <CircularProgress/>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            : <>
                                { myCustomers.map((row) =>  <Row row={row} currentUser={currentUser}/>)}
                            </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {summary && <Paper className={classes.summary} >
                <Typography variant={"h6"}>{t("global:summary")}</Typography>
                <br/>
                <span>{t("totalCustomerPV")}:</span> {summary.totalCustomerPV} PV
                <br />
                <span>{t("totalReferralPV")}:</span>  {summary.totalReferralPV} PV
                <br />
                <span>{t("numberOfCustomers")}:</span> {summary.numberOfCustomers}
            </Paper>}
        </TabPanel>
        <TabPanel value={"downline"} >
            <Box maxWidth={330} display="flex" >
                <Box flex={1} marginRight={2}>
                    <TextField placeholder="john.smith@email.com" fullWidth
                               onKeyPress = {e => handleKey(e)}
                               onChange={handleLookupValue} value={lookupValue} disabled={loadingDownlineCustomer} />
                </Box>
            {loadingDownlineCustomer
                ? <CircularProgress size={24} />
                : <Button variant={"contained"} color={"primary"} onClick={lookupDownlineCustomer}>{t("global:lookup")}</Button>}
                {lookupDownlineCustomerError ? <Alert severity="error" variant={"filled"}  >{lookupDownlineCustomerError}</Alert> : null}
            </Box>

            {downlineCustomer &&   <div id="divDownlineCustomerInfo" >

                <Typography variant={"subtitle1"}>{t("customerFound")}</Typography>
                    <ListItem>
                        <ListItemText primary={`${downlineCustomer.customerFirstName} ${downlineCustomer.customerLastName} `}
                                      secondary={downlineCustomer.custEmail} />
                    </ListItem>
              <Typography variant={"subtitle1"}>{t("uplineMember")}</Typography>
                    <ListItem>
                        <ListItemText primary={`${downlineCustomer.uplineMemberLifeNo} -  ${downlineCustomer.uplineMemberFirstName} ${downlineCustomer.uplineMemberLastName} `}
                                      secondary={downlineCustomer.uplineMemberEmail} />
                    </ListItem>


                <ThemeProvider theme={greenButtonTheme}>
                    { updatingCustomerToMemberEnrollStatusCall.includes( downlineCustomer.customerLifeNo )
                        ? <CircularProgress size={24} />
                        : (inviteSent
                            ? <Typography color={"secondary"}><CheckIcon/> Invite Sent</Typography>
                            :<Button variant={"contained"}
                            fullWidth
                            size={"large"} color={"secondary"}
                            onClick={sendInvite}>
                        {t('sendInvite')}
                    </Button>)}
                </ThemeProvider>
            </div>}

        </TabPanel>

        </TabContext>

        {showNotEligible && <Dialog open={showNotEligible} onClose={handleCloseNotEligible}>
            <DialogTitle>{t("notEligible")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("notEligibleMessage")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseNotEligible} color="primary">
                    {t("global:close")}
                </Button>
            </DialogActions>
            </Dialog>}


    </div>)

})