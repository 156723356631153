import React, {useEffect} from 'react';
import {useStores} from "../../hooks/use-stores";
import IFrame from "../../components/IFrame";
import ErrorMessage from "../Lifeline/CreateVideo/ErrorMessage";
import {CircularProgress} from "@material-ui/core";
import {observer} from "mobx-react";
import {useViewContext} from "../../contexts/useViewContext";


function LTAI(){

    const {authStore} = useStores()

    const  {ltaiTokenError, ltaiData, ltaiTokenLoading} = authStore;
    const viewContext = useViewContext();

    useEffect(() => {

        void authStore.loadLTAIToken();
        viewContext.setFrameLessRequested(true);
        return () => {
            viewContext.setFrameLessRequested(false);
        }
    }, []);

    if( ltaiTokenLoading === null ||ltaiTokenLoading)
         return <CircularProgress size={50}/>

    if( ltaiTokenError || !ltaiData  )
        return <ErrorMessage message={ltaiTokenError|| "Failed to load page"}></ErrorMessage>
    return <IFrame url={ltaiData.url} fullScreenFrame={viewContext.frameLess} returnTitle={"BACK TO STARFISH"} />

}

export default observer( LTAI);