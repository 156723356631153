import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import { updateCardRules, updatePostalCodeRules, updateCardTypeRules } from "./helpers";


const fields = {
    cardType: {
        label: 'enrollment:cardtype',
        rules: 'string|required',
        placeholder: 'enrollment:cardtype',
        extra: [
            { value: "Visa", label: "Visa"},
            { value: "MasterCard", label: "Mastercard"},
            { value: "Discover", label: "Discover"},
            { value: "AMEX", label: "American Express"},
            { value: "VisaDebit", label: "Visa Debit"},
            { value: "MasterCardDebit", label: "Mastercard Debit"}]
    },
    cardNumber: {
        label: 'enrollment:cardnumber',
        rules: 'string|required',
        placeholder: 'enrollment:cardnumber',
        "extra": "#### #### #### ####"
    },
    cardExpMonth: {
        label: 'enrollment:expmonth',
        rules: 'integer|required',
        placeholder: 'enrollment:expmonth',
    },
    cardExpYear: {
        label: 'enrollment:expyear',
        placeholder: 'enrollment:expyear',
        rules: 'integer|required',
    },
    cardCode: {
        label: 'enrollment:securitycode',
        rules: 'string|required',
        placeholder: 'enrollment:securitycode',
    },
    billFirstName: {
        label: 'enrollment:firstname',
        rules: 'string|required',
        placeholder: 'enrollment:firstname',
    },
    billLastName: {
        label: 'enrollment:lastname',
        rules: 'string|required',
        placeholder: 'enrollment:lastname',
    },
    billAddress: {
        label: 'enrollment:address',
        rules: 'string|required',
        placeholder: 'enrollment:address',
    },
    billCity: {
        label: 'enrollment:city',
        placeholder: 'enrollment:city',
        rules: 'string|required',
    },
    billRegion: {
        label: 'enrollment:state',
        placeholder: 'enrollment:state',
        rules: 'string|required',
    },
    billPostalCode: {
        label: 'enrollment:zipcode',
        placeholder: 'enrollment:zipcode',
        rules: 'string|required',
    },
    billCountry: {
        label: 'enrollment:country',
        rules: 'string|required',
        placeholder: 'enrollment:country',
    }
};



export default function create(initials, hooks) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }
    const form = new MobxReactForm({ fields: f }, { plugins, hooks });

    form.$('billCountry')
        .observe(({ form, field, change }) => {

            updatePostalCodeRules(form, field.value);
            updateCardTypeRules(form, field.value);
        });

    updatePostalCodeRules(form, initials.billCountry);
    updateCardTypeRules(form, initials.billCountry);

    form.$('cardType')
        .observe(({ form, field, change }) => {
            updateCardRules(form, field.value);
        });
    updateCardRules(form);


    return form;



}

