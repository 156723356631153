import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {
    ReactReader, // A simple epub-reader with left/right button and chapter navigation
} from "react-reader";


const useStyles = makeStyles((theme) => ({
    root: props=> ({
        position: "relative",
        width: "100%",
        height: props.fullScreen?"100%": "90vh"
    })
}));

export default observer(({url, title, fullScreen }) => {
    const classes = useStyles({fullScreen});
    const [location, setLocation] = useState(null)



    return <div className={classes.root}>
        <ReactReader
            url={url}
            title={title}
            swipeable={true}
            location={location}
            locationChanged={ setLocation }
        />
    </div>
})


