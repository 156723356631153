import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStores} from "../hooks/use-stores";
import 'react-owl-carousel2/lib/styles.css';
import '../css/owl.scss';
import {useLocalObservable} from "mobx-react-lite";
import { useParams} from "react-router";
import IFrame from "../components/IFrame";

function OnBoarding({}) {

    const {onboardStore} = useStores();
    const params = useParams();
    const {tag} = params;
    const {shoppingBossOnboardingLoading} = onboardStore;


    useEffect(()=>{
        onboardStore.loadShoppingBossOnboardingInfo()
    }, []);

    const localStore = useLocalObservable(() => ({
        tag,
        get url() {
            let item = onboardStore.shoppingBossAvailableSubs.find(item=>{
                let itemTag =   item.tag || (item.title.replace(/\+/g, " plus") ).replace(/\s+/g, "_").toLowerCase()
                return itemTag === this.tag
            })
            return item && item.url
        },

    }));


    return <>
        {!shoppingBossOnboardingLoading && <IFrame url={localStore.url}></IFrame>}
        </>
}


export default observer(OnBoarding)