import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {getUniqKey, joinPath} from "../utils/helpers";
import {calculateItemPath, getItemPath, getItemUrl, isCustomHandler, isNewWindow, showItemNativeAppModal } from "../utils/menuUtils";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {useStores} from "../hooks/use-stores";

const useStyles = makeStyles((theme) => ({

    link:{
        textDecoration:"none",
        color: "white",
        display:"block",
        "&:hover":{
            color:"white",
            textDecoration:"none",
        }
    },
    vertical:{
        width:100,
        marginBottom: 20,
        display:"inline-flex",
        alignItems:"center",
        flexDirection:"column",
        justifyContent:"center",
    }

}));

export default observer(({parentMatch, baseComponent, notMatchedOnClick, def, linkPrefix, classes={}, item, menu, onPlay, children, notMatchedInNewWindow = false,  vertical=true, lookupInMenu=false}) => {
    const cl = useStyles();
    const { commonStore, menuStore, authStore } = useStores();
    const { isNativeApp } = commonStore;
    const { currentUser } = authStore;
    const tag = getUniqKey(item);

    const itemPath = useMemo( ()=>{
        try {
            return  getItemPath(item, currentUser) ||
                ((menu && lookupInMenu)
                        ? calculateItemPath(menu, item)
                        : parentMatch && joinPath(parentMatch.url, (linkPrefix != null) ? linkPrefix : getUniqKey(def), getUniqKey(item))
                );
        }
        catch(e){

        }
    }, [item, parentMatch, currentUser, menu, lookupInMenu, linkPrefix, def ]);

    const itemUrl = useMemo(  ()=>{
        return getItemUrl(item);
    }, [item])


    const isVideo = useMemo(  ()=>{
        if ( item.videos && item.videos.length ===1 ) return true;
        return itemUrl &&  itemUrl.indexOf("player.vimeo.com")>-1
    }, [item, itemUrl])


    const isVideoAlbum = useMemo(  ()=>{
        return item.videos && item.videos.length>0
    }, [item])

    const inNewWindow =  useMemo(()=>{
        if ( notMatchedInNewWindow && (!itemPath && itemUrl ) )
            return true
        return isNewWindow(item)
    }, [item, itemPath, itemUrl, notMatchedInNewWindow ])

    const customHandler = useMemo(()=>{
        return isCustomHandler(item, currentUser)
    }, [item, currentUser])

    const props = useMemo(()=>{
        let res = {}
        if ( inNewWindow ) {
            res.href = itemUrl
            res.target = "_blank"
        }
        else {
            res.to = itemPath
        }
        return res
    },[inNewWindow, itemUrl, itemPath])


    const showInModal = useMemo(()=>{
        return showItemNativeAppModal(item)
    }, [item])

    const openNativeAppModal = ()=>{
        menuStore.setForceNativeAppModal(item)
    }

    const handleCustom = ()=>{
        menuStore.handleCustom(item, currentUser)
    }

    const BaseComponent = useMemo(()=>{
        return baseComponent? baseComponent: Link
    }, [baseComponent])


    const Component = useMemo(()=>{

       return  ( inNewWindow || (!itemPath && itemUrl ) )?"a":BaseComponent;
    },[inNewWindow, itemPath, itemUrl ])


    const isMedia =  useMemo(()=>{
        return !isNativeApp && itemUrl &&  itemUrl.startsWith("playmedia://")
    }, [isNativeApp, itemUrl])


    if ( customHandler )
        return  <span className={clsx( (classes && classes.link ) ? classes.link: cl.link, { [cl.vertical]: vertical } ) }
                      onClick={handleCustom } style={{cursor:"pointer"}}>
                   {children}
            </span>

    if ( showInModal )
        return  <span className={clsx( (classes && classes.link ) ? classes.link: cl.link, { [cl.vertical]: vertical } ) }
                      onClick={openNativeAppModal } style={{cursor:"pointer"}}>
                   {children}
            </span>
    if ( (isMedia || isVideo) )
        return <span className={clsx( (classes && classes.link ) ? classes.link: cl.link, { [cl.vertical]: vertical } ) }
                     onClick={e=>{ onPlay(itemUrl, item) }} style={{cursor:"pointer"}}>
                   {children}
            </span>

    if ( isVideoAlbum )
        return <span className={clsx( (classes && classes.link ) ? classes.link: cl.link, { [cl.vertical]: vertical } ) }
                     onClick={e=>{ onPlay(itemUrl, item) }} style={{cursor:"pointer"}}>
                   {children}
            </span>



    return <Component onClick={notMatchedOnClick} className={clsx( (classes && classes.link ) ? classes.link: cl.link, { [cl.vertical]: vertical } ) } {...props}>
                {children}
            </Component>

})


