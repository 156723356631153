import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import { updatePostalCodeRules } from './helpers';

const fields = {
    email: {
        label: 'Email',
        rules: 'string|email|required|between:4,150',
        placeholder: 'Email',
    },
    phone: {
        label: 'Phone',
        type: "tel",
        rules: 'string',
        placeholder: 'Mobile Phone',
        "extra": "(###) ###-####"
    },
    address1: {
        label: 'Address 1',
        rules: 'string|between:1,1500',
        placeholder: 'Address 1',
    },
    address2: {
        label: 'Address 2',
        rules: 'string|between:1,1500',
        placeholder: 'Address 2',
    },
    city: {
        label: 'City',
        rules: 'string|between:1,1500',
        placeholder: 'City',
    },
    zip: {
        label: 'Zip/Postal Code',
        rules: 'string|digits:5|required',
        placeholder: 'Zip/Postal Code',
    },
    preferredLanguage: {
        label: 'Language',
        rules: 'string',
        placeholder: 'Language',
    }
};



export default function create(hooks) {
    const form = new MobxReactForm({ fields }, { plugins, hooks });
    form.updateZipRules = (country) => {
        updatePostalCodeRules(form, country, "zip");
    };

    return form;
}

