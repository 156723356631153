import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        secondary: {
            dark: "#ffffff",
            main: "#ffffff",
            light: "#ffffff"
        },
    },
});

export default theme