import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { joinPath } from "../../utils/helpers";
import RequestCode from "./requestCode";
import Player from "./player";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({

    shopWrapper: {
        height: "100%",
        minHeight: "100vh",
        background: "black",
        color: "white"
    },
    spacer: {
        height: 30
    }
}));


export default observer(function ShopIndex({ theme }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const shareMatch = useRouteMatch();


    return <Switch>
            <Route path={shareMatch.url} exact render={() => <RequestCode shareMatch={shareMatch} theme={theme} />} />

            <Route path={joinPath(shareMatch.url, `:code`)}
                render={() => <Player shareMatch={shareMatch} theme={theme} />} />


        </Switch>;
});