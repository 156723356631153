import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Container, Grid, IconButton, Typography, makeStyles, Icon } from '@material-ui/core';
import FlipCameraIosOutlinedIcon from '@material-ui/icons/FlipCameraIosOutlined';
import StopIcon from '@material-ui/icons/Stop';
import AdjustIcon from '@material-ui/icons/Adjust';
import Alert from '@material-ui/lab/Alert'
import { useStores } from '../../../hooks/use-stores';

const VIDEO_CONSTRAINT_OPTIONS = {
  audio: true,
  video: {
    width: { min: 640, max: 1920 },
    height: { min: 400, ideal: 1080 },
    aspectRatio: 1.777777778,
    frameRate: { max: 30 },
    facingMode: "user",
  }
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    color: 'black',
    height: '100%',
    minHeight: '100%',
    padding: 0,
  },
  content: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  heading: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem'
    }
  },
  button: {
    color: 'black',
    backgroundColor: 'white',
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: 'gray',
    },
  },
  flipCameraIcon: {
    color: 'rgb(58,128,243)',
    fontSize: '2.5rem'
  },
  stopButton: {
    padding: 0,
    margin: '8px',
    color: 'black',
    backgroundColor: 'red',
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgba(255,0,0,.5)',
    }
  },
  recordButton: {
    padding: 0,
    margin: '8px',
    color: 'red',
    backgroundColor: 'black',
    '&:disabled': {
      background: 'grey',
      color: 'white'
    }
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '0 auto',
    backgroundColor: 'black',
    color: 'white',
    marginTop: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  videoContainer: {
    border: '1px solid black'
  },
  video: {
    width: '100%',
    height: 'auto',
    maxHeight: '60vh'
  },
  videoError: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  timeLeft: {
    color: 'red'
  }
}));

const CreateVideo = ({ lifelineMatch }) => {
  const { t } = useTranslation();
  const { authStore, lifelineStore } = useStores();
  const classes = useStyles();
  const history = useHistory();

  const maxVideoTimeInSeconds = lifelineStore.videoDuration(authStore.currentUser.userID);

  const videoRef = useRef();
  const recordedChunks = useRef([]);

  const [videoStream, setVideoStream] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const [cameras, setCameras] = useState(null);
  const [timeLeft, setTimeLeft] = useState(maxVideoTimeInSeconds);
  const [success, setSuccess] = useState(false);
  const [capturing, setCapturing] = React.useState(false);
  const [currentCameraId, setCurrentCameraId] = React.useState(null);

  const updateStream = () => {
    if (!videoError && videoStream && videoRef.current) {
      videoRef.current.srcObject = videoStream;
    }
  }

  const handleCanPlay = () => {
    videoRef.current.play();
  }

  const register = async (constraints) => {
    try {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices) {
        // Get the stream reference using the default constraints
        setVideoStream(await navigator.mediaDevices.getUserMedia(constraints || VIDEO_CONSTRAINT_OPTIONS));

        // Get all the user's cameras so we can change cameras if desired
        const devices = await navigator.mediaDevices.enumerateDevices();
        setCameras(devices.filter(device => device.kind === 'videoinput'));
      } else {
        setVideoError(t('lifeline:videoRecorder.unsupported'));
      }
    } catch (err) {
      setVideoError(t('lifeline:videoRecorder.unavailable'));
    }

  }

  const startRecording = async () => {
    recordedChunks.current = [];
    setCapturing(true);

    let countdown;
    let recorder = new MediaRecorder(videoStream);

    // we need a reference to the 1 minute promise timeout so we can end it early if needed
    let resolveTimeoutPromise;

    countdown = setInterval(() => setTimeLeft(timeLeft => timeLeft - 1), 1000);
    recorder.ondataavailable = event => recordedChunks.current.push(event.data);
    recorder.start(1000); // timeslice to 1 sec or ffmpeg freaks out

    // Set up a max time of one minute for the recording - it will auto stop then
    let recorded = new Promise(resolve => {
      resolveTimeoutPromise = resolve;
      setTimeout(resolve, (maxVideoTimeInSeconds + 1) * 1000); // needs to be in MS
    }).then(
      () => {
        setCapturing(false);
        if (recorder?.state == "recording") {
          recorder.stop() // if we're still recording, stop it, which will trigger onstop below
        }
      }
    );

    let stopped = new Promise((resolve, reject) => {
      recorder.onstop = () => {
        resolve(); //when the recording is stopped, we resolve to end the promise race
        if (resolveTimeoutPromise) {
          resolveTimeoutPromise(); // if we stop the movie manually, resolve the timeout promise
        }
      }
      recorder.onerror = (event) => {
        reject(event.name);
        if (resolveTimeoutPromise) {
          resolveTimeoutPromise();// if we stop the movie manually, resolve the timeout promise
        }
      }
    });

    await Promise.any([
      recorded,
      stopped,
    ])

    clearInterval(countdown)
    setCapturing(false);
    setSuccess(true);
  }

  const stopRecording = () => {
    videoStream?.getTracks().forEach(track => track.stop());
  }

  // Camera flip is disabled during recording because the spec says
  // switching tracks will require all previous media to be deleted
  const flipCamera = async () => {
    let current = currentCameraId;
    if (current == null) {
      current = cameras[0].deviceId;
    }

    const nextCamera = cameras.filter(cam => cam.deviceId !== current);
    if (nextCamera[0]) {
      const newCameraId = nextCamera[0].deviceId
      setCurrentCameraId(newCameraId)

      const updatedConstraints = {
        ...VIDEO_CONSTRAINT_OPTIONS,
        ...{
          video: {
            deviceId: {
              exact: newCameraId
            }
          }
        }
      };

      register(updatedConstraints)
    }
  }

  useEffect(() => {
    if (success == true) {
      const blob = new Blob(recordedChunks.current, {
        type: "video/mp4"
      });

      lifelineStore.videoFile = blob;
      history.push(`${lifelineMatch.url}/reviewVideo`);
    }
  }, [success]);

  useEffect(() => {
    updateStream();
  }, [videoStream]);

  useEffect(() => {
    register();

    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach(track => {
          track.stop();
          track.enabled = false;
        });
      }
    }
  }, [])

  return (
    <Container className={classes.wrapper} >
      <div className={classes.content}>
        {(videoError) && <Alert className={classes.videoError} severity="error">{videoError}</Alert>}
        <div className={classes.videoContainer}>
          <video className={classes.video} ref={videoRef} onCanPlay={handleCanPlay} autoPlay playsInline muted />
        </div>
        {videoStream &&
          <div className={classes.buttonContainer}>
            <Grid container xs={12} alignItems="center">
              <Grid item xs={4} sm={2}>
                {cameras?.length > 1 && !capturing &&
                  <IconButton
                    aria-label="flipCamera"
                    onClick={flipCamera}
                  >
                    <FlipCameraIosOutlinedIcon className={classes.flipCameraIcon} />
                  </IconButton>
                }
              </Grid>
              <Grid item xs={4} sm={8} container justifyContent="center">
                  <>
                    {capturing ? (
                      <IconButton className={classes.stopButton} onClick={stopRecording}>
                        <StopIcon style={{ fontSize: '3rem' }} />
                      </IconButton>
                    ) : (
                      <IconButton disabled={!videoStream || videoError} className={classes.recordButton} onClick={startRecording}>
                        <AdjustIcon style={{ fontSize: '3rem' }} />
                      </IconButton>
                    )
                    }
                  </>
              </Grid>
              <Grid item xs={4} sm={2} container justifyContent="center" alignItems="center" direction="column">
                <Typography variant="body1">{t('lifeline:createVideo.home.timeLeft')}</Typography>
                <Typography variant="body1" className={classes.timeLeft}>{moment(timeLeft * 1000).format('mm:ss')}</Typography>
              </Grid>
            </Grid>
          </div>
        }
      </div>
    </Container>
  );
};

export default CreateVideo;
