import { Button, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStores } from "../../hooks/use-stores";
import ProductSearch, { selectedProductsKey } from "./components/ProductSearch";
import UserSearch from "./components/UserSearch";
import { ContentPageStyles } from "./styles";

const productTransferDocID = "65fd59b025d16a66ceb2d601";

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  pageLoading: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  labelWrapper: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    height: "40px",
    marginBottom: "20px",
  },
  label: {
    width: "fit-content",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
  },
  page: { padding: "0 16px 32px 16px" },
  submitButton: { marginTop: "20px" },
  select: {
    "& .MuiSelect-select": {
      padding: "10.5px 36px 10.5px 16px",
    },
  },
}));

export function getMonths({ time, isLeader }) {
  // Convert supplied time to EST String
  const options = {
    timeZone: "America/New_York",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const estString = time.toLocaleString("en-US", options);

  const estCurrentMonth = time.toLocaleString("en-US", {
    timeZone: "America/New_York",
    month: "long",
    year: "numeric",
  });

  // convert EST String to Date Object
  const estDate = new Date(estString);

  // get previous month
  const firstDayOfCurrentMonth = new Date(
    estDate.getFullYear(),
    estDate.getMonth(),
    1
  );
  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
  lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1);
  const estPreviousMonth = lastDayOfPreviousMonth.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
  });

  const date = estDate.getDate();
  const hour = estDate.getHours();
  const deadline = isLeader ? 4 : 3;

  // 1st, 2nd, 3rd 0-1:59AM: current month and previous month
  // 1st, 2nd, 3rd, 4th 0-1:59AM: current month and previous month for LEADER
  // 3rd 2AM and rest of month: current month
  // 4th 2AM and rest of month: current month for LEADER

  if (date < deadline || (date === deadline && hour < 2)) {
    return [
      {
        label: estPreviousMonth,
        value: `${lastDayOfPreviousMonth.getFullYear()}-${
          lastDayOfPreviousMonth.getMonth() + 1
        }-${lastDayOfPreviousMonth.getDate()}`,
      },
      {
        label: estCurrentMonth,
        value: `${estDate.getFullYear()}-${
          estDate.getMonth() + 1
        }-${estDate.getDate()}`,
      },
    ];
  }

  return [
    {
      label: estCurrentMonth,
      value: `${estDate.getFullYear()}-${
        estDate.getMonth() + 1
      }-${estDate.getDate()}`,
    },
  ];
}

function PVTransfer() {
  const classes = useStyles();
  const { authStore, webTextStore, pvTransferStore, commonStore } = useStores();
  const { webTextInfo, webTextInfoLoading } = webTextStore;
  const { pvTransferInfo, pvTransferInfoLoading } = pvTransferStore;
  const { currentUser } = authStore;
  const { language } = commonStore;

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [toastMessage, setToastMessage] = React.useState(null);

  const now = new Date();

  const availableMonths = getMonths({
    time: now,
    isLeader: currentUser.isLeader,
  });

  const [transferMonth, setTransferMonth] = useState(
    availableMonths[availableMonths.length - 1].value
  );

  const appliedMonth = [1, 2, 3].includes(now.getDate())
    ? transferMonth
    : `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

  // After fetching currentUser data, get local data from currentUser's userID
  useEffect(() => {
    if (currentUser) {
      const localKey = `${selectedProductsKey}-${currentUser.userID}`;
      const localData = localStorage.getItem(localKey);
      if (localData) {
        setSelectedProducts(JSON.parse(localData));
      } else {
        localStorage.setItem(localKey, JSON.stringify([]));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!pvTransferInfoLoading) {
      if (pvTransferInfo?.message) {
        setSelectedProducts([]);
        localStorage.setItem(
          `${selectedProductsKey}-${currentUser.userID}`,
          JSON.stringify([])
        );
      }
    }
  }, [pvTransferInfoLoading]);

  // Fetching webtext data one time (at initial start)
  useEffect(() => {
    if (!webTextInfoLoading) {
      void webTextStore.getWebTextByLanguageAndId([
        currentUser.preferredLanguage,
        productTransferDocID,
      ]);
    }
  }, []);

  if (webTextInfoLoading) {
    return (
      <div className={classes.pageLoading}>
        <CircularProgress size={90} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.page)}>
      <div dangerouslySetInnerHTML={{ __html: webTextInfo?.text }} />

      <label className={clsx(classes.labelWrapper)}>
        <span className={clsx(classes.label)}>Transfer Month:</span>
        <Select
          className={clsx(classes.select)}
          labelId="month-select-label"
          value={transferMonth}
          onChange={(e) => setTransferMonth(e.target.value)}
          variant="outlined"
        >
          {availableMonths.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </label>

      <UserSearch
        pvTransferInfoLoading={pvTransferInfoLoading}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <ProductSearch
        pvTransferInfoLoading={pvTransferInfoLoading}
        selectedUser={selectedUser}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
      />

      <Button
        disabled={
          pvTransferInfoLoading ||
          selectedProducts.length === 0 ||
          selectedProducts.find((product) => product.Qty <= 0)
        }
        className={classes.submitButton}
        variant="contained"
        color="primary"
        onClick={async () => {
          const data = {
            selectedProducts,
            appliedMonth: appliedMonth,
            sender: {
              name: `${currentUser.firstName} ${currentUser.lastName}`,
              lang: language,
              email: currentUser.email,
              userId: currentUser.userID,
            },
          };
          await pvTransferStore.transferPV(data);
          setToastMessage("Transfer successful.");
        }}
      >
        Record Transfer
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!toastMessage}
        autoHideDuration={2500}
        onClose={() => setToastMessage(null)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setToastMessage(null)}
          severity="success"
        >
          {toastMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default observer(PVTransfer);
