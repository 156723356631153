
import RestService from "./RestService";

export default class SeminarWebinarService extends RestService {
  constructor() {
    super('/api/seminar');
  }

  async fetchInfo(date = '') {
    return this.get(`${this.baseUrl}/${date}`);
    // return this.get(`http://localhost:3000/api/seminar/${date}`)
  }

  async fetchDetails(date = '') {
    return this.get(`${this.baseUrl}/details/${date}`);
    // return this.get(`http://localhost:3000/api/seminar/details/${date}`)
  }
}
