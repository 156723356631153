import { Link, Tab, makeStyles } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStores } from "../../hooks/use-stores";
import { ContentPageStyles } from "./styles";

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  page: {
    "& .MuiAccordion-root.Mui-expanded": {
      marginBottom: 0,
    },
    "& .MuiAccordion-root.MuiPaper-root": {
      backgroundColor: "black",
    },
    "& .MuiTabs-flexContainer": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  blueText: {
    color: "#005de8",
  },
  link: {
    color: "#ffffff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  promoText: {
    lineHeight: 1.5,
  },
  tabArea: {
    marginTop: "40px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#005de8",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#005de8",
    },
  },
  tab: {
    width: "25%",
  },
  detailsHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  detailsHeaderItem: {
    width: "45%",
  },
  whiteSpace: {
    whiteSpace: "pre-wrap",
  },
  linkButton: {
    padding: "10px 15px",
    borderRadius: "4px",
    display: "block",
    backgroundColor: "#007BFF",
    color: "white",
  },
  videoPlayer: {
    margin: "25px auto 50px auto",
    width: 350,
    height: 197,
    border: `1px solid #007bff`,
    borderRadius: 5,
    display: "block",
    [theme.breakpoints.up(520)]: {
      width: 510,
      height: 286,
    },
    [theme.breakpoints.up(720)]: {
      width: 700,
      height: 394,
    },
  },
}));

function Convention() {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState("");
  const { conventionLeadershipStore, commonStore } = useStores();
  const { conventionInfo, conventionLoading } = conventionLeadershipStore;
  const { conventionTabDetail, conventionDetail, button } =
    conventionInfo || {};
  const { language } = commonStore;

  const handleChangeTab = (_event, newValue) => {
    setCurrentTab(newValue);
  };

  const loadConvention = () => {
    if (!conventionLoading) {
      void conventionLeadershipStore.loadConvention({
        languageCode: language,
      });
    }
  };

  useEffect(() => {
    loadConvention();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conventionTabDetail) {
      setCurrentTab(conventionTabDetail[0].id);
    }
  }, [conventionTabDetail]);

  return (
    conventionInfo && (
      <div className={clsx(classes.page)}>
        <div className={clsx(classes.header)}>
          <h1>{conventionDetail.name}</h1>
          <Link
            className={clsx(classes.linkButton)}
            href={conventionDetail.purchaseTicketUrl}
            variant='button'
            target='_blank'
            rel='noopener'
          >
            {button.purchase_tickets}
          </Link>
        </div>
        <iframe
          title='convention-video'
          className={classes.videoPlayer}
          src={`https://player.vimeo.com/video/${conventionDetail.vimeoId}`}
        />
        <p className={classes.promoText}>{conventionDetail.promoText}</p>
        <div className={clsx(classes.tabArea)}>
          {currentTab && (
            <TabContext value={currentTab} className={clsx(classes.tabs)}>
              <TabList
                onChange={handleChangeTab}
                aria-label='conventions'
                className={clsx(classes.tabs)}
              >
                {conventionTabDetail.map((tab) => (
                  <Tab
                    label={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tab.name,
                        }}
                      />
                    }
                    value={tab.id}
                    className={clsx(classes.tab)}
                    disableRipple={true}
                  />
                ))}
              </TabList>

              {conventionTabDetail.map((tab) => (
                <TabPanel value={tab.id}>
                  <div className={clsx(classes.detailsHeader)}>
                    <div className={clsx(classes.detailsHeaderItem)}>
                      <h3 className={clsx(classes.blueText)}>
                        Event Information
                      </h3>
                      <div className={clsx(classes.whiteSpace)}>
                        <div
                          dangerouslySetInnerHTML={{ __html: tab.summary }}
                        />
                      </div>
                    </div>
                    <div className={clsx(classes.detailsHeaderItem)}>
                      <h3 className={clsx(classes.blueText)}>Ticket Prices</h3>
                      <ul>
                        <li>
                          <p>
                            Ticket: ${conventionDetail.ticketPriceUsd} (USD)
                          </p>
                        </li>
                        <li>
                          <p>
                            New member ticket: $
                            {conventionDetail.ticketPriceNewUsd} (USD){' '}
                            <span>*</span>
                          </p>
                        </li>
                      </ul>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: conventionDetail.ticketPriceDisclaimer,
                        }}
                      />
                    </div>
                  </div>
                  <h3 className={clsx(classes.blueText)}>Event Details</h3>
                  <div dangerouslySetInnerHTML={{ __html: tab.detail }} />
                </TabPanel>
              ))}
            </TabContext>
          )}
        </div>
        {/* <div>
          <h2 className={clsx(classes.blueText)}>FUTURE EVENTS:</h2>
          <p>{futureEvent?.summary}</p>
        </div> */}
      </div>
    )
  )
}

export default observer(Convention);
