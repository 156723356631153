import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import {useStores} from '../../hooks/use-stores'
import {useTranslation} from 'react-i18next'
import {starFishColor} from '../../components/styles'
import {ContentPageStyles} from './styles'
import {observer} from 'mobx-react'
import 'quill/dist/quill.core.css'

const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),
    pointsList: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        display: 'flex',
    },
    subsection: {
        paddingTop: 16,
        paddingBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    pointText: {
        paddingLeft: 56,
        paddingRight: 96,
        paddingTop: 14,
        paddingBottom: 14,
        width: '100%',
    },
    subPoints: {
        marginBottom: 14,
        padding: '0 8px',
    },
    subPointsUL: {
        listStyleType: 'square',
        margin: 0,
        marginTop: 14,
        padding: 0
    },
    expanded: {
        display: 'block',
    },
    cardGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 30,
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },

    },
    card: {
        display: 'flex',
        width: "100%",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    cardMedia: {
        width: "100%",
        maxHeight: 290,
        objectFit: 'cover',
    },
    coverImage:{
        width: "100%",
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: 500,
        },
    }
}))

const citates = [
    {
        text: "citate1",
        author: {firstName: "Jim", lastName: "Bailey"}
    },
    {
        text: "citate2",
        author: {firstName: "Judy", lastName: "Shrewsbury"}
    },
    {
        text: "citate3",
        author: {firstName: "Tom & Jeana", lastName: "Greene"}},
    {
        text: "citate4",
        author: {firstName: "Debbie", lastName: "Lentz"}
    },
]

function TopGun() {
    const classes = useStyles()
    const {authStore, commonStore, videoStore} = useStores()
    const {t} = useTranslation('starfish')

    const { currentUser } = authStore;
    const showVideo = () => {
        videoStore.setMedia({
            url: 'https://vimeo.com/793349516',
            title: 'Royal Starfish',
            vimeo: true,
        })
    }

    const subscribeLink =   `https://shop.mainhomepage.com/quickcheckout.aspx?login=${authStore.loginGuid}&type=subnew&id=789`

    const alreadyHasRoyal = ()=>{
        commonStore.showConfirm( "You are already subscribed to Royal Starfish"
            , "OK"
            , "Already subscribed",  ()=>{}, null );
    }

    const basicButtonProps = {}

    if ( authStore.subscriptions.hasSuperAppPlus ) {
        basicButtonProps.onClick = alreadyHasRoyal
    }
    else {
        basicButtonProps.target = "_blank"
        basicButtonProps.href = subscribeLink
    }




    return <div>
        <section className={clsx(classes.section, classes.noBottomMargin)} style={{minHeight: 500}}>
            <img
                src='/img/royal_promo/Cover.jpg'
                className={classes.coverImage}
                role='img'
            />
            <div className={clsx(classes.textContainer, classes.overlayImage)}>
                <Typography align="center" className={classes.headText} variant="h3"><span
                    className={classes.linkColor}>Royal Starfish</span> {t("royal_promo.subscription")}
                </Typography>
                <Typography align="center" className={classes.subheadItalicText} variant="h5">
                    {t("royal_promo.unlock")}
                </Typography>
                <a {...basicButtonProps} target={"_blank" } className={classes.linkColor} >
                    <Typography align="center" className={classes.subheadUnderlinedText} variant="h4">
                        {t("royal_promo.subscribe")}
                    </Typography>
                </a>
            </div>
        </section>
        <section className={clsx(classes.section, classes.inverse)}>
            <div className={clsx(classes.textContainer)}>
                <Typography
                    align='center'
                    className={classes.headText}
                    variant='h3'
                >
                    {t('royal_promo.why_royal')}
                </Typography>
                <Typography
                    align='center'
                    className={clsx(classes.mainText, classes.noBottomMargin)}
                    variant='body1'
                >
                    {t('royal_promo.unleash')} {' '}
{currentUser.isMember && <>-{' '}<Button
                    variant='text'
                    onClick={showVideo}
                    atarget='_blank'
                    ahref={'https://vimeo.com/780782440/ef1ee5f971'}
                >
                    {t('royal_promo.watch_now')}
                </Button></>}

                </Typography>
            </div>
        </section>
        <section className={clsx(classes.section)}></section>
        <section className={clsx(classes.section)}>
            <div className={clsx(classes.textContainer)}>
                <Typography align='center' className={classes.headText} variant='h3'>
                    {t('royal_promo.subscription_includes')}
                </Typography>
            </div>
        </section>

        <section className={clsx(classes.section, classes.pointsList)}>

            <Box className={clsx(classes.cardGrid)}>
                <Box className={clsx(classes.card)}>
                    <img src='/img/royal_promo/Media-Library.jpg' className={clsx(classes.cardMedia)}/>
                    <Typography align='center' className={classes.headText} variant='h3'>
                         <span
                             className={classes.linkColor}>
                             {t('royal_promo.media_library')}
                         </span>
                    </Typography>
                    <Typography align='center' className={classes.condensedTextNoSize} variant='h5'>
                        {t('royal_promo.20years')}
                    </Typography>
                    <Typography align='center' className={classes.subheadItalicText} variant='subtitle1'>
                        {t('royal_promo.available_in')}
                    </Typography>
                    <ul className={classes.subPointsUL}>
                        <li className={classes.subPoints}>
                            {t('royal_promo.media_library_fact1')}
                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.media_library_fact2')}
                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.media_library_fact3')}
                        </li>
                    </ul>

                </Box>
                <Box className={clsx(classes.card)}>
                    <img src='/img/royal_promo/Marketplace.jpg' className={clsx(classes.cardMedia)}/>
                    <Typography align='center' className={classes.headText} variant='h3'>
                           <span
                               className={classes.linkColor}>
                               {t('royal_promo.marketplace')}
                           </span>
                    </Typography>
                    <Typography align='center' className={classes.condensedTextNoSize} variant='h5'>
                        {t('royal_promo.pathway')}

                    </Typography>
                    <ul className={classes.subPointsUL}>
                        <li className={classes.subPoints}>
                            {t('royal_promo.marketplace_fact1')}

                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.marketplace_fact2')}
                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.marketplace_fact3')}
                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.marketplace_fact4')}
                        </li>
                    </ul>
                </Box>
                <Box className={clsx(classes.card)}>
                    <img src='/img/royal_promo/Rascal-Radio.jpg' className={clsx(classes.cardMedia)}/>
                    <Typography align='center' className={classes.headText} variant='h3'>
                         <span
                             className={classes.linkColor}>

                         </span>
                    </Typography>
                    <Typography align='center' className={classes.condensedTextNoSize} variant='h5'>
                        {t('royal_promo.stream')}
                    </Typography>
                    <Typography align='center' className={classes.subheadItalicText} variant='subtitle1'>
                        {t('royal_promo.available_in')}
                    </Typography>
                    <ul className={classes.subPointsUL}>
                        <li className={classes.subPoints}>
                            {t('royal_promo.rascal_fact1')}
                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.rascal_fact2')}
                        </li>
                        <li className={classes.subPoints}>
                            {t('royal_promo.rascal_fact3')}
                        </li>
                    </ul>
                </Box>
                <Box className={clsx(classes.card)}>
                    <img src='/img/royal_promo/Books.jpg' className={clsx(classes.cardMedia)}/>
                    <Typography align='center' className={classes.headText} variant='h3'>
                          <span
                              className={classes.linkColor}>
                              {t("royal_promo.books")}
                          </span>
                    </Typography>
                    <Typography align='center' className={classes.condensedTextNoSize} variant='h5'>
                        {t("royal_promo.books_and_exclusive")}

                    </Typography>
                    <Typography align='center' className={classes.subheadItalicText} variant='subtitle1'>
                        {t('royal_promo.available_in')}
                    </Typography>
                    <ul className={classes.subPointsUL}>
                        <li className={classes.subPoints}>
                            {t('royal_promo.books_fact1')}
                        </li>
                    </ul>
                </Box>
            </Box>
        </section>

        <section className={clsx(classes.section)}>
            <div className={clsx(classes.textContainer)}>
                <Typography align='center' className={classes.headText} variant='h3'>
                    {t('royal_promo.people_saying')} <span
                    className={classes.linkColor}> Royal
                    </span>
                </Typography>

            </div>

        </section>

        <section className={clsx(classes.section)}>
            <Grid
                container
                spacing={3}
                alignItems={'center'}
                justifyContent={'center'}
            >
                {citates.map((item, index) => (
                    <Grid item sm={3} xs={12} key={index} justifyContent={"center"} alignItems={"flex-start"}>
                        <div style={{padding: 12, textAlign: 'center', fontSize: "20pt"}} className={classes.headText}>
                            <strong>“{t(`royal_promo.${item.text}`)}“</strong>
                        </div>
                        <img src='/img/royal_promo/5%20Stars.png' width={"100%"}/>
                        <div style={{padding: 12, textAlign: 'center', fontSize: "25pt"}} className={classes.headText}>
                                    <span
                                        className={classes.linkColor}>
                                        {item.author.firstName}
                                    </span>
                            {' '}
                            {item.author.lastName}
                        </div>
                    </Grid>
                ))}
            </Grid>
        </section>
        <section className={clsx(classes.section)}>
            <div className={clsx(classes.textContainer)}>
                <Typography align='center' className={classes.headText} variant='h3'>
                    {t('royal_promo.subscribe_today')}
                </Typography>
                <a {...basicButtonProps}  target={"_blank" } className={classes.linkColor} >
                    <Typography align="center" className={classes.subheadUnderlinedText}
                                                               variant="h4">

                            {t("royal_promo.subscribe")}

            </Typography></a>
            </div>

        </section>
        <section className={clsx(classes.section)}>

            <Box display={"flex"} style={{gap: 16}}>
                <a href={"https://www.facebook.com/starfishperks/"} target={"_blank"}>
                    <img height={52}
                         src='/img/royal_promo/Facebook.png'
                         className={classes.middleImage}
                         role='img'
                    />
                </a>
                <a href={"https://www.instagram.com/starfishperks?r=nametag"} target={"_blank"}>
                    <img height={52}
                         src='/img/royal_promo/Instagram.png'
                         className={classes.middleImage}
                         role='img'
                    />
                </a>
                <a href={"https://www.orrinwoodward.com/"} target={"_blank"}>
                    <img height={52}
                         src='/img/royal_promo/OW.png'
                         className={classes.middleImage}
                         role='img'
                    />
                </a>
                <a href={"https://www.chrisbrady.com/"} target={"_blank"}>
                    <img height={52}
                         src='/img/royal_promo/CB.png'
                         className={classes.middleImage}
                         role='img'
                    />
                </a>
            </Box>

        </section>

        <section className={clsx(classes.section)}></section>
    </div>

}

export default observer(TopGun)
