import React from "react";
import {observer} from "mobx-react";
import {
    Box,
    Button, Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Thermometer from "./thermometer";
import clsx from "clsx";
import {useStores} from "../../../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import {starFishColor} from "../../../components/styles";
import {formatNumber} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    thermometer: (props)=>({
        ...( props.fullWidth ? {width: "100%"} : {minWidth: 320}),
        margin: 10
    }),
    thermometerBody: {
        padding: 10
    },
    thermometerHeader: {
        // ...starFishColor(theme, "backgroundColor"),
        borderTopRightRadius: 3,
        borderTopLeftRadius: 3,
        padding: 6,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },

    primaryIcon:{
        ...starFishColor(theme)
    },

    requirementsHead:{
        ...starFishColor(theme, "backgroundColor"),
    },

    requirementsRow:{
        "&:nth-child(odd) ":{
            backgroundColor: "rgb(51,51,51)",
        },
        "&:nth-child(even) ":{
            fontWeight: "bold",
        },
        "&> td":{
            padding: 5,
            borderBottom: "none"
        }
    },

    requirementsDescription:{
        whiteSpace: "pre-wrap"
    }
}));


function CustomerRequirements( {value, pvShort, requiredValue, showDetails, requirements, Container=Paper, headerClass, fullWidth=false} ) {
    const {t} = useTranslation("teams");
    const {commonStore, myTeamsStore} = useStores();
    const classes = useStyles({fullWidth});
    const toggleConfirm = ()=> {
        commonStore.toggleConfirmOk( );
    }

    console.log(pvShort)
    const showInfoPopup = (e)=>{
        e.stopPropagation();
        e.preventDefault();

        commonStore.showConfirm(  <>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.requirementsHead} >{t("bussines_status")}</TableCell>
                            <TableCell  className={classes.requirementsHead}>{t("customer_pv_requirements")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("enrollmentMonthPlusNext4Months")}</TableCell>
                            <TableCell align={"center"}>50 PV</TableCell>
                        </TableRow>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("noSecondSalesTeamVolume")}</TableCell>
                            <TableCell align={"center"}>100 PV</TableCell>
                        </TableRow>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("hasSecondSalesTeamVolume")}</TableCell>
                            <TableCell align={"center"}>150 PV</TableCell>
                        </TableRow>
                        <TableRow className={classes.requirementsRow}>
                            <TableCell>{t("qualifiedLeaderOrAbove")}</TableCell>
                            <TableCell align={"center"}>200 PV</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <p className={classes.requirementsDescription}>
                    {t("customerRequirementsDescription")}
                </p>
            </>
            , "OK"
            , null
            , null
            , null
            , undefined
            , { hideHeader:true })
    }
    const selfReportPV = async () => {

        if ( requirements?.CanSelfReport ) {
            commonStore.showConfirm(  <>
                    <>
                        <FormControlLabel
                            control={<Checkbox color="primary" onClick={toggleConfirm} />}
                            label={t("SelfReportedDescription")}
                        />
                    </>
                </>
                , "Proceed"
                , null
                , ()=>{
                    myTeamsStore.selfReportPV()
                }
                , null
                , undefined
                , { hideHeader:true,  disabledOkButton: true})
        }
        else{
            commonStore.showConfirm(  <>
                    <>
                        {t("SelfReportImpossible")}
                        <br/>
                        <br/>
                        {t("SelfReportImpossibleRemain", {pv: formatNumber( pvShort,2 )})}
                    </>
                </>
                , "Close"
                , null
                , null
                , null
                , undefined
                , { hideHeader:true})
        }


    }


    return <>
      <Container className={classes.thermometer}>
          <Box className={clsx( classes.thermometerHeader, headerClass )}>
              {t("CustomerRequirements")}
              <InfoIcon onClick={showInfoPopup} style={{cursor: "pointer", marginLeft:10 }}/>
          </Box>
          <Thermometer hideValue={requirements?.CustomerRequirementsMet} value={requirements?.CustomerRequirementsMet? requiredValue : value} requiredValue={requiredValue}/>

      </Container>
      {showDetails && <>
          { requirements?.CustomerRequirementsMet
              ? <>
                  <i className={clsx(classes.primaryIcon, "fa", "fa-2x fa-check-circle")}></i>
                  <Box paddingTop={1} paddingBottom={1} >
                      {t("SelfReported")}
                  </Box>
              </>
              : <>
                  { !requirements?.CanSelfReport
                      && <Box paddingTop={1} paddingBottom={1}>{t("PVNeeded")}: {formatNumber(
                          requirements?.PVRequirement
                          - requirements?.RegisteredCustomerPV, 2)} PV</Box>}

                  <Button onClick={selfReportPV}
                          color={"primary"} variant={"contained"}>{t("SelfReport")}</Button>
              </>
          }
      </>}
  </>
}


export default observer(CustomerRequirements)