import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {Box, ListItem, ListItemAvatar, ListItemText, makeStyles} from "@material-ui/core";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Input from "@material-ui/icons/Input";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import ReactMarkdown from 'react-markdown'
import { Link as RouterLink } from "react-router-dom";

function RLink(props) {
    return (
        props.href.match(/^(https?:)?\/\//)
            ? <a href={props.href}>{props.children}</a>
            : <Link component={RouterLink} to={props.href}>{props.children}</Link>
    );
}


const useStyles = makeStyles((theme) => ({
    primaryText: {
        fontSize: 20
    },
    secondaryText: {
        fontSize: 14,
        color: theme.palette.text.primary

    }
}));

const LearnAboutSats = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Container>
            <h1 align="center">{t('wallet:learnAboutSats.title')}</h1>
            <ListItem>
                <ListItemAvatar>
                    <HelpOutline fontSize="large"/>
                </ListItemAvatar>
                <ListItemText classes={{primary: classes.primaryText, secondary: classes.secondaryText }}
                    primary={t('wallet:learnAboutSats.section1.primary')}
                    secondary={ <ReactMarkdown components={{a: RLink}}>{t('wallet:learnAboutSats.section1.secondary')}</ReactMarkdown>}

                />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemAvatar>
                    <Input fontSize="large"/>
                </ListItemAvatar>
                <ListItemText classes={{primary: classes.primaryText, secondary: classes.secondaryText }}
                    primary={t('wallet:learnAboutSats.section3.primary')}
                              secondary={ <ReactMarkdown components={{a: RLink}}>{t('wallet:learnAboutSats.section3.secondary' )}</ReactMarkdown>}

                />
            </ListItem>
            <Divider />
        </Container >
    )
}

export default LearnAboutSats;