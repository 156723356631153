import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadList} from "../utils/mobx";

class RascalTunesStore {
    constructor(libraryStore) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore
    }

    rascalTunesSeries = []
    seriesLoading = false
    mediaType = false

    error = null

    setError(error, type = "series") {
        error = extractErrorMessage(error)
        this.error = error
    }

    loadSeries = generateLoadList( "series", this, "seriesLoading"
        , async (id)=>{
            if ( this.libraryStore.library.length ===0 )
                await this.libraryStore.loadLibrary();
            try {
                return  services.Library.details("eTL0400")
            }
            catch(e){
            }
        }, "rascalTunesSeries", "[0].files")



}

export default RascalTunesStore