import RestService from "./RestService";

export default class ConventionLeaderhipService extends RestService {
  constructor() {
    super("api/convention");
  }

  async getConvention(params) {
    return this.get(`${this.baseUrl}/details/starfish/${params.languageCode}`);
  }
}
