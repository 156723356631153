import React from "react";
import {observer} from "mobx-react";
import {Table, TableBody, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "./TableCell";
import {formatNumberOrZero} from "../../../utils/helpers";


function TrainingTable( {t, source, totalQTY, totalBonus } ) {
    return <Table stickyHeader size="small" style={{marginBottom: 20}}>
    <TableHead>
        <TableRow>
            <TableCell>{t('member_organization')}</TableCell>
            <TableCell align={"right"}>{t('group_qty')}</TableCell>
            <TableCell align={"right"}>{t('Rebate')}</TableCell>
            <TableCell align={"right"}>{t('RebateDifferential')}</TableCell>
            <TableCell align={"right"}>{t('RebateAmount')}</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {source.map((item, index) => {
            return <TableRow key={index}>
                <TableCell>{item.MemberName}</TableCell>
                <TableCell
                    align={"right"}>{formatNumberOrZero(item.GroupValue, 2)}</TableCell>
                <TableCell
                    align={"right"}>{formatNumberOrZero(item.BonusPercent, 2)}%</TableCell>
                <TableCell
                    align={"right"}>{formatNumberOrZero((item.BonusPercent - item.SubtractValue1), 2)}%</TableCell>
                <TableCell
                    align={"right"}>${formatNumberOrZero(item.Bonus, 2)}</TableCell>
            </TableRow>
        })
        }
    </TableBody>

    <TableHead>
        <TableRow>
            <TableCell></TableCell>
            <TableCell
                align={"right"}>${formatNumberOrZero(totalQTY, 2)}</TableCell>
            <TableCell align={"right"}></TableCell>
            <TableCell align={"right"}></TableCell>
            <TableCell
                align={"right"}>${formatNumberOrZero(totalBonus, 2)}</TableCell>
        </TableRow>
    </TableHead>
</Table>
}


export default observer(TrainingTable);