import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Vimeo from '@u-wave/react-vimeo';
import { Box, Divider, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from "@material-ui/lab/Alert";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    videoModalContainer: {
        width: '100%',
        minHeight: '300px',
    },
    videoModalContentContainer: {
        width: '100%',
        marginBottom: theme.spacing(1)
    },
    videoModalTitle: {
        maxWidth: '95%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: "'Bebas Neue', cursive",
    },
    relatedVideoList: {
        width: '100%',
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4)
        }
    },
    videoImage: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '150px',
        paddingRight: theme.spacing(1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    playIcon: {
        verticalAlign: 'middle',
    },
    videoLink: {
        display: 'flex',
        maxHeight: 150,
        margin: theme.spacing(1),
        "&:hover": {
            cursor: 'pointer'
        },
        maxWidth: '400px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%'
        }
    },
    modalVideoTitle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '90%',
        overflow: 'hidden'
    },
    modalVideoDescription: {
        maxHeight: '130px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '90%',
        '-webkit-line-clamp': 3,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical'
    },
    videoItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            maxHeight: 360,
            overflowY: 'scroll',
        }
    },
    videoImageContainer: {
        display: 'flex',
        width: '25%',
    },
    videoInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        padding: '15px',
        paddingLeft: 0
    },
    videoProgressContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000
    },
    videoPlayer: {
        padding: theme.spacing(.5)
    },
    alertBox: {
        maxWidth: 300,
        margin: '0 auto'
    },
    currentlyPlayingVideo: {
        border: '1px solid gold'
    }
}));

const VideoModal = ({ videoModalOpen, onCloseVideoModal, setCurrentVideoInfo, currentVideoInfo, videoModalTitle, relatedVideos, hasVideoPermissions, noPermissionErrorText, customStringMap }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    if (!currentVideoInfo) {
        return null;
    }

    const onPlayerLoaded = () => setLoading(false);

    const closeModal = () => {
        setLoading(true); // reset this so the loader shows the next time the modal is open
        onCloseVideoModal();
    };

    const hasOtherVideos = (videoId) => {
        return relatedVideos && relatedVideos.length > 0 && (relatedVideos.filter(vids => vids.vimeoID != videoId).length > 0);
    };

    return (
        <Dialog className={classes.videoModalContainer}
            PaperProps={{
                style: {
                    backgroundColor: '#343a40',
                    color: 'white',
                },
            }}
            open={videoModalOpen}
            onClose={closeModal}
            maxWidth="md"
            fullWidth>
            {currentVideoInfo &&
                <DialogTitle display="flex">
                    <Typography className={classes.videoModalTitle} variant="h3">{currentVideoInfo.title}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseVideoModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            }
            <DialogContent className={classes.videoModalContentContainer}>
                <Grid container>
                    {currentVideoInfo &&
                        <Grid item md={hasOtherVideos(currentVideoInfo.vimeoID) ? 6 : 12} xs={12} className={clsx(hasVideoPermissions && "embed-responsive")}>
                            {
                                (!hasVideoPermissions) ?
                                    <Box className={classes.alertBox}>
                                        <Alert severity="error" variant="standard">{noPermissionErrorText}</Alert>
                                    </Box> :
                                    <Box>
                                        <Vimeo
                                            id="vimeoPlayer"
                                            onLoaded={onPlayerLoaded}
                                            onError={onPlayerLoaded}
                                            className={clsx("embed-responsive-item", classes.videoPlayer)}
                                            allow="fullscreen"
                                            video={currentVideoInfo.vimeoID} />
                                        {loading && <Box className={classes.videoProgressContainer}>
                                            <CircularProgress />
                                        </Box>
                                        }
                                    </Box>
                            }
                        </Grid>
                    }
                    {hasOtherVideos(currentVideoInfo.vimeoID) && <Grid item md={6} xs={12} className={classes.relatedVideoList}>
                        <Typography variant="h5" className={classes.videoModalTitle}>{t('conventionProgram:content.videosOfType', { type: videoModalTitle })}</Typography>
                        <Divider />
                        <Box className={classes.videoItemContainer}>
                            {relatedVideos
                                .map(video =>
                                    <>
                                        <Box key={video.vimeoID} className={clsx(classes.videoLink, (video.vimeoID === currentVideoInfo.vimeoID) && classes.currentlyPlayingVideo)} onClick={() => setCurrentVideoInfo(video)}>
                                            <Box className={classes.videoImageContainer}>
                                                <img className={classes.videoImage} src={video.thumbnailURL} />
                                            </Box>
                                            <Box className={classes.videoInfoContainer}>
                                                <Typography variant="h6" className={classes.modalVideoTitle}>{video.title}</Typography>
                                                {(!!video.vipTicketRequired || !!video.futureTicketRequired) && <Typography className={classes.modalVideoDescription} variant="body2">
                                                    {!!video.vipTicketRequired ? customStringMap.needsVipTicket : customStringMap.needsFutureTicket}
                                                </Typography>
                                                }
                                            </Box>
                                        </Box>
                                    </>
                                )}
                        </Box>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default VideoModal;