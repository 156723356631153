import RestService from "./RestService";


class FavoritesService extends RestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/audios/favorites`)
        }
        else
            throw new Error("Can be only nested service")
    }
}

export default class LibraryService extends RestService {
    constructor() {
        super('/api/library' )
    }


    async loadSeries(id){
        return this.post( `${this.baseUrl}/series`,{productType: "all", syncDate: null, series: id} )
    }

    async loadSpeaker(id){
        return this.post( `${this.baseUrl}/speaker`,{productType: "all", syncDate: null, speakerID: id} )
    }
    async details(id){
        return this.get( `${this.baseUrl}/details/${id}` )
    }

    async audiosPlayed(){
        return this.get( `${this.baseUrl}/audios/played` )
    }

    async search(searchString){
        return this.get( `${this.baseUrl}/search/${encodeURI( searchString )}` )
    }

    async album(albumID){
        return this.get( `${this.baseUrl}/albums/${albumID}` )
    }

    async audioProgress(audioID, time, completed){
        return this.patch( `${this.baseUrl}/audios/${audioID}/time`, {time, completed} )
    }

    async audioTotalTime(audioID,  time ){
        return this.patch( `${this.baseUrl}/medias/${audioID}/time`, {time} )
    }

    async checkPurchased( productSku ){
        return this.post( `${this.baseUrl}/medias/checkPurchased`, {productSku} )
    }

    favoritesService(){
        return new FavoritesService( this )
    }
}

