
import IdLessRestService from "./IdLessRestService";


export default class GiftCardsService extends IdLessRestService {
    constructor() {
        super('/api/v1/shoppingboss/giftcards');
    }

    list( status ){
        return this.get(`${this.baseUrl}/${status}`);
    }

    listUserCards( status ){
        return this.get(`/api/v1/giftcards/cards/${status}`);
    }

    updateGiftCardStatusLegacy( status, orderNumber ){
        return this.put(`${this.baseUrl}/${status}/order/${orderNumber}`);
    }
    updateGiftCardStatus( status, giftCardId ){
        return this.put(`/api/v1/giftcards/cards/${status}/gift-id/${giftCardId}`);
    }

    balance( orderNumber ){
        return this.get(`${this.baseUrl}/order/${orderNumber}/balance`);
    }

    notes(  ){
        return this.get(`/api/v1/giftcards/cards/notes`);
    }

    updateNotes( giftCardId, notes ){
        return this.put(`/api/v1/giftcards/cards/notes/${giftCardId}`, {notes});
    }
}
