import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CartRow from './CartRow';
import LoadingIndicator from '../../../components/shop/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSync
} from '@fortawesome/pro-light-svg-icons';
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/use-stores";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { setCookie } from "../../../utils/cookieUtil";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AutoshipUpdatedModal from "./AutoshipUpdatedModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import compact from "lodash/compact";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ShippingAddressForm from "../../../components/enrollment/shippingAddressForm";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import AutoShipStatusBanner from '../../../components/shop/AutoshipStatusBanner';
import {useShopType} from "../useShopType";
import {Box} from "@material-ui/core";
import {useLocalObservable} from "mobx-react-lite";
import CreateShopAccount from "../CreateShopAccount";
import {formatNumberOrZero, joinPath} from "../../../utils/helpers";

const useStyles = makeStyles((theme) => ({

    card: {
        height: "100%",
        display: 'flex',
        flexDirection: "column",
    },
    cardMainArea: {
        flexGrow: 1,
        display: "inline-flex",
        alignItems: "flex-start",
        justifyContent: "flex-start"
    },
    cellContainer: {
        border: "1px solid #666666",
        backgroundColor: "#292929",
        fontFamily: "Helvetica, sans-serif",
        fontWeight: 300,
        fontSize: 16
    },
    cartCellList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },

    cartCell: {
        padding: "10px 20px"
    },

    cartCellHeader: props => ({
        display: "grid",
        gridTemplateColumns: props.isMember ? "8fr 2fr 2fr" : "10fr 1fr",
        height: 20
    }),
    cartSubtotalHeader: {
        fontWeight: "bold",
        fontSize: "medium",
        alignSelf: "center",
        paddingLeft: 15
    },
    cartSubtotalPrice: {
        color: "#ff0000",
        fontSize: "x-large",
        alignSelf: "center",
        paddingLeft: 10
    },

    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    runDay: {
        marginLeft: 10,
        marginRight: 10,
    },
    cancelAutoship: {
        marginTop: 10
    } ,
    alert: {
        marginTop: 10,
        marginBottom: 10
    },
    popup: {
        margin: 10
    },

    suggestionLabel:{
        fontWeight: "bold",
    },
    suggestionOldValue:{
        paddingLeft: 10,
        textDecoration: "line-through",
        opacity: 0.5
    },
    suggestionNewValue:{
        paddingLeft: 10,
    },

}));


function Cart({ shoppingMatch, shoppingRoot, standalone=false, isRoyal=false }) {
    if ( !shoppingRoot )
        shoppingRoot=shoppingMatch


    const shopOptions = useShopType()
    const history = useHistory();

    const { authStore, shopStore, commonStore, userProfileStore } = useStores();
    const [showEditAddressModal, setShowEditAddressModal] = useState(false);
    const [showEditPayment, setShowEditPayment] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const { isGuest, currentUser, inProgress } = authStore;
    const isMember = currentUser && currentUser.isMember;
    let country = authStore.currentUser && authStore.currentUser.address && authStore.currentUser.address.country;
    const {
        isAutoshipChanged, autoShipUserCartGuid, runDay, updatingAutoShip
        , autoshipOrderStatus, autoshipPaymentInfo, autoshipShippingInfo, addressForm, addressFormSubmitting
        , nextProcessingDate, currentAutoShipItems, suggestions
        , userCartGuid, customerLevelID, guestCountry, shippingError, updatinPaymentID
        , subscriptionUserCartGuid, loadCurrentAutoShipError,
        getUserCartError
    } = shopStore;
    const unblock = useRef();
    useEffect(()=>{
        shopStore.setError(null, "getUserCart")
        shopStore.setError(null, "loadCurrentAutoShip")
    }, [])
    useEffect(() => {
        if (isAutoshipChanged) {
            unblock.current = history.block(tx => {
                if( tx.pathname === "/shopping/autoship/cart"){
                    return true
                }
                commonStore.showConfirm(t("shopping:unsaved_confirmation")
                    , t("shopping:unsaved_button")
                    , t("shopping:unsaved_title")
                    , () => {
                            unblock.current();
                            history.push(tx.pathname);
                    }
                    , t("shopping:cancel_title")
                    , {onCancelAction: async () => {
                            let result = await shopStore.udateAutoShip();
                            if (result) {
                                unblock.current();
                                history.push(tx.pathname);
                            }
                        }});
                return false;
            });
        }
        else {
            if (unblock.current)
                unblock.current();
        }

    }, [isAutoshipChanged, history.location.pathname]);

    const { allCountries, regionsByCountry } = userProfileStore;
    const { t } = useTranslation();
    const classes = useStyles({ isMember });

    const updateCartItem = (item, quantity) => {
        shopStore.updateItemQty(item, quantity);
    }




    const getCartData = async () => {
        switch (shopOptions.shopId) {
            case "subscriptions":
                await Promise.all([shopStore.loadSubscriptionsCartItems(),
                    , shopStore.getSubscriptionCartTotals(subscriptionUserCartGuid)]);
                break;
            case "autoship":
                await Promise.all([shopStore.loadAutoShipCartItems(),
                    , shopStore.loadCurrentAutoShipInfo()
                    , shopStore.loadCurrentAutoShipItems()
                    , shopStore.getAutoShipCartTotals(autoShipUserCartGuid)]);
                break;
            case "standard":
                if (isGuest) {
                  // guest should Login first
                }
                else if (currentUser.shoppingUserDetails){

                    const region = currentUser.shoppingUserDetails.Region;
                    const country = currentUser.shoppingUserDetails.Country;
                    const customerLevelID = currentUser.shoppingUserDetails.CustomerLevelID;
                    await shopStore.getUserCart({cartGuid: userCartGuid, customerLevelID});
                    await shopStore.getCartTotals({cartGuid: userCartGuid, customerLevelID, country, region});



                }
                break;

        }
    };

    const reloadAutoshipItems = () => {
        shopStore.loadCurrentAutoShipItems();
    }

    useEffect(async () => {
        userProfileStore.loadAllCountries();
        userProfileStore.loadRegionsByCountry(country);
        getCartData();
        shopStore.setEditForm(handleHideShippingEdit);
    }, [currentUser]);


    useEffect(async () => {
        if (!!autoshipShippingInfo && !!autoshipShippingInfo.country)
            await userProfileStore.loadRegionsByCountry(autoshipShippingInfo.country || "US");
    }, [autoshipShippingInfo]);


    const cart = shopStore[shopOptions.cartProp]
    const effectiveCart = shopStore[shopOptions.cartEffectiveProp]

    const cartLoading =  shopStore[shopOptions.cartLoadingProp]

    const totalsLoading =  shopStore[shopOptions.totalsLoadingProp]
    const totals =  shopStore[shopOptions.totalsProp]
    const cartItems =  shopStore[shopOptions.cartItemsProp]



    const handleRunDayChange = (e) => {
        shopStore.setRunDay(e.target.value);
    };

    const getProceedToCheckoutURL = () => {
        let url = `${window.location.origin}/checkout`;
        switch (shopOptions.shopId) {
            case 'autoship':
                url += `?is_autoship=true&cart_guid=${autoShipUserCartGuid}`;
                break;
            case 'subscriptions':
                url += `?is_subscription=true&cart_guid=${subscriptionUserCartGuid}`;
                break;
            default:
                break;
        }
        return url;
    };

    const onProceedToCheckoutClickHandler = () => {
        //history.push('/checkout');
        setCookie("cart_guid", userCartGuid);
        setCookie("selected_lang", commonStore.language);
        shopStore.setGuestCountry(guestCountry); //ensure in cookie
        window.location.replace(getProceedToCheckoutURL());
    };

    const [updatedType, setUpdatedType] = useState();
    const onUpdateAutoShipClickHandler = async () => {
        let result = await shopStore.udateAutoShip();
        if (result)
            setUpdatedType("updated");
    };

    const onCancelAutoShipClickHandler = async () => {
        let result = await shopStore.cancelAutoShip();
        if (result)
            setUpdatedType("canceled");
    };
    const onProceedToShopHomeHandler = () => {
        closeUpdatedModal();
        history.push(shoppingMatch.url);
    };

    const closeUpdatedModal = () => {
        setUpdatedType(null);
    };

    const handleOpenShippingEdit = () => {
        setShowEditAddressModal(true);
    };
    const handleHideShippingEdit = () => {
        setShowEditAddressModal(false);
    };


    const handleUpdateAddress = async (event) => {
        let result = await shopStore.validateAddressForm();
        if ( result && result.length>0){
            commonStore.showConfirm( <>
                    <Typography>{t("suggestions.suggestions_pretext")}</Typography>
                    <div>
                        {result.map((item, index) => {
                            return <div key={index}>
                                <span className={classes.suggestionLabel}>{item.field.label}:</span>
                                <span  className={classes.suggestionOldValue}>{item.field.value}</span>
                                <span className={classes.suggestionNewValue}>{item.suggestedValue}</span> </div>

                        })}
                    </div>
                </>
                , [
                    { key: "apply", value: t("suggestions.apply_suggestions"), variant:"contained" },
                    { key: "ignore", value: t("suggestions.ignore_suggestions"), variant:"contained"}
                ]
                , t("suggestions.suggestions_title")
                , (key)=>{

                    if ( key === "apply") {
                        result.forEach(item => {
                            item.field.set("value", item.suggestedValue );
                        });

                        shopStore.submitAddressForm();
                    }
                    if ( key === "ignore") {
                        {
                            shopStore.submitAddressForm();
                        }
                    }

                },"Cancel" );
        }
        else
            await shopStore.submitAddressForm();


    };
    const handleOpenPaymentEdit = () => {
        setShowEditPayment(true);
        setSelectedPaymentMethod(autoshipPaymentInfo.paymentID);
    };

    const handleHidePaymentEdit = () => {
        setShowEditPayment(false);
    };

    const handleSavePaymentMethod = async () => {
        await shopStore.updatePaymentMethod(selectedPaymentMethod);
        await shopStore.loadCurrentAutoShipInfo();
        handleHidePaymentEdit();
    };

    const handleCountryChanged = (newVal) => {
        if (newVal)
            userProfileStore.loadRegionsByCountry(newVal);
    };

    const showLoading = useMemo((newVal) => {
        //   console.log("recalc as ",  cartLoading || totalsLoading, cartLoading, totalsLoading)
        return cartLoading || totalsLoading || inProgress;

    }, [cartLoading, totalsLoading]);

    const toggleAutoshipStatusModal = async () => {
        let result = await shopStore.reactivateAutoship();
        if (result)
            setUpdatedType("reactivated");
    };

    const localStore = useLocalObservable(() => ({


        get shouldShowUpdateCart() {
            if( shopOptions.shopId !== "autoship") return  false
            if ( autoshipOrderStatus && autoshipOrderStatus.orderStatus === 'cancelled' )
                return  false
            return  currentAutoShipItems && currentAutoShipItems.length > 0
        },

        get shouldShowProcessToCheckout() {
            if( shopOptions.shopId !== "autoship") return  true
            if ( autoshipOrderStatus && autoshipOrderStatus.orderStatus === 'cancelled' )
                return  false
            return  !currentAutoShipItems || currentAutoShipItems.length === 0
        },

        get shouldShowCancelAutoship() {
            if( shopOptions.shopId !== "autoship") return  false
            if ( autoshipOrderStatus && autoshipOrderStatus.orderStatus === 'cancelled' )
                return  false
            return  ((currentAutoShipItems && currentAutoShipItems.length > 0) && !updatingAutoShip)
        },

    }));



    return (<>

        {(showLoading) ? (<LoadingIndicator tag={"cart"} />) : (
            <Container maxWidth={"md"}>
                <Grid container>
                    <Grid container item direction={"column"} spacing={3}>
                        {!!autoshipOrderStatus && <AutoShipStatusBanner enabled={autoshipOrderStatus.orderStatus === 'cancelled' ? true : false} onClickHandler={toggleAutoshipStatusModal} />}
                        <Typography variant={"h5"}> {shopOptions.cartname}</Typography>

                        {loadCurrentAutoShipError && <Alert severity={"error"} variant={"filled"} className={classes.alert}
                                                            action={
                                                                <Button color="inherit" size="small" onClick={reloadAutoshipItems}>
                                                                    Retry
                                                                </Button>
                                                            }>Error while loading current items</Alert>}

                        {getUserCartError && <Alert severity={"error"} variant={"filled"} className={classes.alert}
                                                            action={
                                                                <Button color="inherit" size="small" onClick={reloadAutoshipItems}>
                                                                    Retry
                                                                </Button>
                                                            }>{getUserCartError}</Alert>}

                        {(!(effectiveCart || cart ) || (effectiveCart || cart ).length === 0) ? <>
                            <div className="no-product-available">
                                <h2 style={{ fontSize: "x-large" }}>There are no items in your cart!</h2>
                            </div>
                        </> : <>
                            <div className={classes.cellContainer}>
                                <ul className={classes.cartCellList}>
                                    <li className={classes.cartCell}>
                                        <div className={classes.cartCellHeader}>
                                            <div></div>
                                            <div>Price</div>
                                            {isMember && <div style={{ textAlign: 'center' }}>PV</div>}
                                        </div>
                                    </li>
                                    {(effectiveCart || cart ).map((item, index) => {
                                        return <CartRow
                                            isMember={isMember}
                                            item={item}
                                            productID={item.productID}
                                            productSKU={item.productSKU}
                                            productName={item.productName}
                                            imageURL={item.imageUrl || item.imageURL}
                                            isDigital={item.isDigital}
                                            price={item.productPrice}
                                            pv={item.productPV}
                                            qty={item.quantity}
                                            onDelete={() => getCartData()}
                                            onUpdate={updateCartItem}
                                            key={item.productID + item.productSKU + index}
                                            isGuest={isGuest} />;
                                    })}
                                </ul>
                            </div>

                            <Grid container item justifyContent={"space-between"}>
                                <Grid xs={3} md={3} item>
                                    <Button size={"large"} variant={"contained"} className={classes.desktop}
                                        onClick={() => history.push(shoppingRoot.url)}
                                        id="btnContinueShopping">Continue Shopping</Button>
                                </Grid>
                                <Grid xs={9} md={9} item container alignItems={"flex-end"}>
                                    <Grid container item justifyContent={"flex-end"} alignItems={"center"}>
                                        <div className={classes.cartSubtotalHeader}>Subtotal</div>
                                        <div
                                            className="cart-subtotal-subline">({cartItems} items):
                                        </div>
                                        <div className={classes.cartSubtotalPrice}>{cartLoading ?
                                            <FontAwesomeIcon icon={faSync} size="1x"
                                                spin /> : `${ formatNumberOrZero( totals.subTotal, 2 )}`}</div>
                                    </Grid>
                                    {isMember && <Grid container item justifyContent={"flex-end"} alignItems={"center"}>
                                        <div className="cart-subtotal-subline">({totals && totals.pv} PV)</div>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            <Grid container item justifyContent={"space-between"} direction={"column"}>
                                <Grid container item direction={"column"} alignItems={"flex-end"} spacing={2}>
                                    {localStore.shouldShowUpdateCart &&<>
                                        { updatingAutoShip ? <CircularProgress /> :
                                            <Grid container item direction={"column"} alignItems={"flex-end"}
                                                  spacing={1}>
                                                <Grid item>
                                                    <div>{t("shopping:cartmodal.next_processing_date")}:
                                                        {nextProcessingDate && moment(nextProcessingDate).format("MMM")}
                                                        <Select onChange={handleRunDayChange} className={classes.runDay}
                                                                value={runDay || 7}
                                                        >
                                                            {([1, 7, 14, 21]).map(x => {
                                                                return <MenuItem key={x} value={x}>{x}</MenuItem>;
                                                            })}
                                                        </Select>
                                                        {nextProcessingDate && moment(nextProcessingDate).format("YYYY")}
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant={"contained"}
                                                            size={"large"} color={"primary"}
                                                            onClick={() => onUpdateAutoShipClickHandler()}>
                                                        {t('shopping:cartmodal.update')}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button size={"large"} variant={"contained"}
                                                            className={classes.mobile}
                                                            onClick={() => history.push(shoppingMatch.url)}
                                                            id="btnContinueShopping">Continue Shopping</Button>
                                                </Grid>
                                            </Grid>}
                                    </>}
                                    {localStore.shouldShowProcessToCheckout &&
                                        <Grid container item direction={"column"} alignItems={"flex-end"}>
                                            <Button variant={"contained"}
                                                size={"large"} color={"primary"}
                                                onClick={() => onProceedToCheckoutClickHandler()}>
                                                {t('shopping:cartmodal.proceedtocheckout')}
                                            </Button>
                                        </Grid>}
                                </Grid>
                                {shopOptions.shopId === "autoship" && <Grid item container spacing={2}>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {autoshipShippingInfo && <Card className={classes.card}>
                                            <CardActionArea className={classes.cardMainArea}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h6" component="h4">
                                                        {t("shopping:autoship.shipping.title")}
                                                    </Typography>
                                                    <Typography gutterBottom variant="body2" component="h4">
                                                        {autoshipShippingInfo.addressName}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {autoshipShippingInfo.address1} {autoshipShippingInfo.address2}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {compact([autoshipShippingInfo.city, autoshipShippingInfo.region]).join(", ")} {autoshipShippingInfo.zip}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {autoshipShippingInfo.country}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Button onClick={handleOpenShippingEdit} size="small"
                                                    variant={"contained"}
                                                    color="primary">
                                                    Edit
                                                </Button>
                                            </CardActions>
                                        </Card>}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {autoshipPaymentInfo && <Card className={classes.card}>
                                            <CardActionArea className={classes.cardMainArea}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h6" component="h4">
                                                        {t("shopping:autoship.billing.title")}
                                                    </Typography>
                                                    {showEditPayment
                                                        ? <>
                                                            <Select renderValue={x => selectedPaymentMethod === 0 ? ("*********" + autoshipPaymentInfo.defaultPayment) : "eWallet"} value={selectedPaymentMethod} onChange={e => { setSelectedPaymentMethod(e.target.value); }}>
                                                                <MenuItem value={0}>{t("shopping:autoship.billing.cardending")}{autoshipPaymentInfo.defaultPayment}</MenuItem>
                                                                <MenuItem value={-1}>{t("shopping:autoship.billing.ewallet")}</MenuItem>
                                                            </Select>
                                                        </>
                                                        : <Typography gutterBottom variant="body2" component="h4">
                                                            {autoshipPaymentInfo.paymentID === 0 && t("shopping:autoship.billing.cardending") + ` ` + autoshipPaymentInfo.defaultPayment}
                                                            {autoshipPaymentInfo.paymentID === -1 && t("shopping:autoship.billing.ewallet")}
                                                        </Typography>
                                                    }`


                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                {showEditPayment
                                                    ? (updatinPaymentID ? <CircularProgress />
                                                        : <>
                                                            <Button onClick={handleSavePaymentMethod} size="small"
                                                                variant={"contained"}
                                                                color="primary">
                                                                Save
                                                            </Button>
                                                            <Button onClick={handleHidePaymentEdit} size="small"
                                                                variant={"contained"}
                                                                color="secondary">
                                                                Cancel
                                                            </Button>
                                                        </>)
                                                    : <Button onClick={handleOpenPaymentEdit} size="small"
                                                        variant={"contained"}
                                                        color="primary">
                                                        Edit
                                                    </Button>
                                                }

                                            </CardActions>
                                        </Card>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>}
                                {localStore.shouldShowCancelAutoship &&
                                <Grid container item direction={"column"} alignItems={"center"} spacing={2} className={classes.cancelAutoship}>
                                    <Grid container item direction={"column"} alignItems={"center"}
                                        spacing={1}>
                                        <Grid item>
                                            <Button variant={"contained"}
                                                size={"large"} color={"secondary"}
                                                onClick={() => onCancelAutoShipClickHandler()}>
                                                {t('shopping:cartmodal.cancelautoship')}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </Grid>

                        </>}
                    </Grid>
                </Grid>
                {updatedType &&
                    <AutoshipUpdatedModal type={updatedType} onProceedToShopHomeHandler={onProceedToShopHomeHandler} closeUpdatedModal={closeUpdatedModal} />}

                {showEditAddressModal && <Dialog maxWidth={"md"} open={true} onClose={handleHideShippingEdit}>
                    <DialogContent>
                        <ShippingAddressForm form={addressForm} country={country}
                            suggestions={suggestions}
                            regionsByCountry={regionsByCountry} allCountries={allCountries} handleCountryChanged={handleCountryChanged} />
                    </DialogContent>
                    <DialogActions>
                        <Box display="flex" flexDirection="column" width="100%">
                            {shippingError ? <Alert severity="error" variant={"filled"}  >{shippingError}</Alert> : null}
                        <Box display="flex"  padding={4} justifyContent="flex-end" >

                        {addressFormSubmitting
                            ? <CircularProgress />
                            : <>
                                <Button onClick={handleHideShippingEdit} variant={"contained"}
                                    color="secondary">{t("cancel")}</Button>

                                <Button onClick={handleUpdateAddress} variant={"contained"}
                                    color="primary">{t("update")}</Button>
                            </>}
                        </Box>
                        </Box>
                    </DialogActions>
                </Dialog>}

            </Container>

        )}
        {isGuest && !inProgress && <CreateShopAccount
            shopOptions={shopOptions}
            open={true}
            onClose={() => {
                // disable modal by creating account, or go back to shopping
            }} />}

    </>);

}


export default observer(Cart);
