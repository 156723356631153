import { Box, Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useStores } from "../../../hooks/use-stores";
import {STARFISH_KIT_EN_SKU, STARFISH_KIT_ES_SKU, STARFISH_KIT_FR_SKU} from "../../../utils/constants";

export const bookKitKey = "book-kit-data";

const bookKitData = [
  {
    ProductID: 21943,
    ProductRef: STARFISH_KIT_EN_SKU,
    PointValue: 50,
    ProductName: "Book Kit",
  },
  {
    ProductID: 22092,
    ProductRef: STARFISH_KIT_FR_SKU,
    PointValue: 50,
    ProductName: "Book Kit (French)",
  },
  {
    ProductID: 22093,
    ProductRef: STARFISH_KIT_ES_SKU,
    PointValue: 50,
    ProductName: "Book Kit (Spanish)",
  },
];

const bookKitOptions = [
  { label: "Book Kit (English)", value: STARFISH_KIT_EN_SKU },
  { label: "Book Kit - French", value: STARFISH_KIT_FR_SKU },
  { label: "Book Kit - Spanish", value: STARFISH_KIT_ES_SKU },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  inputArea: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      gap: "16px",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  inputLabel: {
    width: "150px",
    margin: "0 0 12px 0",
    [theme.breakpoints.up("sm")]: {
      margin: "0",
    },
  },
  inputNumberWrapper: {
    textAlign: "right",
  },
  inputNumber: {
    background: "transparent",
    display: "block",
    width: "70px",
    color: "white",
    border: "1px solid gray",
    height: "40px",
    padding: "0 8px 0 16px",
    borderRadius: "4px",
  },
  table: {
    marginTop: "24px",
  },
  select: {
    "& .MuiSelect-select": {
      padding: "10.5px 36px 10.5px 16px",
    },
  },
}));

const BookKitSelect = ({
  selectedUser,
  selectedProducts,
  setSelectedProducts,
  labelClassName,
}) => {
  const classes = useStyles();
  const { authStore } = useStores();
  const { currentUser } = authStore;
  const [bookKitQuantity, setBookKitQuantity] = useState(0);
  const [selectedBookKit, setSelectedBookKit] = useState(
    bookKitOptions[0].value
  );

  const updateLocalStorage = (newData) => {
    localStorage.setItem(
      `${bookKitKey}-${currentUser.userID}`,
      JSON.stringify(newData)
    );
  };

  return (
    <Box className={classes.wrapper}>
      <div className={classes.inputArea}>
        <p className={clsx(classes.inputLabel, labelClassName)}>
          Book Kit Sold:
        </p>
        <Select
          className={clsx(classes.select)}
          labelId="book-kit-select-label"
          value={selectedBookKit}
          onChange={(e) => {
            setSelectedBookKit(e.target.value);
          }}
          variant="outlined"
        >
          {bookKitOptions.map((kit) => (
            <MenuItem key={kit.value} value={kit.value}>
              {kit.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className={classes.inputArea}>
        <p className={clsx(classes.inputLabel, labelClassName)}>Quantity: </p>
        <input
          type="number"
          disabled={!bookKitData}
          className={classes.inputNumber}
          value={bookKitQuantity || 0}
          onChange={(e) => {
            const value = Number(e.target.value);
            setBookKitQuantity(value);
          }}
        />
      </div>

      <div className={classes.inputArea}>
        <Button
          disabled={bookKitQuantity <= 0 || !selectedUser}
          variant="contained"
          color="primary"
          onClick={() => {
            const toAddProduct = bookKitData.find(
              (kit) => kit.ProductRef === selectedBookKit
            );
            const newSelectedProducts = [
              ...selectedProducts,
              {
                ...toAddProduct,
                uuid: uuid(),
                Qty: bookKitQuantity,
                toAccountId: selectedUser.UserID,
                toAccountEmail: selectedUser.Email,
                toAccountName: `${selectedUser.firstName || ""} ${
                  selectedUser.lastName || ""
                }`,
                toSponsorEmail: selectedUser.SponsorEmail,
              },
            ];
            setSelectedProducts(newSelectedProducts);
            updateLocalStorage(newSelectedProducts);
            setBookKitQuantity(0);
          }}
        >
          Add product
        </Button>
      </div>

      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product SKU</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">PV</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">To Account</TableCell>
              <TableCell align="right">Total PV</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProducts.map((product) => {
              return (
                <TableRow key={product.ProductRef}>
                  <TableCell>{product.ProductRef}</TableCell>
                  <TableCell>{product.ProductName}</TableCell>
                  <TableCell align="right">{product.PointValue}</TableCell>
                  <TableCell align="right">
                    <div className={classes.inputNumberWrapper}>
                      {product.Qty}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    #&nbsp;{product.toAccountId}
                  </TableCell>
                  <TableCell align="right">
                    {product.PointValue * product.Qty}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        const newSelectedProducts = selectedProducts.filter(
                          (item) => item.uuid !== product.uuid
                        );
                        updateLocalStorage(newSelectedProducts);
                        setSelectedProducts(newSelectedProducts);
                      }}
                    >
                      DELETE
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default observer(BookKitSelect);
