import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faShoppingBag,
    faThList,
    faTimes
} from '@fortawesome/pro-light-svg-icons';
import { getCookie } from '../../../../utils/cookieUtil';
import { useStores } from "../../../../hooks/use-stores";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ShopIcon from "@material-ui/icons/ShoppingCart";
import GlobeIcon from "@material-ui/icons/Language";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import { Link as RouteLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { joinPath } from "../../../../utils/helpers";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useShopType} from "../../../../containers/shop/useShopType";
import {IconButton} from "@material-ui/core";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import CreateShopAccount from "../../../../containers/shop/CreateShopAccount";


const useStyles = makeStyles((theme) => ({

    appBarRoot: {
        height: 78,
        backgroundColor: "black",
    },
    appBarContainer: {
        height: 78
    },
    appbarIcons: {
        whiteSpace: "nowrap"
    },
    appbarToolbar: {
        height: "100%"
    },
    grow: {
        flexGrow: 1,
    },
    label: {
        textTransform: "uppercase",
        fontSize: "1.125rem",
        padding: " 0 10px"
    },
    icon: {
        fontSize: "18px",
        margin: "5px",
    },
    shoppingBag: {
        [theme.breakpoints.down(600)]: {
            display: 'none',
        },
    },
    languageLabel: {
        [theme.breakpoints.down(600)]: {
            display: 'none',
        },
    },
    cartLink: {
        cursor: "pointer",
        [theme.breakpoints.down(600)]: {
            display: 'none',
        },
    },
    logo: {
        width: 40
    },
    toolBarFlex: {
        display: "flex",
        alignItems: "center",
        margin: 10
    },
    navLink: {
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        color: "white"
    }
}));

function Navbar({  isGuest, disableCountryDDL, shoppingMatch, autoship, standalone }) {
    const { commonStore, shopStore, enrollmentStore } = useStores();
    const classes = useStyles();
    const { language,onStarfish } = commonStore;
    const { marketIds, guestCountryIndex } = shopStore;
    const { t } = useTranslation();
    const history = useHistory();
    const [languageDisplay] = useState("english");
    const [languageModalShow, setLanguageModalShow] = useState(false);
    const [createAccountModalShow, setCreateAccountModalShow] = useState(false);

    const shopOptions = useShopType()
    const {cartGuidProp, cartItemsProp} = shopOptions
    const  cartGUID = shopStore[cartGuidProp]
    const  cartItems = shopStore[cartItemsProp]
    const handleLanguageChange = (lang) => {
        commonStore.setLanguage(lang, true);
    };

    const showLanguageModal = () => {
        setLanguageModalShow(true);
    };

    const handleUserMenuOpen = (event) => {
        history.push({
            pathname: standalone? '/userprofile': '/starfish/userprofile',
            state: { fromModule: 'SHOP' }
        });
    };

    const onCountryModalClose = () => {
        setLanguageModalShow(false);
    };

    const handleCountryChange = (event) => {
        if (isGuest) {
            shopStore.setGuestCountry(marketIds[event.target.value]);
        }
    };
    const ChangeLanguageModal = () => {
        return (
            <Dialog open={languageModalShow} onClose={onCountryModalClose} maxWidth={"xs"} fullWidth>
                <DialogTitle disableTypography >
                    <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                        <div style={{ cursor: "pointer" }} onClick={onCountryModalClose}>
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                        </div>
                    </Grid>
                </DialogTitle>
                {isGuest && <>
                    <DialogTitle disableTypography >
                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                            <Typography>{t('shopping:home.language.selectcountry')}</Typography>
                        </Grid>
                    </DialogTitle>
                    <select
                        disabled={disableCountryDDL}
                        selectedIndex={guestCountryIndex}
                        className="modal-lang"
                        value={guestCountryIndex}
                        onChange={handleCountryChange}
                    >
                        {
                            marketIds.map((marketItem, index) => {
                                return <option
                                    key={index}
                                    data-marketid={marketItem.marketID}
                                    data-customerlevelid={marketItem.customerLevelID}
                                    value={index}>{marketItem.countryName}</option>;
                            })
                        }
                    </select>
                </>}

                <DialogTitle disableTypography >
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>{t('shopping:home.language.language')}</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent className="modal-lang-country__form-group">
                    <div className="form-check">
                        <label className="selectgroup-item">
                            <input
                                type="radio"
                                name="react-tips"
                                value="en"
                                checked={language === "en"}
                                onChange={() => handleLanguageChange("en")}
                                className="form-check-input"
                            />
                            <span className="input-language">{t('shopping:home.language.english')}</span>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="selectgroup-item">
                            <input
                                type="radio"
                                name="react-tips"
                                value="fr"
                                checked={language === "fr"}
                                onChange={() => handleLanguageChange("fr")}
                                className="form-check-input"
                            />
                            <span className="input-language">
                                {t('shopping:home.language.french')}
                            </span>
                        </label>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button variant={"contained"} color={"primary"}
                        onClick={onCountryModalClose}
                        type="button"
                        className="btn btn-primary" data-dismiss="modal">{t('shopping:home.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleOpenCart = () => {
       if ( isGuest ){
           setCreateAccountModalShow(true);
       }else
            history.push(shopOptions.cartUrl);

    };

    return (
        <>
            <AppBar position={"static"} classes={{ root: classes.appBarRoot }}>
                <Toolbar className={classes.appbarToolbar}>
                    <div className={clsx(classes.grow, classes.toolBarFlex)}>
                        <Link component={RouteLink} className={classes.navLink} to={shopOptions.home}><img className={classes.logo}
                            onClick={() => {
                                history.push(shopOptions.home);
                            }}
                            src={ "/starfish.png"}
                            alt=""
                        />
                            <Typography variant={"h6"} className={clsx(classes.label, classes.shoppingBag)}>{t(shopOptions.name)}</Typography>
                            <FontAwesomeIcon className={classes.icon} data-dismiss="modal" icon={faShoppingBag} />
                        </Link>
                    </div>
                    <div className={classes.toolBarFlex} >
                        {
                            ( shopOptions.shopId==="standard" )
                                ? (
                                    <Link component={RouteLink} to={joinPath(shoppingMatch.url, "search")} className={clsx(classes.toolBarFlex, classes.navLink)} >
                                        <SearchIcon />
                                        <Typography variant={"h6"} className={clsx(classes.label)}>{t('shopping:home.search')}</Typography>
                                    </Link>
                                )
                                : ''
                        }

                        <div
                            id="globe"
                            onClick={showLanguageModal}
                            className={classes.toolBarFlex}>
                            <GlobeIcon />
                            <Typography variant={"h6"} className={clsx(classes.label, classes.languageLabel)}>{t(`shopping:home.language.${languageDisplay}`)}</Typography>

                        </div>
                        {
                            (cartGUID) ? (
                                <div className={classes.toolBarFlex} onClick={handleOpenCart}>
                                    <Badge badgeContent={cartItems} color="secondary">
                                        <ShopIcon />
                                    </Badge>
                                    <Typography variant={"h6"} className={clsx(classes.label, classes.cartLink)}>{shopOptions.cartname}</Typography>
                                </div>
                            ) : ('')
                        }
                        {!isGuest && <IconButton color="inherit"  onClick={handleUserMenuOpen}>
                            <AccountCircleRoundedIcon className={classes.smallAvatar} />
                        </IconButton>}
                    </div>
                </Toolbar>
            </AppBar>
            {createAccountModalShow && <CreateShopAccount
               shopOptions={shopOptions}
               open={createAccountModalShow}
               onClose={() => setCreateAccountModalShow(false)} />}




            <ChangeLanguageModal />
        </>
    );
}


export default observer(Navbar);