import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStores } from "../hooks/use-stores";
import EpubView from "../components/epub";
import { observer } from "mobx-react-lite";
import Alert from "@material-ui/lab/Alert";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import greenButtonTheme from "../themes/greenButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        width: "100%",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    buyNativeAlert: {
        background: "black",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    progressContainer: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
    }
}));


export default observer(({ }) => {
    const classes = useStyles();
    const params = useParams();
    const { sku } = params;

    const { authStore, bookStore, commonStore, libraryStore } = useStores();
    const { loginGuid } = authStore;
    const { isNativeApp } = commonStore;
    const { media: book, mediaURL: bookUrl, mediaTitle: bookTitle, error } = bookStore;
    const { detailsLoadingFor } = libraryStore;

    useEffect(() => {
        bookStore.setMedia({ mediaID: sku });
    }, [sku, bookStore]);


    return <div className={classes.root}>
        <CssBaseline />
        {error && <Alert variant={"filled"} severity={"error"}>{error}</Alert>}
        {(book && !bookUrl && isNativeApp)
            && <Alert variant={"filled"} severity={"error"} className={classes.buyNativeAlert}>
                To purchase this eBook please access your account from the website.
                You will then be able to read from the Life app or website
                       </Alert>}
        {(book && !bookUrl && !isNativeApp) && <ThemeProvider theme={greenButtonTheme}>
            <Button component={"a"} href={`https://shop.mainhomepage.com/quickcheckout.aspx?login=${loginGuid}&type=product&id=${book.mediaID}`}
                variant={"contained"}
                color={"secondary"}>Buy Now</Button>
        </ThemeProvider>}
        {!!detailsLoadingFor && !bookUrl &&
            <div className={classes.progressContainer}>
                <CircularProgress color={"primary"} thickness={3} size={90} />
            </div>}
        {(!!bookUrl) && <EpubView url={bookUrl} title={bookTitle} fullScreen />}
    </div>;


});


