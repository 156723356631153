import services from "../services"

export const asyncRules = {
    uniq_username: async (value, attr, key, passes) => {
        const msg = `The username ${value} is already taken.`;
        // show error if the call does not returns entries
        try {
            await services.UserProfile.UsernameService().validateUserName(value)
            passes()
        }
        catch(e){
            passes(false, e.response.data.message || msg )

        }
    },

    uniq_username_enroll: async (value, attr, key, passes) => {
        const msg = `The username ${value} is already taken.`;
        // show error if the call does not returns entries
        try {
            await services.UserProfile.UsernameService().validateUserNameEnroll(value)
            passes()
        }
        catch(e){
            passes(false, e.response.data.message || msg )

        }
    },

    pid_not_taken: async (value, attr, key, passes) => {
        const [type, msgKey] = attr.split(":")
        const msg = `enrollmember:${msgKey}`;
        // show error if the call does not returns entries
        try {
            await services.MemberEnrollment.checkIfPersonalInfoNumberIsTaken({pid:value,type})
            passes()
        }
        catch(e){
            passes(false,  msg )

        }
    },
};