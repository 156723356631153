import React from 'react';
import NumberFormat from "react-number-format";
import { makeStyles } from '@material-ui/core';

export function currencyFormatter(value) {
    if (isNaN(Number(value))) {
      return 0
    }

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    return `${currencyFormatter.format(value / 100)}`;
}

const useStyles = makeStyles((theme) => ({
    textField: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 40,
        letterSpacing: 1,
        [theme.breakpoints.up('sm')]: {
            fontSize: 46,
        },
    },
    wrapper: {
        textAlign: 'center',
    },
    number: {
        fontWeight: 'bold'
    },
    currency: {
        fontSize: 14,
        verticalAlign: 'super',
        color: 'gray',
        marginLeft: 1
    },
    disabled: {
        color: 'gray'
    }
}));

const StyledValueWithCurrency = ({ value, onChange, currency, useDefault, color }) => {
    const classes = useStyles();

    return (
        <NumberFormat
            disabled={true}
            style={{ color: value == 0 ? 'gray' : color }}
            className={classes.textField}
            format={currencyFormatter}
            value={value} // formatted to the 100s place
            onChange={onChange}
            prefix={'$'}
            displayType={'text'}
            renderText={useDefault ? undefined : (value, props) => <><span className={classes.wrapper} {...props}><span className={classes.number}>{value}</span>{currency && <span className={classes.currency}>{currency}</span>}</span> </>}
        />)
}

export default StyledValueWithCurrency;