import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class SupportStore {
    constructor() {
        makeAutoObservable(this)
    }


    supportInfoLoading = null
    supportInfo = null

    error = null

    setError(error, type = "support") {
        error = extractErrorMessage(error)
        this.error = error
     }


    loadSupportInfo = generateLoadEntity( "supportInfo", this, "supportInfoLoading"
        , (section)=>{ return services.Support.list() }, "supportInfo" )




}

export default SupportStore