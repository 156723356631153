import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class ResetPasswordStore {
  validateTokenLoading = null;
  validateTokenInfo = null;

  resetPasswordLoading = null;
  resetPasswordInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "resetPasswordError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  validateToken = generateLoadEntity(
    "validateTokenInfo",
    this,
    "validateTokenLoading",
    (params) => {
      return services.ResetPasswordService.validateToken(params);
    },
    "validateTokenInfo"
  );

  resetPassword = generateLoadEntity(
    "resetPasswordInfo",
    this,
    "resetPasswordLoading",
    (params) => {
      return services.ResetPasswordService.resetPassword(params);
    },
    "resetPasswordInfo"
  );
}

export default ResetPasswordStore;
