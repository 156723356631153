import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {observer} from "mobx-react";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    content: (props) => ({
        flexGrow: 1,
        display: 'flex',
        width: "calc(100% - 200px);",
        [theme.breakpoints.up('md')]: {
            borderLeft: "1px solid #f50057",
            marginLeft: 200,
        },
        marginBottom:  0
    }),

    withMobileAudioPlayer: (props) => ({
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom:  105
        },
    }),


    centralPane: (props) => ({
        display: 'flex',
        flexGrow: 1,
        flexDirection: "column",
        padding: 10,
        paddingBottom:0,
        width: "calc(100% - 200px)",
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingTop:10,
        },
        overflow: "hidden",
    }),
}));


function MainWrapper ({mobileAudioPlayer=false, children, rightPane}){
    const classes = useStyles({ mobileAudioPlayer });
    return  <main className={ clsx (classes.content, {[classes.withMobileAudioPlayer] :mobileAudioPlayer})}>
        <div className={classes.centralPane}>
            {children}
        </div>
        {rightPane}
    </main>
}

export default observer(MainWrapper);