import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import {useShopType} from "../../useShopType";


const useStyles = makeStyles((theme) => ({
    img: {
        height: 300,
        width: "min-content"
    }
}));
const AddedToCartModal = ({ t, product, selectedQty, showProceedToCheckout, productType, onAddToCartClickHandler, onProceedToCheckoutClickHandler, onProceedToShopHomeHandler }) => {
    const shopOptions = useShopType()
    const classes = useStyles();
    if (!showProceedToCheckout) {
        return null;
    }

    if (productType !== 'Physical') {
        return (
            <Dialog open={true} onClose={onAddToCartClickHandler} >
                <DialogTitle disableTypography >
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>{t('shopping:cartmodal.addtocart')}</Typography>
                        <div style={{ cursor: "pointer" }} onClick={() => onAddToCartClickHandler()}>
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent={"flex-start"} direction={"column"} alignContent={"center"}>
                        <Grid container item xs={12} justifyContent={"center"}>
                            <img className={classes.img} src={product.bannerImage||product.imageURL} alt="" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} color={"primary"} onClick={() => onProceedToShopHomeHandler()} className="btn btn-light">
                        {t(shopOptions.name)}
                    </Button>
                    <Button variant={"contained"} color={"primary"} onClick={() => onProceedToCheckoutClickHandler()} className="btn btn-primary" style={{ color: "white" }}>
                        {t('shopping:cartmodal.proceedtocheckout')}
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }
    // non digital items
    return (
        <Dialog open={true} onClose={onAddToCartClickHandler} >
            <DialogTitle disableTypography >
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>{t('shopping:cartmodal.addtocart')}</Typography>
                    <div style={{ cursor: "pointer" }} onClick={() => onAddToCartClickHandler()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent={"flex-start"} direction={"column"} alignContent={"center"}>
                    <Grid container item xs={12} justifyContent={"center"}>
                        <img className={classes.img} src={product.productMedia[0].imageURL || product.productMedia[0].imageUrl} alt="" />
                    </Grid>
                </Grid>
                <div className="info">
                    <h1>{product.productName}</h1>
                    <p>Qty: {selectedQty}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={() => onProceedToShopHomeHandler()} className="btn btn-light">
                    {t(shopOptions.name)}
                </Button>
                <Button variant={"contained"} color={"primary"} onClick={() => onProceedToCheckoutClickHandler()} className="btn btn-primary" style={{ color: "white" }}>
                    {t('shopping:cartmodal.proceedtocheckout')}
                </Button>
            </DialogActions>
        </Dialog>

    );
};



export default observer(AddedToCartModal);