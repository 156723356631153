import RestService from "./RestService";

export default class TestService extends RestService {
  constructor() {
    super("/api/quiz");
  }

  async getTestByIdLabel(testIdLabel, lang) {
    return this.get(`${this.baseUrl}/getQuiz/${testIdLabel}/${lang}`);
  }

  async submitAnswer(testIdLabel, lang, answers) {
    return this.post(
      `${this.baseUrl}/submitAnswer/${testIdLabel}/${lang}`,
      answers
    );
  }
}
