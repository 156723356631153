import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {Box} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({

    travelModalTitleWrapper: {
        backgroundImage: '-webkit-linear-gradient(left, #006e94 15%,#00ade0 100%)',
        display: 'flex',
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    travelModalTitle: {
        display: "flex",
        alignItems: 'center',
        padding: '10px',
    },
    travelModalContent: {
        display: "flex",
        flexDirection: 'column',
        padding: 20,
        backgroundColor: 'white',
        color: 'black'
    },
    travelModalButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    travelButton: {
        backgroundImage: '-webkit-linear-gradient(left, #006e94 15%,#00ade0 100%)',
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: '10px'
    },

}));

function Things2travelDialog({ showNativeAppModal, handleCloseNativeAppModal, currentUser }) {

    const classes = useStyles();
    const {t} = useTranslation();

    const onTravelLinkClicked = (e) => {
        const url = `https://secure.rezserver.com/ivm/inbound/?refid=8827&mk=363497f7ed862911d49ee491479413c635e077836ed9cd6d423824c62441053df717929c41b48fbd&refclickid=${currentUser.userID}`;
        window.open(url, "_blank", "toolbar=no,scrollbars=yes,resizable=yes,top=0,left=0,width=600,height=800");
        handleCloseNativeAppModal();
    };

    const onTravelCloseClicked = (e) => {
            e.preventDefault()
           handleCloseNativeAppModal();
    };

    return <Dialog open={showNativeAppModal}
                   onClose={handleCloseNativeAppModal}
                   className={classes.modalRoot}
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
    >
        <div className={classes.travelModalContainer}>
            <div className={classes.travelModalTitleWrapper}>
                <div className={classes.travelModalTitle}>
                    <img src={`/logo-header.png`} width="150" style={{ marginRight: '10px' }} />
                    <img src={`/ppn-white.png`} width="100" height="auto" />
                </div>
                <Box display="flex" justifyContent="flex-end" >
                    <IconButton edge="start" color="inherit" onClick={onTravelCloseClicked} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </div>
            <div className={classes.travelModalContent}>
                <div>
                    <div><b>{t('global:thingOr2.title')}</b></div>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: t('global:thingOr2.howItWorks') }} />
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: t('global:thingOr2.info') }} />
                </div>
                <div className={classes.travelModalButtons}>
                    <Button className={classes.travelButton} onClick={onTravelLinkClicked}>
                        {t('global:thingOr2.continue')}
                    </Button>
                </div>
            </div>
        </div>
    </Dialog>
}

export default Things2travelDialog;