import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import { generateLoadList} from "../utils/mobx";
import {makeAutoObservable} from "mobx";


class PeriodsStore {
    periodsLoading = null;
    periods = []


    get paidPeriods(){
        return this.periods.filter(p=>p.IsPaidOut).filter( p=>p.PVPeriodID >= 142)
    }

    constructor() {
        makeAutoObservable(this);
    }

    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    loadPeriods = generateLoadList(
        "periods",
        this,
        "periodsLoading",
         (userID) => {
            return services.MyTeams.listPeriods(userID);
        },
        "periods"
    );

}





export default PeriodsStore