import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/styles";
import {useStores} from "../../hooks/use-stores";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import PageTitle from "../../components/PageTitle";
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        padding: theme.spacing(1),
        position: "relative",
        marginBottom: theme.spacing(3),

    },
    spouseEnableSection: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    infoIcon: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    popoverBody: {
        padding: theme.spacing(1)
    },
    textField: {},
    fieldValue: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
}));

export default observer(function UserProfile() {
    const classes = useStyles();
    const {t} = useTranslation();
    const {userProfileStore, commonStore} = useStores();
    const [anchorEl, setAnchorEl] = useState(null);
    const [BlastSubscribedValue, setBlastSubscribedValue] = useState("");
    const [AutoRenewalValue, setAutoRenewalValue] = useState("");
    const [CancelRenewalRemindersValue, setCancelRenewalRemindersValue] = useState("");

    const {
        userProfileLoading, userRegistrationLoading
        , userRegistration, userProfile, error
    } = userProfileStore;
    const open = Boolean(anchorEl);


    useEffect(() => {
        (async () => {
            await userProfileStore.createEditForm(() => {
                commonStore.success("User Profile updated");
            });
            await Promise.all([
                await userProfileStore.loadUserProfile()
                , await userProfileStore.loadUserRegistration()]);

            await userProfileStore.loadRegionsByCountry(userProfileStore.userProfile.address.country);

        })();

    }, []);

    useEffect(() => {
        if (userRegistration) {
            setBlastSubscribedValue(userRegistration.BlastSubscribed)
            setAutoRenewalValue( JSON.stringify( userRegistration.LIFEAutoRenew ) )
            setCancelRenewalRemindersValue(userRegistration.CancelRenewalReminders)
        }

    }, [userRegistration]);



    const handleUpdateUserInfo =  async (e) => {
        if (userRegistration.LIFEAutoRenew !== AutoRenewalValue ){
            await userProfileStore.updateAutoRenewal( AutoRenewalValue )
        }
        const propsToUpdate = []
        if (userRegistration.BlastSubscribed !== BlastSubscribedValue ){
            propsToUpdate.push({ key: "BlastSubscribed", "value": BlastSubscribedValue})
        }

        if (userRegistration.CancelRenewalReminders !== CancelRenewalRemindersValue ){
            propsToUpdate.push({ key: "CancelRenewalReminders", "value": CancelRenewalRemindersValue })
        }


        if ( propsToUpdate.length>0 ) {
            await userProfileStore.updateProps(propsToUpdate)
        }
    };


    const handleBlastSubscribedChange = (e) => {
        setBlastSubscribedValue(e.target.value)
    };
    const handleAutoRenewalChange = (e) => {
        setAutoRenewalValue(e.target.value)
    };
    const handleCancelRenewalRemindersChange = (e) => {
        setCancelRenewalRemindersValue(e.target.value)
    };

    return <>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {(userProfileLoading || userRegistrationLoading)
            ? <CircularProgress/>
            : <>
                <PageTitle>{t("registrationInformation")}</PageTitle>
                {userProfile && !userProfile.isMember
                    ? <>
                        <Typography variant={"subtitle1"}>{t("memberInformation")}</Typography>
                        <Paper className={classes.section}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" >{t("memberName")}</FormLabel>
                                        {userRegistration && userRegistration.enroller && <>
                                            <Typography className={classes.fieldValue}
                                                        variant={"body1"}>
                                                {`${userRegistration.enroller.lastName}, ${userRegistration.enroller.firstName}(${userRegistration.enroller.userID})`}
                                            </Typography>
                                            <Link
                                                href={`mailto:${userRegistration.enroller.email}`}>{userRegistration.enroller.email}</Link>
                                        </>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
                    : <>
                        <Typography variant={"subtitle1"}>{t("uplineInformation")}</Typography>
                        <Paper className={classes.section}>
                            <Grid container spacing={3}>
                               <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" >{t("enrollerName")}</FormLabel>
                                        {userRegistration && userRegistration.enroller && <>
                                            <Typography className={classes.fieldValue}
                                                    variant={"body1"}>
                                            {`${userRegistration.enroller.lastName}, ${userRegistration.enroller.firstName}(${userRegistration.enroller.userID})`}
                                        </Typography>
                                        <Link
                                            href={`mailto:${userRegistration.enroller.email}`}>{userRegistration.enroller.email}</Link>
                                        </>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" >{t("leaderName")}</FormLabel>
                                        {userRegistration && userRegistration.leader && <>
                                            <Typography className={classes.fieldValue}
                                                    variant={"body1"}>
                                            {`${userRegistration.leader.lastName}, ${userRegistration.leader.firstName}(${userRegistration.leader.userID})`}
                                        </Typography>
                                        <Link
                                            href={`mailto:${userRegistration.leader.email}`}>{userRegistration.leader.email}</Link>
                                        </>}
                                    </FormControl>
                                </Grid>

                                 <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" >{t("connectorName")}</FormLabel>
                                        {userRegistration && userRegistration.connector && <>
                                        <Typography className={classes.fieldValue}
                                                    variant={"body1"}>
                                            {`${userRegistration.connector.lastName}, ${userRegistration.connector.firstName}(${userRegistration.connector.userID})`}
                                        </Typography>
                                        <Link
                                            href={`mailto:${userRegistration.connector.email}`}>{userRegistration.connector.email}</Link>
                                        </>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>

                    </>}




                <Typography variant="subtitle1">{t("registrationInformation")}</Typography>
                <Paper className={classes.section}>
                    {(userProfile && userRegistration) && <Grid container spacing={3}>
                        {userProfile.isMember && <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{ userRegistration.HasAgreedtoTOS
                                    ? t("AcceptedTOSOn")
                                    : t("DeclinedTOSOn")
                                }</FormLabel>
                                <Typography className={classes.fieldValue}
                                    variant={"body1"}>{
                                    userRegistration.HasAgreedtoTOS
                                       ? moment(userRegistration.AreedTOSDate).format("LLL")
                                       : moment(userRegistration.DeclinedTOSDate).format("LLL")
                                    }</Typography>
                            </FormControl>
                        </Grid>}
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("registrationDate")}</FormLabel>
                                <Typography className={classes.fieldValue}
                                    variant={"body1"}>{moment(userRegistration.DateAdded).format("LLL")}</Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("renewalDate")}</FormLabel>
                                <Typography className={classes.fieldValue}
                                    variant={"body1"}>{moment(userRegistration.RenewDate).format("L")}</Typography>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("blastSubscribed")} </FormLabel>
                                <RadioGroup row aria-label="position" name="position"
                                            value={BlastSubscribedValue}
                                            onChange={handleBlastSubscribedChange}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="primary"/>}
                                        label={t("yes")}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary"/>}
                                        label={t("no")}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {userProfile.isMember && <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("autoRenewal")} </FormLabel>
                                <RadioGroup row aria-label="position" name="position"
                                            value={AutoRenewalValue}
                                            onChange={handleAutoRenewalChange}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="primary"/>}
                                        label={t("yes")}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary"/>}
                                        label={t("no")}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>}
                        {userProfile.isMember && <Grid item xs={12} md={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t("cancelRenewalReminders")} </FormLabel>
                                <RadioGroup row aria-label="position" name="position"
                                            value={CancelRenewalRemindersValue}
                                            onChange={handleCancelRenewalRemindersChange}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio color="primary"/>}
                                        label={t("yes")}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary"/>}
                                        label={t("no")}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>}

                    </Grid>}
                </Paper>
                <Button fullWidth variant="contained"
                        onClick={handleUpdateUserInfo}
                        color="primary" className={classes.textField}>{t("updateRegistrationInformation")}</Button>
            </>
        }
    </>;
});