import Grid from "@material-ui/core/Grid";
import MaterialSelect from "./MaterialSelect";
import React from "react";
import {observer} from "mobx-react-lite";


function  CardExpMonth({form, fieldName="cardExpMonth"}) {

    return <Grid item xs={4} lg={4}>
        <MaterialSelect
            fullWidth
            margin="normal"
            field={form.$(fieldName)}
            InputLabelProps={{
                shrink: true,
            }}
            labelAccessor={(x => x.toString().padStart(2, "0"))}
            valueAccessor={(x => x)}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
        />
    </Grid>
}


export default observer(CardExpMonth)