import RestService from "./RestService";

export default class CriticalMessageService extends RestService {
  constructor() {
    super("/api/criticalmessage");
  }

  async getMessage(params) {
    return this.get(`${this.baseUrl}`, params);
  }
}
