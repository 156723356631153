import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { useStores } from '../../hooks/use-stores'
import { useTranslation } from 'react-i18next'
import { starFishColor } from '../../components/styles'
import { ContentPageStyles } from './styles'
import { observer } from 'mobx-react'
import 'quill/dist/quill.core.css'

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  pointsList: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
  },
  subsection: {
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  pointText: {
    paddingLeft: 56,
    paddingRight: 96,
    paddingTop: 14,
    paddingBottom: 14,
    width: '100%',
  },
  subPoints: {
    marginTop: 14,
    borderTop: '1px solid rgba(128, 128, 128, 0.5)',
    padding: '0 8px',
  },
  subPointsUL: {
    listStyleType: 'square',
    margin: 0,
    marginTop: 14,
    padding: 0,
    display: 'none',
  },
  expanded: {
    display: 'block',
  },
}))

function TopGun() {
  const classes = useStyles()
  const { commonStore, topGunStore, videoStore } = useStores()
  const { t } = useTranslation('starfish')

  const { topGunInfoLoading, topGunInfo } = topGunStore

  useEffect(() => {
    void topGunStore.loadInfo(commonStore.language)
  }, [])

  const showVideo = () => {
    videoStore.setMedia({
      url: 'https://vimeo.com/780782440/ef1ee5f971',
      title: 'The Top Gun Contest',
      vimeo: true,
    })
  }

  return topGunInfoLoading === null || topGunInfoLoading ? (
    <CircularProgress />
  ) : (
    <div>
      <section className={clsx(classes.section)}>
        <img
          src={ commonStore.language === 'fr'
                 ? `/img/topgun_bg.jpg`
                 : `/img/TGP_top.jpg`
        }
          className={classes.middleImage}
          role='img'
        />

        { commonStore.language === 'fr' && <img
          src={`/img/topgun_fr.png`}
          className={classes.overlayImage}
          role='img'
        />}
      </section>
      <section className={clsx(classes.section)}> </section>
      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Typography align='center' className={classes.headText} variant='h3'>
            {t('topgun.who_will')}
            <span className={classes.linkColor}>{t('topgun.win')}</span>
          </Typography>
          <Typography
            align='center'
            className={classes.mainText}
            variant='body1'
          >
            {t('topgun.thousands_will_compete')}
          </Typography>
        </div>
      </section>

      <section className={clsx(classes.section)}>
        <Button
          color='primary'
          variant='contained'
          onClick={showVideo}
          atarget='_blank'
          ahref={'https://vimeo.com/780782440/ef1ee5f971'}
        >
          {t('topgun.watch_video')}
        </Button>
      </section>
      <section className={clsx(classes.section)}>
        <img
          src={`/img/TGP_middle.jpg`}
          className={classes.middleImage}
          role='img'
        />
      </section>
      <section className={clsx(classes.section, classes.inverse)}>
        <div className={clsx(classes.textContainer)}>
          <section className={clsx(classes.subsection)}>
            <Typography
              align='center'
              className={classes.headText}
              variant='h3'
            >
              {t('topgun.why_top_gun')}
            </Typography>
          </section>
          <section className={clsx(classes.subsection)}>
            <Typography
              align='center'
              className={classes.mainText}
              variant='body1'
            >
              {t('topgun.topgun_goals')}
            </Typography>
          </section>
          <section className={clsx(classes.subsection)}>
            <Typography
              align='center'
              className={classes.mainText}
              variant='body1'
            >
              {t('topgun.find_topgun_rules')}
            </Typography>
          </section>
        </div>
      </section>
      <section className={clsx(classes.section)}> </section>
      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Typography align='center' className={classes.headText} variant='h3'>
            <span className={classes.linkColor}>{t('topgun.top_gun')}</span>
            {t('topgun.contest_rules')}
          </Typography>
        </div>
      </section>

      <section className={clsx(classes.section, classes.pointsList)}>
        <Typography
          className={clsx(classes.pointText, classes.condensedText)}
          variant='body1'
        >
          <div
            className='ql-editor'
            dangerouslySetInnerHTML={{
              __html: topGunInfo.contestRules?.html,
            }}
          ></div>
        </Typography>
      </section>

      <section className={clsx(classes.section)}>
        <img
          src={`/img/TGP_bottom.jpg`}
          className={classes.middleImage}
          role='img'
        />
      </section>

      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Typography align='center' className={classes.headText} variant='h3'>
            {commonStore.language === 'en' ? (
              <>
                <span className={classes.linkColor}>
                  {topGunInfo.contestRules.conventionMonth}
                </span>{' '}
                {topGunInfo.contestRules.conventionName}
                <span className={classes.linkColor}>
                  {t('topgun.winner_title')}
                </span>
              </>
            ) : (
              <>
                <span className={classes.linkColor}>
                  {t('topgun.winner_title')}
                </span>{' '}
                {topGunInfo.contestRules.conventionName}{' '}
                <span className={classes.linkColor}>
                  {' '}
                  {topGunInfo.contestRules.conventionMonth}
                </span>
              </>
            )}
          </Typography>
        </div>
      </section>

      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Grid
            container
            spacing={3}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {topGunInfo?.labelItems?.map((item, index) => (
              <Grid item sm={3} xs={12} key={index}>
                <div style={{ padding: 12, textAlign: 'center' }}>
                  <strong>{item.label}</strong>
                </div>
                <div style={{ padding: 12, textAlign: 'center' }}>
                  {item.value}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
      <section className={clsx(classes.section)}> </section>
    </div>
  )
}

export default observer(TopGun)
