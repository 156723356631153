import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, } from "@fortawesome/pro-light-svg-icons";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useStores } from "../../hooks/use-stores";
import ErrorMessageModal from "../../components/shop/ErrorMessageModal";
import MerchantInfo from "./components/MerchantInfo";
import { currencyFormatter } from "./components/StyledValueWithCurrency";
import GiftCardImage from "./components/GiftCardPreview";
import {copyToClipBoard} from "../../components/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
  },
  congrats: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  giftcardButton: {
    backgroundColor: "#007BFF",
    color: "white",
    justifyContent: "center",
    borderRadius: '22px',
    "&:hover": {
      color: "#007BFF",
      backgroundColor: "white",
    },
  },
  ...copyToClipBoard(theme),
  navigateButtons: {
    color: "#007BFF",
    backgroundColor: "white",
    border: "1px solid gray",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "&:hover": {
      color: "white",
      backgroundColor: "#007BFF",
    },
  },
}));

const Complete = ({ shoppingBossMatch, fromModule = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { shoppingBossStore, commonStore } = useStores();
  const { merchantInfo, eCodeInfo } = shoppingBossStore;

  const history = useHistory();
  const { error, giftCards } = shoppingBossStore;
  const { isNativeApp } = commonStore;

  const returnToShoppingBossHome = () => history.push(shoppingBossMatch.url);
  const returnToHomePage = () => history.push("/");

  const getRedirectURL = (giftcardURL) => {
    if (!!isNativeApp) {
      return window.location.href = `inappbrowser://${giftcardURL}`;
    } else {
      return window.open(giftcardURL, '_blank');
    }
  };

  // useEffect(() => {
  //   if (giftCards && !!isNativeApp) {
  //     giftCards.map((giftCard) => {
  //       return window.location.href = `inappbrowser://${giftCard.giftcardURL}`;
  //     });
  //   }
  // }, []);

  return (
    <Container maxWidth="sm" className={classes.root}>
      {/* {error || !giftCards.length ? (
        <ErrorMessageModal
          t={t}
          message={t("shoppingBoss:error.noCards")}
          onClickHandler={returnToShoppingBossHome}
        />
      ) : ( */}
      <>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: "#007BFF", fontSize: 70 }}
            />
          </Box>
          <Box container display="flex" flexDirection="column">
            <Box>
              <Typography className={classes.congrats} variant="h6">
                {t("shoppingBoss:complete.congrats")}
              </Typography>
            </Box>
            <List>
              {giftCards.map((giftCard) => (
                <>
                  <Box style={{ padding: !giftCard.barcode ? '24px' : '0px', marginTop: '8px', border: !giftCard.barcode ? '1px solid #888888' : 0, borderRadius: "20px" }}>
                    {/* If there's no barcode or pin, this is not a newer style card, so show the merchant info */}
                    {!giftCard?.barcode && !giftCard?.pin &&
                      <MerchantInfo
                        logo={merchantInfo.logo}
                        name={merchantInfo.name}
                        hideCbPercent={true}
                        /* Making this area of the component generic */
                        rightSide={
                          () => <Box style={{ width: '100%', textAlign: 'right' }}>
                            <Typography variant="h4">{currencyFormatter(giftCard.denomination * 100)}</Typography>
                          </Box>
                        }
                      />
                    }
                    {/* If there is a gift card code, show a formatted Gift Card Image (optional pin) */}
                    {giftCard.barcode && <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      {
                        giftCard.barcode && <GiftCardImage
                          backgroundColor={'white'}
                          fontColor={'black'}
                          merchantName={giftCard.merchant}
                          logo={giftCard?.logo || ''}
                          amount={currencyFormatter(giftCard.denomination * 100)}
                          useGiftCardNumber
                          giftCardNumber={giftCard.barcode}
                          currency={giftCard.currency}
                          pin={giftCard.pin}
                        />
                      }
                    </Box>
                    }
                    {/* There are cases where there is no barcode and there IS a pin, so use the PIN as the barcode  */}
                    {!giftCard.barcode && giftCard.pin && <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      {
                        <GiftCardImage
                          backgroundColor={'white'}
                          fontColor={'black'}
                          merchantName={giftCard.merchant}
                          logo={giftCard?.logo || ''}
                          amount={currencyFormatter(giftCard.denomination * 100)}
                          useGiftCardNumber
                          giftCardNumber={giftCard.pin} // use the pin as the cardNumber (and leave the real pin 'undefined' so it doesn't show)
                          currency={giftCard.currency}
                        />
                      }
                    </Box>
                    }
                    {/* If there is a security code, show it */}
                    {!!giftCard.securityCode && <>
                      <Typography variant="caption" style={{ color: '#aaaaaa' }} >{t('shoppingBoss:complete.securityCode')}</Typography>
                      <Box display="flex" flexDirection="row" justifyContent='center' alignItems={'center'} style={{ width: '100%', marginBottom: '10px' }}>
                        <Box display="flex" justifyContent="center" style={{ backgroundColor: 'white', color: 'black', borderRadius: '22px', padding: '12px', width: '100%' }}>
                          <Typography variant="h4" style={{ fontSize: '1.25rem' }}>{giftCard.securityCode}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" style={{ padding: '4px', height: '100%' }}>
                          <CopyToClipboard text={giftCard.securityCode}
                            onCopy={() => commonStore.success("Link copied")}>
                            <Button fullWidth color="primary" variant="contained" className={classes.copyToClipBoard} disabled={!giftCard.securityCode}>
                              {/* <FontAwesomeIcon className={classes.faIcons} icon={faCopy} size="1x" /> */}
                              {t("shoppingBoss:complete.copyCode")}
                            </Button>
                          </CopyToClipboard>
                        </Box>
                      </Box>
                    </>
                    }
                    {giftCard.giftcardURL &&
                      <ListItem
                        key={giftCard.orderNumberDetail + giftCard.denomination}
                        component={Button}
                        size="large"
                        className={classes.giftcardButton}
                        onClick={() => getRedirectURL(giftCard.giftcardURL)}
                      >
                        {t("shoppingBoss:complete.view")}
                      </ListItem>
                    }
                  </Box>
                  {!!giftCard.securityCode && <>
                    <p style={{ color: "goldenrod" }}> {t("shoppingBoss:complete.securityCodeDetails")} </p>
                  </>
                  }

                  {/* Show the gift card notice on starfishy */}
                  {fromModule && fromModule === 'STARFISH' &&
                    <Box style={{ textAlign: 'center', marginTop: '8px' }}>
                      <Typography variant="body2" style={{ fontSize: '12px' }}>
                        {t("shoppingBoss:complete.giftCardNotice")}
                      </Typography>
                    </Box>
                  }
                  {!!giftCard?.redemptionInstructionsHtml && <Box style={{ marginTop: '20px' }}>
                    <p style={{ textDecoration: 'underline' }}>  {t("shoppingBoss:complete.redeemptionInstructions")}</p>
                    <p dangerouslySetInnerHTML={{ __html: giftCard?.redemptionInstructionsHtml }}></p>
                  </Box>
                  }
                  {/* Check here for the disclaimer and show if it exists */}
                  {!!giftCard?.disclaimerHtml && <Box style={{ marginTop: '20px' }}>
                    <p style={{ textDecoration: 'underline' }}>  {t("shoppingBoss:complete.termsAndConditions")}</p>
                    <p dangerouslySetInnerHTML={{ __html: giftCard?.disclaimerHtml }}></p>
                  </Box>
                  }
                </>
              ))}
            </List>
          </Box>
        </Box>
        <Box
          width="100%"
          container
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            textAlign="center"
            width="100%"
            container
            display="flex"
            flexDirection="column"
          >
            {/* Hiding the purchase and nav buttons on starfish for now */}
            {!(fromModule && fromModule === 'STARFISH') &&
              <>
                <Button
                  className={classes.navigateButtons}
                  onClick={returnToShoppingBossHome}
                >
                  {t("shoppingBoss:complete.purchaseAnother")}
                </Button>
                <Typography>{t("shoppingBoss:complete.or")}</Typography>
                <Button
                  className={classes.navigateButtons}
                  onClick={returnToHomePage}
                >
                  {t("shoppingBoss:complete.returnHome")}
                </Button>
              </>
            }
          </Box>
        </Box>
      </>
      {/* )} */}
    </Container>
  );
};

export default observer(Complete);
