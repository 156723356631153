import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { useStores } from '../../hooks/use-stores'
import { useTranslation } from 'react-i18next'
import { starFishColor } from '../../components/styles'
import { ContentPageStyles } from './styles'
import { observer } from 'mobx-react'
import 'quill/dist/quill.core.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ...ContentPageStyles(theme),
  pointsList: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
  },
  subsection: {
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  pointText: {
    paddingLeft: 56,
    paddingRight: 96,
    paddingTop: 14,
    paddingBottom: 14,
    width: '100%',
  },
  subPoints: {
    marginTop: 14,
    borderTop: '1px solid rgba(128, 128, 128, 0.5)',
    padding: '0 8px',
  },
  subPointsUL: {
    listStyleType: 'square',
    margin: 0,
    marginTop: 14,
    padding: 0,
    display: 'none',
  },
  expanded: {
    display: 'block',
  },
  usersContainer:{
        width:"100%",
  },
  usersItem:{
    flexWrap:"nowrap",
    [theme.breakpoints.down("xs")]: {
      marginLeft:10,
    },

    "& h4":{
        [theme.breakpoints.down("xs")]: {
            fontSize: 24,
        }
    }
  },
  userAvatar:{
    width: 62,
    height: 62,
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      width: 48,
      height: 48,
    },
  }
}))

function UsersList({users}){
  const classes = useStyles()

  return      <Grid container spacing={2} justifyContent={"center"} className={classes.usersContainer}>
    {users.map((item, index) => {
      const nameParts = item.name.split(" ");
      const lastName = nameParts.pop();
      return <Grid item xs={12} sm={6} key={index} container alignItems={"center"} className={classes.usersItem}>
        <Avatar src={item.photourl} variant={"rounded"} className={classes.userAvatar}/>
        <Typography align='center' className={classes.headText} variant='h4' noWrap>
          <span className={classes.linkColor}>{index + 1}. {nameParts.join(" ")}</span> {lastName}
        </Typography>
      </Grid>

    })}

  </Grid>
}

function TopTen() {
  const classes = useStyles()
  const { commonStore, topTenStore } = useStores()
  const { t } = useTranslation('starfish')
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("xs"));

  const { topTenInfoLoading, topTenInfo } = topTenStore

  useEffect(() => {
    void topTenStore.loadInfo()
  }, [])


  return topTenInfoLoading === null || topTenInfoLoading ? (
    <CircularProgress />
  ) : (
    <div>
      <section className={clsx(classes.sectionDense)} style={{overflow:"hidden"}}>
        <img
            src={
              commonStore.language === 'fr'
                  ? `/img/top10-fr.jpg`
                  : `/img/top10-en.jpg`
            }
            style={{marginRight:-1}}
          className={classes.middleImage}
          role='img'
        />
          <Typography align='center' className={classes.headText} variant='h2'>
            <span className={classes.linkColor}>{topTenInfo.monthlyLabel}</span>
          </Typography>
      </section>
      <section className={clsx(classes.section)}> </section>
      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Typography align='center' className={classes.headText} variant='h3'>
            <span className={classes.linkColor}>{t('topten.conquer')}</span>
          </Typography>
          <Typography
            align='center'
            className={classes.mainText}
            variant='body1'
          >
            {t('topten.conquer-description')}
          </Typography>
          <Divider className={classes.blueDivider} />
        </div>
      </section>
      <section className={clsx(classes.section)}>
          <UsersList users={topTenInfo.conquer}/>

      </section>
      <Divider className={classes.blueDivider} />
      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Typography align='center' className={classes.headText} variant='h3'>
            <span className={classes.linkColor}>{t('topten.fortify')}</span>
          </Typography>
          <Typography
            align='center'
            className={classes.mainText}
            variant='body1'
          >
            {t('topten.fortify-description')}
          </Typography>
          <Divider className={classes.blueDivider} />
        </div>
      </section>
      <section className={clsx(classes.section)}>
        <UsersList users={topTenInfo.fortify}/>
      </section>
      <Divider className={classes.blueDivider} />
      <section className={clsx(classes.section)}>
        <div className={clsx(classes.textContainer)}>
          <Typography align='center' className={classes.headText} variant='h3'>
            <span className={classes.linkColor}>{t('topten.domesticate')}</span>
          </Typography>
          <Typography
              align='center'
              className={classes.mainText}
              variant='body1'
          >
            {t('topten.domesticate-description')}
          </Typography>
          <Divider className={classes.blueDivider} />
        </div>
      </section>
      <section className={clsx(classes.section)}>
        <UsersList users={topTenInfo.domesticate}/>
      </section>

      <section className={clsx(classes.section)}> </section>
    </div>
  )
}

export default observer(TopTen)
