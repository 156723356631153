import RestService from "./RestService";

export default class ProductService extends RestService {
  constructor() {
    super("/api/product");
  }

  search(params) {
    return this.get(`${this.baseUrl}/search`, params);
  }
}
