import RestService from "./RestService";
import stores from '../stores'


export default class Branding extends RestService {
    constructor() {
            super("/api/spark")
    }

    async oauthToken(){
        return this.post( `${this.baseUrl}/oauth/token`)
    }

}
