import React from 'react';
import {Avatar, Box, Button, Dialog, DialogActions, DialogContent, Tab, Tabs, Typography} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {starFishColor} from "../../../components/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    legendCell:{
        ...starFishColor(theme, "borderColor"),
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        padding:4
    },

    avatar:{
        paddingTop:3,
        backgroundColor:"white",
        "& img": {
            objectFit: "contain"
        }
    },
    opaque:{
        "& img": {
            opacity: 0.3
        }
    },

    avatarDescription:{
        display:"inline-flex",
        alignItems:"center"
    },

    slider:{
        "& .slick-list": {
            margin: "0 40px",
            [theme.breakpoints.down('xs')]: {
                margin: "0 30px",
            }
        }
    }


}));

function BonusChart({showingLegend, hideLegend, showSymbols=false }) {
    const classes = useStyles();
    const {t} = useTranslation("teams");

    const [legendTabValue, setLegendTabValue] = React.useState(0);
    const handlesLegendTabValueChange = (event, newValue) => {
        setLegendTabValue(newValue);
    };

    const handleClose = () =>{
        setLegendTabValue(0)
        hideLegend()
    }

    return <Dialog open={showingLegend} maxWidth={"sm"} fullWidth onClose={handleClose}>
        <DialogContent>

            <Tabs value={legendTabValue} onChange={handlesLegendTabValueChange} indicatorColor="primary">
                <Tab label={t("bonusChart")}/>
                {showSymbols && <Tab label={t("SymbolKey")}/>}
            </Tabs>
            <br/>
            {legendTabValue === 0 && <Box flex={1} display={"grid"} gridTemplateColumns={"1fr 1fr 4fr"}>

                <Typography align={"right"} className={classes.legendCell}>8,000</Typography>
                <Typography align={"right"} className={classes.legendCell}>27%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>6,000</Typography>
                <Typography align={"right"} className={classes.legendCell}>24%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>4,000</Typography>
                <Typography align={"right"} className={classes.legendCell}>21%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>2,500</Typography>
                <Typography align={"right"} className={classes.legendCell}>18%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>1,500</Typography>
                <Typography align={"right"} className={classes.legendCell}>15%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>1,000</Typography>
                <Typography align={"right"} className={classes.legendCell}>12%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>600</Typography>
                <Typography align={"right"} className={classes.legendCell}>9%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>300</Typography>
                <Typography align={"right"} className={classes.legendCell}>6%</Typography>
                <Typography align={"right"}></Typography>
                <Typography align={"right"} className={classes.legendCell}>150</Typography>
                <Typography align={"right"} className={classes.legendCell}>3%</Typography>
                <Typography align={"right"}></Typography>

            </Box>}
            {showSymbols && legendTabValue === 1 && <Box display={"grid"} gridTemplateColumns={"1fr 8fr"} gridRowGap={5}>
                <Avatar variant={"rounded"} className={classes.avatar} src={`/images/SymbolGreen.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolCRMet")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolRed.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolCRNotMet")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolOrange.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolPastRenew")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolBlue.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolNotEnrolled")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolPurple.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolM2CStart")}</Typography>
                <Avatar variant={"rounded"} className={clsx( classes.avatar, classes.opaque)} src={`/images/SymbolPurple.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolM2C")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolDot.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolLeaders")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolSign.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolPV")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolHat.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolSOT")}</Typography>
                <Avatar variant={"rounded"} className={classes.avatar}  src={`/images/SymbolGlasses.jpg`}> </Avatar>
                <Typography className={classes.avatarDescription}>{t("SymbolMS")}</Typography>

            </Box>
            }

        </DialogContent>

        <DialogActions>
            <Button onClick={hideLegend} color="secondary">Close</Button>
        </DialogActions>
    </Dialog>
}


export default BonusChart;