import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';

export const ORRIN_ID = 61241449;
export const CHRIS_ID = 61487006;

/* Chris and Orrin can create long audios and videos */
const MAX_DURATION_WHITELIST = [ORRIN_ID, CHRIS_ID];
const MAX_AUDIO_DURATION = 60 * 6; // 6 min. for audio
const MAX_VIDEO_DURATION = 60 * 7; // 7 min. for video
const DEFAULT_AUDIO_DURATION = 60;
const DEFAULT_VIDEO_DURATION = 120;

class LifelineStore {
    constructor() {
        makeAutoObservable(this);
    }

    baseline = null;
    loadingBaseline = false;

    settings = null;
    audioFile = null;
    videoFile = null;

    uploading = false;
    respondingToMessageID = 0;
    responseType = null;
    replyUserID = null;
    replyLastName = null; // used for reply to group
    replyToName = null; // used for reply (single)
    forSpouse = false; // hardcoded to false for now  - will be updated in future

    reset = () => {
        this.audioFile = null;
        this.videoFile = null;

        this.respondingToMessageID = 0;
        this.responseType = null;
        this.replyUserID = null;
        this.replyLastName = null; // used for reply to group
        this.replyToName = null; // used for reply (single)
    }

    async fetchBaseline() {
        try {
            runInAction(() => {
                this.loadingBaseline = true;
            });

            const result = await services.LifelineService.getBaseline();
            runInAction(() => {
                this.loadingBaseline = false;
                this.baseline = result;
            });
        } catch (e) {
            runInAction(() => {
                this.loadingBaseline = false;
            });
        }
    }

    isSpecialUser = (userId) => {
        return MAX_DURATION_WHITELIST.includes(userId);
    }

    audioDuration = (userId) => {
        if (this.isSpecialUser(userId)) {
            return MAX_AUDIO_DURATION;
        }
        return DEFAULT_AUDIO_DURATION;
    }

    videoDuration = (userId) => {
        if (this.isSpecialUser(userId)) {
            return MAX_VIDEO_DURATION;
        }
        return DEFAULT_VIDEO_DURATION;
    }
}
export default LifelineStore;
