import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class SpeakerModal extends Component {
  static audioTrack;

  constructor(props) {
    super(props);
    this.playIndicatorRef = React.createRef();
    this.state = {
      audioIsPlaying: false,
    }
  }

  removeClassList = (e) => {
    const els = document.querySelectorAll('.modal-audio-list__audio-control')
    els.forEach( (item) => {
      item.children[4].classList.add('hide-item')

      if (item.children[1].classList.contains('fa-pause-circle')) {
        item.children[1].classList.remove('fa-pause-circle')
        item.children[1].classList.add('fa-play-circle')
      }
    })

    this.playIndicatorRef.current.classList.remove('hide-item')
    e.target.classList.remove('fa-play-circle')
    e.target.classList.add('fa-pause-circle')
  }

  playTrack = (track, idx, e) => {
    if (this.state.audioIsPlaying && this.props.idx === this.props.currentIdx) {
      this.props.pauseTrack(idx)
      e.target.classList.remove('fa-pause-circle')
      e.target.classList.add('fa-play-circle')
      this.playIndicatorRef.current.classList.add('hide-item')
      this.setState({ audioIsPlaying: false })
    } else {
      this.props.playTrackAudio(track, idx)
      this.setState({ audioIsPlaying: true })
    }
  }

  onMediaClick = (productSKU, parentID, productID, audioItem) => {
    this.props.history.push(`/category/${parentID}/product-detail/${productSKU}/${productID}`)
    this.props.onAdditionalProductHandler(audioItem)
  }

  render() {
    const { t } = this.props;
    const { audioItem } = this.props;
    return (
      <>
        {
          this.props.audioItem.productDetails.map((mediaItem) => {
            return (
              mediaItem.mediaURL.length < 1 ? (
                <div className="modal-speaker__content modal-audio-list__audio-control">
                  <img className="speaker-img" src={mediaItem.imageURL} alt="" />
                  <p>{mediaItem.productName}</p>
                </div>
              ) : (
                  <div className="modal-speaker__content modal-audio-list__audio-control">
                      <p className="modal-speaker__subtype">{t(`audiomodal.${mediaItem.productSubType}`)}</p>
                      <i
                        onClick={ (e) => {
                          this.removeClassList(e)
                          this.playTrack(mediaItem.mediaURL, this.props.idx, e)
                        }}
                        className="modal-speaker__play-icon fal fa-play-circle">
                      </i>
                    <img data-dismiss="modal" onClick={
                      () => this.onMediaClick(audioItem.productSKU, audioItem.parentCategoryID, audioItem.productCategoryID, audioItem)} 
                      className="speaker-img" src={mediaItem.imageURL} alt=""
                    />
                    <p className="audio-title" data-dismiss="modal" onClick={
                      () => this.onMediaClick(audioItem.productSKU, audioItem.parentCategoryID, audioItem.productCategoryID, audioItem)} 
                    >{mediaItem.productName}</p>
                    <img
                      alt="play indicator"
                      ref={this.playIndicatorRef}
                      className="modal-audio-list__audio-indicator hide-item"
                      src="https://m.media-amazon.com/images/G/01/digital/music/player/web/dragonfly/eqSmBlueLoop.gif">
                    </img>
                  </div>
                )
            )
          })
        }
      </>
    )
  }
}

export default withTranslation()(withRouter(SpeakerModal));