import React, {useEffect} from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { joinPath } from "../../../utils/helpers";
import AddEWallet from "./AddEWallet";
import AddEWalletImpl from "./AddEWalletImpl";
import VerifyIdentity from "./VerifyIdentity";
import LearnAboutACHByCountry from "./ETransferLearnMore";
import services from "../../../services";
import {CircularProgress, Grid} from "@material-ui/core";



export default observer(function EWallet({walletMatch, showProgress=true}) {
    const eWalletMatch = useRouteMatch();
    if ( !walletMatch ) {
        walletMatch = eWalletMatch
    }
    const [hasAcceptedSparkTerms, setHasAcceptedSparkTerms] = React.useState(false);
    const [achDisabled, setAchDisabled] = React.useState(false);
    const [cardStatus, setCardStatus] = React.useState({allowCCFunding:false});
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        (async ()=>{
            setLoading(true)
            try {
                await Promise.all([getSparkWalletTermsStatus(), getUserACHDisabledStatus(), getUserCardDisabledStatus()])
            }finally {
                setLoading(false)
            }
        })()

    }, []);


    const getSparkWalletTermsStatus = async () => {
        try {
            const { hasAcceptedSparkTerms = true } = await services.UserProfile.getSparkWalletTermsStatus();
            setHasAcceptedSparkTerms(hasAcceptedSparkTerms);
        } catch (e) {
            setHasAcceptedSparkTerms(true);
        }
    };

    const getUserACHDisabledStatus = async () => {
        try {
            const { achDisabled = false } = await services.UserProfile.getUserACHDisabledStatus();
            setAchDisabled(achDisabled);
        } catch (e) {
            setAchDisabled(false);
        }
    };
    const getUserCardDisabledStatus = async () => {
        try {
            const status = await services.UserProfile.getUserCardDisabledStatus();
            setCardStatus(status);
        } catch (e) {
            setCardStatus({allowCCFunding: false});
        }
    };

    if ( loading ) return <Grid container >
        {showProgress && <Grid item xs={12} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
        </Grid>}
    </Grid>

    return <Switch>
        <Route path={joinPath(eWalletMatch.url, `add_more_money/verify_identity`)} render={props => {
            return <VerifyIdentity parentMatch={eWalletMatch} />;
        }} />
        <Route path={joinPath(eWalletMatch.url, `add_more_money/card`)} render={props => {
            return <AddEWalletImpl parentMatch={eWalletMatch} walletMatch={walletMatch} cardStatus={cardStatus} />;
        }} />
        <Route path={joinPath(eWalletMatch.url, `add_more_money/bank`)} render={props => {
            return <AddEWalletImpl type={"bank"} achDisabled={achDisabled} parentMatch={eWalletMatch}  walletMatch={walletMatch} />;
        }} />
        <Route path={joinPath(eWalletMatch.url, `add_more_money/bank-ca`)} render={props => {
            return <AddEWalletImpl type={"bank"} achDisabled={achDisabled} parentMatch={eWalletMatch}  walletMatch={walletMatch} />;
        }} />
        <Route path={joinPath(eWalletMatch.url, `add_more_money/etransfer`)} render={props => {
            return <LearnAboutACHByCountry parentMatch={eWalletMatch} />;
        }} />
        <Route path={joinPath(eWalletMatch.url, `add_more_money/funds-transfer`)} render={props => {
            return <LearnAboutACHByCountry parentMatch={eWalletMatch} />;
        }} />
        <Route path={joinPath(eWalletMatch.url, `add_more_money`)} render={props => {
            return <AddEWallet parentMatch={eWalletMatch}
                               hasAcceptedSparkTerms={hasAcceptedSparkTerms}
                               cardStatus={cardStatus}
                               achDisabled={achDisabled}

            />;
        }} />
    </Switch>;
});