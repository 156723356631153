import React from "react";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {getUniqKey, patchCDN} from "../../utils/helpers";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import ItemWrapper from "../../containers/ItemWrapper";
import {getItemUrl} from "../../utils/menuUtils";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({

    modalHeader:{
        border:"none"
    },
    modalTitle:{
        padding:theme.spacing(1),
        flexGrow:1
    },
    modalBody:{
        padding: theme.spacing(1)
    },
    link:{
        textDecoration:"none",
        color: "white",
        display:"inline-flex",
        alignItems:"center",
        flexDirection:"column",
        justifyContent:"center",
        width:100,
        marginBottom: 20,
        "&:hover":{
            color:"white",
            textDecoration:"none",
        }
    },
    subitems:{
        display: "flex",
    },
    icon:{
        width:75,
        height:75
    },
    text:{
        display:"inline-block",
        width:"100%",
        textAlign:"center"
    },
    close: {
        position: "absolute",
        right: 15,
        top: 5,
        opacity: 1,
        cursor:"pointer"
    },
    thumbContainer:{
        position: "relative"
    },

    loadOverlay:{
        position: "absolute",
        top:0,
        bottom:0,
        left:0,
        right:0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }

}));



export default observer(({ sectionItem, items, parentMatch, onClose, onPlay, detailsLoadingFor }) => {
    const classes = useStyles();
    return <>
            <div className={`${classes.modalHeader}`}>
                <Typography className={classes.modalTitle} variant={"h6"} align={"center"}>{sectionItem.title}</Typography>
                <Close fontSize={"large"} type="button" className={classes.close} data-dismiss="modal" aria-label="Close" onClick={onClose}/>
            </div>
            <div className={classes.modalBody}>
                <Grid  item container alignItems="flex-start" >
                    {items.map(x=>{
                        let itemUrl = getItemUrl(x);
                        const mediaID =   itemUrl &&  itemUrl.startsWith("playmedia://") && itemUrl.substr("playmedia://".length )
                        return <Grid item xs={3}   key={getUniqKey(x)}>
                            <ItemWrapper def={sectionItem} item={x} parentMatch={parentMatch} onPlay={onPlay}>
                                <ListItemAvatar className={classes.thumbContainer}>
                                    <>
                                        <Avatar src={patchCDN(x.thumbnail)} variant={"rounded"} className={classes.icon}/>
                                        {detailsLoadingFor && detailsLoadingFor.includes(mediaID) &&
                                        <div className={classes.loadOverlay }>
                                            <CircularProgress color={"secondary"} thickness={3}/>
                                        </div>}
                                    </>
                                </ListItemAvatar>
                                <Typography variant="subtitle1"  className={classes.text}> {x.title}</Typography>

                            </ItemWrapper>
                        </Grid>
                    })}
                </Grid>
            </div>
        </>

})


