import React, {Fragment, useEffect} from "react";
import {  observer } from "mobx-react-lite";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {useStores} from "../hooks/use-stores";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import MediaList from "../components/MediaList";
import SpeakersList from "../components/SpeakersList";
const useStyles = makeStyles((theme) => ({
    root:{
        color:"white",
    },
    margin:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    divider:{
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },
    colorWhite:{
        color:"white",
    },
    grid: {

        "&:after":{
            content: '""',
            flex: "auto"
        }
    },

    btnSeries:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        color: "white",
        textDecoration:"none"
    },


    seriesIcon: {
        borderRadius: "50%",
        width: "95%"
    },
    lineClamp:{
        margin: "5px 0",
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        lineHeight: "1.5em",
    },

    notchedOutline:{
        borderColor: "white"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

}));



export default observer ( function LibrarySeries ({prefix}) {
    const classes = useStyles();
    const {speakerStore, audioStore, authStore, mediaHandlers } = useStores();
    const params = useParams();
    const {speakerId} = params
    const {books, packs, audios, videos, coSpeakers, sections, mediaType, seriesLoading, error } = speakerStore;
    const {mediaID,  audioProgress, audioPaused} = audioStore;
    const {loginGuid} = authStore;

    useEffect(()=>{
        ( async ()=>{
            if ( speakerId )
                await speakerStore.loadSeries(speakerId)
            speakerStore.setMediaType( sections[0] && sections[0].mediaType )
        })()
    },[speakerId])

    const handleTabChange =  (event, newValue)=>{
        speakerStore.setMediaType( newValue )
    }


    const effectiveMediaType = mediaType || (sections[0]&& sections[0].mediaType)

    return <div className={classes.root}>
        {error ?  <Alert severity="error">{error}</Alert> : null}

        {seriesLoading  ? <CircularProgress/>
            :<>
                {effectiveMediaType && <TabContext value={effectiveMediaType}>
                    <AppBar position="static">
                        <Tabs onChange={handleTabChange} value={effectiveMediaType} >
                            {sections.map(x=>{
                                return <Tab key={x.mediaType} value={x.mediaType} label={x.title} selected={x.mediaType===mediaType}/>
                            })}

                        </Tabs>
                    </AppBar>
                    <TabPanel value={"audio"} >
                        <MediaList currentMediaID={mediaID} items={audios}
                                   onAudioPlay={mediaHandlers.handleAudioPlay}
                                   audioPaused = {audioPaused}
                                   loginGuid={loginGuid}
                                   audioProgress={audioProgress}
                                   onAudioPause={mediaHandlers.handleAudioPause} />
                    </TabPanel>
                    <TabPanel value={"video"} >
                        <MediaList items={videos} onVideoOpen={mediaHandlers.handleVideoOpen}/>
                    </TabPanel>
                    <TabPanel value={"book"}>
                        <MediaList items={books} onOpenBook={mediaHandlers.handleBookOpen}/>
                    </TabPanel>
                    <TabPanel value={"pack"}>
                        <MediaList items={packs}/>
                    </TabPanel>
                    <TabPanel value={"speaker"} >
                        <SpeakersList items={coSpeakers} prefix={prefix}/>
                    </TabPanel>
                </TabContext>}

            </>}

    </div>
})