import React from "react";
import {observer} from "mobx-react-lite";
import { makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {getUniqKey, joinPath, patchCDN} from "../../utils/helpers";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position:"relative",
        color: "white",
        flexDirection:"column",
        marginBottom:20
    },
    link:{
        textDecoration:"none",
        color: "white",
        display:"inline-flex",
        alignItems:"center",
        flexDirection:"column",
        justifyContent:"center",
        width:100,
        "&:hover":{
            color:"white",
            textDecoration:"none",
        }
    },
    subitems:{
        display: "flex",
        alignItems:"flex-start",
    },
    icon:{

    },
    text:{
        display:"inline-block",
        overflow:"hidden",
        textOverflow:"ellipsis",
        width:"100%",
        textAlign:"center",
        padding: 7
    }
}));

export default observer(({def, parentMatch}) => {
    const classes = useStyles();

    return def   ? <Grid  item xs={12} container className={classes.root} key={def.tag}>
        <Typography variant="subtitle1">{def.title}</Typography>
        <Grid  item container className={classes.subitems}>
            {def.subsection.map(x=>{
            return <Link key={getUniqKey(x)} to={joinPath(parentMatch.url, getUniqKey(def), getUniqKey(x))} className={classes.link}>
                   <Avatar src={ patchCDN( x.thumbnail ) } className={classes.icon}/>
                    <Typography variant="caption"  className={classes.text}> {x.title}</Typography>
            </Link>
        })}
        </Grid>

    </Grid>: null
})


