import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import {updateCardRules, updateCardTypeRules, updatePostalCodeRules} from "./helpers";

const createFields = {

    cardType: {
        label: 'Card Type',
        rules: 'string|required',
        placeholder: 'Card Type',
        default: "Visa"
    },
    cardNumber: {
        label: 'Card Number',
        rules: 'string|required|max:19',
        placeholder: 'Card Number',
    },
    "firstName": {
        label: 'First Name',
        rules: 'string',
        placeholder: 'First Name',
    },
    "lastName": {
        label: 'Last Name',
        rules: 'string',
        placeholder: 'Last Name',
    },
};

export const expirationFields = {
    "expMonth": {
        label: 'Month',
        rules: 'integer',
        placeholder: 'Month',
    },
    "expYear": {
        label: 'Year',
        rules: 'integer',
        placeholder: 'Year',
    },
    "cvv": {
        label: 'CVV',
        rules: 'string|required',
        placeholder: 'CVV',
    },
};
export const billingFields = {
    address1: {
        label: 'Address 1',
        rules: 'string|required',
        placeholder: 'Address 1',
    },
    address2: {
        label: 'Address 2',
        rules: 'string',
        placeholder: 'Address 2',
    },
    city: {
        label: 'City',
        rules: 'string|required',
        placeholder: 'City',
    },
    zip: {
        label: 'Zip/Postal Code',
        rules: 'string|digits:5|required',
        placeholder: 'Zip/Postal Code',
    },
    region: {
        label: 'State / Province',
        rules: 'string|required',
        placeholder: 'State / Province',
    },
    country: {
        label: 'Country',
        rules: 'string',
        placeholder: 'Country',
        initialValue: "US"
    },
    primary: {
        rules: 'boolean'
    }
};




export default function create(forCreate, hooks) {
    const form  = new  MobxReactForm({ fields: { ...(forCreate ? createFields : {}), ...expirationFields, ...billingFields } }, { plugins, hooks });
    form.$('country')
    .observe(({ form, field, change }) => {
        updatePostalCodeRules(form, field.value, "zip" )
        updateCardTypeRules(form, field.value);
    })
    updateCardTypeRules(form, billingFields.country.initialValue);
    updatePostalCodeRules(form, billingFields.country.initialValue, "zip" )

    form.$('cardType')
    .observe(({ form, field, change }) => {
        updateCardRules(form, field.value, 'cvv');
    });
    updateCardRules(form, expirationFields.cvv.initialValue, 'cvv');

    return form
}

