import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import {Box, Button, DialogTitle} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DialogContent from "@material-ui/core/DialogContent";
import {Alert} from "@material-ui/lab";
import BillingInfoList from "../../Profile/BillingInfo/BillingInfoList";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTranslation} from "react-i18next";
import {useStores} from "../../../hooks/use-stores";


const useStyles = makeStyles((theme) => ({

    backButton: {
        flex: 1,
        minWidth: "fit-content"
    },

    rightCell: {
        flex: 1,
        minWidth: 100
    },
    simpleTitle: {
        flex: "1 1 auto",
        textAlign: "center",
        textTransform: "uppercase",

    },
    appbarToolbar: {
        height: "100%"
    },
    dialogTitle: {
        padding: 0
    }
}));


function AddBankModal({showBankModal, handleHidePaymentEdit, handleSavedPaymentEdit  }){
    const classes = useStyles();
    const {subscriptionStore}   = useStores();
    const { t } = useTranslation("wallet");
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { updatePaymentError } = subscriptionStore;

    return  <>{showBankModal && <Dialog maxWidth={"md"} fullScreen={!isDesktop} fullWidth open={true} onClose={handleHidePaymentEdit}>
        {!isDesktop && <DialogTitle classes={{ root: classes.dialogTitle }}>
            <Toolbar className={classes.appbarToolbar}>
                <Box display="flex" width="100%" alignItems={"center"}>
                    <Button onClick={handleHidePaymentEdit} className={classes.backButton} size="small" startIcon={<ArrowBackIcon />}>Back</Button>
                    <div className={classes.simpleTitle}>{t("global:newCard")}</div>
                    <div className={classes.rightCell} />
                </Box>
            </Toolbar>
        </DialogTitle>}
        <DialogContent>
            {updatePaymentError && <Alert variant={"filled"} color={"error"}>{updatePaymentError}</Alert>}
            <BillingInfoList allowSelect onlyBanks showEwallet newOnly hideHeader={!isDesktop}
                             onSaved={handleSavedPaymentEdit}
                             onCancel={handleHidePaymentEdit} />
        </DialogContent>
    </Dialog>}</>

}

export default AddBankModal;