import React from 'react';
import {observer} from "mobx-react";
import {Link as RouteLink} from "react-router-dom";
import {joinPath} from "../../../utils/helpers";
import Link from "@material-ui/core/Link";




function LinkWrapper  ({shoppingMatch, audioItem, children, className }) {
 // static audioTrack;

    return audioItem.parentItem
                      ? <Link component={RouteLink} className={className} to={joinPath( shoppingMatch.url
                                , `category`
                                , audioItem.parentItem.parentCategoryID
                                , "sub-category"
                                , audioItem.parentItem.productCategoryID
                                , "product-detail"
                                , audioItem.parentItem.productSKU)}>{children}</Link>
                      :<>{children}</>


}

export default observer(LinkWrapper);
