import React from "react";
import {Button, Container, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStores} from "../../hooks/use-stores";
import {useTranslation} from "react-i18next";
import {ContentPageStyles} from "./styles";


const useStyles = makeStyles((theme) => ({
    ...ContentPageStyles(theme),

    head: {
        paddingTop: 60,
        paddingBottom: 100,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
            paddingBottom: 50,
        }
    },

    grayBg: {
        backgroundColor: theme.palette.background.default,
    }

}));


const glossary = [
    {
        "title": "BAMFAM:",
        content: <>An acronym for “Book a meeting from a meeting,” meant to remind Life Members to always put a
            time in the calendar to take the next step with a prospect. This helps avoid “chasing” them
            later to try to get back with them. This step is often missed, and therefore the unusual acronym
            proves a helpful reminder.</>
    },
    {
        "title": "Bonus Chart:", content: <>The cornerstone of the Member
            Compensation Plan. This chart shows the % of bonus paid out at corresponding levels of total
            Point Volume (PV) of product flow. For more information on how this is applied, see the Member
            Compensation Plan.</>
    },
    {
        "title": "Building Numbers:",
        content: <> A term that refers to one of the main objectives in building the business. By constructing an
            organization with large numbers of people in it, there is the potential for huge customer volume
            and product flow and hence incomes. But more specifically, the numbers that count are those
            actually plugged into the Marketing System and attending events. This is best achieved by the
            building of Depth.</>
    },
    {
        "title": "Consumer Rebellion:", content: <>The wave of customers tired of
            being scammed by credit card fees and interest who switch to using Starfish to make their
            national brand purchases instead.</>
    },
    {
        "title": "Contacting Session (Business Launch):",
        content: <>A gathering where Life Members each call through their name’s lists in friendly competition and
            encouragement.</>
    },
    {
        "title": "Core 4:", content: <>The four main or “core”
            elements of the business committed to by all serious Life Members: 1) Standing Order Ticket
            (SOT), 2) Royal Starfish 100, 3) Marketing System Premium (MSP), 4) Major Convention
            Ticket.</>
    },
    {
        "title": " Cross-lining:",
        content: <>Talking to anyone not in your upline or downline about business specifics or results.</>
    },
    {
        "title": "Depth:",
        content: <>The concept of enrolling new Life Members “under” current Life Members in the Line of Sponsorship.
            This enlarges the business of everyone above the new growth by potentially bringing in more volume,
            customers, and further new Life Members. Depth tends to increase peoples’ belief and potential in the
            business.</>
    },
    {"title": "Downline:", content: <>The Life Members who have enrolled “below” you in your Line of Sponsorship.</>},
    {
        "title": "Duplication:",
        content: <>The concept borrowed from the franchise business world in which productive activities are carefully
            designed to be mimicked by all participants in order to produce a multiplicative result. The idea is to make
            sure everything you do as a leader in the business is worthy of being copied by your organization.</>
    },
    {
        "title": "Edification:",
        content: <>Finding true and specific good things to say about someone and using these attributes as a way to
            give that person credibility in the eyes of others. This can be applied to introducing an upline leader to
            someone in your group, introducing a speaker who is about to show the plan, etc. The better the edification,
            the higher the person’s instant credibility, with the result that they can be more effective in helping you
            build your business.</>
    },
    {
        "title": "Five Step Pattern:", content: <> 1) Make a List<br/>
            2) Contact<br/>
            3) Show the Plan<br/>
            4) Follow Through<br/>
            5) Rotate the Pattern (Get Them Started)<br/></>
    },
    {
        "title": "Go Getter:",
        content: <>The desirable activity level of showing the plan a minimum of fifteen separate times in the course of
            one month. All Power Players are Go Getters.</>
    },
    {
        "title": "House Plan:",
        content: <>A gathering at a new Life Member’s home where the plan can be presented to many people at once.</>
    },
    {
        "title": "IDS (Income Disclosure Statement):",
        content: <>Legal document released annually showing the actual results of Life Members in the Member
            Compensation Plan. This must be made available to each and every prospective new Life Member.</>
    },
    {
        "title": "Legs:",
        content: <>A leg is made up of Life Members enrolled and connected directly to you through the first person
            enrolled “under you” in your Line of Sponsorship. Each time you enroll a new Life Member connected directly
            to you, it begins a new leg.</>
    },
    {
        "title": "Life Accelerator:",
        content: <>A premium video and audio personal development product that teaches the Three Keys to Wealth
            (Leadership, Literacy (financial), and Leverage). The tag line is to “buy it once and use it forever.” This
            means any new content and future Pay Per Views will all automatically be included for those who made this
            one-time purchase.</>
    },
    {
        "title": "Life Training:",
        content: <>The entire range of business development activities and subscriptions designed to help you build your
            business. Included under the Life Training umbrella are 1) Marketing System 2) Marketing System Premium 3)
            all Events, including Opens, Seminars, and Major Conventions 4) individual sales aids including banners and
            handouts 5) books such as this one and any “first night” materials or books. Note: there is no point value
            (PV) on anything in the Life Training realm. All aspects of Life Training are strictly optional.</>
    },
    {
        "title": "Lifeline:",
        content: <>Part of the Life Training Marketing System, Lifeline is a video communication tool Life Members use
            to send and receive messages to/from their upline and a place for direct corporate communications. Lifeline
            is a very important part of being plugged in to the daily happenings and excitement of the growth of the
            business.</>
    },
    {
        "title": "Line of Sponsorship:",
        content: <>The structure of Life Members and their arrangement to each other in the business.</>
    },
    {
        "title": "Major Functions (Major Conventions) (Majors):",
        content: <> The biggest Life Training live event held three times annually. The New Year’s Summit is the winter
            event, Masters of Leadership is the summer event, and the fall event is Life University. Major functions are
            the most important, most influential, most information-packed opportunities Life Members must build their
            own belief and that of their teams. As we like to say, “All the big decisions get made at major
            functions.”</>
    },
    {
        "title": "Marketing System:",
        content: <>A subscription within the Life Training system designed to help Life Members build their businesses.
            Provides basic audio training, an introductory amount of monthly Sendouts to use with prospective customers,
            the Lifeline video communication tool, and more.</>
    },
    {
        "title": "Marketing System Premium:",
        content: <>A subscription within the Life Training system designed to not only help Life Members build their
            businesses (as it contains everything offered in the Marketing System subscription), but also to help them
            build themselves into profitable entrepreneurs. Includes ten times the Sendouts given in the basic Marketing
            System, as well as the complete library of twenty years’ worth of audios and videos teaching how to develop
            wealth thinking and build strong and profitable businesses.</>
    },
    {
        "title": "Media War:",
        content: <>The very serious struggle for attention span caused by a bombardment of error-filled and destructive
            messages from the world, versus constructive and positive messaging provided from sources (such as the Life
            Platform) trying to help people and build them up instead. The reason this is so important is because what
            we allow into our heads determines how we think, which determines how we act, which in turn determines our
            results in life. Wrong thinking equals pain and suffering.</>
    },
    {
        "title": "One-on-One:",
        content: <>Showing the plan to one person (or one couple). The idea is to try and book House Plans as much as
            possible, and then fill in the gaps in your calendar with One-on-Ones to find more prospects you can plug
            into house plans or host one of their own.</>
    },
    {
        "title": "Open Meetings (Opens):",
        content: <>Weekly introductory meetings held throughout our markets at which the business opportunity is shared
            at the first session and some of the how to is taught at the second “Night Owl” session. Opens have multiple
            purposes, including 1) providing a second look for prospects to whom you’ve shown the plan, 2) Life Members
            learning how to show the plan by seeing it each week, 3) Life Members connecting and building camaraderie
            with their upline, 4) upline having the chance to meet new prospects in depth, 5) prospects and new Life
            Members getting to meet their new upline, etc.</>
    },
    {
        "title": "Orchestration:",
        content: <>The result of thinking through one’s business and directing events and the actions of others to
            maximize results and make the best use of time invested. Once someone has learned to work hard in the
            business, orchestration is where one, as a leader, begins to work smart.</>
    },
    {
        "title": "PDCA (Plan, Do, Check, Adjust):",
        content: <>The nearly self-explanatory but very productive process loop of improvement.</>
    },
    {
        "title": "Pin (Rank):",
        content: <>Name given to the various levels of achievement in the Member Compensation Plan (such as Leader,
            Coordinator, Sr. Coordinator, etc). It used to be that physical lapel pins were awarded to signify the
            highest level attained by a Life Member. Although this practice has long been retired, the term “pin”
            continues to be used when referring to the concept of rank.</>
    },
    {
        "title": "Platform Revolution:",
        content: <>The collective action of Life Members taking advantage of the enormous opportunities provided in
            platform business structures that are usually reserved for only a select few billionaires.</>
    },
    {
        "title": "Point Value (PV):",
        content: <>Starfish, Royal Starfish, and each of the products in the Marketplace have a certain amount of Point
            Value assigned to them. PV is used to calculate the bonuses Life Members earn in the Compensation Plan.</>
    },
    {
        "title": "Posture:",
        content: <>Your mental stance or level of conviction about what you are doing; the higher your belief, the
            higher your posture, and the more effective you will be showing the plan.</>
    },
    {
        "title": "Power Player:",
        content: <>The main program for fast business growth, structured around the three areas of Theory, Activity, and
            Results.</>
    },
    {
        "title": "Prezi (or Share Your Life):",
        content: <>A presentation tool used to show the plan to prospects at a distance or to a group of people at a
            house plan.</>
    },
    {
        "title": "Promote:",
        content: <>Helping prospects and new Life Members understand the value and importance of the Life Training
            system and/or any of its components. This is accomplished by showing them what’s in for them, and
            specifically, how it connects to building a business that will help them fulfill their dreams.</>
    },
    {
        "title": "Read, Listen, Associate (RLA):",
        content: <>The core activities involved in getting “plugged in” to the Life Training system. These learning
            steps are the secret to gaining wealth thinking and learning from those who have the “fruit on the tree.”</>
    },
    {
        "title": "Royal Starfish:",
        content: <>The premium Starfish subscription which includes everything in the basic Starfish subscription plus
            1) a complete media library containing over twenty years’ worth of personal, professional, and financial
            development material in the form of audios, videos, eBooks, and audio books, 2) the extraordinary
            Marketplace products and services 3) Thing or 2 travel discount service, 4) and, for Members, Royal Starfish
            also pays a significantly higher level of PV for national brand merchant purchases.</>
    },
    {
        "title": "RT/AC/PC:",
        content: <>Round Table, Advisory Council, and Policy Council are three levels of business achievement wherein
            the Life Member has built multiple legs of certain size (inquire upline for the latest qualifications for
            these levels). Round Table is the lowest while Policy Council is the highest. These are increasingly
            exclusive circles of achievers who are involved in special meetings and communications and input into the
            running of the overall business.</>
    },
    {
        "title": "Seminars:",
        content: <>Monthly Life Training events designed to teach the how-to of building the business, recognize
            achievement, connect Life Members with their upline support teams, and be the source of duplicatable
            business development.</>
    },
    {
        "title": "Sendouts:",
        content: <>Digital materials to be shared with prospects after showing them the plan, during the follow-through
            process, and as they become new Life Members. The purpose is to teach them about the business, answer their
            questions, give them a vision for what could be possible, and to awaken the belief that they can do it
            too.</>
    },
    {
        "title": "SOT (Standing Order Ticket):",
        content: <>Subscription program providing electronic verification for access to the monthly seminar event in any
            market area. In addition to the convenience, the idea of being “on” SOT is to commit to attending the
            monthly seminars and setting a duplicatable example.</>
    },
    {"title": "Starfish:", content: <>The flagship product offered through the Life Platform.</>},
    {
        "title": "Stargetter:",
        content: <> Using Starfish to make national brand purchases fifteen times or more in a month.</>
    },
    {"title": "System:", content: <> General term referring to the complete Life Training system in all its parts.</>},
    {
        "title": "Team Approach:",
        content: <>The approach to building depth wherein Life Members work together to enroll new Members at the bottom
            of a leg through a variety of methods. The result is that Life Members can be in business for themselves but
            not by themselves.</>
    },
    {"title": "Teams:", content: <>Synonymous with “legs” in your downline line of sponsorship.</>},
    {
        "title": "The Three Keys to Wealth:", content: <> 1) Literacy (Financial)<br/>
            2) Leadership<br/>
            3) Leverage<br/></>
    },
    {
        "title": "Top Five Books:", content: <>Recommended reading for all Life Members. Includes these books:<br/>

            1) The Magic of Thinking Big by Dr. David Schwartz,<br/>

            2) How to Win Friends and Influence People by Dale Carnegie<br/>

            3) How I Raised Myself from Failure to Success in Selling by Frank Bettger<br/>

            4) Personality Plus by Florence Littauer<br/>

            5) How to Have Confidence and Power in Dealing with People by Les Giblin<br/></>
    },
    {
        "title": "Top Gun:",
        content: <>The most prestigious competition for Life Members. Measures business growth between Major
            Functions.</>
    },
    {
        "title": "Turbo:",
        content: <>A measure of “numbers at functions” in your business. Turbo 5 means two legs with at least five
            people in each attending a seminar or major. Turbo 10 means two legs with at least ten people in each
            attending a seminar or major. Similarly for Turbo 25, Turbo 50, and Turbo 100.</>
    },
    {"title": "Upline:", content: <>Those Life Members “above” you in the Line of Sponsorship.</>},


]

const glossaryFr = [
    {
        "title": "Accélérateur Life:",
        content: <>Un produit de développement personnel audio et vidéo haut de gamme qui enseigne Les trois clés de la
            richesse (Leadership, Littéracie (financière), et effet de Levier). Le slogan est « achetez-le une fois et
            utilisez-le pour toujours ». Cela signifie que tout nouveau contenu et tous les visionnements à la carte
            sont automatiquement inclus pour ceux qui ont fait cet achat unique.</>
    },
    {
        "title": "Approche d'équipe:",
        content: <>L'approche de la construction en profondeur dans laquelle les membres Life travaillent ensemble pour
            enrôler de nouveaux membres à la base d’une jambe par le biais d'une variété de méthodes. Le résultat est
            que les membres Life peuvent être en entreprise pour eux-mêmes, mais pas par eux-mêmes.</>
    },
    {
        "title": "BAMFAM:",
        content: <> Acronyme tiré de l’expression anglophone « Book A Meeting From A Meeting » (fixer le prochain
            rendez-vous pendant un rendez-vous), destiné à rappeler aux membres Life qu'il faut toujours prévoir une
            date dans le calendrier pour passer à l'étape suivante avec un prospect. Cela permet d'éviter de « courir »
            après eux pour essayer de les recontacter. Cette étape est souvent oubliée, et cet acronyme constitue donc
            un rappel utile.</>
    },
    {
        "title": "Les cinq meilleurs livres:",
        content: <>Lectures recommandées pour tous les membres Life. Comprend:<br/>

            1) La magie de voir grand de David Schwartz<br/>

            2) Comment se faire des amis et influencer les autres de Dale Carnegie<br/>

            3) De l'échec au succès: Ma formule de succès dans la vente de Frank Bettger<br/>

            4) Comment avoir confiance et pouvoir dans les relations avec les gens de Leslie Giblin<br/>

            5) Personnalité Plus de Florence Littauer. </>
    },
    {
        "title": "Construire des nombres:",
        content: <>Un terme qui fait référence à l'un des principaux objectifs de la création d'une entreprise. En
            construisant une organisation avec un grand nombre de personnes, il est possible d'obtenir un volume de
            clients et un flux de produits considérables, et donc des revenus. Mais plus précisément, les chiffres qui
            comptent sont ceux qui sont effectivement connectés au Système de marketing et qui participent aux
            événements. La meilleure façon d'y parvenir est de créer de la profondeur.</>
    },
    {
        "title": "Core 4:",
        content: <>Les quatre éléments principaux ou « essentiels » de l'activité auxquels s'engagent tous les membres
            Life sérieux : 1) le SOT (billet mensuel), 2) Royal Starfish 100, 3) Système de marketing Premium (SMP), 4)
            billet de Convention majeure.</>
    },
    {
        "title": "Croisement de lignes:",
        content: <>Parler des spécificités ou des résultats de l'entreprise à des personnes qui ne font pas partie de
            votre ligne ascendante ou descendante. </>
    },
    {
        "title": "Duplication:",
        content: <>Concept emprunté au monde de la franchise dans lequel les activités productives sont soigneusement
            conçues pour être imitées par tous les participants afin de produire un résultat multiplicatif. L'idée est
            de s'assurer que tout ce que vous faites en tant que Leader de l'entreprise mérite d'être copié par votre
            organisation.</>
    },
    {
        "title": "Édification:",
        content: <>Trouver des choses vraies et précises à dire sur quelqu'un et utiliser ces attributs pour donner de
            la crédibilité à cette personne aux yeux des autres. Cela peut s'appliquer à la présentation d'un Leader de
            ligne ascendante à un membre de votre groupe, à la présentation d'un conférencier qui s'apprête à présenter
            le plan, etc. Plus l'édification est grande, plus la crédibilité instantanée de la personne est élevée, ce
            qui lui permet d'être plus efficace pour vous aider à développer votre entreprise.</>
    },
    {
        "title": "Envois Sendouts:",
        content: <>Des documents numériques à partager avec les prospects après leur avoir présenté le plan, pendant le
            processus de suivi et lorsqu'ils deviennent de nouveaux membres Life. L'objectif est de les informer sur
            l'entreprise, de répondre à leurs questions, de leur donner une vision de ce qui pourrait être possible et
            de les amener à croire qu'ils peuvent le faire eux aussi.</>
    },
    {
        "title": "Équipes:",
        content: <>Synonyme de « jambes » dans votre ligne de parrainage descendante.</>
    },
    {
        "title": "Fonctions majeures (Conventions majeures) (Majeurs) :",
        content: <>Le plus grand événement en direct de Life Training, qui a lieu trois fois par an. La Convention du
            Sommet du Nouvel An est l'événement d'hiver, la Convention des Maîtres en Leadership est l'événement d'été
            et l'événement d'automne est la Convention Université Life. Les fonctions majeures sont les occasions les
            plus importantes, les plus influentes et les plus riches en informations que les membres Life doivent saisir
            pour renforcer leurs propres convictions et celles de leurs équipes. Comme nous aimons dire, « toutes les
            grandes décisions sont prises lors des fonctions majeures ».</>
    },
    {
        "title": "Go Getter:",
        content: <>Le niveau d'activité souhaitable qui consiste à montrer le plan au moins quinze fois au cours d'un
            mois. Tous les «Power Players» sont des «Go Getters».</>
    },
    {
        "title": "Guerre des médias:",
        content: <>La lutte pour la capacité d'attention causée par un bombardement de messages remplis d'erreurs et
            destructeurs provenant de l’extérieur, par opposition aux messages constructifs et positifs fournis par des
            sources (telles que la Plateforme Life) qui essaient d'aider les gens et de les édifier. La raison pour
            laquelle c’est si important est que ce que nous laissons entrer dans notre mental détermine notre façon de
            penser, notre façon d'agir, ce qui détermine à son tour nos résultats dans la vie. Une pensée erronée est
            synonyme de douleur et de souffrance.</>
    },
    {
        "title": "IDS (déclaration de revenu):",
        content: <>Document légal publié chaque année et indiquant les résultats réels des membres Life dans le cadre du
            Plan de rémunération des membres. Ce document doit être mis à la disposition de chaque nouveau membre Life
            potentiel.</>
    },
    {
        "title": "Jambe:",
        content: <>Une jambe est constituée des membres Life inscrits et reliés directement à vous par l'intermédiaire
            de la première personne inscrite « en-dessous de vous » dans votre ligne de parrainage. Chaque fois que vous
            inscrivez un nouveau membre Life directement lié à vous, une nouvelle jambe commence.</>
    },
    {
        "title": "Lifeline:",
        content: <>Partie intégrante du Système de marketing Life Training, Lifeline est un outil de communication vidéo
            que les membres de Life Training utilisent pour envoyer et recevoir des messages à destination et en
            provenance de leur ligne ascendante, ainsi qu'un lieu de communication directe avec l'entreprise. Lifeline
            est un élément très important qui vous permet d'être au courant des événements quotidiens et de l'excitation
            liée à la croissance de l'entreprise.</>
    },
    {
        "title": "Life Training:",
        content: <>La gamme complète d'activités de développement commercial et d'abonnements conçus pour vous aider à
            développer votre entreprise. Sont inclus dans la gamme Life Training : 1) le Système de marketing 2) le
            Système de marketing Premium 3) tous les événements, y compris les Réunions ouvertes, les séminaires et les
            fonctions majeures 4) les aides à la vente individuelles, y compris les bannières et les documents à
            distribuer 5) les livres tels que celui-ci et tout matériel ou livre de « soirée initiale ». Remarque : il
            n'y a pas de valeur du point (PV) en ce qui concerne Life Training. Tout le matériel Life Training est
            strictement facultatif.</>
    },
    {
        "title": "Ligne ascendante:",
        content: <>Les membres Life qui se trouvent « au-dessus » de vous dans la ligne de parrainage.</>
    },
    {
        "title": "Ligne de parrainage:",
        content: <>La structure des membres Life et les relations qu'ils entretiennent entre eux au sein de
            l'entreprise.</>
    },
    {
        "title": "Ligne descendante:",
        content: <>Les membres Life qui se sont inscrits « en-dessous » de vous dans votre ligne de parrainage.</>
    },
    {
        "title": "Lire, écouter, s'associer (RLA):",
        content: <>Les activités de base nécessaires pour se « brancher » au Système Life Training. Ces étapes
            d'apprentissage sont le secret pour acquérir la pensée prospère en apprenant de ceux qui ont le "fruit sur
            l'arbre".</>
    },
    {
        "title": "Modèle à cinq étapes:",
        content: <>1) Faire une liste<br/>

            2) Contacter<br/>

            3) Présenter le plan<br/>

            4) Faire un suivi<br/>

            5) Faire une rotation du modèle (les faire démarrer)<br/></>
    },
    {
        "title": "Orchestration:",
        content: <>Le résultat d'une réflexion sur son activité et d'une orientation des événements et des actions des
            autres afin de maximiser les résultats et d'utiliser au mieux le temps investi. Une fois que l'on a appris à
            travailler fort au sein de l'entreprise, l'orchestration est le moment où, en tant que Leader, on commence à
            travailler intelligemment.</>
    },
    {
        "title": "PFVA (Planifier, Faire, Vérifier, Ajuster):",
        content: <>La très productive boucle du processus d'amélioration.</>
    },
    {
        "title": "Pin (Rang):",
        content: <>Nom donné aux différents niveaux de réussite du Plan de rémunération des membres (tels que Leader,
            Coordonnateur, Coordonnateur principal, etc.) Autrefois, des épinglettes étaient décernées pour indiquer le
            rang le plus élevé atteint par un membre Life. Bien que cette pratique ait été abandonnée depuis longtemps,
            le terme « pin » continue d'être utilisé pour désigner le concept de rang.</>
    },
    {
        "title": "Plan maison:",
        content: <>Une réunion au domicile d'un nouveau membre Life, au cours de laquelle le plan peut être présenté à
            plusieurs personnes à la fois.</>
    },
    {
        "title": "Posture:",
        content: <>
            Votre position mentale ou votre niveau de conviction par rapport à ce que vous faites; plus votre conviction
            est élevée, plus votre posture est élevée et plus vous montrerez le plan de manière efficace.</>
    },
    {
        "title": "Power Player:",
        content: <>Le principal programme de croissance rapide de l'entreprise, structuré autour des trois domaines de
            la Théorie, l'Activité et les Résultats.</>
    },
    {
        "title": "Prezi (ou Partagez votre vie):",
        content: <>Un outil de présentation utilisé pour montrer le plan à des prospects à distance ou à un groupe de
            personnes à un Plan maison.</>
    },
    {
        "title": "Profondeur:",
        content: <>Le concept d'inscription de nouveaux membres Life « sous » les membres Life actuels de la ligne de
            parrainage. Cela élargit l’entreprise de tous ceux qui se trouvent au-dessus de la nouvelle croissance en
            apportant potentiellement plus de volume, de clients et d'autres nouveaux membres Life. La profondeur tend à
            accroître la confiance et le potentiel des gens dans l'entreprise. </>
    },
    {
        "title": "Promouvoir:",
        content: <>Aider les prospects et les nouveaux membres Life à comprendre la valeur et l'importance du Système
            Life Training et/ou de l'un de ses composants. Pour ce faire, nous leur montrons ce qu'ils peuvent en
            retirer et, plus particulièrement, comment ils peuvent créer une entreprise qui les aidera à réaliser leurs
            rêves.</>
    },
    {
        "title": "Rébellion du consommateur:",
        content: <>La vague de clients qui s’abonnent à Starfish pour effectuer leurs achats de marques nationales parce
            qu’ils sont écœurés d'être escroqués par les frais et les intérêts des cartes de crédit.</>
    },
    {
        "title": "Réunions ouvertes (Opens):",
        content: <>Des réunions d'introduction hebdomadaires organisées sur l'ensemble de nos marchés, au cours
            desquelles l'opportunité commerciale est présentée lors de la première session et une partie de la méthode
            est enseignée lors de la deuxième session appelée « Night Owl ». Les Opens ont de multiples objectifs,
            notamment 1) donner un second regard aux prospects à qui vous avez montré le plan, 2) les membres Life
            apprennent à montrer le plan en le voyant chaque semaine, 3) les membres Life nouent des liens et
            développent la camaraderie avec leur ligne ascendante, 4) la ligne ascendante a la possibilité de rencontrer
            de nouveaux prospects en profondeur, 5) les prospects et les nouveaux membres Life rencontrent leur nouvelle
            ligne ascendante, etc.</>
    },
    {
        "title": "La révolution de la Plateforme:",
        content: <>L'action collective des membres Life qui tirent profit des énormes possibilités offertes par les
            structures des plateformes commerciales, habituellement réservées aux rares milliardaires de ce monde.</>
    },
    {
        "title": "Royal Advance:",
        content: <>Le processus par lequel un membre abonné à Royal Starfish augmente son volume personnel de 100 à 200,
            puis de 200 à 300 et ainsi de suite.</>
    },
    {
        "title": "Royal Starfish:",
        content: <>L'abonnement Starfish premium qui comprend tout ce qui est inclus dans l'abonnement Starfish de base
            plus 1) une médiathèque complète contenant plus de vingt ans de matériel de développement personnel,
            professionnel et financier sous forme d'enregistrements audios, de vidéos, de livres numériques et de livres
            audio, 2) les produits et services extraordinaires du marché numérique 3) le service de rabais Thing or 2
            Travel, 4) et, pour les membres, Royal Starfish paie également un niveau de PV significativement plus élevé
            pour les achats effectués auprès des commerçants de marque nationale.</>
    },
    {
        "title": "RT/AC/PC - Table ronde, Conseil consultatif et Conseil des politiques",
        content: <>Il existe trois niveaux de réussite commerciale dans lesquels le membre Life a construit plusieurs
            jambes d'une certaine taille (renseignez-vous auprès de votre ligne ascendante pour connaître les dernières
            qualifications pour ces niveaux). La Table ronde est le niveau le plus bas, tandis que le Conseil des
            politiques est le plus élevé. Il s'agit de cercles de plus en plus exclusifs de membres performants qui
            participent à des réunions et des communications spéciales et contribuent à la gestion de l'entreprise dans
            son ensemble.</>
    },
    {
        "title": "Séminaires:",
        content: <>Des événements mensuels de formation Life Training conçus pour enseigner le mode d'emploi de la
            création d'entreprise, reconnaître les réalisations, mettre en relation les membres Life avec leurs équipes
            de soutien de ligne ascendante et être la source d'un développement d'entreprise duplicable.</>
    },
    {
        "title": "Session de prise de contact (Lancement d'entreprise):",
        content: <>Une réunion au cours de laquelle les membres Life contactent les personnes sur leurs listes de noms
            dans le cadre d'une compétition amicale et d'encouragements.</>
    },
    {
        "title": "SOT (Billet mensuel):",
        content: <>Il s'agit d'un programme d'abonnement qui permet de vérifier électroniquement l'accès aux séminaires
            mensuels dans n'importe quelle zone de marché. Outre l'aspect pratique, l'idée d'être « sur » SOT est de
            s'engager à assister aux séminaires mensuels et de montrer l'exemple.</>
    },
    {"title": "Starfish:", content: <>Le produit phare proposé par la Plateforme Life. </>},
    {
        "title": "Stargetter:",
        content: <>Utiliser Starfish pour effectuer des achats de marque nationale quinze fois ou plus au cours d'un
            mois.</>
    },
    {"title": "Système:",
        content: <>Terme général désignant l'ensemble du Système de formation Life Training dans toutes ses
            composantes.</>
    },
    {
        "title": "Système de marketing:",
        content: <>Un abonnement au Système de formation Life Training conçu pour aider les membres Life à développer
            leur entreprise. Il fournit une formation audio de base, une quantité initiale d'Envois Sendouts mensuels à
            utiliser avec des clients potentiels, l'outil de communication vidéo Lifeline, et plus encore. </>
    },
    {
        "title": "Système de marketing Premium:",
        content: <>Un abonnement au Système Life Training conçu non seulement pour aider les membres Life à développer
            leurs entreprises (puisqu'il contient tout ce qui est offert dans l'abonnement au Système de marketing),
            mais aussi pour les aider à devenir des entrepreneurs rentables. Il comprend dix fois plus d’Envois Sendouts
            que le Système de marketing de base, ainsi que la médiathèque complète de vingt ans d'audios et de vidéos
            qui enseignent comment développer la pensée prospère et construire des entreprises solides et rentables.</>
    },
    {
        "title": "Tableau des primes:",
        content: <>La pierre angulaire du Plan de rémunération des membres. Ce tableau indique le pourcentage de la
            prime versée à des niveaux correspondants du volume total de points (PV) du flux de produits. Pour plus
            d'informations sur l'application de ce système, consultez le Plan de rémunération des membres.</>
    },
    {
        "title": "Tête-à-tête:",
        content: <>Montrer le plan à une personne (ou à un couple). L'idée est d'essayer de réserver des Plans maison
            autant que possible, puis de combler les lacunes de votre calendrier par des rencontres de type tête-à-tête
            afin de trouver d'autres prospects que vous pourrez inscrire à des Plans maison ou qui pourront en organiser
            eux-mêmes.</>
    },
    {
        "title": "Top Gun:",
        content: <>Le concours le plus prestigieux pour les membres Life. Mesure la croissance de l'activité entre les
            fonctions majeures. </>
    },
    {
        "title": "Les trois clés de la richesse:", content: <>1) La Littéracie financière<br/>

            2) Le Leadership<br/>

            3) L’effet de Levier.<br/></>
    },
    {"title": "Turbo:",
        content: <>Une mesure du « nombre de personnes présentes » dans votre entreprise. Turbo 5 signifie que deux
            jambes comptant chacune au moins cinq personnes assistent à un séminaire ou à une fonction majeure. Turbo 10
            signifie que deux jambes comptant chacune au moins dix personnes assistent à un séminaire ou à une fonction
            majeure. Il en va de même pour Turbo 25, Turbo 50 et Turbo 100.</>
    },
    {"title": "Valeur de point (PV):",
        content: <>Starfish, Royal Starfish et chacun des produits du marché numérique ont une certaine valeur en points
            qui leur est attribuée. La valeur de point est utilisée pour calculer les bonus que les membres Life gagnent
            dans le cadre du Plan de rémunération.</>
    },


]


function Glossary() {

    const classes = useStyles();
    const {commonStore} = useStores();
    const {t} = useTranslation("starfish");

    return <div>
        <section className={clsx(classes.section, classes.head)}>
            <Typography align="center" className={classes.headText} variant="h2">
                {t("glossary.glossary")}<span className={classes.linkColor}>{t("glossary.terms")}</span></Typography>

        </section>

        <section className={clsx(classes.section, classes.grayBg)}>
            <Container maxWidth={"md"}>
                {(commonStore.language === "fr"?glossaryFr:glossary).map((item, index) => {
                    return <div className={clsx(classes.textContainer)}>
                        <Typography align="left" className={classes.headText} variant="h4">
                            <span className={clsx({[classes.linkColor]: index % 2 === 0})}>
                                {item.title}
                            </span>
                        </Typography>
                        <Typography align="left" className={classes.mainText} variant="body1">
                            {item.content}
                        </Typography>
                    </div>
                })}
            </Container>
        </section>

        <section className={clsx(classes.section)}>
            {' '}
        </section>
        <section className={clsx(classes.section)}>
            {' '}
        </section>

    </div>
}

export default Glossary