import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import { generateLoadList} from "../utils/mobx";

class MediaHandlers {
    constructor( libraryStore, audioStore, videoStore, bookStore ) {
        makeAutoObservable(this)
        this.libraryStore = libraryStore;
        this.audioStore = audioStore;
        this.videoStore = videoStore;
        this.bookStore = bookStore;
        this.handleAudioPlay = this.handleAudioPlay.bind(this)
        this.handleAudioPause = this.handleAudioPause.bind(this)
        this.handleVideoOpen = this.handleVideoOpen.bind(this)
        this.handleBookOpen = this.handleBookOpen.bind(this)
        this.handleVideoAlbumOpen = this.handleVideoAlbumOpen.bind(this)
    }


    handleAudioPlay (media) {
        this.videoStore.closeMedia()
        this.bookStore.closeMedia()
        this.videoStore.setVimeoAlbum(null)
        this.audioStore.setMedia(media)
    }

    handleAudioPause (media) {
        this.audioStore.pauseMedia(media)
    }

    handleVideoOpen (media){
        this.audioStore.stopAudio()
        this.bookStore.closeMedia()
        this.videoStore.setVimeoAlbum(null)
        this.videoStore.setMedia(media)

    }

    handleVideoAlbumOpen (media){
        this.audioStore.stopAudio()
        this.bookStore.closeMedia()
        this.videoStore.closeMedia()
        this.videoStore.setVimeoAlbum(media)

    }
    handleBookOpen (media) {
        this.videoStore.closeMedia()
        this.audioStore.stopAudio()
        this.videoStore.setVimeoAlbum(null)
        this.bookStore.setMedia(media)
    }


}

export default MediaHandlers