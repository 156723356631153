import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";
import {observer, useLocalObservable} from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import Search from './components/Search';
import { useStores } from "../../hooks/use-stores";
import { joinPath } from "../../utils/helpers";
import { FAVORITES_FILTER } from "../../stores/shoppingBossStore";

const countries = [
    {
        name: "United States",
        code: "US",
        flag: `/flag-us.png`
    },
    {
        name: "Canada",
        code: "CA",
        flag: `/flag-ca.png`
    }
];

const useStyles = makeStyles((theme) => ({
    merchant: {
        textDecoration: "none",
        fontWeight: "bold",
        color: "white",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            color: "white",
            textDecoration: "none",
            cursor: 'pointer'
        }
    },
    countryFlag: {
        height: '30px',
        verticalAlign: 'middle',
        paddingBottom: '1px'
    },
    countryLabel: {
        fontSize: 14,
        marginLeft: theme.spacing(.5),
        [theme.breakpoints.up('sm')]: {
            fontSize: 18
        }
    },
    spacer: {
        height: 30
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginRight: theme.spacing(2),
        backgroundColor: "#fff",
        '& > img': {
            objectFit: 'contain'
        }
    },
    modalContainer: {
        padding: 20,
        borderRadius: 5,
        maxWidth: 400,
        outline: "none",
    },
    filters: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        marginTop: theme.spacing(1),
        paddingBottom: '10px',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    countrySelect: {
        marginBottom: theme.spacing(1),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        width: '100%'
    },
}));

const Merchants = ({ shoppingBossMatch }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();


    const { shoppingBossStore, identityVerificationStore, commonStore, authStore } = useStores();
    const {
        identityVerificationStatus,
    } = identityVerificationStore;
    const { currentUser } = authStore;
    const { country, preferredLanguage } = currentUser;
    const { error, favorites } = shoppingBossStore;
    // use this over the one in the store for more control
    const [countryCode, setCountryCode] = useState('US');
    const [modalOpen, setModalOpen] = useState(false);


    const localStore = useLocalObservable(() => ({
        searchQuery: "",
        activeFilter: null,

        setSearchQuery(value) {
            this.searchQuery = value
        },

        setActiveFilter(value){
            this.activeFilter = value
        },

        get filteredMerchants() {
            //console.log("recalc filteredMerchants")
            if (shoppingBossStore.loadingMerchants) {
                return [];
            }

            //console.log("shoppingBossStore.merchants", shoppingBossStore.merchantsByCountry.length )
            // Filter by Country
            let filteredMerchants = shoppingBossStore.merchantsByCountry ;

            // Filter by selected category
            if (this.activeFilter) {
                let byCategory;
                if (this.activeFilter.id === FAVORITES_FILTER.id) {
                    byCategory = (m => isMerchantFavorite(m.id));
                } else {
                    byCategory = m => m.categories.includes(this.activeFilter.id);
                }

                filteredMerchants = filteredMerchants.filter(byCategory);
            }

            // Filter by search query
            if (this.searchQuery) {
                const regex = new RegExp(this.searchQuery, 'i');
                filteredMerchants = filteredMerchants.filter(m => m.name.match(regex));
            }


            return uniqBy(filteredMerchants, 'id');
        }
    }))


    const didMount = useRef(false);

    useEffect(() => {
        void identityVerificationStore.checkIdentityVerification();
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isFailedKyc = React.useMemo(() => {
        return ["CLOSED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const handleMerchantSelectionCanProceed = (merchant) => {
        if (authStore.currentUser.UserApprovedKYC === false && country === 'US') {
            commonStore.showKYCPopup();
            return false;
        }

        if (!!isFailedKyc) {
            commonStore.showError("We apologize, but National Brand Stores is currently unavailable for your account. Please contact customer support for more details.");
            return false;
        } else if (!!isUnverified) {
            commonStore.showError("Before making a purchase, we need you to complete the Identity Verification process.", 15000);
            history.push(joinPath(commonStore.onStarfishOrRoyalStarfish ? shoppingBossMatch.url : '/wallet/ewallet/', `add_more_money`));
            return false;
        }

        if(!!merchant?.outofstock) {
            commonStore.showError("Merchant is currently out of stock and unavailable. We are working on getting more inventory. Stay tuned!.", 15000);
            return false; 
        }
        return true;//!merchant.outofstock;
    }



    const onSearchQueryUpdated = (newSearchQuery) => {
        localStore.setSearchQuery(newSearchQuery);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const toggleFavorite = async (isFavorite, merchantId) => {
        await shoppingBossStore.toggleFavorite(isFavorite, merchantId);
    };

    const filterByCategory = (category) => {
        if (localStore.activeFilter?.id === category.id) {
            localStore.setActiveFilter(null);
        }
        else {
            localStore.setActiveFilter(category);
        }
    };

    const isMerchantFavorite = (merchantId) => {
        let isFavorite = false;
        const favoriteIds = favorites.map(f => f.id);

        if (favoriteIds.includes(merchantId)) {
            isFavorite = true;
        }

        return isFavorite;
    };

    const getCountryFlag = (countryCode) => {
        let country = countries.find(country => country.code == countryCode);
        if (country) {
            return <img className={classes.countryFlag} src={country.flag} />;
        }
        return '';
    };

    const countrySelector = () => (
        <Grid container item xs={12} justifyContent="flex-end">
            <Select
                className={classes.countrySelect}
                value={countryCode}
                renderValue={(value) => <span><span className={classes.countryFlag}>{getCountryFlag(value)}</span><span className={classes.countryLabel}>{countryCode}</span></span>}
                onChange={handleCountryChange}
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {countries.map((country) =>
                    <MenuItem key={country.code} value={country.code}><span className={classes.countryFlag}>{getCountryFlag(country.code)}</span><span className={classes.countryLabel}>{country.code}</span></MenuItem>
                )}
            </Select>
        </Grid>);

    const onSelectMerchant = (merchant) => {
        const canProceed = handleMerchantSelectionCanProceed(merchant);
        if (!!canProceed) {
            shoppingBossStore.selectedMerchant = merchant;
            history.push(joinPath(shoppingBossMatch.url, `/review`));
        }
    };

    useEffect(() => {
        if (!!error && error.includes('account has not been created')) {
            return history.push(`/onboarding/shopping_boss`);
        }
    }, [error]);

    useEffect(() => {
        shoppingBossStore.currentPage = 'merchants';
        (async () => {
            shoppingBossStore.selectedMerchant = null;
            shoppingBossStore.merchantInfo = null;
            shoppingBossStore.eCodeInfo = null;

            await Promise.all([shoppingBossStore.fetchUserCountry(), shoppingBossStore.fetchUserBalance()]);
            await shoppingBossStore.updateMerchants(shoppingBossStore.userCountry); // this handles the loading indicator


            setCountryCode(shoppingBossStore.userCountry);
            localStore.setActiveFilter(null);

            if (didMount.current) {
                setModalOpen(true);
            } else {
                didMount.current = true;
            }
        })();
    }, []);


    const handleCountryChange = async  (e)=>{
        await shoppingBossStore.updateMerchants(e.target.value);
        setCountryCode(e.target.value);
        localStore.setActiveFilter(null);
    }
    if ( !shoppingBossStore.switchingMerchants  && (shoppingBossStore.loadingMerchants === null || shoppingBossStore.loadingMerchants ) ) {
        return null
    }

    return (
        <Box style={{ paddingBottom: '58px' }}>
            { (shoppingBossStore.switchingMerchants) ?
                <Box className={classes.progressContainer}>
                    <CircularProgress />
                </Box>
                :
                <>
                    {error &&
                        <Alert severity="error">
                            <AlertTitle>{t("shoppingBoss:error.title")}</AlertTitle>
                            {error}
                        </Alert>
                    }
                    <Box>
                        <Dialog
                            open={modalOpen}
                            onClose={closeModal}
                        >
                            <Typography className={classes.modalContainer}>{t(`shoppingBoss:merchants.viewingMerchants${countryCode == 'CA' ? 'CA' : 'US'}`)}</Typography>
                            <Button variant={"contained"} color={"primary"}
                                onClick={closeModal}
                                type="button"
                                className="btn btn-primary" data-dismiss="modal">{t('shoppingBoss:ok')}
                            </Button>
                        </Dialog>
                        <Grid item container xs={12}>{countrySelector()}</Grid>
                        <Search searchQuery={localStore.searchQuery} onSearchQueryChanged={onSearchQueryUpdated} />
                        <Box className={classes.filters}>
                            {shoppingBossStore.categories.map(c =>
                                <Chip
                                    key={c.id}
                                    icon={localStore.activeFilter?.id == c.id ? <CheckCircleSharpIcon /> : undefined}
                                    clickable
                                    style={{ textTransform: 'capitalize' }}
                                    label={c[preferredLanguage ?? 'en']}
                                    onClick={() => filterByCategory(c)} />)
                            }
                        </Box>
                        <List>
                            {localStore.filteredMerchants.map(merchant => {
                                const isFavorite = isMerchantFavorite(merchant.id);
                                return (
                                    <ListItem
                                        key={merchant.id}
                                        component={Link}
                                        className={classes.merchant}
                                        style={{ cursor: merchant.outofstock ? 'not-allowed' : 'pointer' }}
                                        to="#"
                                        onClick={() => onSelectMerchant(merchant)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                className={classes.avatar}
                                                variant="rounded"
                                                alt={` `}
                                                src={`${merchant.logo}`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={merchant.name} secondary={t('shoppingBoss:merchants.percentBack', { percent: merchant.percent || '0%' })} />
                                        {
                                        !!merchant?.outofstock ?
                                            <ListItemSecondaryAction style={{ maxWidth: '25%' }}>
                                                <Typography style={{ color: 'red', textAlign: 'center', textTransform: 'uppercase' }}>{t('shoppingBoss:merchants.outOfStock')}</Typography>
                                            </ListItemSecondaryAction> :
                                            <ListItemSecondaryAction onClick={() => toggleFavorite(isFavorite, merchant.id)}>
                                                <IconButton edge="end" aria-label="delete">
                                                    {isFavorite ? <StarIcon /> : <StarBorderIcon />}
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                );
                            })
                            }
                        </List>
                    </Box>
                </>}


        </Box>
    );
};

export default observer(Merchants);