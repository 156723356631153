import React, {useEffect, useMemo, useState} from 'react'
import ProductList from '../ProductList/ProductList';
import Pagination from "react-js-pagination";
import LoadingIndicator from '../../../components/shop/LoadingIndicator'
//import { getCookie, deleteCookie } from '../../utils/cookieUtil';
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {useStores} from "../../../hooks/use-stores";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({

  paginationContainer: {

  }
}))


function SubCategoryList({categoryId, subCategoryId}) {
  // state = {
  //   childItems: [],
  //   activePage: 1,
  //   pageNumbers: [],
  //   numberOfPages: 0,
  //   parentCategory: '',
  //   childCategory: '',
  //   showFooter: false
  // }

  const classes = useStyles()

  const [activePage, setActivePage] = useState(1)
  const {commonStore, shopStore} = useStores()
  const {parentCategoryIds, numberOfPages, childItems , productsByChildCategoryIdLoading, customerLevelID, market} = shopStore


  //console.log('subCategoryID', subCategoryId)
  //console.log('categoryId', categoryId)

  useEffect(()=>{
    (async ()=>{
      if ( categoryId &&  subCategoryId )
      await shopStore.getProductsByChildCategoryId({ childCategoryId:subCategoryId
        , pageIndex:activePage
        , customerLevelID, marketID:market
        , language:commonStore.language})
      window.scrollTo(0, 0)
    })()
  },[categoryId, subCategoryId, activePage ])

  const pageNumbers = useMemo( () => {
    const pageNumArray = []
    for (let i = 0; i < numberOfPages; i++) {
      pageNumArray.push(i + 1)
    }
    return pageNumArray
  },[numberOfPages])

  const handlePageChange = (pageNumber) =>{
    setActivePage(pageNumber)
  }

    return (
    <>
      {
        productsByChildCategoryIdLoading ?
        (<LoadingIndicator />) : (
        <Container maxWidth={"md"}>
            <Grid container>
              <ProductList
                category={'category-view-img'}
                subCategoryId={subCategoryId}
                products={childItems}
              />
            </Grid>
          {numberOfPages>0 && <Grid container className={classes.paginationContainer}>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={4}
                totalItemsCount={pageNumbers.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </Grid>}
          </Container>)
      }
    </>
    )

}

export default observer(SubCategoryList);
