import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {isAudio, isBook, isPack, isVideo, isTypeObservable, isVimeo, isVimeoAlbum, isFullDetails} from "../utils/mediaUtils";
import services from "../services";
import {useStores} from "../hooks/use-stores";
import every from "lodash/every";
import {runInAction} from "mobx";
import {joinPath} from "../utils/helpers";
import MediaHandlers from "../stores/MediaHandlers";
import menu from "..//containers/RoyalStarfish/menu.json";
import {prepareBannerItemUrl} from "../utils/menuUtils";



async function checkAccess(authStore, pack, media, prefix, libraryStore ){
    if ( media.sku.startsWith( "eTL0702")){
        if ( libraryStore.checkedPurchased["eTL0702"] === undefined )
            await libraryStore.checkPurchased("eTL0702")

        if ( !libraryStore.checkedPurchased["eTL0702"] ) {
            let { itemUrl,isLoading}  = prepareBannerItemUrl( { url: menu.find(x => x.tag === "extras").items.find(x => x.tag === "insidious").purchaseUrl}, true, authStore)
            return itemUrl
        }
        return null

    }
    if ( media.sendout )
        return null;
    if ( pack && [15343, 18386, 18489, 12174].includes( pack.mediaID)  &&  (authStore.subscriptions.hasSuperAppPlus
        || authStore.subscriptions?.hasLifePremium
        || authStore.subscriptions?.hasSuperApp ) ){
        return null;
    }
    let userprofile = "userprofile";
    if ( prefix === "/starfish" ){
        userprofile =  "profile"
    }
    else if( prefix === ""){
        userprofile = "profile"
    }

    if (  media.sku.startsWith("eSPLTMSFirst")
        || media.sku.startsWith("eLTMSSA")
        || media.sku.startsWith("eMSPSPLTPK")
        || media.sku.startsWith("eLTLIB")
        || media.sku.startsWith("eFRLTLIB") ) {
        if (!(authStore.subscriptions?.hasLTBasic || authStore.subscriptions?.hasLTPremium)) {
            return  "/members/marketing_systems"
        }
    } else if (media.sku.startsWith("eLTMSPPS")) {
        if (!authStore.subscriptions?.hasLTPremium) {
            return  "/members/marketing_systems"
        }
    }
    else if (media.sku.startsWith("eLT")) {
        if( authStore.subscriptions?.hasLTBasic && media.hasPurchased ){
           return null;
        }
        else if (!authStore.subscriptions?.hasLTPremium) {
            return  "/members/marketing_systems"
        }
    }  else if (media.sku.startsWith("eMSP")) {
        if (!authStore.subscriptions?.hasLTPremium) {
            return  "/members/marketing_systems"
        }
    } else if (media.seriesValue === "LT" && media.files && media.files.length > 0 && every(media.files, x => !x.mediaURL)) { //PACK under LT
        if (!authStore.subscriptions?.hasLTPremium) {
            return  "/members/marketing_systems"
        }
    } else if (media.sku.startsWith("eRP") || media.sku.startsWith("eFRRP")) {
        if (!(authStore.subscriptions?.hasSuperAppPlus || authStore.isStarfish))
            return  joinPath( userprofile, "subscriptions" )
    } else if (!authStore.subscriptions?.hasSuperAppPlus) {
        return  joinPath( userprofile,  "subscriptions" )
    }
}

export default observer(({audioPaused, media, currentMediaID, onSelect, onVideoOpen, onVideoAlbumOpen, onOpenBook, onAudioPlay, onAudioPause,  playMatch }) => {

    const history = useHistory();
    const { authStore, libraryStore, seriesStore, packsStore, mediaHandlers } = useStores();
    if( ! onAudioPlay )
        onAudioPlay = mediaHandlers.handleAudioPlay
    //const { freeAudiosForMSBasicSKUs } = seriesStore
    const { pack } = packsStore
    const fetchIdRef = React.useRef(0)

    const fetchMediaDetails = React.useCallback( async (media)=>{
        const fetchId = ++fetchIdRef.current
        try {
            libraryStore.addDetailsLoadingFor( media.mediaID )
            let result = await services.Library.details(media.mediaID)
            if (fetchId === fetchIdRef.current && result && result[0]) {
                handleMedia( result[0] )
            }
        }
        catch(e){
            if (fetchId === fetchIdRef.current) {
            }
        }
        finally{
            runInAction(() => {
                libraryStore.removeDetailsLoadingFor( media.mediaID )
            })
        }
    })



    const handleMedia = async (media)=>{
        if ( media ){
            let prefix ="";
            if ( authStore.subscriptions?.hasSuperAppPlus ){
                prefix = "/royalstarfish"
            } else if (  authStore.isStarfish  ){
                prefix = "/starfish"
            }
            if ( media.sku && media.mediaID ) {
                const redirect = await checkAccess(authStore, pack, media, prefix, libraryStore);
                if ( redirect )
                    return history.push(  redirect.startsWith(prefix)? redirect: joinPath(prefix, redirect))
            }
        }
        if ( onSelect ){
            onSelect(media);
            return
        }

        if ( isPack(media) ){
            history.push( `/library/packs/${media.mediaID}`)
            return;
        }
        else if ( isVimeoAlbum(media) ) {
            onVideoAlbumOpen(media)
        }
        else if ( isVideo(media)|| isVimeo(media) ) {
            onVideoOpen(media)
        }
        else if ( isAudio(media) ){
            if (currentMediaID === media.mediaID && !audioPaused)
                onAudioPause(media);
            else
                onAudioPlay(media);
        }
        else if ( isBook(media) ){
            onOpenBook(media)
        }
        if ( playMatch && media.seriesValue ){
             history.push( `/library/series/` + media.seriesValue)
         }
        else if ( playMatch  ){
             history.push( `/library`)
         }

    }

    const handleMediaChange = async (media)=>{
        if ( !media ) return
        const typeObservable = isTypeObservable ( media)
        const fullDetails = isFullDetails(media)

        if ( typeObservable && fullDetails)
            await handleMedia(media)
        else
            fetchMediaDetails(media)

    }
    useEffect(()=>{
        handleMediaChange(media)
    }, [media])


    return <></>
})


