import {makeAutoObservable, reaction} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateCreteEntity, generateLoadEntity, generateLoadList, generateUpdateEntity} from "../utils/mobx";

class OnboardStore {
    constructor(commonStore, authStore) {
        makeAutoObservable(this)
        this.commonStore  = commonStore
        this.authStore  = authStore
        reaction(
            () => this.commonStore.language,
            language => {
                if (language) {
                    this.loadCards()
                }
            }
        );
    }

    cardsLoading = false
    memberCardsLoading = false
    shoppingBossOnboardingLoading = false
    savingSBData = false
    savingSBDataError = null
    error = null
    cards = []
    memberCards = []
    shoppingBossOnboarding = {}

    setError(error, type) {
        error = extractErrorMessage(error)
        switch (type) {
            case "saveSBData":
                this.savingSBDataError = error
                break;
            default:
                this.error = error
                break;
        }


    }

    get shoppingBossAvailableSubs() {
        return this.shoppingBossOnboarding.availablesubs || []
    }

    get shoppingBossFeatures() {
        let array =  this.shoppingBossOnboarding.features || []
        let res = array.reduce(function(result, value, index, array) {
            if (index % 2 === 0)
                result.push(array.slice(index, index + 2));
            return result;
        }, []);
        return res
    }


    loadCards = generateLoadList( "cards", this, "cardsLoading"
        , async (id)=>{
            return services.Onboard.cards(  this.commonStore.language )

        }, "cards" )

    loadMemberOnboardingCards = generateLoadList( "memberCards", this, "memberCardsLoading"
        , async (id)=>{
            return services.Onboard.memberOnboardingCards(  this.commonStore.language )

        }, "memberCards" )


    loadShoppingBossOnboardingInfo = generateLoadEntity( "shoppingBossOnboardingLoading", this, "shoppingBossOnboardingLoading"
        , async (id)=>{
            return services.Onboard.shoppingBossOnboarding(  this.commonStore.language )

        }, "shoppingBossOnboarding" )


    saveSBData = generateCreteEntity( "saveSBData", this, "savingSBData"
        , async (values)=>{
            await services.ShoppingBoss.User().create( values )
            this.authStore.setSBSetupCompleted()
        } )

}

export default OnboardStore