import plugins from './plugins';
import MobxReactForm from "mobx-react-form";
import {updateAllocationType, updateCardRules} from "./helpers";


const fields = {

    id: {
        label: 'id',
        rules: 'integer',
        type: "number"
    },
    priority: {
        label: 'Priority',
        rules: 'integer|required',
        placeholder: 'Priority',
        type: "number"
    },
    type: {
        label: 'Type',
        rules: 'integer|required',
    },
    value: {
        label: 'Value',
        rules: 'numeric|required',
        placeholder: 'Value',

    }
};



export default function create(hooks, initials ={}) {
    let f = { ...fields };
    if (initials) {
        Object.keys(initials).forEach(k => {
            if (f[k])
                f[k].value = initials[k];
        });
    }

    const form = new MobxReactForm({  fields:f  }, { plugins, hooks });

    form.$('type')
    .observe(({ form, field, change }) => {
        updateAllocationType(form, field.value);
        if ( change.oldValue && change.newValue && change.oldValue !== change.newValue )
            form.$("value").set( "" );
    });
    updateAllocationType(form, initials.value);

    return form;
}

