import services from "../services";
import {extractErrorMessage} from "../utils/helpers";
import {
    generateCreteEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity
} from "../utils/mobx";
import {makeAutoObservable, reaction} from "mobx";
import sumBy from "lodash/sumBy";
import IncomeAllocation from "../services/IncomeAllocation";


class IncomeAllocationStore {
    incomeAllocationsLoading = null;
    incomeAllocations = [];
    incomeAllocationsCreating= false;
    incomeAllocationsUpdating= [];
    incomeAllocationsDeleting= [];
    error = null;
    incomeAllocationsLTLoading = null;
    incomeAllocationsLT = [];
    incomeAllocationsLTCreating= false;
    incomeAllocationsLTUpdating= [];
    incomeAllocationsLTDeleting= [];

    constructor(periodsStore, authStore) {
        makeAutoObservable(this);

        this.periodsStore = periodsStore;
        this.authStore = authStore;


    }

    setError(error, type = "periods") {
        error = extractErrorMessage(error);
        this.error = error;
    }

    loadIncomeAllocation = generateLoadList(  "incomeAllocations",
        this,
        "incomeAllocationsLoading",
        () => {
            return services.IncomeAllocation.list(  );
        },
        "incomeAllocations")

    createAllocation =  generateCreteEntity( "incomeAllocations", this, "incomeAllocationsCreating",
        (data) => {
            return services.IncomeAllocation.create( data );
    })
    updateAllocation =  generateUpdateEntity( "incomeAllocations", this, "incomeAllocationsUpdating",
        async (id, data) => {
            await services.IncomeAllocation.update( data, id );
            const existing = this.incomeAllocations.find(x=>x.id === id);
            if(existing){
                Object.assign(existing, data);
            }

        })

    deleteAllocation =  generateDeleteEntity( "incomeAllocations", this, "incomeAllocationsDeleting",
        async (id) => {
            await services.IncomeAllocation.delete( id );
            const existing = this.incomeAllocations.find(x=>x.id === id);
            if(existing){
                this.incomeAllocations.remove(existing);
            }

        })
     
    loadIncomeAllocationLT = generateLoadList(  "incomeAllocationsLT",
        this,
        "incomeAllocationsLTLoading",
        () => {
            return services.IncomeAllocation.listLT();
        },
        "incomeAllocationsLT")   

    createAllocationLT =  generateCreteEntity( "incomeAllocationsLT", this, "incomeAllocationsLTCreating",
        (data) => {
            return services.IncomeAllocation.createLT( data );
    })
    updateAllocationLT =  generateUpdateEntity( "incomeAllocationsLT", this, "incomeAllocationsLTUpdating",
        async (id, data) => {
            await services.IncomeAllocation.updateLT( data, id );
            const existing = this.incomeAllocationsLT.find(x=>x.id === id);
            if(existing){
                Object.assign(existing, data);
            }

        })
    deleteAllocationLT =  generateDeleteEntity( "incomeAllocationsLT", this, "incomeAllocationsLTDeleting",
        async (id) => {
            await services.IncomeAllocation.deleteLT( id );
            const existing = this.incomeAllocationsLT.find(x=>x.id === id);
            if(existing){
                this.incomeAllocationsLT.remove(existing);
            }

        })

}


export default IncomeAllocationStore