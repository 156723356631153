import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {TableRow} from "@material-ui/core";


const StyledHeadTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
}))(TableRow);

export default StyledHeadTableRow