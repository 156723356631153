import {makeAutoObservable} from 'mobx'
import services from "../services"
import {extractErrorMessage} from "../utils/helpers";
import {generateLoadEntity} from "../utils/mobx";
import get from "lodash/get";

class StatStore {
    constructor() {
        makeAutoObservable(this)
    }

    statistics = null
    statisticsLoading = false
    error = null


    get eWalletBalance() {
        return get(this.statistics, "wallet.eWallet.balance") || 0
    }

    get giftCardsStats() {
        return get(this.statistics, "wallet.giftCards")
    }

    get userBusinessStats() {
        return get(this.statistics, "wallet.userBusinessStats")
    }

    get giftCardsPVStats() {
        return get(this.statistics, "wallet.giftCardsPVStats")
    }

    get eWalletMaximumAllowed() {
        return get(this.statistics, "wallet.eWallet.maximumAllowed")  || 0
    }

    get eWalletCanAdd() {
        return Math.max(this.eWalletMaximumAllowed - this.eWalletBalance, 0)
    }

    setError(error, type = "statistics") {
        error = extractErrorMessage(error)
        this.error = error
     }


    loadStats = generateLoadEntity( "statistics", this, "statisticsLoading"
        , ()=>{ return services.UserProfile.Wallet().stats() }, "statistics" )


}

export default StatStore