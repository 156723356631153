import * as React from 'react'
import { observer } from 'mobx-react-lite'
import TextField from '@material-ui/core/TextField'
import {useTranslation} from "react-i18next";

export default observer(({
  field, translationPrefix,
    ...rest
}) => {
    const {t} = useTranslation(translationPrefix);

    const translatedError = field.error &&  t( field.error.replace(/\./g, "_") ).replace(/_/g, ".")

    let inputProps = { ...rest.inputProps, ...( (field.extra && field.extra.inputProps)  || {}) }
    return <div>
        <TextField error={!!field.error} helperText={translatedError} fullWidth
                   {...field.bind()}
                   label={t(field.label)}
                   placeholder={t(field.placeholder)}
                   inputProps={inputProps}
                   {...rest}
        />
    </div>
}
)