import RestService from "./RestService";
import stores from '../stores'


export default class Branding extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/api/shoppingcart/parentcategory`)
        }
        else
            super("/api/shoppingcart/parentcategory")
    }

    async getProductsByCategoryId(categoryId, access_token, lang){
        stores.authStore.setToken(access_token)
        return this.get( `${this.baseUrl}/${categoryId}/${lang}`)
    }

}
