import React from 'react';
import { Container } from 'react-bootstrap';
import { makeStyles, } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import {
    Grid,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { ArrowForward, } from "@material-ui/icons";
import { useStores } from "../../../hooks/use-stores";

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center'
    },
    learnMore: {
        marginTop: '32px',
    },
    icons: {
        gap: theme.spacing(2),
        flexBasis: "initial"
    },
    avatar: {
        width: 75,
        height: 75
    },
}));

const LearnAboutACHByCountry = () => {
    const classes = useStyles();
    const { t } = useTranslation("wallet");
    const { authStore, } = useStores();
    const { currentUser } = authStore;
    const {
        country: userCountry
    } = currentUser;

    return (
        <Container>
            <h1 className={classes.title}>{t('wallet:add_money.eTransfer_title')}</h1>
            <Grid container item xs={12} alignItems={"center"} justifyContent={"center"} className={classes.icons}>
                <Avatar className={classes.avatar}
                    src="https://resources.lifeinfoapp.com/public/icon/wallet/ewallet.png" variant="rounded" />
                <ArrowForward />
                <Avatar className={classes.avatar}
                    src="https://resources.lifeinfoapp.com/public/icon/wallet/giftcards.png" variant="rounded" />
            </Grid>
            {
                userCountry && userCountry === 'CA'
                    ?
                    <>
                        <div className={classes.learnMore} dangerouslySetInnerHTML={{ __html: t('add_money.eTransferLearnMore') }} />
                    </>
                    :
                    <>
                        <div className={classes.learnMore} dangerouslySetInnerHTML={{ __html: t('add_money.achAustralia_LearnMore') }} />

                    </>
            }

        </Container >
    );
};

export default LearnAboutACHByCountry;