import { makeAutoObservable } from "mobx";
import services from "../services";
import { extractErrorMessage } from "../utils/helpers";
import { generateLoadEntity } from "../utils/mobx";

class PVTransferStore {
  pvTransferInfo = null;
  pvTransferInfoLoading = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError(error, type = "pvTransferError") {
    error = extractErrorMessage(error);
    this.error = error;
  }

  transferPV = generateLoadEntity(
    "pvTransferInfo",
    this,
    "pvTransferInfoLoading",
    (params) => {
      return services.PVTransferService.create(params);
    },
    "pvTransferInfo"
  );
}

export default PVTransferStore;
