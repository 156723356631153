import * as React from 'react'
import {observer} from 'mobx-react-lite'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


export default observer(({
                             field,
                             className,
                             InputLabelProps,
                             margin,
                             options,
                             labelAccessor,
                             valueAccessor,
                             fullWidth,
                             ...rest
                         }) => {
    valueAccessor = valueAccessor || ((x)=>x)
    labelAccessor = labelAccessor || ((x)=>x)

    return (
        <>
        <InputLabel error ={field.error} {...InputLabelProps} >{field.label}</InputLabel>
        <FormControl fullWidth={fullWidth} margin={margin} className={className} >

            <RadioGroup name={field.name}   {...field.bind()} row >
                {options.map(x=>{
                    return <FormControlLabel key={valueAccessor(x)} value={valueAccessor(x)} control={<Radio />} label={labelAccessor(x)} />
                })}
            </RadioGroup>
            <FormHelperText  error ={field.error} >
                { (field.error || rest.helperText )  }
            </FormHelperText>
        </FormControl>
        </>
    )
})