import stores from '../stores';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { version } from '../../package.json';

const API_ROOT = import.meta.env.REACT_APP_API_URL;
const appId = uuid();
const appVersion = version;
const SHOPPING_BOSS_VERSION = '5.4';

class BaseService {
    handleErrors(err) {
        if (err && err.response && err.response.status === 401) {
            stores.authStore.logout(401);
        }
        return err;
    }


    commonHeaders() {

        let headers = {
            'X-App-Request-Id': `${appId}`,
            'X-App-Version': `${appVersion}`,
            'X-Version': SHOPPING_BOSS_VERSION

        };

        if (stores.authStore.token || stores.authStore.guestToken)
            headers['Authorization'] = `Bearer ${stores.authStore.token || stores.authStore.guestToken}`;
        return { headers };

    }


    async del(url, body) {
        try {
            let response = await axios.delete(url, { baseURL: API_ROOT, ...this.commonHeaders(), data: body });
            return response.data;
        }
        catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled)
                throw notHandled;
        }
    }

    async get(url, params) {
        try {
            let response = await axios.get(url, {
                params,
                baseURL: API_ROOT, ...this.commonHeaders()
            });
            return response.data;
        }
        catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled)
                throw notHandled;
        }
    }

    async put(url, body, options) {
        try {
            let config = { ...this.commonHeaders(), ...options };
            if (!url.startsWith("http://") && !url.startsWith("https://"))
                config.baseURL = API_ROOT;
            let response = await axios.put(url, body, config);
            return response.data;
        }
        catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled)
                throw notHandled;
        }
    }
    async patch(url, body, options) {
        try {
            let config = { ...this.commonHeaders(), ...options };
            if (!url.startsWith("http://") && !url.startsWith("https://"))
                config.baseURL = API_ROOT;
            let response = await axios.patch(url, body, config);
            return response.data;
        }
        catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled)
                throw notHandled;
        }
    }

    async post(url, body, options) {
        try {
            let config = { ...this.commonHeaders(), ...options };
            if ( ! ( url.startsWith("http://")||url.startsWith("https://")) )
                config.baseURL=API_ROOT
            let response = await axios.post(url, body, config);
            return response.data;
        }
        catch (e) {
            let notHandled = this.handleErrors(e);
            if (notHandled)
                throw notHandled;
        }
    }
}

export default BaseService;