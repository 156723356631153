
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Box, Container, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import WithdrawButton from '../../../components/Wallet/WithdrawButton';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '18px',
        textAlign: 'center',
        margin: '14px 0px',
        fontWeight: 'bold'
    },
    successIcon: {
        color: 'rgb(5, 73, 255)',
        fontSize: 200,
        textAlign: 'center',
        width: '100%'
    },
    notifyText: {
        textAlign: 'center',
        fontSize: '18px'
    },
    depositInfo: {
        textAlign: 'center',
        margin: `18px 0px`
    }
}));

const BTCWithdrawalSuccess = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const goToWalletHome = () => {
        history.push('/wallet');
    }

    useEffect(() => {
        if (!props.btcDepositAddress || props.btcDepositAddress == null) {
            history.push('/wallet/withdraw/home')
        }
    }, []);

    if (!props.btcDepositAddress || props.btcDepositAddress == null) {
        return null;
    }

    return (
        <Container>
            {
                loading ? <CircularProgress /> :
                    <Box>
                        <Typography
                            variant="h2"
                            className={classes.title}
                        >
                            {t('wallet:withdrawal.success.congratulations')}
                        </Typography>
                        <Box>
                            <CheckCircleIcon className={classes.successIcon} />
                        </Box>
                        <Typography className={classes.notifyText}>
                            {t('wallet:withdrawal.success.requestSuccess')}
                        </Typography>
                        <Typography className={classes.depositInfo} style={{ fontWeight: 'bold' }}>
                            {props.withdrawAmountAfterFee} Sats
                        </Typography>
                        <Typography className={classes.notifyText}>
                            {t('wallet:withdrawal.success.notify')}
                        </Typography>
                        <Typography className={classes.depositInfo}>
                            {props.btcDepositAddress}
                        </Typography>
                        <WithdrawButton onClick={goToWalletHome}>OK</WithdrawButton>
                    </Box>
            }
        </Container>
    )
}

export default BTCWithdrawalSuccess;